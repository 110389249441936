<template>
  <section>
    <span @click="openCPTermsModal" title="CP Terms">
      <i class="fa fa-external-link-square" aria-hidden="true"></i>
    </span>

    <div :id="`myCPTermsModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">CP Terms</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6">Cargo</div>
                    <div class="col-md-6">
                      <span class="myBold">{{
                        voyageCargo.cargo_name.full_name
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">Charterer</div>
                    <div class="col-md-6">
                      <span class="myBold">{{
                        voyageCargo.charterer
                          ? voyageCargo.charterer.short_name
                          : ""
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">CP Qty</div>
                    <div class="col-md-6">
                      <span class="myBold"
                        >{{ voyageCargo.qty }}
                        {{
                          voyageCargo.unit ? voyageCargo.unit.unit : ""
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">CP Date</div>
                    <div class="col-md-6">
                      <span class="myBold">{{ voyageCargo.cp_date }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Option %</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        type="number"
                        class="form-control"
                        placeholder="Option %"
                        v-model="voyageCargo.option_percent"
                        @change="onVoyageCargoUpdated"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Min/Max Qty</div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            disabled
                            v-uppercase
                            type="number"
                            class="form-control"
                            :placeholder="'Min. Qty'"
                            v-model="voyageCargo.min_qty"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            disabled
                            v-uppercase
                            type="number"
                            class="form-control"
                            :placeholder="'Max. Qty'"
                            v-model="voyageCargo.max_qty"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3">Frt Type</div>
                    <div class="col-md-9">
                      <my-select
                        maxWidth="YES"
                        disableFormControl="true"
                        :selection.sync="voyageCargo.freight_type_id"
                        :suggestions="freightTypes"
                        placeholder="Enter freight type"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Frt Rate</div>
                    <div class="col-md-9">
                      <span class="myBold">{{ voyageCargo.freight_rate }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Comm %</div>
                    <div class="col-md-9">
                      <span class="myBold"
                        >{{ voyageCargo.freight_commission }}%</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Company</div>
                    <div class="col-md-9">
                      <my-select
                        disableFormControl="true"
                        :selection.sync="voyageCargo.company_id"
                        :suggestions="addresses"
                        placeholder="Enter company"
                      ></my-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3">Laycan From</div>
                    <div class="col-md-9">
                      <span class="myBold">{{ voyageCargo.laycan_from }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Laycan To</div>
                    <div class="col-md-9">
                      <span class="myBold">{{ voyageCargo.laycan_to }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Demurrage</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        type="number"
                        placeholder="Rate"
                        v-model="voyageCargo.demurrage_amount"
                        @change="onDemurrageChanged"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Despatch</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        type="number"
                        placeholder="Rate"
                        v-model="voyageCargo.despatch_amount"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Per Day/Hr</div>
                    <div class="col-md-9">
                      <span class="myBold">
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="voyageCargo.per_day_per_hr"
                          :suggestions="perDays"
                          placeholder="Select perDay/Hr"
                        ></my-select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <h5><u>Brokers</u></h5>
              <div class="editable-table-wrapper">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Broker</th>
                      <th>Rate</th>
                      <th>Type</th>
                      <th>Payment Method</th>
                      <th>Claim</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(voyageBroker, vb) in form.voyage_brokers"
                      :key="`voyageBroker${vb}`"
                    >
                      <td @click="deleteVoyageBroker(voyageBroker)">
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                        {{ vb + 1 }}.
                      </td>
                      <td>
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="voyageBroker.broker_id"
                          :suggestions="addresses"
                          placeholder="Broker"
                        ></my-select>
                      </td>
                      <td>
                        <input
                          v-uppercase
                          type="number"
                          placeholder="Rate"
                          v-model="voyageBroker.rate"
                        />
                      </td>
                      <td>
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="voyageBroker.rate_type"
                          :suggestions="brokerRateTypes"
                          placeholder="Rate Type"
                        ></my-select>
                      </td>
                      <td>
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="voyageBroker.payment_method"
                          :suggestions="brokerFreightPaymentMethods"
                          placeholder="Payment Method"
                        ></my-select>
                      </td>
                      <td>
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="voyageBroker.is_claim_commissionable"
                          :suggestions="brokerCommissionables"
                          placeholder="Claim"
                        ></my-select>
                      </td>
                      <td>
                        <input
                          v-uppercase
                          type="text"
                          placeholder="Remarks"
                          v-model="voyageBroker.remarks"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="9"
                        align="center"
                        @click="addEmptyVoyageBroker()"
                      >
                        Add New Row
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveCpTerms">
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeCpTermsModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "cpTermsModal",
  data() {
    return {};
  },
  components: {},
  props: [
    "form",
    "count",
    "voyageCargo",
    "cargoNames",
    "perDays",
    "freightTypes",
    "addresses",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerCommissionables",
    "brokerFreightPaymentMethods"
  ],
  methods: {
    async openCPTermsModal() {
      var modal = document.getElementById("myCPTermsModal" + this.count);
      modal.style.display = "block";
    },
    closeCpTermsModal() {
      var modal = document.getElementById("myCPTermsModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyVoyageBroker() {
      this.form.voyage_brokers.push({
        broker_id: "",
        rate: 0,
        rate_type: "%",
        payment_method: "Deduct from hire",
        is_claim_commissionable:
          "Claim comissions will be deducted from claim invoice",
        remarks: "",
      });
    },
    deleteVoyageBroker(voyageBroker) {
      this.form.voyage_brokers.splice(
        this.form.voyage_brokers.indexOf(voyageBroker),
        1
      );
    },
    saveCpTerms() {
      this.onVoyageCargoUpdated();
      this.$emit("calculateCost");
      this.closeCpTermsModal();
    },
    onVoyageCargoUpdated() {
      this.$emit("onVoyageCargoUpdated");
    },
    onDemurrageChanged() {
      if (this.voyageCargo.demurrage_amount) {
        this.voyageCargo.despatch_amount =
          parseFloat(this.voyageCargo.demurrage_amount) / 2;
      }
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>