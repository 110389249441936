<template>
  <span>
    <span @click="openPortExpensesModal" title="Port Expenses">
      &nbsp;<i class="fa fa-money" aria-hidden="true"></i>
    </span>

    <div :id="`myPortExpensesModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Port Expenses Summary</h4>
          <!-- <PortExpenseCreate
            :count="count"
            :form="form"
            :voyageItenary="voyageItenary"
            :agents="agents"
          ></PortExpenseCreate> -->
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Prev. Port :</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ previousPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Curr. Port</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ currentPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Destination:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ destinationPort }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Function:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ portFunction }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Arrival:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ arrivalTime }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Departure:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ departureTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Vendor</th>
                <th>Adv. Date</th>
                <th>Adv. Invoice No.</th>
                <th>Adv. Am.</th>
                <th>Adv. Am. Base</th>
                <th>Adv. Curr.</th>
                <th>Date</th>
                <th>Invoice no.</th>
                <th>FDA Am.</th>
                <th>FDA Base Am.</th>
                <th>FDA Curr.</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  voyageItenaryPortExpense, vipe
                ) in voyageItenary.voyage_itenary_port_expenses"
                :key="`voyageItenaryPortExpense${vipe}`"
              >
                <td @click="deletePortExpense(voyageItenaryPortExpense)">
                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                  {{ vipe + 1 }}.
                </td>
                <td>
                  <my-select
                    maxWidth="YES"
                    disableFormControl="true"
                    :selection.sync="voyageItenaryPortExpense.vendor_id"
                    :suggestions="agents"
                    placeholder="Select agent"
                  ></my-select>
                </td>
                <td>
                  <my-datepicker
                    disableFormControl="true"
                    :date.sync="voyageItenaryPortExpense.advance_date"
                    placeholder="Advance Date"
                    myWidth="200px"
                  ></my-datepicker>
                </td>
                <td>
                  <input
                    style="width: 200px !important"
                    v-uppercase
                    type="text"
                    placeholder="Adv. Invoice no"
                    v-model="voyageItenaryPortExpense.advance_invoice_no"
                  />
                </td>
                <td>
                  <input
                    :style="
                      voyageItenaryPortExpense.advance_amount != ''
                        ? 'color: blue;'
                        : ''
                    "
                    v-uppercase
                    type="text"
                    placeholder="Adv. Amount"
                    v-model="voyageItenaryPortExpense.advance_amount"
                  />
                </td>

                <td>
                  <input
                    :style="
                      voyageItenaryPortExpense.advance_base_amount != ''
                        ? 'color: blue;'
                        : ''
                    "
                    v-uppercase
                    type="text"
                    placeholder="Adv. Base Amount"
                    v-model="voyageItenaryPortExpense.advance_base_amount"
                  />
                </td>
                <td>
                  <my-select
                    maxWidth="YES"
                    disableFormControl="true"
                    :selection.sync="
                      voyageItenaryPortExpense.advance_currency_id
                    "
                    :suggestions="currencies"
                    placeholder="Adv. currency"
                  ></my-select>
                </td>
                <td>
                  <my-datepicker
                    disableFormControl="true"
                    :date.sync="voyageItenaryPortExpense.date"
                    placeholder="Date"
                    myWidth="200px"
                  ></my-datepicker>
                </td>
                <td>
                  <input
                    v-uppercase
                    type="text"
                    placeholder="Invoice no"
                    v-model="voyageItenaryPortExpense.invoice_no"
                  />
                </td>
                <td>
                  <input
                    :style="
                      voyageItenaryPortExpense.fda_amount != ''
                        ? 'color: blue;'
                        : ''
                    "
                    v-uppercase
                    type="text"
                    placeholder="FDA Amount"
                    v-model="voyageItenaryPortExpense.fda_amount"
                  />
                </td>
                <td>
                  <input
                    :style="
                      voyageItenaryPortExpense.fda_base_amount != ''
                        ? 'color: blue;'
                        : ''
                    "
                    v-uppercase
                    type="text"
                    placeholder="FDA Base Amount"
                    v-model="voyageItenaryPortExpense.fda_base_amount"
                  />
                </td>
                <td>
                  <my-select
                    maxWidth="YES"
                    disableFormControl="true"
                    :selection.sync="voyageItenaryPortExpense.fda_currency_id"
                    :suggestions="currencies"
                    placeholder="FDA currency"
                  ></my-select>
                </td>
              </tr>
              <tr>
                <td colspan="12" align="center" @click="addEmptyPortExpense()">
                  Add New Row
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveMyPortExpenses"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyPortExpensesModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script type="text/javascript">
// import PortExpenseCreate from "./create.vue";

export default {
  data() {
    return {
      previousPort: "",
      currentPort: "",
      destinationPort: "",
      portFunction: "",
      arrivalTime: "",
      departureTime: "",
    };
  },
  components: {
    // PortExpenseCreate,
  },
  props: [
    "voyageItenary",
    "voyageItenaries",
    "count",
    "form",
    "agents",
    "currencies",
  ],
  watch: {
    voyageItenary: {
      handler(value) {
        this.onVogayeItenaryChanged(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.onVogayeItenaryChanged(this.voyageItenary);
  },
  methods: {
    async openPortExpensesModal() {
      var modal = document.getElementById("myPortExpensesModal" + this.count);
      modal.style.display = "block";
    },
    closeMyPortExpensesModal() {
      var modal = document.getElementById("myPortExpensesModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyPortExpense() {
      let usdCurrency = this.currencies.find((curr) => curr.name == "USD");
      this.voyageItenary.voyage_itenary_port_expenses.push({
        vendor_id: "",
        advance_date: "",
        advance_amount: 0,
        advance_invoice_no: "",
        advance_base_amount: 0,
        advance_currency_id: usdCurrency.id,
        date: "",
        invoice_no: "",
        fda_amount: 0,
        fda_base_amount: 0,
        fda_currency_id: usdCurrency.id,
      });
    },
    deletePortExpense(portExpense) {
      this.voyageItenary.voyage_itenary_port_expenses.splice(
        this.voyageItenary.voyage_itenary_port_expenses.indexOf(portExpense),
        1
      );
    },
    onVogayeItenaryChanged(value) {
      if (value.position != 1)
        this.previousPort = this.voyageItenaries[parseInt(value.position) - 2]
          .port_name
          ? (this.previousPort =
              this.voyageItenaries[
                parseInt(value.position) - 2
              ].port_name.port_name)
          : "";
      this.currentPort = value.port_name ? value.port_name.port_name : "";
      this.destinationPort = this.voyageItenaries[
        this.voyageItenaries.length - 1
      ].port_name
        ? this.voyageItenaries[this.voyageItenaries.length - 1].port_name
            .port_name
        : "";
      this.portFunction = value.port_function
        ? value.port_function.port_operation
        : "";
      this.arrivalTime = value.arrival_date;
      this.departureTime = value.departure_date;
    },
    saveMyPortExpenses() {
      this.$emit("onPortExpensesChanged");
      this.closeMyPortExpensesModal();
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>