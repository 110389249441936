<template>
    <TcCommission></TcCommission>
</template>

<script>
import TcCommission from '../../tc-ins/commissions/tc-commission.vue'
export default {
    components : {
        TcCommission
    }
}
</script>