<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Claim Commission
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/commissions/claim-commission"
                >Claim Commission</router-link
              >
            </li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="col-md-12">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Broker</th>
                              <th>Type</th>
                              <th>Invoice No</th>
                              <th>Date</th>
                              <th>Curr</th>
                              <th>Amount</th>
                              <th>St</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "freightCommission",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/class_societies", this.form);
        this.isLoading = false;
        this.$router.push("/estimates");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
