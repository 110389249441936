<template>
  <section>
    <h5>Bunker</h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Description</th>
            <th
              v-for="(fuelType, fT) in this.fuelTypes"
              :key="`fuelType${fT}`"
            >
              {{ fuelType.fuel_type }} QTY | Price
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(tcInBunker, tcb) in form.tc_in_bunkers"
            :key="`tcInBunker${tcb}`"
          >
            <td>{{ tcb + 1 }}.</td>
            <td>{{ tcInBunker.description }}</td>
            <td
              v-for="(
                tcInBunkerDetail, tibd
              ) in tcInBunker.tc_in_bunker_details"
              :key="`tcInBunkerDetail${tibd}`"
              style="border: 1px solid black; padding: 10px;"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        :style="
                          tcInBunkerDetail.qty != '' ? 'color: blue;' : ''
                        "
                        v-uppercase
                        placeholder="Qty"
                        v-model="tcInBunkerDetail.qty"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        :style="
                          tcInBunkerDetail.rate != '' ? 'color: blue;' : ''
                        "
                        v-uppercase
                        placeholder="Rate"
                        v-model="tcInBunkerDetail.rate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- <td>
              <input
                v-uppercase
                type="number"
                placeholder="LSF Qty"
                v-model="lsf_qty"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="LSF Price"
                v-model="lsf_price"
              />
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: ["form", "fuelTypes"],
  watch: {
    form: "addInitialBunkers",
    fuelTypes: {
      handler() {
        this.addInitialBunkers();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    addInitialBunkers() {
      let descriptions = [
        "Pre-purchased By Operator",
        "Est Delivery",
        "Actual Delivery",
        "Delivery Difference",
        "Supplement to Delivery",
        "Est Redelivery",
        "Paid by TCI Owner",
        "For TCI Owner's Account",
        "Actual Redelivery",
        "Redelivery Difference",
      ];
      descriptions.forEach((description) => {
        let tcInBunk = this.form.tc_in_bunkers
          ? this.form.tc_in_bunkers.find(
              (tib) => tib.description == description
            )
          : null;
        if (!tcInBunk) {
          let tcInBunker = {
            description: description,
            tc_in_bunker_details: [],
          };
          if (this.fuelTypes)
            this.fuelTypes
              // .filter(
              //   (fT) =>
              //     fT.fuel_type != "HFO" &&
              //     fT.fuel_type != "HSFO" &&
              //     fT.fuel_type != "IFO" &&
              //     fT.fuel_type != "MDO"
              // )
              .forEach((fuelType) => {
                if (tcInBunker.tc_in_bunker_details)
                  tcInBunker.tc_in_bunker_details.push({
                    fuel_type_id: fuelType.id,
                    qty: "",
                    rate: "",
                  });
              });

          if (this.form.tc_in_bunkers) this.form.tc_in_bunkers.push(tcInBunker);
        }
      });
    },
  },
};
</script>