<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/financial/statement/charts-of-accounts"
            ></back-button>
            Create a new Charts Of Accounts
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/financial/statement/charts-of-accounts"
                >Charts Of Accounts</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Code</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Code"
                                v-model="form.code"
                              />
                              <span class="error-block">{{ errors.code }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Account Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Account Name"
                                v-model="form.account_name"
                              />
                              <span class="error-block">{{
                                errors.account_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Category</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Category"
                                v-model="form.category"
                              />
                              <span class="error-block">{{
                                errors.category
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Sub Category</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Sub Category"
                                v-model="form.subcategory"
                              />
                              <span class="error-block">{{
                                errors.subcategory
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Lob</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_lob"
                                placeholder="Is Lob"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_lob
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Ves</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_ves"
                                placeholder="Is Ves"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_ves
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Voyage</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_voyage"
                                placeholder="Is Voyage"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_voyage
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Ic</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_port"
                                placeholder="Is Port"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_port
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Dept</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_dept"
                                placeholder="Is Dept"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_dept
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Is Ic</label
                            >
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_ic"
                                placeholder="Is Ic"
                              ></my-checkbox>

                              <span class="error-block">{{
                                errors.is_ic
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "chartsOfAccountCreate",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        let chartsOfAccount = await axios.post(`/charts_of_accounts`, this.form);
        this.form = chartsOfAccount.data.data;
        this.isLoading = false;
        this.$router.push("/financial/statement/charts-of-accounts");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
