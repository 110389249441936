<template>
  <section>
    <h5><u>Bunkers</u></h5>
    <div class="tab-struct custom-tab-1">
      <ul role="tablist" class="nav nav-tabs" id="myTabs_7">
        <li class="active" role="presentation">
          <a
            aria-expanded="true"
            data-toggle="tab"
            role="tab"
            id="bunker_tab_1"
            href="#bunker_1"
            >Bunkers</a
          >
        </li>
        <li role="presentation">
          <a
            aria-expanded="true"
            data-toggle="tab"
            role="tab"
            id="bunker_tab_2"
            href="#bunker_2"
            >Master</a
          >
        </li>
        <!-- <li role="presentation">
          <a aria-expanded="true" data-toggle="tab" role="tab" id="bunker_tab_3" href="#bunker_3">Settings</a>
        </li> -->
      </ul>
      <div class="tab-content" id="`myTabContent_7`">
        <!-- Bunkers -->
        <div class="tab-pane fade active in" role="tabpanel" id="bunker_1">
          <div class="tab-struct custom-tab-2">
            <ul role="tablist" class="nav nav-tabs" id="myTabs_8">
              <li
                :class="bm == 0 ? 'active' : ''"
                role="presentation"
                v-for="(bunkerMaster, bm) in bunkersMaster"
                :key="`bunkerMaster${bm}`"
              >
                <a
                  aria-expanded="true"
                  data-toggle="tab"
                  role="tab"
                  id="fuel_tab_1"
                  :href="`#fuel_${bm}`"
                  >{{ bunkerMaster.fuel_name }}</a
                >
              </li>
            </ul>
            <div class="tab-content" id="`myTabContent_8`">
              <div
                :class="
                  bm == 0 ? 'tab-pane fade in active' : 'tab-pane fade in'
                "
                role="tabpanel"
                v-for="(bunkerMaster, bm) in bunkersMaster"
                :key="`bunkerMaster${bm}`"
                :id="`fuel_${bm}`"
              >
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Initial Qty</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          class="form-control"
                          placeholder="Inital Quantity"
                          v-model="bunkerMaster.initialQuantity"
                          @blur="updateBunkers"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-sm-3 control-label"
                        >Initial Price</label
                      >
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          class="form-control"
                          placeholder="Initial Price"
                          v-model="bunkerMaster.initialPrice"
                          @blur="updateBunkers"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Consumed Qty</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          class="form-control"
                          placeholder="Consumed Qty"
                          disabled
                          v-model="bunkerMaster.totalConsumption"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="editable-table-wrapper">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Port Name</th>
                        <th>Function</th>
                        <th>Sea Cons.</th>
                        <th>ROB Arr.</th>
                        <th>Port Cons.</th>
                        <th>Receive</th>
                        <th>Price</th>
                        <th>ROB Dpt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(bunker, b) in bunkerMaster.bunkers"
                        :key="`bunker${b}`"
                        :style="
                          bunker.sea_consumption < 0 ||
                          bunker.rob_arr < 0 ||
                          bunker.port_consumption < 0
                            ? 'background: lightpink;'
                            : ''
                        "
                      >
                        <td>{{ bunker.port_name }}</td>
                        <td>{{ bunker.port_function }}</td>
                        <td :title="bunker.sea_consumption">
                          {{ bunker.sea_consumption | round2 }}
                        </td>
                        <td :title="bunker.rob_arr">
                          {{ bunker.rob_arr | round2 }}
                        </td>
                        <td :title="bunker.port_consumption">
                          {{ bunker.port_consumption | round2 }}
                        </td>
                        <td>
                          <input
                            v-uppercase
                            type="number"
                            placeholder="Receive"
                            v-model="bunker.receive"
                            @blur="updateBunkers"
                          />
                        </td>
                        <td>
                          <input
                            v-uppercase
                            type="number"
                            placeholder="Price"
                            v-model="bunker.price"
                            @blur="updateBunkers"
                          />
                        </td>
                        <td>{{ bunker.rob_dpt }}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td :title="bunkerMaster.totalSeaConsumption">
                          <h5>
                            {{ bunkerMaster.totalSeaConsumption | round2 }}
                          </h5>
                        </td>
                        <td></td>
                        <td :title="bunkerMaster.totalPortConsumption">
                          <h5>
                            {{ bunkerMaster.totalPortConsumption | round2 }}
                          </h5>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Master -->
        <div class="tab-pane fade in" role="tabpanel" id="bunker_2">
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Ballast Speed</th>
                  <th>Laden Speed</th>
                  <th>Port Loading</th>
                  <th>Port Discharging</th>
                  <th>Port Idle</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(bunkerMaster, ar) in bunkersMaster"
                  :key="`bunkerMaster${ar}`"
                >
                  <td>{{ bunkerMaster.fuel_name }}</td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Price"
                      v-model="bunkerMaster.price"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Sea Ballast Consumption"
                      v-model="bunkerMaster.sea_ballast_consumption"
                      @change="updateBunkers"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Sea Laden Consumption"
                      v-model="bunkerMaster.sea_laden_consumption"
                      @change="updateBunkers"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Port Loading Consumption"
                      v-model="bunkerMaster.port_loading_consumption"
                      @change="updateBunkers"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Port Discharge Consumption"
                      v-model="bunkerMaster.port_discharge_consumption"
                      @change="updateBunkers"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Port Idle Consumption"
                      v-model="bunkerMaster.port_idle_consumption"
                      @change="updateBunkers"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Settings -->
        <!-- <div class="tab-pane fade in" role="tabpanel" id="bunker_3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-sm-3 control-label">Initial Qty</label>
                <div class="col-sm-9">
                  <input v-uppercase type="number" class="form-control" placeholder="Inital Quantity" v-model="bunkerSettings.initialQuantity">
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-sm-3 control-label">Initial Price</label>
                <div class="col-sm-9">
                  <input v-uppercase type="number" class="form-control" placeholder="Initial Price" v-model="bunkerSettings.initialPrice">
                </div>
              </div>
            </div>
          </div>

        </div> -->
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: [
    "selectedVessel",
    "bunkers",
    "bunkersMaster",
    "bunkerSettings",
    "tcIn",
  ],
  watch: {
    selectedVessel: "updateBunkersMaster",
    tcIn: "updateBunkersMaster",
  },
  methods: {
    updateBunkersMaster() {
      if (!this.selectedVessel) return;
      let ballast_vessel_speed_consumptions =
        this.selectedVessel.vessel_speed_consumptions.find(
          (vsc) =>
            vsc.speed == this.selectedVessel.ballast_speed &&
            vsc.b_l == "Ballast"
        );
      let laden_vessel_speed_consumptions =
        this.selectedVessel.vessel_speed_consumptions.find(
          (vsc) =>
            vsc.speed == this.selectedVessel.laden_speed && vsc.b_l == "Laden"
        );
      // console.log("Laden Speed");
      // console.log(this.selectedVessel.laden_speed);
      // console.log(this.selectedVessel.vessel_speed_consumptions);
      // console.log(laden_vessel_speed_consumptions);
      this.selectedVessel.vessel_in_port_consumptions.forEach(
        (vessel_in_port_consumption) => {
          // Ballast and laden vessel speed consumption for a specific fuel type
          let ballast_vessel_speed_consumption = 0;
          if (ballast_vessel_speed_consumptions)
            ballast_vessel_speed_consumption =
              ballast_vessel_speed_consumptions.vessel_speed_consumption_fuels.find(
                (vscf) =>
                  vscf.fuel_type_id == vessel_in_port_consumption.fuel_type_id
              );
          let laden_vessel_speed_consumption = 0;
          if (laden_vessel_speed_consumptions)
            laden_vessel_speed_consumption =
              laden_vessel_speed_consumptions.vessel_speed_consumption_fuels.find(
                (vscf) =>
                  vscf.fuel_type_id == vessel_in_port_consumption.fuel_type_id
              );

          let bunkerMaster =
            this.bunkersMaster.length > 0
              ? this.bunkersMaster.find(
                  (bm) =>
                    bm.fuel_type_id == vessel_in_port_consumption.fuel_type.id
                )
              : null;
          if (bunkerMaster == null) {
            let initialQuantity = 0;
            let initialPrice = 0;
            let tcInBunker;
            console.log('Inside Bunker Master')
            if (
              vessel_in_port_consumption &&
              vessel_in_port_consumption.fuel_type &&
              this.tcIn.tc_in_bunkers
            ) {
              tcInBunker = this.tcIn.tc_in_bunkers.find(
                (tib) => tib.description == "Est Delivery"
              );
              console.log(tcInBunker);
              if (tcInBunker && tcInBunker.tc_in_bunker_details.length) {
                let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                  (tibd) =>
                    tibd.fuel_type_id == vessel_in_port_consumption.fuel_type.id
                );
                if (tcInBunkerDetail) {
                  initialQuantity = tcInBunkerDetail.qty;
                  initialPrice = tcInBunkerDetail.rate;
                }
              }
            }
            this.bunkersMaster.push({
              id: 0,
              initialQuantity: initialQuantity,
              initialPrice: initialPrice,
              fuel_type_id: vessel_in_port_consumption
                ? vessel_in_port_consumption.fuel_type.id
                : "",
              fuel_name: vessel_in_port_consumption
                ? vessel_in_port_consumption.fuel_type.fuel_type
                : "",
              price: 0,
              sea_ballast_consumption: ballast_vessel_speed_consumption
                ? ballast_vessel_speed_consumption.value
                : 0,
              sea_laden_consumption: laden_vessel_speed_consumption
                ? laden_vessel_speed_consumption.value
                : 0,
              port_loading_consumption: vessel_in_port_consumption
                ? vessel_in_port_consumption.loading
                : 0,
              port_discharge_consumption: vessel_in_port_consumption
                ? vessel_in_port_consumption.discharge
                : 0,
              port_idle_consumption: vessel_in_port_consumption
                ? vessel_in_port_consumption.idle_on
                : 0,
              totalSeaConsumption: 0,
              totalPortConsumption: 0,
              totalConsumption: 0,
              bunkers: [],
            });
          } else {
            // bunkerMaster.fuel_type_id = vessel_in_port_consumption
            //   ? vessel_in_port_consumption.fuel_type.id
            //   : "";
            // bunkerMaster.fuel_name = vessel_in_port_consumption
            //   ? vessel_in_port_consumption.fuel_type.fuel_type
            //   : "";
            // bunkerMaster.sea_ballast_consumption =
            //   ballast_vessel_speed_consumption
            //     ? ballast_vessel_speed_consumption.value
            //     : 0;
            // bunkerMaster.sea_laden_consumption = laden_vessel_speed_consumption
            //   ? laden_vessel_speed_consumption.value
            //   : 0;
            // bunkerMaster.port_loading_consumption = vessel_in_port_consumption
            //   ? vessel_in_port_consumption.loading
            //   : 0;
            // bunkerMaster.port_discharge_consumption = vessel_in_port_consumption
            //   ? vessel_in_port_consumption.discharge
            //   : 0;
            // bunkerMaster.port_idle_consumption = vessel_in_port_consumption
            //   ? vessel_in_port_consumption.idle_on
            //   : 0;
          }
        }
      );
    },
    updateBunkers() {
      this.$emit("updateBunkers");
    },
  },
};
</script>