import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

require('@/store/subscriber')

require('./mixin.js')
require('./axios.js')
require('./directives.js')
require('./components.js')
require('./filters.js')

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VCalendar from 'v-calendar';
Vue.use(VCalendar)

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

store.dispatch('auth/attempt', localStorage.getItem('token'))
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })






