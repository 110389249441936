<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Laytime Calculation AR Claim</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/laytime/calculation-ar-claim"
                >Laytime Calculation AR Claim</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Charterer</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="OPG"
                                v-model="charterer"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice No.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="invoice"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel</label>
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="JAL VAIBHAV"
                                v-model="vessel"
                                disabled
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="JALVAI"
                                v-model="vessel"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage No./Opr Type</label
                            >
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="1010"
                                v-model="voyage_no"
                                disabled
                              />
                            </div>
                            <div class="col-sm-6">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="TCOV"
                                v-model="opr_type"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >CP From</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="cp_from"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >CP Date</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="07/02/20"
                                v-model="cp_date"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Status</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Under Review"
                                v-model="under_review"
                              />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Analyst</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="analyst"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <h5>Itenary</h5> -->
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <!-- <th>Sr. No.</th> -->
                              <th>Port Name</th>
                              <th>Cargo</th>
                              <th>F</th>
                              <th>Quantity</th>
                              <th>L/D Rate</th>
                              <th>Terms</th>
                              <th>Dem Rate/D</th>
                              <th>Des Rate/D</th>
                              <th>Allowed</th>
                              <th>Used</th>
                              <th>Deduction</th>
                              <th>Balance</th>
                              <th>Laycan From</th>
                              <th>Laycan To</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="TABONEO"
                                  v-model="port_name"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="COAL"
                                  v-model="coal"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="L"
                                  v-model="f"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="82,400.000"
                                  v-model="quantity"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="12,000.00"
                                  v-model="l_d_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="SHINC"
                                  v-model="terms"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="6,500.00"
                                  v-model="dem_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="3,250.00"
                                  v-model="des_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="06d20:48"
                                  v-model="allowed"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="used"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="deduction"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="balance"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="laycan_from"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  placeholder="2020-02-20T00:00:00"
                                  v-model="laycan_to"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="TABONEO"
                                  v-model="port_name"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="COAL"
                                  v-model="coal"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="L"
                                  v-model="f"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="82,400.000"
                                  v-model="quantity"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="12,000.00"
                                  v-model="l_d_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="SHINC"
                                  v-model="terms"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="6,500.00"
                                  v-model="dem_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="3,250.00"
                                  v-model="des_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="06d20:48"
                                  v-model="allowed"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="used"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="deduction"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="balance"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="00d00:00"
                                  v-model="laycan_from"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  placeholder="2020-02-20T00:00:00"
                                  v-model="laycan_to"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Total</label>
                            <div class="col-sm-2">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="10d03:54"
                                v-model="t1"
                                disabled
                              />
                            </div>
                            <div class="col-sm-2">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="10d03:54"
                                v-model="t2"
                                disabled
                              />
                            </div>
                            <div class="col-sm-2">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="10d03:54"
                                v-model="t3"
                                disabled
                              />
                            </div>
                            <div class="col-sm-2">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="10d03:54"
                                v-model="t4"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Tabs-->
                      <div class="row">
                        <div class="col-md-8">
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                id="Taboneo-tab"
                                data-toggle="tab"
                                href="#Taboneo"
                                role="tab"
                                aria-controls="Taboneo"
                                aria-selected="true"
                                >Taboneo</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="Ennore-tab"
                                data-toggle="tab"
                                href="#Ennore"
                                role="tab"
                                aria-controls="Ennore"
                                aria-selected="false"
                                >Ennore</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="Allport-tab"
                                data-toggle="tab"
                                href="#Allport"
                                role="tab"
                                aria-controls="Allport"
                                aria-selected="false"
                                >Allport</a
                              >
                            </li>
                          </ul>
                          <div class="tab-content" id="myTabContent">
                            <div
                              class="tab-pane fade show active"
                              id="Taboneo"
                              role="tabpanel"
                              aria-labelledby="Taboneo-tab"
                            >
                              <div class="row">
                                <div class="col-md-3">
                                  <button class="btn btn-primary">
                                    Port Activity
                                  </button>
                                </div>
                                <div class="col-md-3">
                                  Select Lines to
                                  <button class="btn btn-primary">
                                    Delete
                                  </button>
                                </div>
                              </div>

                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Date/Time</th>
                                    <th>Activity</th>
                                    <th>Remarks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      align="center"
                                      @click="addEmptyVesselSpeedConsumption()"
                                    >
                                      Add New Row
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="5"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <h5>Deductions</h5>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Activity</th>
                                    <th>From Date/Time</th>
                                    <th>Duration</th>
                                    <th>%</th>
                                    <th>To Date/Time</th>
                                    <th>Remarks</th>
                                    <th>Deduction</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>

                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="duration"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder="100.00"
                                        v-model="Percent"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="deduction"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="8"
                                      align="center"
                                      @click="addEmptyVesselSpeedConsumption()"
                                    >
                                      Add New Row
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="8"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="row">
                                <div class="col-md-6">
                                  <button class="btn btn-primary">
                                    Reset Percentage
                                  </button>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Total Deduction</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="total_deduction"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Date
                                      </label>
                                    </div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Time</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Used</label
                                      >
                                    </div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Allowed</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocated Used</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_used"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Laytime Commenced</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="laytime_commenced"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Counterparty Qty</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="laytime_commenced"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="laytime_commenced"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocated Allowed</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Laytime Completed</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="laytime_completed"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Total Qty</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="total_qty1"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="total_qty2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Minimum Allowed</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <div class="col-sm-9">
                                      <my-checkbox
                                        :selection.sync="
                                          form.is_consecutive_voyage
                                        "
                                        placeholder="Allow Allocation Percent to Rate"
                                      ></my-checkbox>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocation Percent</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="allocation_percent1"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="allocation_percent2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Grace Time</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Dispatch Time</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              id="Ennore"
                              role="tabpanel"
                              aria-labelledby="Ennore-tab"
                            >
                              <div class="row">
                                <div class="col-md-3">
                                  <button class="btn btn-primary">
                                    Port Activity
                                  </button>
                                </div>
                                <div class="col-md-3">
                                  Select Lines to
                                  <button class="btn btn-primary">
                                    Delete
                                  </button>
                                </div>
                              </div>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Date/Time</th>
                                    <th>Activity</th>
                                    <th>Remarks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      align="center"
                                      @click="addEmptyVesselSpeedConsumption()"
                                    >
                                      Add New Row
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="5"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <h5>Deductions</h5>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Activity</th>
                                    <th>From Date/Time</th>
                                    <th>Duration</th>
                                    <th>%</th>
                                    <th>To Date/Time</th>
                                    <th>Remarks</th>
                                    <th>Deduction</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>

                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="duration"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder="100.00"
                                        v-model="Percent"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="deduction"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="8"
                                      align="center"
                                      @click="addEmptyVesselSpeedConsumption()"
                                    >
                                      Add New Row
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="8"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="row">
                                <div class="col-md-6">
                                  <button class="btn btn-primary">
                                    Reset Percentage
                                  </button>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Total Deduction</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="total_deduction"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Date
                                      </label>
                                    </div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Time</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Used</label
                                      >
                                    </div>
                                    <div class="col-md-4">
                                      <label class="col-sm-3 control-label"
                                        >Allowed</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocated Used</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_used"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Laytime Commenced</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="laytime_commenced"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Counterparty Qty</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="laytime_commenced"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="laytime_commenced"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocated Allowed</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        disabled
                                        class="form-control"
                                        placeholder=""
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Laytime Completed</label
                                    >
                                    <div class="col-sm-9">
                                      <input
                                        v-uppercase
                                        type="text"
                                        class="form-control"
                                        placeholder=""
                                        v-model="laytime_completed"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Total Qty</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="total_qty1"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="total_qty2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Minimum Allowed</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <div class="col-sm-9">
                                      <my-checkbox
                                        :selection.sync="
                                          form.is_consecutive_voyage
                                        "
                                        placeholder="Allow Allocation Percent to Rate"
                                      ></my-checkbox>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Allocation Percent</label
                                    >
                                    <div class="col-md-9">
                                      <div class="row">
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="allocation_percent1"
                                          />
                                        </div>
                                        <div class="col-md-6">
                                          <input
                                            v-uppercase
                                            type="number"
                                            class="form-control"
                                            placeholder=""
                                            v-model="allocation_percent2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Grace Time</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label class="col-sm-3 control-label"
                                      >Dispatch Time</label
                                    >
                                    <div class="col-md-9">
                                      <input
                                        v-uppercase
                                        type="number"
                                        class="form-control"
                                        placeholder="00d00:00"
                                        v-model="allocated_allowed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              id="Allport"
                              role="tabpanel"
                              aria-labelledby="Allport-tab"
                            >
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Date/Time</th>
                                    <th>Activity</th>
                                    <th>Remarks</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <h5>Deductions</h5>
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Sr. No.</th>
                                    <th>Days</th>
                                    <th>Activity</th>
                                    <th>From Date/Time</th>
                                    <th>Duration</th>
                                    <th>%</th>
                                    <th>To Date/Time</th>
                                    <th>Remarks</th>
                                    <th>Deduction</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(
                                      vesselSpeedConsumption, ar
                                    ) in form.vessel_speed_consumptions"
                                    :key="`contact${ar}`"
                                  >
                                    <td
                                      @click="
                                        deleteVesselSpeedConsumption(
                                          vesselSpeedConsumption
                                        )
                                      "
                                    >
                                      <i
                                        class="fa fa-times-circle-o"
                                        aria-hidden="true"
                                      ></i>
                                      {{ ar + 1 }}.
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="days"
                                      />
                                    </td>

                                    <td>
                                      <my-select
                                        disableFormControl="true"
                                        :selection.sync="
                                          vesselSpeedConsumption.activity
                                        "
                                        :suggestions="Activity"
                                        placeholder="Select Activity"
                                      ></my-select>
                                    </td>
                                    <td>
                                      <my-datepicker
                                        :date.sync="form.date_time"
                                        placeholder="Enter Date/time"
                                      ></my-datepicker>
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="duration"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder="100.00"
                                        v-model="Percent"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        placeholder=""
                                        v-model="remarks"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        v-uppercase
                                        type="text"
                                        disabled
                                        placeholder=""
                                        v-model="deduction"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="8"
                                      align="center"
                                      @click="addEmptyVesselSpeedConsumption()"
                                    >
                                      Add New Row
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="8"></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-9">
                                  <button class="btn btn-info">
                                    Import Activities
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-4 control-label"
                                  >Calculation</label
                                >
                                <div class="col-sm-8">
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="Standard"
                                    v-model="form.add_1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-9">
                                  <my-checkbox
                                    :selection.sync="form.demurrage"
                                    placeholder="Show Demurrage Strat"
                                  ></my-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-8">
                                  <my-checkbox
                                    :selection.sync="form.pl"
                                    placeholder="Include In P&L"
                                  ></my-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-9">
                                  <my-checkbox
                                    :selection.sync="form.laytime"
                                    placeholder="Continuous Laytime"
                                  ></my-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-8">
                                  <my-checkbox
                                    :selection.sync="form.format"
                                    placeholder="HH:MM Format"
                                  ></my-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-sm-9">
                                  <my-checkbox
                                    :selection.sync="form.laytime"
                                    placeholder="Once On/Always On Dem"
                                  ></my-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Net Used Time</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="Exact"
                                  v-model="form.used"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Net Used Time</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="Exact"
                                  v-model="form.used"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Allowed Days</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Used Days</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Balance Days</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Original Claim Amount</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Curr/Exch Rate</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Amount Paid To Owner</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Despatch Amount</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Despatch Amount</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Agreed Demurrage</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Agreed Despatch</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Difference</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="10.0000"
                                  v-model="form.used"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Difference Reason</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="Difference Reason"
                                  v-model="form.used"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Calculation Remarks</label
                              >

                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="Calculation Remarks"
                                  v-model="form.used"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <errors :data="errors" />

                      <!-- <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions =
        this.form.vessel_in_port_consumptions.filter(
          (ar) => ar != vesselInPortConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions =
        this.form.vessel_speed_consumptions.filter(
          (ar) => ar != vesselSpeedConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>