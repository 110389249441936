<template>
  <span>
    <span @click="openPortCargosModal" title="Port Cargos">
      &nbsp;<i class="fa fa-archive" aria-hidden="true"></i>
    </span>

    <div :id="`myPortCargosModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Port Cargos Summary</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Prev. Port :</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ previousPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Curr. Port</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ currentPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Destination:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ destinationPort }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Function:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ portFunction }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Arrival:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ arrivalTime }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Departure:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ departureTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Grade</th>
                <th>BL Code</th>
                <th>BL/Discharge Date</th>
                <th>BL/Discharge Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  voyageItenaryCargo, vic
                ) in voyageItenary.voyage_itenary_cargos"
                :key="`voyageItenaryCargo${vic}`"
              >
                <td @click="deletePortCargo(voyageItenaryCargo)">
                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                  {{ vic + 1 }}.
                </td>
                <td>
                  <my-select
                    maxWidth="YES"
                    disableFormControl="true"
                    :selection.sync="voyageItenaryCargo.cargo_name_id"
                    :suggestions="cargoNames"
                    placeholder="Select cargo"
                  ></my-select>
                </td>
                <td>
                  <input
                    style="width: 200px !important"
                    v-uppercase
                    type="text"
                    placeholder="BL Code"
                    v-model="voyageItenaryCargo.bl_code"
                  />
                </td>
                <td>
                  <my-datepicker
                    disableFormControl="true"
                    :date.sync="voyageItenaryCargo.bl_discharge_date"
                    placeholder="BL/Disc. Code"
                    myWidth="200px"
                  ></my-datepicker>
                </td>
                <td>
                  <input
                    style="width: 200px !important"
                    v-uppercase
                    type="text"
                    placeholder="BL/Disc. Qty"
                    v-model="voyageItenaryCargo.bl_discharge_qty"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="12" align="center" @click="addEmptyPortCargo()">
                  Add New Row
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveMyPortCargos"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyPortCargosModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      previousPort: "",
      currentPort: "",
      destinationPort: "",
      portFunction: "",
      arrivalTime: "",
      departureTime: "",
    };
  },
  components: {},
  props: ["voyageItenary", "voyageItenaries", "count", "form", "cargoNames"],
  watch: {
    voyageItenary: {
      handler(value) {
        this.onVogayeItenaryChanged(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.onVogayeItenaryChanged(this.voyageItenary);
  },
  methods: {
    async openPortCargosModal() {
      var modal = document.getElementById("myPortCargosModal" + this.count);
      modal.style.display = "block";
    },
    closeMyPortCargosModal() {
      var modal = document.getElementById("myPortCargosModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyPortCargo() {
      let cargoNameId = "";
      this.form.voyage_cargos.forEach((voyageCargo) => {
        cargoNameId = voyageCargo.cargo_name_id;
      });
      this.voyageItenary.voyage_itenary_cargos.push({
        cargo_name_id: cargoNameId,
        bl_code: "",
        bl_discharge_date: "",
        bl_discharge_qty: 0,
      });
    },
    deletePortCargo(portExpense) {
      this.voyageItenary.voyage_itenary_port_expenses.splice(
        this.voyageItenary.voyage_itenary_port_expenses.indexOf(portExpense),
        1
      );
    },
    saveMyPortCargos() {
      this.$emit("onPortCargosChanged");
      this.closeMyPortCargosModal();
    },
    onVogayeItenaryChanged(value) {
      if (value.position != 1)
        this.previousPort = this.voyageItenaries[parseInt(value.position) - 2]
          .port_name
          ? this.voyageItenaries[parseInt(value.position) - 2].port_name
              .port_name
          : "";
      this.currentPort = value.port_name ? value.port_name.port_name : "";
      this.destinationPort = this.voyageItenaries[
        this.voyageItenaries.length - 1
      ].port_name
        ? this.voyageItenaries[this.voyageItenaries.length - 1].port_name
            .port_name
        : "";
      this.portFunction = value.port_function
        ? value.port_function.port_operation
        : "";
      this.arrivalTime = value.arrival_date;
      this.departureTime = value.departure_date;
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>
