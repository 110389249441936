<template>
  <section>
    <div class="col-md-4">
      <div class="panel panel-default card-view pandl">
        <div class="panel-wrapper collapse in">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-wrap">
                  <table
                    class="table table-hover table-striped"
                    style="height: 400px"
                  >
                    <caption>
                      P&L
                    </caption>
                    <thead>
                      <tr>
                        <th>All</th>
                        <th class="right">Posted</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        this.form.tc_out_id == null || this.form.tc_out_id == ''
                      "
                    >
                      <tr>
                        <td>
                          <b><u>REVENUES</u></b>
                        </td>
                      </tr>
                      <tr v-if="freightCost != 0">
                        <td>Freight</td>
                        <td class="right">{{ freightCost | round2 }}</td>
                      </tr>
                      <tr v-if="freightCommission != 0">
                        <td>Freight Comm.</td>
                        <td class="right">-{{ freightCommission | round2 }}</td>
                      </tr>
                      <tr v-if="demurrage">
                        <td>Demurrage</td>
                        <td class="right">{{ demurrage | round2 }}</td>
                      </tr>
                      <tr v-if="demurrage">
                        <td>Demurrage Commission</td>
                        <td class="right">
                          {{ demurrageCommission | round2 }}
                        </td>
                      </tr>
                      <tr v-if="despatch">
                        <td>Despatch</td>
                        <td class="right">-{{ despatch | round2 }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b><u>Total Revenues</u></b>
                        </td>
                        <td class="right">{{ totalRevenue | round2 }}</td>
                      </tr>
                    </tbody>

                    <tbody style="height: 100px" v-else>
                      <tr>
                        <td colspan="2">
                          <b><u>REVENUES</u></b>
                        </td>
                      </tr>
                      <tr v-if="OhireCost != 0">
                        <td>TCO Hire</td>
                        <td class="right">{{ OhireCost | round2 }}</td>
                      </tr>
                      <tr v-if="OhireAddComm != 0">
                        <td>TCO Hire Comm.</td>
                        <td class="right">-{{ OhireAddComm | round2 }}</td>
                      </tr>
                      <tr v-if="Obunkers != 0">
                        <td>TCO Bunkers</td>
                        <td class="right">{{ Obunkers | round2 }}</td>
                      </tr>
                      <tr v-if="OtciBunkerAdjustment != 0">
                        <td>TCO Bunker Adjustment</td>
                        <td class="right">
                          {{ OtciBunkerAdjustment | round2 }}
                        </td>
                      </tr>
                      <tr v-if="OoffHireBunkers != 0">
                        <td>TCO Off Hire Bunkers</td>
                        <td class="right">-{{ OoffHireBunkers | round2 }}</td>
                      </tr>
                      <tr v-if="Ocve != 0">
                        <td>TCO CVE</td>
                        <td class="right">{{ Ocve | round2 }}</td>
                      </tr>
                      <tr v-if="OoffHire != 0">
                        <td>TCO Off Hire</td>
                        <td class="right">-{{ OoffHire | round2 }}</td>
                      </tr>
                      <tr v-if="OoffHireAddComm != 0">
                        <td>TCO Off Hire Comm.</td>
                        <td class="right">{{ OoffHireAddComm | round2 }}</td>
                      </tr>
                      <tr v-if="OoffHireCve != 0">
                        <td>TCO Off Hire CVE</td>
                        <td class="right">-{{ OoffHireCve | round2 }}</td>
                      </tr>
                      <tr v-if="OholdCleaning != 0">
                        <td>TCO Hold Cleaning</td>
                        <td class="right">{{ OholdCleaning | round2 }}</td>
                      </tr>
                      <tr v-if="OtciMiscExpenses != 0">
                        <td>TCO Misc. Revenues</td>
                        <td class="right">{{ OtciMiscExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="OportExpenses != 0">
                        <td>Port Expenses</td>
                        <td class="right">{{ OportExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="OmiscExpenses != 0">
                        <td><u>Misc. Expenses</u></td>
                        <td class="right">{{ OmiscExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="totalRevenue != 0">
                        <td>
                          <b><u>Total Revenue</u></b>
                        </td>
                        <td class="right">{{ totalRevenue | round2 }}</td>
                      </tr>
                    </tbody>

                    <br />

                    <tbody style="height: 100px">
                      <tr>
                        <td colspan="2">
                          <b><u>EXPENSES</u></b>
                        </td>
                      </tr>
                      <tr v-if="hireCost != 0">
                        <td>Hire</td>
                        <td class="right">{{ hireCost | round2 }}</td>
                      </tr>
                      <tr v-if="hireAddComm != 0">
                        <td>Hire Comm.</td>
                        <td class="right">-{{ hireAddComm | round2 }}</td>
                      </tr>
                      <tr v-if="bunkers != 0">
                        <td>Bunkers</td>
                        <td class="right">{{ bunkers | round2 }}</td>
                      </tr>
                      <tr v-if="tciBunkerAdjustment != 0">
                        <td>TCI Bunker Adjustment</td>
                        <td class="right">
                          {{ tciBunkerAdjustment | round2 }}
                        </td>
                      </tr>
                      <tr v-if="offHireBunkers != 0">
                        <td>Off Hire Bunkers</td>
                        <td class="right">-{{ offHireBunkers | round2 }}</td>
                      </tr>
                      <tr v-if="cve != 0">
                        <td>CVE</td>
                        <td class="right">{{ cve | round2 }}</td>
                      </tr>
                      <tr v-if="offHire != 0">
                        <td>Off Hire</td>
                        <td class="right">-{{ offHire | round2 }}</td>
                      </tr>
                      <tr v-if="offHireAddComm != 0">
                        <td>Off Hire Comm.</td>
                        <td class="right">{{ offHireAddComm | round2 }}</td>
                      </tr>
                      <tr v-if="offHireCve != 0">
                        <td>Off Hire CVE</td>
                        <td class="right">-{{ offHireCve | round2 }}</td>
                      </tr>
                      <tr v-if="holdCleaning != 0">
                        <td>Hold Cleaning</td>
                        <td class="right">{{ holdCleaning | round2 }}</td>
                      </tr>
                      <tr v-if="tciMiscExpenses != 0">
                        <td>TCI Misc. Expenses</td>
                        <td class="right">{{ tciMiscExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="portExpenses != 0">
                        <td>Port Expenses</td>
                        <td class="right">{{ portExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="miscExpenses != 0">
                        <td><u>Misc. Expenses</u></td>
                        <td class="right">{{ miscExpenses | round2 }}</td>
                      </tr>
                      <tr v-if="totalExpense != 0">
                        <td>
                          <b><u>Total Expenses</u></b>
                        </td>
                        <td class="right">{{ totalExpense | round2 }}</td>
                      </tr>
                    </tbody>

                    <br />

                    <tbody>
                      <tr v-if="voyageResult != 0">
                        <td>Voyage Result</td>
                        <td class="right">{{ voyageResult | round2 }}</td>
                      </tr>
                      <tr v-if="voyageResult != 0">
                        <td>Profit/Loss</td>
                        <td class="right">
                          <h5>{{ voyageResult | round2 }}</h5>
                        </td>
                      </tr>
                      <tr v-if="totalVoyageDays != 0">
                        <td>Total Voyage Days</td>
                        <td class="right">{{ totalVoyageDays | round2 }}</td>
                      </tr>
                      <tr v-if="totalOffhireDays != 0">
                        <td>Offire Days</td>
                        <td class="right">{{ totalOffhireDays | round2 }}</td>
                      </tr>
                      <tr v-if="netVoyageDays != 0">
                        <td>Net Voyage Days</td>
                        <td class="right">{{ netVoyageDays | round2 }}</td>
                      </tr>
                      <tr v-if="totalSeaDays != 0">
                        <td>Sea Days</td>
                        <td class="right">{{ totalSeaDays | round2 }}</td>
                      </tr>
                      <tr v-if="totalPortDays != 0">
                        <td>Port Days</td>
                        <td class="right">{{ totalPortDays | round2 }}</td>
                      </tr>
                    </tbody>

                    <br />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import moment from "moment";
export default {
  data() {
    return {
      freightCost: 0,
      freightCommission: 0,
      demurrage: 0,
      demurrageCommission: 0,
      despatch: 0,

      OhireCost: 0,
      OhireAddComm: 0,
      Obunkers: 0,
      OtciBunkerAdjustment: 0,
      OoffHireBunkers: 0,
      Ocve: 0,
      OoffHire: 0,
      OoffHireAddComm: 0,
      OoffHireCve: 0,
      OholdCleaning: 0,
      OtciMiscExpenses: 0,
      OtciMiscAdjustment: 0,
      OportExpenses: 0,
      OmiscExpenses: 0,
      OtotalVoyageDays: 0,
      OtotalOffhireDays: 0,
      OnetVoyageDays: 0,
      OtotalSeaDays: 0,
      OtotalPortDays: 0,

      hireCost: 0,
      hireAddComm: 0,
      bunkers: 0,
      tciBunkerAdjustment: 0,
      offHireBunkers: 0,
      cve: 0,
      offHire: 0,
      offHireAddComm: 0,
      offHireCve: 0,
      holdCleaning: 0,
      tciMiscExpenses: 0,
      tciMiscAdjustment: 0,
      portExpenses: 0,
      miscExpenses: 0,
      totalVoyageDays: 0,
      totalOffhireDays: 0,
      netVoyageDays: 0,
      totalSeaDays: 0,
      totalPortDays: 0,

      fuelConsumption: 0,
      pandl: {
        expense: {
          hire: {
            actual: {
              amount: 0,
              description: "",
            },
          },
        },
      },
    };
  },
  props: ["form", "fuelTypes"],
  watch: {
    form: {
      handler() {
        this.updatePandL();
      },
      deep: true,
    },
  },
  computed: {
    totalRevenue() {
      if (this.form.tc_out_id == null || this.form.tc_out_id == "") {
        return (
          this.freightCost +
          this.freightCommission +
          this.demurrage +
          this.demurrageCommission +
          this.despatch
        );
      } else {
        return (
          parseFloat(this.OhireCost) +
          parseFloat(this.OhireAddComm) +
          parseFloat(this.Obunkers) +
          parseFloat(this.OtciBunkerAdjustment) +
          parseFloat(this.OoffHireBunkers) +
          parseFloat(this.Ocve) +
          parseFloat(this.OoffHire) +
          parseFloat(this.OoffHireAddComm) +
          parseFloat(this.OoffHireCve) +
          parseFloat(this.OholdCleaning) +
          parseFloat(this.OtciMiscExpenses) +
          parseFloat(this.OtciMiscAdjustment) +
          parseFloat(this.OportExpenses) +
          parseFloat(this.OmiscExpenses)
        );
      }
    },
    totalExpense() {
      return (
        parseFloat(this.hireCost) +
        parseFloat(this.hireAddComm) +
        parseFloat(this.bunkers) +
        parseFloat(this.tciBunkerAdjustment) +
        parseFloat(this.offHireBunkers) +
        parseFloat(this.cve) +
        parseFloat(this.offHire) +
        parseFloat(this.offHireAddComm) +
        parseFloat(this.offHireCve) +
        parseFloat(this.holdCleaning) +
        parseFloat(this.tciMiscExpenses) +
        parseFloat(this.tciMiscAdjustment) +
        parseFloat(this.portExpenses) +
        parseFloat(this.miscExpenses)
      );
    },
    voyageResult() {
      return this.totalRevenue - this.totalExpense;
    },
  },
  methods: {
    updatePandL() {
      // Freight Cost
      let freightCost = 0;
      this.form.voyage_freight_invoices.forEach((voyageFreightInvoice) => {
        freightCost =
          parseFloat(freightCost) +
          parseFloat(voyageFreightInvoice.invoice_amount ?? 0);
      });
      this.freightCost = freightCost;
      // Freight Comission
      let freightCommission = 0;
      this.form.voyage_brokers.forEach((voyageBroker) => {
        freightCommission =
          parseFloat(freightCommission) +
          (parseFloat(voyageBroker.rate) * parseFloat(freightCost)) / 100;
      });
      this.freightCommission = -freightCommission;
      // Demurrage and Despatch
      let demurrage = 0;
      let despatch = 0;
      let voyageLaytimes = [];
      this.form.voyage_laytimes.forEach((voyageLaytime) => {
        let vL = voyageLaytimes.find(
          (l) => l && l.position == voyageLaytime.position
        );
        if (!vL) {
          voyageLaytimes.push(voyageLaytime);
        }
      });
      console.log(voyageLaytimes);
      voyageLaytimes.forEach((voyageLaytime) => {
        if (voyageLaytime.include_in_p_and_l) {
          if (voyageLaytime.total_demurrage_discharge == "Demurrage") {
            demurrage =
              parseFloat(demurrage) + parseFloat(voyageLaytime.total_amount);
          } else if (voyageLaytime.total_demurrage_discharge == "Despatch") {
            despatch =
              parseFloat(despatch) + parseFloat(voyageLaytime.total_amount);
          }
        }
      });
      this.demurrage = -demurrage;
      this.despatch = -despatch;
      // Demurrage Comission
      let demurrageCommission = 0;
      this.form.voyage_brokers.forEach((voyageBroker) => {
        demurrageCommission =
          parseFloat(demurrageCommission) +
          (parseFloat(voyageBroker.rate) * parseFloat(demurrage)) / 100;
      });
      this.demurrageCommission = demurrageCommission;

      // TC INs
      let hireCost = 0;
      let hireAddComm = 0;
      let bunkers = 0;
      let cve = 0;
      let offHire = 0;
      let offHireBunkers = 0;
      let offHireAddComm = 0;
      let offHireCve = 0;
      let holdCleaning = 0;
      let tciMiscExpenses = 0;
      let tciMiscAdjustment = 0;
      let fuelConsumptionPrice = 0;
      let tciBunkerAdjustmentPrice = 0;

      if (this.form.tc_in) {
        // let tcInPayments = this.form.tc_in.tc_in_payments.filter(
        //   (tip) => tip.payment_type == "Increemental"
        // );
        // if (tcInPayments.length == 0)
        //   tcInPayments = this.form.tc_in.tc_in_payments;
        // else {
        //   tcInPayments = [
        //     this.form.tc_in.tc_in_payments[
        //       this.form.tc_in.tc_in_payments.length - 1
        //     ],
        //   ];
        // }

        let tcInPayments = [];
        let checkIfIncreementalReached = 0;
        let reversePayments = [...this.form.tc_in.tc_in_payments].reverse();
        reversePayments.forEach((tcInPayment) => {
          // If the increemental invoice is reached and it is included in pandl
          if (
            checkIfIncreementalReached == 0 &&
            tcInPayment.include_in_pand_l == 1
          ) {
            tcInPayments.push(tcInPayment);
            if (tcInPayment.payment_type == "Increemental")
              checkIfIncreementalReached = 1;
          }
        });
        console.log("Reverse Payments");
        console.log(tcInPayments);

        tcInPayments.forEach((tcInPayment) => {
          tcInPayment.tc_in_payment_details.forEach((tcInPaymentdetail) => {
            // alert(tcInPaymentdetail.voyage_no)
            // alert(this.form.voyage_no)
            if (tcInPaymentdetail.voyage_no == this.form.voyage_no) {
              // Hire
              if (tcInPaymentdetail.code == "TCH") {
                hireCost =
                  parseFloat(hireCost) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
                // this.pandl.expense.actual.description =
                //   tcInPaymentdetail.description + " " + tcInPaymentdetail.remarks;
                // this.pandl.expense.actual.amount = hireCost;
              }

              // Hire Add Comm.
              if (tcInPaymentdetail.code == "HAC")
                hireAddComm =
                  parseFloat(hireAddComm) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              // Graduities
              if (tcInPaymentdetail.code == "GAP")
                cve =
                  parseFloat(cve) + parseFloat(tcInPaymentdetail.amount ?? 0);
              // TCI Bunkers Adjustment
              // this.fuelTypes.forEach((fuelType) => {
              //   if (tcInPaymentdetail.code == fuelType.code + "DL") {
              //     bunkers =
              //       parseFloat(bunkers) +
              //       parseFloat(tcInPaymentdetail.amount ?? 0);
              //   }
              //   if (tcInPaymentdetail.code == fuelType.code + "RDL") {
              //     bunkers =
              //       parseFloat(bunkers) +
              //       parseFloat(tcInPaymentdetail.amount ?? 0);
              //   }
              // });

              // Offhire Bunkers
              this.fuelTypes.forEach((fuelType) => {
                if (
                  tcInPaymentdetail.code == "O" + fuelType.code ||
                  tcInPaymentdetail.code == "OR" + fuelType.code
                ) {
                  offHireBunkers =
                    parseFloat(offHireBunkers) +
                    parseFloat(tcInPaymentdetail.amount ?? 0);
                }
              });
              // Off Hire
              if (tcInPaymentdetail.code == "OFFH") {
                offHire =
                  parseFloat(offHire) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              }

              if (tcInPaymentdetail.code == "OFFHR") {
                offHire =
                  parseFloat(offHire) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              }
              // Off Hire Add. Comm.
              if (tcInPaymentdetail.code == "OHAC")
                offHireAddComm =
                  parseFloat(offHireAddComm) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              if (tcInPaymentdetail.code == "OHRAC")
                offHireAddComm =
                  parseFloat(offHireAddComm) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);

              // Off Hire Graduities
              if (tcInPaymentdetail.code == "OGAP")
                offHireCve =
                  parseFloat(offHireCve) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              if (tcInPaymentdetail.code == "OHRAC")
                offHireCve =
                  parseFloat(offHireCve) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);

              // holdCleaning
              if (
                tcInPaymentdetail.code == "ILOHC" ||
                tcInPaymentdetail.code == "ILOHCEX"
              )
                holdCleaning =
                  parseFloat(holdCleaning) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);

              // tciMiscExpenses
              if (
                tcInPaymentdetail.code == "VOYEX" ||
                tcInPaymentdetail.code == "VOYEXPEX" ||
                tcInPaymentdetail.code == "VOYEXP" ||
                tcInPaymentdetail.code == "MISCEXP" ||
                tcInPaymentdetail.code == "MISCEXPEX"
              )
                tciMiscExpenses =
                  parseFloat(tciMiscExpenses) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);

              // TCI Adjsutment
              // if (tcInPaymentdetail.code == "MISCEXP")
              //   tciMiscAdjustment =
              //     parseFloat(tciMiscAdjustment) +
              //     parseFloat(tcInPaymentdetail.amount ?? 0);
            }
          });
        });

        // Fuel consumptions
        this.fuelTypes.forEach((fuelType) => {
          let fuelConsumption = 0;
          this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
            if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
              voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                (voyageBunkerMasterBunker) => {
                  fuelConsumption =
                    parseFloat(fuelConsumption) +
                    (parseFloat(voyageBunkerMasterBunker.sea_consumption) +
                      parseFloat(voyageBunkerMasterBunker.port_consumption));
                }
              );
            }
          });
          console.log("TFC:" + fuelConsumption);
          // Fuel Consumption Price
          let qtyReceived = 0;
          let qtyDelivered = 0;

          if (this.form.bunker_calculation_type == "FIFO") {
            let fuelRemainingAtCP = 0;
            let fuelAmountRemainingAtCP = 0;
            let fuelRemainingAtReceived = 0;
            let fuelAmountRemainingAtReceived = 0;

            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered = parseFloat(tcInBunkerDetail.qty);
                if (fuelConsumption > qtyDelivered) {
                  fuelConsumptionPrice =
                    parseFloat(fuelConsumptionPrice) +
                    parseFloat(qtyDelivered) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption =
                    parseFloat(fuelConsumption) - parseFloat(qtyDelivered);
                } else {
                  fuelConsumptionPrice =
                    parseFloat(fuelConsumptionPrice) +
                    parseFloat(fuelConsumption) *
                      parseFloat(tcInBunkerDetail.rate);

                  fuelRemainingAtCP =
                    parseFloat(qtyDelivered) - parseFloat(fuelConsumption);
                  fuelAmountRemainingAtCP =
                    parseFloat(fuelRemainingAtCP) *
                    parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption = 0;
                }
              }
            }
            console.log("Fuel Consumption Price");
            console.log(fuelConsumptionPrice);

            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived = parseFloat(
                        voyageBunkerMasterBunker.received_qty
                      );

                      if (fuelConsumption > qtyReceived) {
                        fuelConsumptionPrice =
                          parseFloat(fuelConsumptionPrice) +
                          parseFloat(qtyReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption =
                          parseFloat(fuelConsumption) - parseFloat(qtyReceived);
                      } else {
                        fuelConsumptionPrice =
                          parseFloat(fuelConsumptionPrice) +
                          parseFloat(fuelConsumption) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelRemainingAtReceived =
                          parseFloat(fuelRemainingAtReceived) +
                          parseFloat(qtyReceived) -
                          parseFloat(fuelConsumption);
                        fuelAmountRemainingAtReceived =
                          parseFloat(fuelAmountRemainingAtReceived) +
                          parseFloat(fuelRemainingAtReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption = 0;
                      }
                      console.log("FCP: " + fuelConsumptionPrice);
                    }
                  }
                );
              }
            });
            console.log("fuelRemainingAtCP: " + fuelRemainingAtCP);
            console.log("fuelAmountRemainingAtCP: " + fuelAmountRemainingAtCP);
            console.log("fuelRemainingAtReceived: " + fuelRemainingAtReceived);
            console.log(
              "fuelAmountRemainingAtReceived: " + fuelAmountRemainingAtReceived
            );

            // TCI Bunkers Adjustment
            let averagePrice = 0;
            if (
              fuelAmountRemainingAtReceived != 0 ||
              fuelAmountRemainingAtCP != 0
            ) {
              averagePrice =
                (parseFloat(fuelAmountRemainingAtReceived) +
                  parseFloat(fuelAmountRemainingAtCP)) /
                (parseFloat(fuelRemainingAtReceived) +
                  parseFloat(fuelRemainingAtCP));
            }

            console.log("averagePrice: " + averagePrice);
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          } else if (this.form.bunker_calculation_type == "LIFO") {
            let fuelRemainingAtCP = 0;
            let fuelAmountRemainingAtCP = 0;
            let fuelRemainingAtReceived = 0;
            let fuelAmountRemainingAtReceived = 0;
            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived = parseFloat(
                        voyageBunkerMasterBunker.received_qty
                      );

                      if (fuelConsumption > qtyReceived) {
                        fuelConsumptionPrice =
                          parseFloat(fuelConsumptionPrice) +
                          parseFloat(qtyReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption =
                          parseFloat(fuelConsumption) - parseFloat(qtyReceived);
                      } else {
                        fuelConsumptionPrice =
                          parseFloat(fuelConsumptionPrice) +
                          parseFloat(fuelConsumption) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelRemainingAtReceived =
                          parseFloat(fuelRemainingAtReceived) +
                          parseFloat(qtyReceived) -
                          parseFloat(fuelConsumption);
                        fuelAmountRemainingAtReceived =
                          parseFloat(fuelAmountRemainingAtReceived) +
                          parseFloat(fuelRemainingAtReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption = 0;
                      }
                      console.log("FCP: " + fuelConsumptionPrice);
                    }
                  }
                );
              }
            });
            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered = parseFloat(tcInBunkerDetail.qty);
                if (fuelConsumption > qtyDelivered) {
                  fuelConsumptionPrice =
                    parseFloat(fuelConsumptionPrice) +
                    parseFloat(qtyDelivered) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption =
                    parseFloat(fuelConsumption) - parseFloat(qtyDelivered);
                } else {
                  fuelConsumptionPrice =
                    parseFloat(fuelConsumptionPrice) +
                    parseFloat(fuelConsumption) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelRemainingAtCP =
                    parseFloat(qtyDelivered) - parseFloat(fuelConsumption);
                  fuelAmountRemainingAtCP =
                    parseFloat(fuelRemainingAtCP) *
                    parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption = 0;
                }
              }
            }

            console.log("Fuel Consumption Price");
            console.log(fuelConsumptionPrice);

            // TCI Bunkers Adjustment
            let averagePrice = 0;
            if (
              fuelAmountRemainingAtReceived != 0 ||
              fuelAmountRemainingAtCP != 0
            ) {
              averagePrice =
                (parseFloat(fuelAmountRemainingAtReceived) +
                  parseFloat(fuelAmountRemainingAtCP)) /
                (parseFloat(fuelRemainingAtReceived) +
                  parseFloat(fuelRemainingAtCP));
            }

            console.log("averagePrice: " + averagePrice);
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          } else if (this.form.bunker_calculation_type == "AVG") {
            let totalQty = 0;
            let totalPrice = 0;
            let averagePrice = 0;

            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered =
                  parseFloat(qtyDelivered) + parseFloat(tcInBunkerDetail.qty);
                totalQty =
                  parseFloat(totalQty) + parseFloat(tcInBunkerDetail.qty);
                totalPrice =
                  parseFloat(totalPrice) +
                  parseFloat(tcInBunkerDetail.qty) *
                    parseFloat(tcInBunkerDetail.rate);
              }
            }

            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived =
                        parseFloat(qtyReceived) +
                        parseFloat(voyageBunkerMasterBunker.received_qty);
                      totalQty =
                        parseFloat(totalQty) +
                        parseFloat(voyageBunkerMasterBunker.received_qty);
                      totalPrice =
                        parseFloat(totalPrice) +
                        parseFloat(voyageBunkerMasterBunker.received_qty) *
                          parseFloat(
                            voyageBunkerMasterBunker.received_price ?? 0
                          );
                    }
                  }
                );
              }
            });
            console.log("Total Price: " + totalPrice);
            console.log("Total Qty: " + totalQty);
            console.log("FuelConsumption: " + fuelConsumption);
            averagePrice =
              totalPrice == 0
                ? 0
                : parseFloat(totalPrice) / parseFloat(totalQty);
            console.log("Average: " + averagePrice);
            fuelConsumptionPrice =
              parseFloat(fuelConsumptionPrice) +
              parseFloat(averagePrice) * parseFloat(fuelConsumption);

            // TCI Bunkers Adjustment
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          }
        });
      }
      this.hireCost = hireCost;
      this.hireAddComm = hireAddComm;
      this.cve = cve;
      this.bunkers = bunkers;
      this.offHireBunkers = offHireBunkers;
      this.offHire = offHire;
      this.offHireAddComm = offHireAddComm;
      this.offHireCve = offHireCve;
      this.holdCleaning = holdCleaning;
      this.tciMiscExpenses = tciMiscExpenses;
      this.tciMiscAdjustment = tciMiscAdjustment;
      this.bunkers = fuelConsumptionPrice;
      this.tciBunkerAdjustment = tciBunkerAdjustmentPrice;

      // TC OUTs
      let OhireCost = 0;
      let OhireAddComm = 0;
      let Obunkers = 0;
      let Ocve = 0;
      let OoffHire = 0;
      let OoffHireBunkers = 0;
      let OoffHireAddComm = 0;
      let OoffHireCve = 0;
      let OholdCleaning = 0;
      let OtciMiscExpenses = 0;
      let OtciMiscAdjustment = 0;
      let OfuelConsumptionPrice = 0;
      let OtciBunkerAdjustmentPrice = 0;

      if (this.form.tc_out) {
        // let OtcInPayments = this.form.tc_out.tc_in_payments.filter(
        //   (tip) => tip.payment_type == "Increemental"
        // );
        // if (OtcInPayments.length == 0)
        //   OtcInPayments = this.form.tc_out.tc_in_payments;
        // else {
        //   OtcInPayments = [
        //     this.form.tc_out.tc_in_payments[
        //       this.form.tc_out.tc_in_payments.length - 1
        //     ],
        //   ];
        // }

        let OtcInPayments = [];
        let checkIfIncreementalReached = 0;
        let reversePayments = [...this.form.tc_out.tc_in_payments].reverse();
        reversePayments.forEach((tcInPayment) => {
          if (
            checkIfIncreementalReached == 0 &&
            tcInPayment.include_in_pand_l == 1
          ) {
            OtcInPayments.push(tcInPayment);
            if (tcInPayment.payment_type == "Increemental")
              checkIfIncreementalReached = 1;
          }
        });

        console.log("O Tc Payments");
        console.log(OtcInPayments);

        OtcInPayments.forEach((tcInPayment) => {
          tcInPayment.tc_in_payment_details.forEach((tcInPaymentdetail) => {
            // Hire
            if (tcInPaymentdetail.code == "TCH") {
              OhireCost =
                parseFloat(OhireCost) +
                parseFloat(tcInPaymentdetail.amount ?? 0);
              // this.pandl.expense.actual.description =
              //   tcInPaymentdetail.description + " " + tcInPaymentdetail.remarks;
              // this.pandl.expense.actual.amount = hireCost;

              console.log(OhireCost);
            }

            // Hire Add Comm.
            if (tcInPaymentdetail.code == "HAC")
              OhireAddComm =
                parseFloat(OhireAddComm) +
                parseFloat(tcInPaymentdetail.amount ?? 0);
            // Graduities
            if (tcInPaymentdetail.code == "GAP")
              Ocve =
                parseFloat(Ocve) + parseFloat(tcInPaymentdetail.amount ?? 0);
            // TCI Bunkers Adjustment
            // this.fuelTypes.forEach((fuelType) => {
            //   if (tcInPaymentdetail.code == fuelType.code + "DL") {
            //     bunkers =
            //       parseFloat(bunkers) +
            //       parseFloat(tcInPaymentdetail.amount ?? 0);
            //   }
            //   if (tcInPaymentdetail.code == fuelType.code + "RDL") {
            //     bunkers =
            //       parseFloat(bunkers) +
            //       parseFloat(tcInPaymentdetail.amount ?? 0);
            //   }
            // });

            // Off Hire
            if (tcInPaymentdetail.code == "OFFH")
              OoffHire =
                parseFloat(OoffHire) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            if (tcInPaymentdetail.code == "OFFHR")
              OoffHire =
                parseFloat(OoffHire) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            // Off Hire Add. Comm.
            if (tcInPaymentdetail.code == "OHAC")
              OoffHireAddComm =
                parseFloat(OoffHireAddComm) +
                parseFloat(tcInPaymentdetail.amount ?? 0);
            if (tcInPaymentdetail.code == "OHRAC")
              OoffHireAddComm =
                parseFloat(OoffHireAddComm) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            // Off Hire Graduities
            if (tcInPaymentdetail.code == "OGAP")
              OoffHireCve =
                parseFloat(OoffHireCve) +
                parseFloat(tcInPaymentdetail.amount ?? 0);
            if (tcInPaymentdetail.code == "OHRAC")
              OoffHireCve =
                parseFloat(OoffHireCve) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            // Offhire Bunkers
            this.fuelTypes.forEach((fuelType) => {
              if (
                tcInPaymentdetail.code == "O" + fuelType.code ||
                tcInPaymentdetail.code == "OR" + fuelType.code
              ) {
                OoffHireBunkers =
                  parseFloat(OoffHireBunkers) +
                  parseFloat(tcInPaymentdetail.amount ?? 0);
              }
            });

            // holdCleaning
            if (
              tcInPaymentdetail.code == "ILOHC" ||
              tcInPaymentdetail.code == "ILOHCEX"
            )
              OholdCleaning =
                parseFloat(OholdCleaning) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            // tciMiscExpenses
            if (
              tcInPaymentdetail.code == "VOYEX" ||
              tcInPaymentdetail.code == "VOYEXPEX" ||
              tcInPaymentdetail.code == "VOYEXP" ||
              tcInPaymentdetail.code == "MISCEXP" ||
              tcInPaymentdetail.code == "MISCEXPEX"
            )
              OtciMiscExpenses =
                parseFloat(OtciMiscExpenses) +
                parseFloat(tcInPaymentdetail.amount ?? 0);

            // TCI Adjsutment
            // if (tcInPaymentdetail.code == "MISCEXP")
            //   tciMiscAdjustment =
            //     parseFloat(tciMiscAdjustment) +
            //     parseFloat(tcInPaymentdetail.amount ?? 0);
          });
        });

        // Fuel consumptions
        this.fuelTypes.forEach((fuelType) => {
          let fuelConsumption = 0;
          this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
            if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
              voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                (voyageBunkerMasterBunker) => {
                  fuelConsumption =
                    parseFloat(fuelConsumption) +
                    (parseFloat(voyageBunkerMasterBunker.sea_consumption) +
                      parseFloat(voyageBunkerMasterBunker.port_consumption));
                }
              );
            }
          });
          console.log("TFC:" + fuelConsumption);
          // Fuel Consumption Price
          let qtyReceived = 0;
          let qtyDelivered = 0;

          if (this.form.bunker_calculation_type == "FIFO") {
            let fuelRemainingAtCP = 0;
            let fuelAmountRemainingAtCP = 0;
            let fuelRemainingAtReceived = 0;
            let fuelAmountRemainingAtReceived = 0;

            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered = parseFloat(tcInBunkerDetail.qty);
                if (fuelConsumption > qtyDelivered) {
                  OfuelConsumptionPrice =
                    parseFloat(OfuelConsumptionPrice) +
                    parseFloat(qtyDelivered) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption =
                    parseFloat(fuelConsumption) - parseFloat(qtyDelivered);
                } else {
                  OfuelConsumptionPrice =
                    parseFloat(OfuelConsumptionPrice) +
                    parseFloat(fuelConsumption) *
                      parseFloat(tcInBunkerDetail.rate);

                  fuelRemainingAtCP =
                    parseFloat(qtyDelivered) - parseFloat(fuelConsumption);
                  fuelAmountRemainingAtCP =
                    parseFloat(fuelRemainingAtCP) *
                    parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption = 0;
                }
              }
            }
            console.log("Fuel Consumption Price");
            console.log(OfuelConsumptionPrice);

            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived = parseFloat(
                        voyageBunkerMasterBunker.received_qty
                      );

                      if (fuelConsumption > qtyReceived) {
                        OfuelConsumptionPrice =
                          parseFloat(OfuelConsumptionPrice) +
                          parseFloat(qtyReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption =
                          parseFloat(fuelConsumption) - parseFloat(qtyReceived);
                      } else {
                        OfuelConsumptionPrice =
                          parseFloat(OfuelConsumptionPrice) +
                          parseFloat(fuelConsumption) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelRemainingAtReceived =
                          parseFloat(fuelRemainingAtReceived) +
                          parseFloat(qtyReceived) -
                          parseFloat(fuelConsumption);
                        fuelAmountRemainingAtReceived =
                          parseFloat(fuelAmountRemainingAtReceived) +
                          parseFloat(fuelRemainingAtReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption = 0;
                      }
                      console.log("FCP: " + OfuelConsumptionPrice);
                    }
                  }
                );
              }
            });
            console.log("fuelRemainingAtCP: " + fuelRemainingAtCP);
            console.log("fuelAmountRemainingAtCP: " + fuelAmountRemainingAtCP);
            console.log("fuelRemainingAtReceived: " + fuelRemainingAtReceived);
            console.log(
              "fuelAmountRemainingAtReceived: " + fuelAmountRemainingAtReceived
            );

            // TCI Bunkers Adjustment
            let averagePrice = 0;
            if (
              fuelAmountRemainingAtReceived != 0 ||
              fuelAmountRemainingAtCP != 0
            ) {
              averagePrice =
                (parseFloat(fuelAmountRemainingAtReceived) +
                  parseFloat(fuelAmountRemainingAtCP)) /
                (parseFloat(fuelRemainingAtReceived) +
                  parseFloat(fuelRemainingAtCP));
            }

            console.log("averagePrice: " + averagePrice);
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          } else if (this.form.bunker_calculation_type == "LIFO") {
            let fuelRemainingAtCP = 0;
            let fuelAmountRemainingAtCP = 0;
            let fuelRemainingAtReceived = 0;
            let fuelAmountRemainingAtReceived = 0;
            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived = parseFloat(
                        voyageBunkerMasterBunker.received_qty
                      );

                      if (fuelConsumption > qtyReceived) {
                        OfuelConsumptionPrice =
                          parseFloat(OfuelConsumptionPrice) +
                          parseFloat(qtyReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption =
                          parseFloat(fuelConsumption) - parseFloat(qtyReceived);
                      } else {
                        OfuelConsumptionPrice =
                          parseFloat(OfuelConsumptionPrice) +
                          parseFloat(fuelConsumption) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelRemainingAtReceived =
                          parseFloat(fuelRemainingAtReceived) +
                          parseFloat(qtyReceived) -
                          parseFloat(fuelConsumption);
                        fuelAmountRemainingAtReceived =
                          parseFloat(fuelAmountRemainingAtReceived) +
                          parseFloat(fuelRemainingAtReceived) *
                            parseFloat(
                              voyageBunkerMasterBunker.received_price ?? 0
                            );
                        fuelConsumption = 0;
                      }
                      console.log("FCP: " + OfuelConsumptionPrice);
                    }
                  }
                );
              }
            });
            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered = parseFloat(tcInBunkerDetail.qty);
                if (fuelConsumption > qtyDelivered) {
                  OfuelConsumptionPrice =
                    parseFloat(OfuelConsumptionPrice) +
                    parseFloat(qtyDelivered) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption =
                    parseFloat(fuelConsumption) - parseFloat(qtyDelivered);
                } else {
                  OfuelConsumptionPrice =
                    parseFloat(OfuelConsumptionPrice) +
                    parseFloat(fuelConsumption) *
                      parseFloat(tcInBunkerDetail.rate);
                  fuelRemainingAtCP =
                    parseFloat(qtyDelivered) - parseFloat(fuelConsumption);
                  fuelAmountRemainingAtCP =
                    parseFloat(fuelRemainingAtCP) *
                    parseFloat(tcInBunkerDetail.rate);
                  fuelConsumption = 0;
                }
              }
            }

            console.log("Fuel Consumption Price");
            console.log(OfuelConsumptionPrice);

            // TCI Bunkers Adjustment
            let averagePrice = 0;
            if (
              fuelAmountRemainingAtReceived != 0 ||
              fuelAmountRemainingAtCP != 0
            ) {
              averagePrice =
                (parseFloat(fuelAmountRemainingAtReceived) +
                  parseFloat(fuelAmountRemainingAtCP)) /
                (parseFloat(fuelRemainingAtReceived) +
                  parseFloat(fuelRemainingAtCP));
            }

            console.log("averagePrice: " + averagePrice);
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          } else if (this.form.bunker_calculation_type == "AVG") {
            let totalQty = 0;
            let totalPrice = 0;
            let averagePrice = 0;

            let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
              (tib) => tib.description == "Est Delivery"
            );
            if (tcInBunker) {
              let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
                (tibd) => tibd.fuel_type_id == fuelType.id
              );
              if (
                tcInBunkerDetail &&
                tcInBunkerDetail.qty &&
                tcInBunkerDetail.rate
              ) {
                qtyDelivered =
                  parseFloat(qtyDelivered) + parseFloat(tcInBunkerDetail.qty);
                totalQty =
                  parseFloat(totalQty) + parseFloat(tcInBunkerDetail.qty);
                totalPrice =
                  parseFloat(totalPrice) +
                  parseFloat(tcInBunkerDetail.qty) *
                    parseFloat(tcInBunkerDetail.rate);
              }
            }

            this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
              if (voyageBunkerMaster.fuel_type_id == fuelType.id) {
                voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                  (voyageBunkerMasterBunker) => {
                    if (
                      voyageBunkerMasterBunker.received_qty != 0 &&
                      voyageBunkerMasterBunker.received_qty != null
                    ) {
                      qtyReceived =
                        parseFloat(qtyReceived) +
                        parseFloat(voyageBunkerMasterBunker.received_qty);
                      totalQty =
                        parseFloat(totalQty) +
                        parseFloat(voyageBunkerMasterBunker.received_qty);
                      totalPrice =
                        parseFloat(totalPrice) +
                        parseFloat(voyageBunkerMasterBunker.received_qty) *
                          parseFloat(
                            voyageBunkerMasterBunker.received_price ?? 0
                          );
                    }
                  }
                );
              }
            });
            console.log("Total Price: " + totalPrice);
            console.log("Total Qty: " + totalQty);
            console.log("FuelConsumption: " + fuelConsumption);
            averagePrice =
              totalPrice == 0
                ? 0
                : parseFloat(totalPrice) / parseFloat(totalQty);
            console.log("Average: " + averagePrice);
            OfuelConsumptionPrice =
              parseFloat(OfuelConsumptionPrice) +
              parseFloat(averagePrice) * parseFloat(fuelConsumption);

            // TCI Bunkers Adjustment
            tciBunkerAdjustmentPrice =
              parseFloat(tciBunkerAdjustmentPrice) +
              this.tciBunkerAdjustmentCalculation(fuelType, averagePrice);
          }
        });
      }
      this.OhireCost = OhireCost;
      this.OhireAddComm = OhireAddComm;
      this.Ocve = Ocve;
      this.Obunkers = Obunkers;
      this.OoffHireBunkers = OoffHireBunkers;
      this.OoffHire = OoffHire;
      this.OoffHireAddComm = OoffHireAddComm;
      this.OoffHireCve = OoffHireCve;
      this.OholdCleaning = OholdCleaning;
      this.OtciMiscExpenses = OtciMiscExpenses;
      this.OtciMiscAdjustment = OtciMiscAdjustment;
      this.Obunkers = OfuelConsumptionPrice;
      this.OtciBunkerAdjustment = OtciBunkerAdjustmentPrice;

      // TC Out Done

      // Port Expenses
      let portExpenses = 0;
      this.form.voyage_itenaries.forEach((voyageItenary) => {
        voyageItenary.voyage_itenary_port_expenses.forEach(
          (voyageItenaryPortExpense) => {
            portExpenses =
              parseFloat(portExpenses) +
              parseFloat(
                voyageItenaryPortExpense.fda_amount != 0
                  ? voyageItenaryPortExpense.fda_amount
                  : voyageItenaryPortExpense.advance_amount
              );
          }
        );
      });
      this.portExpenses = portExpenses;
      // Miscellaneous Expenses
      let miscExpenses = 0;
      OtciMiscExpenses = 0;
      this.form.voyage_other_rev_expenses.forEach((voyageOtherRevExpense) => {
        if (voyageOtherRevExpense.is_revenue_expense == "Expense") {
          miscExpenses =
            parseFloat(miscExpenses) +
            parseFloat(voyageOtherRevExpense.amount ?? 0);
        } else {
          OtciMiscExpenses =
            parseFloat(OtciMiscExpenses) +
            parseFloat(voyageOtherRevExpense.amount ?? 0);
        }
      });
      this.miscExpenses = miscExpenses;
      this.OtciMiscExpenses += OtciMiscExpenses;

      // Voyage, sea and port days
      let totalSeaDays = 0;
      let totalPortDays = 0;
      this.form.voyage_itenaries.forEach((voyageItenary) => {
        totalSeaDays =
          parseFloat(totalSeaDays) + parseFloat(voyageItenary.total_sea_days);
        totalPortDays =
          parseFloat(totalPortDays) + parseFloat(voyageItenary.total_port_days);
      });
      this.totalVoyageDays =
        parseFloat(totalSeaDays) + parseFloat(totalPortDays);
      this.totalSeaDays = parseFloat(totalSeaDays);
      this.totalPortDays = parseFloat(totalPortDays);

      // Off Hire Days
      let totalOffhireHours = 0;
      this.form.voyage_delays.forEach((voyageDelay) => {
        totalOffhireHours =
          parseFloat(totalOffhireHours) + parseFloat(voyageDelay.hours);
      });
      this.totalOffhireDays = totalOffhireHours / 24;

      // Net Voyage Days
      this.netVoyageDays =
        parseFloat(this.totalVoyageDays) - parseFloat(this.totalOffhireDays);
    },
    // tcInDeliveredBunkerCalculation(fuelType) {},
    // tcInReceivedBunkerCalculation(fuelType) {},
    tciBunkerAdjustmentCalculation(fuelType, estimatedPrice) {
      let tcInBunker = this.form.tc_in.tc_in_bunkers.find(
        (tib) => tib.description == "Actual Redelivery"
      );
      if (tcInBunker) {
        let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
          (tibd) => tibd.fuel_type_id == fuelType.id
        );
        let tempTciBunkerAdjustmentPrice = 0;
        if (tcInBunkerDetail) {
          tempTciBunkerAdjustmentPrice =
            parseFloat(tcInBunkerDetail.qty ?? 0) *
              parseFloat(estimatedPrice ?? 0) -
            parseFloat(tcInBunkerDetail.qty ?? 0) *
              parseFloat(tcInBunkerDetail.rate ?? 0);
        }
        console.log(
          "Temp TCI Bunker Adjustment Price: " + tempTciBunkerAdjustmentPrice
        );
        return parseFloat(tempTciBunkerAdjustmentPrice);
      }
    },
  },
};
</script>

<style scoped>
.right {
  float: right !important;
}
</style>