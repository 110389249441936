<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Other Loading Terms
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
            <button class="btn btn-info btn-sm" @click="save">
              <i class="fa fa-save"></i> Save
            </button>
            <!-- <upload-button link="/masters/bunker-purchase-types"></upload-button> -->
          </h5>
          <!-- <my-select style="margin: 9px !important;" @change="searchData" disableFormControl="true" :selection.sync="search" :suggestions="groups" placeholder="Select group"></my-select> -->
          <small v-if="isSaving">Saving...</small>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Other Loading Terms</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Code</th>
                          <th>Term</th>
                          <th>Paragraph</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(form, ar) in forms" :key="`form${ar}`">
                          <td>
                            <span
                              @click="deleteOtherLoadingTerms(form)"
                              v-if="!form.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Code"
                              v-model="form.code"
                            />
                          </td>
                          <!-- <td>
                            <input type="file" id="file" name="logo_path" ref="file" accept="image/*" multiple />
                          </td> -->
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Term"
                              v-model="form.term"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="text"
                              placeholder="Paragraph"
                              v-model="form.paragraph"
                            />
                          </td>
                          <td>
                            <my-checkbox
                              :selection.sync="form.is_active"
                              placeholder="Is Active"
                            ></my-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="13"
                            align="center"
                            @click="addEmptyOtherLoadingTerms()"
                          >
                            Add New Row
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "otherLoadingTerms",
  data() {
    return {
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      forms: [],
      isSaving: false,
      searchText: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let forms = await axios.get(
        `/other_loading_terms?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.forms = forms.data.data;
      this.count = forms.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData() {
      this.getData(1);
    },
    addEmptyOtherLoadingTerms() {
      this.forms.push({
        site_id: this.site.id,
        code: "",
        term: "",
        paragraph: "",
        is_active: true,
      });
    },
    deleteOtherLoadingTerms(form) {
      this.forms = this.forms.filter((ar) => ar != form);
    },
    async save() {
      if (this.forms.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.forms,
        };
        await axios.post(`/other_loading_term_multiple`, payload);
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
th {
}
</style>
