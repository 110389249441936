<template>
  <section>
    <h5><u>Itinerary</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Action</th>
            <th>Sr. No.</th>
            <th>Port</th>
            <th>Miles</th>
            <th>Spd</th>
            <th>SDays</th>
            <th>XSD</th>
            <th>F</th>
            <th>Cargo</th>
            <th>L/D Qty</th>
            <th>L/D Rate</th>
            <th>Terms</th>
            <th>PDays</th>
            <th>XPD</th>
            <th>PortExp</th>
            <!-- <th>OExp$/t</th>
            <th>Dem</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(itenary, it) in itenaries"
            :key="`itenary${it}`"
            style="color: red"
          >
            <td colspan="2">
              {{ it + 1 }}
              <span
                v-if="it != 0 && it != itenaries.length - 1"
                @click="deleteItenary(itenary)"
              >
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              </span>
              &nbsp;&nbsp;
              <span v-if="it != 0" @click="addItenary(it)">
                <i class="fa fa-plus-circle"></i>
                &nbsp;&nbsp;
              </span>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.port_name_id"
                :suggestions="portNames"
                placeholder="Select port"
                @change="updateMiles(itenary)"
              ></my-select>
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Miles"
                v-model="itenary.miles"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Speed"
                v-model="itenary.speed"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Sea Days"
                v-model="itenary.sea_days"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Extra Sea Days"
                v-model="itenary.extra_sea_days"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.port_function_id"
                :suggestions="portFunctions"
                placeholder="Select port function"
                @change="updateItenaryDetails"
              ></my-select>
            </td>
            <td>
              <my-select
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.cargo_id"
                :suggestions="cargoNames"
                placeholder="Select cargo"
              ></my-select>
            </td>
            <td>
              <input
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                v-uppercase
                type="number"
                placeholder="L/D Qty"
                v-model="itenary.load_discharge_qty"
                @blur="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                v-uppercase
                type="number"
                placeholder="L/D Rate"
                v-model="itenary.load_discharge_rate"
                @blur="updateItenaryDetails"
              />
            </td>
            <td>
              <my-select
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.laytime_term_id"
                :suggestions="laytimeTerms"
                placeholder="Select laytime term"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Port Days"
                v-model="itenary.port_days"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Extra Port Days"
                v-model="itenary.extra_port_days"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Port Expenses"
                v-model="itenary.port_expenses"
                @change="updateItenaryDetails"
              />
            </td>
            <!-- <td>
              <input
                v-uppercase
                type="number"
                placeholder="Other Expenses"
                v-model="itenary.other_expenses"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Demurrage"
                v-model="itenary.demurrage"
                @change="updateItenaryDetails"
              />
            </td> -->
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <h5>{{ totalMiles | round2 }}</h5>
            </td>
            <td></td>
            <td>
              <h5>{{ totalSeaDays | round2 }}</h5>
            </td>
            <td>
              <h5>{{ totalExtraSeaDays | round2 }}</h5>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <h5>{{ totalPortDays | round2 }}</h5>
            </td>
            <td>
              <h5>{{ totalExtraPortDays | round2 }}</h5>
            </td>
            <td>
              <h5>{{ totalPortExpenses | round2 }}</h5>
            </td>
            <!-- <td>
              <h5>{{ totalOtherExpenses | round2 }}</h5>
            </td>
            <td>
              <h5>{{ totalDemurrageExpenses | round2 }}</h5>
            </td> -->
          </tr>
          <!-- <tr>
            <td colspan="16" align="center" @click="addEmptyAddressContact()">Add New Row</td>
          </tr> -->
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "estimateItenaries",
  data() {
    return {
      cargoNames: [],
    };
  },
  computed: {
    totalMiles() {
      let totalMiles = 0;
      this.itenaries.forEach((itenary) => {
        totalMiles += parseFloat(itenary.miles);
      });
      return totalMiles;
    },
    totalSeaDays() {
      let totalSeaDays = 0;
      this.itenaries.forEach((itenary) => {
        totalSeaDays += parseFloat(itenary.sea_days);
      });
      return totalSeaDays;
    },
    totalExtraSeaDays() {
      let totalExtraSeaDays = 0;
      this.itenaries.forEach((itenary) => {
        totalExtraSeaDays += parseFloat(itenary.extra_sea_days);
      });
      return totalExtraSeaDays;
    },
    totalPortDays() {
      let totalPortDays = 0;
      this.itenaries.forEach((itenary) => {
        totalPortDays += parseFloat(itenary.port_days);
      });
      return totalPortDays;
    },
    totalExtraPortDays() {
      let totalExtraPortDays = 0;
      this.itenaries.forEach((itenary) => {
        totalExtraPortDays += parseFloat(itenary.extra_port_days);
      });
      return totalExtraPortDays;
    },
    totalPortExpenses() {
      let totalPortExpenses = 0;
      this.itenaries.forEach((itenary) => {
        totalPortExpenses += parseFloat(itenary.port_expenses);
      });
      return totalPortExpenses;
    },
    totalOtherExpenses() {
      let totalOtherExpenses = 0;
      this.itenaries.forEach((itenary) => {
        totalOtherExpenses += parseFloat(itenary.other_expenses);
      });
      return totalOtherExpenses;
    },
    totalDemurrageExpenses() {
      let totalDemurrageExpenses = 0;
      this.itenaries.forEach((itenary) => {
        totalDemurrageExpenses += parseFloat(itenary.demurrage);
      });
      return totalDemurrageExpenses;
    },
  },
  props: [
    "itenaries",
    "cargos",
    "portNames",
    "portFunctions",
    "laytimeTerms",
    "allCargoNames",
  ],
  watch: {
    cargos: {
      handler(value) {
        let cargoNames = [];
        value.forEach((cargo) => {
          let cargoName = this.allCargoNames.find(
            (acn) => acn.id == cargo.cargo_name_id
          );
          if (cargoName) cargoNames.push(cargoName);
        });
        this.cargoNames = cargoNames;
      },
      deep: true,
    },
  },
  methods: {
    deleteItenary(itenary) {
      this.itenaries.splice(this.itenaries.indexOf(itenary), 1);
      // this.itenaries = this.itenaries.filter(it => it != itenary)
    },
    addItenary(it) {
      let itenary = {
        port: "",
        port_name_id: "",
        port_name: "",
        miles: 0,
        speed: 0,
        sea_days: 0,
        extra_sea_days: 0,
        port_function_id: "",
        cargo: "",
        cargo_id: "",
        load_discharge_qty: 0,
        load_discharge_rate: 0,
        laytime_term_id: "",
        port_days: 0,
        extra_port_days: 0,
        port_expenses: 0,
        other_expenses: 0,
        demurrage: 0,
      };
      this.itenaries.splice(it, 0, itenary);
      this.itenaries.forEach((itenary, i) => (itenary.position = i + 1));
    },
    updateMiles(itenary) {
      let checkIfPortEmpty = false;
      this.itenaries.forEach((itenary) => {
        if (itenary.port_name_id == "") checkIfPortEmpty = true;
      });
      if (!checkIfPortEmpty) {
        let portName = this.portNames.find(
          (pn) => pn.id == itenary.port_name_id
        );
        itenary.port = portName;
        itenary.port_name = portName ? portName.name : "";
        this.$emit("updateMiles");
      }
    },
    updateItenaryDetails() {
      this.$emit("updateItenaryDetails");
    },
    onCargosChanged() {},
  },
};
</script>