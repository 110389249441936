<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <!-- <back-button link="/voyages"></back-button>  -->
            Bunker New Requirements
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/bunkers/new-requirements"
                >Bunker New Requirements</router-link
              >
            </li>
            <!-- <li class="active">Create</li> -->
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage No</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.voyage_id"
                                :suggestions="voyages"
                                placeholder="Select voyage"
                              ></my-select>
                              <span class="error-block">{{
                                errors.voyage_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Req Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.req_delivery"
                                placeholder="Enter req delivery Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-2"></div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.port_id"
                                :suggestions="ports"
                                placeholder="Select ports"
                              ></my-select>
                              <span class="error-block">{{
                                errors.port_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETA</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.voyage_id"
                                :suggestions="voyages"
                                placeholder="Select voyage"
                              ></my-select>
                              <span class="error-block">{{
                                errors.voyage_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.delivery_date"
                                placeholder="Enter Delivery Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-2"></div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Charterer</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Charterer"
                                v-model="form.charterer"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETB</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="ETB"
                                v-model="form.etb"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Window From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.window_from"
                                placeholder="Enter window From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Request Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.request_date"
                                placeholder="Enter window From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Delivery Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.delivery_type_id"
                                :suggestions="deliveryTypes"
                                placeholder="Select Delivery Type"
                              ></my-select>
                              <span class="error-block">{{
                                errors.delivery_type_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Owner</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Owner"
                                v-model="form.onwer"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETD</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="ETD"
                                v-model="form.etd"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Window To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.window_to"
                                placeholder="Enter window To"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Last Update</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="form.last_update"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Last Updated By</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="form.last_update_by"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Agent</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.agent_id"
                                :suggestions="agents"
                                placeholder="Select agent"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company_id"
                                :suggestions="companies"
                                placeholder="Select Company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2"></div>
                        <div class="col-md-2"></div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Grade</th>
                                <th>Req. Qty</th>
                                <th>Min. Qty</th>
                                <th>Max Qty</th>
                                <th>Opr. Qty</th>
                                <th>Alt Qty</th>
                                <th>All Units</th>
                                <th>Sulfur %</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>LSF</td>
                                <td>
                                  <my-select
                                    :selection.sync="form.grade_id"
                                    :suggestions="grades"
                                    placeholder="Select grade"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form.req_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._min_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._max_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._opr_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._alt_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._all_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._sulfur"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>LSM</td>
                                <td>
                                  <my-select
                                    :selection.sync="form.grade_id"
                                    :suggestions="grades"
                                    placeholder="Select grade"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form.req_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._min_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._max_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._opr_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._alt_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._all_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._sulfur"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>IFO</td>
                                <td>
                                  <my-select
                                    :selection.sync="form.grade_id"
                                    :suggestions="grades"
                                    placeholder="Select grade"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form.req_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._min_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._max_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._opr_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._alt_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._all_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._sulfur"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>MDO</td>
                                <td>
                                  <my-select
                                    :selection.sync="form.grade_id"
                                    :suggestions="grades"
                                    placeholder="Select grade"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form.req_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._min_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._max_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._opr_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._alt_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._all_qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="0.000"
                                    v-model="form._sulfur"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                          <div class="col-md-10">
                            <p>Request Notes:</p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <p>Bunkering Detailes</p>
                            <textarea
                              class="form-control"
                              placeholder="Bunkering Detailes"
                              v-model="form.bunkering_detailes"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <!-- <h5>Cargos</h5> -->
                      <!-- <br> -->
                      <div class="editable-table-wrapper">
                        <div class="row">
                          <div class="col-md-2">
                            <p>Inquireies And Purchases</p>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <my-checkbox
                                :selection.sync="form.currency"
                                placeholder="Display in Base currency"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port</th>
                              <th>Vendor</th>
                              <th>S</th>
                              <th>Status</th>
                              <th>Curr</th>
                              <th>Exchange Rate</th>
                              <th>LSF Qty</th>
                              <th>LSF Price</th>
                              <th>LSM Qty</th>
                              <th>LSM Price</th>
                              <th>IFO Qty</th>
                              <th>IFO Price</th>
                              <th>MDO Qty</th>
                              <th>MDO Price</th>
                              <th>Berg Rate</th>
                              <th>Berging</th>
                              <th>Other</th>
                              <th>PortCost</th>
                              <th>Tax %</th>
                              <th>Total</th>
                              <th>Range</th>
                              <th>Pay Terms</th>
                              <th>Window From</th>
                              <th>Window To</th>
                              <th>Comments</th>
                              <th>Ext Ref</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                vesselSpeedConsumption, ar
                              ) in form.vessel_speed_consumptions"
                              :key="`contact${ar}`"
                            >
                              <td
                                @click="
                                  deleteVesselSpeedConsumption(
                                    vesselSpeedConsumption
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.port_id
                                  "
                                  :suggestions="ports"
                                  placeholder="Select Port"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.vendor_id
                                  "
                                  :suggestions="vendors"
                                  placeholder="Select Vender"
                                ></my-select>
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.s"
                                  placeholder=""
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="inquiry"
                                  v-model="vesselSpeedConsumption.status"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.currncy_id
                                  "
                                  :suggestions="currencies"
                                  placeholder="USD"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="1.00000"
                                  v-model="vesselSpeedConsumption.exch_rate"
                                />
                                <!-- <my-select disableFormControl="true" :selection.sync="vesselSpeedConsumption.chartere_id" :suggestions="charterers" placeholder="Enter charterers"></my-select> -->
                              </td>
                              <td>
                                <!-- <my-select disableFormControl="true" :selection.sync="vesselSpeedConsumption.exc_currency_id" :suggestions="excCurrencies" placeholder="Enter exc currencies"></my-select> -->
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.lsf_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.lsf_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.lsm_qty"
                                />
                                <!-- <my-datepicker :date.sync="form.cp_date" placeholder="Enter CP Date"></my-datepicker> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.lsm_price"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Option Percent" v-model="vesselSpeedConsumption.option_percent"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.ifo_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.ifo_price"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Max Qty" v-model="vesselSpeedConsumption.max_qty"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.mdo_qty"
                                />
                                <!-- <input v-uppercase type="text" placeholder="Laycan From" v-model="vesselSpeedConsumption.laycan_from"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.mdo_price"
                                />
                                <!-- <input v-uppercase type="text" placeholder="Laycan To" v-model="vesselSpeedConsumption.laycan_to"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.berg_rate"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Demurrage Amount" v-model="vesselSpeedConsumption.demurrage_amount"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.berging"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Despatch Amount" v-model="vesselSpeedConsumption.despatch_amount"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.other"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Per Day / Hr" v-model="vesselSpeedConsumption.per_day_per_hr"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.port_cost"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Per Day / Hr" v-model="vesselSpeedConsumption.per_day_per_hr"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.tax"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Per Day / Hr" v-model="vesselSpeedConsumption.per_day_per_hr"> -->
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="0.000"
                                  v-model="vesselSpeedConsumption.total"
                                />
                                <!-- <input v-uppercase type="number" placeholder="Per Day / Hr" v-model="vesselSpeedConsumption.per_day_per_hr"> -->
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.rang"
                                  placeholder=""
                                ></my-checkbox>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.pay_term
                                  "
                                  :suggestions="payerms"
                                  placeholder="Select Pay Terms"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.window_from"
                                  placeholder=""
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.window_to"
                                  placeholder=""
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder=""
                                  v-model="vesselSpeedConsumption.comments"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="vesselSpeedConsumption.exchg_rate"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="10"
                                align="center"
                                @click="addEmptyVesselSpeedConsumption()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="10"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions =
        this.form.vessel_in_port_consumptions.filter(
          (ar) => ar != vesselInPortConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions =
        this.form.vessel_speed_consumptions.filter(
          (ar) => ar != vesselSpeedConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>