<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/delay-reasons"></back-button>
            Create a new delay reason
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/delay-reasons">Delay Reasons</router-link>
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Code*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Code"
                            v-model="form.code"
                          />
                          <span class="error-block">{{ errors.code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Reason</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Reason"
                            v-model="form.reason"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Parent Reason</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.parent_reason_id"
                            :suggestions="delayReasons"
                            placeholder="Enter Parent Delay Reason"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Delay Type</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.delay_type_id"
                            :suggestions="delayTypes"
                            placeholder="Enter Delay Type"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Property</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.property"
                            :suggestions="properties"
                            placeholder="Enter Property"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_active"
                            placeholder="Is Active"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "delayReasonCreate",
  data() {
    return {
      form: {
        parent_reason_id: "",
        delay_type_id: "",
        property: "",
        is_active: true,
      },
      delayReasons: [],
      delayTypes: [],
      properties: [
        {
          id: "UNPLANNED OFFHIRE",
          name: "UNPLANNED OFFHIRE",
        },
        {
          id: "PLANNED MAINTENANCE",
          name: "PLANNED MAINTENANCE",
        },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("delay_reasons/masters");
      masters = masters.data;
      masters.delay_reasons.forEach((delay_reason) => {
        this.delayReasons.push({
          id: delay_reason.id,
          name: delay_reason.code + " - " + delay_reason.reason,
        });
      });
      masters.delay_types.forEach((delay_type) => {
        this.delayTypes.push({
          id: delay_type.id,
          name: delay_type.code + " - " + delay_type.description,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/delay_reasons", this.form);
        this.isLoading = false;
        this.$router.push("/delay-reasons");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
