<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/expense-revenue-codes"></back-button>
            Create a new expense revenue code
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/expense-revenue-codes"
                >Expense Revenue Codes</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Code*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Code"
                            v-model="form.code"
                          />
                          <span class="error-block">{{ errors.code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Description*</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Description"
                            v-model="form.description"
                          />
                          <span class="error-block">{{
                            errors.description
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_active"
                            placeholder="Is Active"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "classSocietyCreate",
  data() {
    return {
      form: {
        is_active: 1,
      },
      expenseRevenueGroups: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters()
  },
  
  methods: {
    // async getMasters() {
    //   let masters = await axios.get("/expense_revenue_groups");
    //   masters = masters.data;
    //   masters.data.forEach((pb) => {
    //     this.periodBasis.push({
    //       id: pb,
    //       name: pb,
    //     });
    //   });
    // },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/expense_revenue_codes", this.form);
        this.isLoading = false;
        this.$router.push("/expense-revenue-codes");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
