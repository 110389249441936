<template>
  <section>
    <h5>Pricing</h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Hire Rate</th>
            <th>Rate Type</th>
            <th>Code</th>
            <th>Lock</th>
            <th>From GMT</th>
            <th>To GMT</th>
            <th>Duration</th>
            <th>Period</th>
            <th>TCL Ins</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tc_in_pricing, ar) in form.tc_in_pricings"
            :key="`contact${ar}`"
          >
            <td @click="deleteTcInPricing(tc_in_pricing)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ ar + 1 }}.
            </td>
            <td>
              <input
                style="width: 200px !important;"
                v-uppercase
                type="number"
                placeholder="Hire Rate"
                v-model="tc_in_pricing.hire_rate"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_pricing.rate_type"
                :suggestions="pricingRateTypes"
                placeholder="Rate Type"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_pricing.pricing_code_id"
                :suggestions="pricingCodes"
                placeholder="Pricing Code"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_pricing.lock"
                :suggestions="pricingLocks"
                placeholder="Lock"
              ></my-select>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_pricing.from_gmt"
                placeholder="From GMT"
                myWidth="200px"
                @change="onPortsUpdated"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_pricing.to_gmt"
                placeholder="To GMT"
                myWidth="200px"
                @change="onPortsUpdated"
              ></my-datepicker>
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Duration"
                v-model="tc_in_pricing.duration"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_pricing.duration_unit"
                :suggestions="durationUnits"
                placeholder="Unit"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="TCL Insurance Rate"
                v-model="tc_in_pricing.tcl_insurance_rate"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Comments"
                v-model="tc_in_pricing.comments"
              />
            </td>
          </tr>
          <tr>
            <td colspan="11" align="center" @click="addEmptyTcInPricing()">
              Add New Row
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: [
    "form",
    "pricingRateTypes",
    "pricingCodes",
    "pricingLocks",
    "durationUnits",
  ],
  methods: {
    addEmptyTcInPricing() {
      this.form.tc_in_pricings.push({
        hire_rate: 0,
        rate_type: "Per Day",
        pricing_code_id: "",
        lock: "",
        from_gmt: "",
        to_gmt: "",
        duration: 0,
        duration_unit: 'Days',
        tcl_insurance_rate: 0,
        comments: "",
      });
      this.onPortsUpdated()
    },
    deleteTcInPricing(tcInPricing) {
      this.form.tc_in_pricings.splice(
        this.form.tc_in_pricings.indexOf(tcInPricing),
        1
      );

      // this.form.tc_in_pricings = this.form.tc_in_pricings.filter(
      //   (ar) => ar != tcInPricing
      // );
    },
    onPortsUpdated() {
      this.$emit('onPortsUpdated')
    }
  },
};
</script>