<template>
    <FreightInvoice></FreightInvoice>
</template>

<script>
import FreightInvoice from '../../freights/freight-invoice.vue'
export default {
    components : {
        FreightInvoice
    }
}
</script>