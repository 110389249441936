<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Payables</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Payables</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-primary btn-sm"
            @click="
              updateTcInPaymentStatus(tcInPayment, (changeStatus = 'Posted'))
            "
          >
            Mark as Posted
          </button>
          &nbsp;
          <button
            class="btn btn-danger btn-sm"
            @click="openTCPaymentRejectModal()"
          >
            Mark as Rejected
          </button>
          <br /><br />
          <div class="row">
            <div class="col-md-1" style="margin-left: 50px">
              <invoice
                count="1"
                :tcInPayment="tcInPayment"
                :form="form"
              ></invoice>
            </div>
            <div class="col-md-1" @click="openTCPaymentReceiptModal">
              Receipt
            </div>
            <div class="col-md-1" @click="openTCPaymentStatementModal">
              Statement
            </div>
          </div>

          <br />
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>TC Code</th>
                        <th>Vessel Name</th>
                        <th>PERIOD FROM</th>
                        <th>PERIOD TO</th>
                        <th>Status</th>
                        <th>TYPE</th>
                        <th>DAYS</th>
                        <th>INVOICE NO</th>
                        <th>DUE DATE</th>
                        <th>AMOUNT</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ 1 }}</td>
                        <td>{{ form.tc_code }}</td>
                        <td>
                          {{ form.vessel ? form.vessel.vessel_name : "" }}
                        </td>
                        <td>{{ tcInPayment.period_from | dmy }}</td>
                        <td>{{ tcInPayment.period_to | dmy }}</td>
                        <td>{{ tcInPayment.is_approved }}</td>
                        <td>{{ tcInPayment.payment_type }}</td>
                        <td>{{ tcInPayment.days | round2 }}</td>
                        <td>{{ tcInPayment.invoice_no }}</td>
                        <td>{{ tcInPayment.due_date }}</td>
                        <td>{{ tcInPayment.amount | round2 }}</td>
                        <td>{{ (tcInPayment.amount - total1) | round2 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- For Rceipt -->
    <section>
      <div :id="`myTCPaymentReceiptModal`" class="modal">
        <div class="modal-content">
          <div class="modal-body">
            <!-- Main Content -->
            <div class="container-fluid">
              <!-- Title -->
              <div class="row heading-bg">
                <div class="col-md-2">
                  <h6>Tc in payment receipt</h6>
                </div>
                <a title="Close" @click="closeTCPaymentModal"
                  ><i
                    ng-click="CloseModal()"
                    class="glyphicon glyphicon-remove pull-right"
                  ></i>
                </a>
              </div>
              <!-- /Title -->

              <!-- Row -->
              <div class="row print-div">
                <div class="col-md-12">
                  <div class="panel panel-default card-view">
                    <div class="panel-wrapper collapse in">
                      <div class="panel-body">
                        <div class="row">
                          <div class="form-wrap form-horizontal">
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Date</label
                                >
                                <div class="col-sm-9">
                                  <my-datepicker
                                    :date.sync="tcpr.date"
                                    placeholder="Enter Date"
                                  ></my-datepicker>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Amount</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Amount"
                                    v-model="tcpr.amount"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Bank</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="tcpr.bank_id"
                                    :suggestions="banks"
                                    placeholder="Select bank"
                                  ></my-select>
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Description</label
                                >
                                <div class="col-sm-9">
                                  <textarea
                                    v-uppercase
                                    class="form-control"
                                    placeholder="Description"
                                    v-model="tcpr.description"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="form-group mb-0">
                                <div class="col-sm-offset-3 col-sm-9">
                                  <submit-button
                                    :isLoading="isLoading"
                                    @submit="save"
                                    text="Save"
                                  ></submit-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Row -->
            </div>
            <!-- /Main Content -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click="closeTCPaymentModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- For Reject Reason -->
    <section>
      <div :id="`myTCPaymentRejectReasonModal`" class="modal">
        <div class="modal-content">
          <div class="modal-body">
            <!-- Main Content -->
            <div class="container-fluid">
              <!-- Title -->
              <div class="row heading-bg">
                <div class="col-md-2">
                  <h6>Reaject Reason</h6>
                </div>
                <a title="Close" @click="closeTCPaymentRejectModal"
                  ><i
                    ng-click="CloseModal()"
                    class="glyphicon glyphicon-remove pull-right"
                  ></i>
                </a>
              </div>
              <!-- /Title -->

              <!-- Row -->
              <div class="row print-div">
                <div class="col-md-12">
                  <div class="panel panel-default card-view">
                    <div class="panel-wrapper collapse in">
                      <div class="panel-body">
                        <div class="row">
                          <div class="form-wrap form-horizontal">
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Reject Reason</label
                                >
                                <div class="col-sm-9">
                                  <textarea
                                    v-uppercase
                                    class="form-control"
                                    placeholder="Description"
                                    v-model="reject_reason"
                                  ></textarea>
                                </div>
                              </div>
                              <div class="form-group mb-0">
                                <div class="col-sm-offset-3 col-sm-9">
                                  <submit-button
                                    :isLoading="isLoading"
                                    @submit="
                                      updateTcInPaymentStatus(
                                        tcInPayment,
                                        (changeStatus = 'Rejected')
                                      )
                                    "
                                    text="Save"
                                  ></submit-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Row -->
            </div>
            <!-- /Main Content -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click="closeTCPaymentRejectModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- For Statement -->
    <section>
      <div :id="`myTCPaymentStatementModal`" class="modal">
        <div class="modal-content">
          <div class="modal-body">
            <!-- Main Content -->
            <div class="container-fluid">
              <!-- Title -->
              <div class="row heading-bg">
                <div class="col-md-2">
                  <h6>Tc in payment statement</h6>
                </div>
                <a title="Close" @click="closeTCPaymentStatementModal"
                  ><i
                    ng-click="CloseModal()"
                    class="glyphicon glyphicon-remove pull-right"
                  ></i>
                </a>
              </div>
              <!-- /Title -->

              <!-- Row -->
              <div class="row no-break">
                <div class="col-md-12">
                  <table class="table table-responsive">
                    <thead>
                      <tr>
                        <th colspan="5">Total Invoice</th>
                        <th style="text-align: right">
                          <h4>
                            <span style="font-weight: bold">{{
                              tcInPayment.amount | round2
                            }}</span>
                          </h4>
                        </th>
                      </tr>
                      <br />
                      <tr>
                        <th colspan="2">Description</th>
                        <th colspan="1">Date</th>
                        <th colspan="2">Bank</th>
                        <th style="text-align: right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tcpr, tipr) in tcprs" :key="`tcpr${tipr}`">
                        <td colspan="2">
                          {{ tcpr.description }}
                        </td>
                        <td colspan="1">
                          {{ tcpr.date | dmy }}
                        </td>
                        <td colspan="2">
                          {{ tcpr.bank.bank_name }}
                        </td>
                        <td style="text-align: right">
                          {{ tcpr.amount | round2 }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td style="text-align: right" colspan="5">
                          <h4>
                            <span style="font-weight: bold"
                              >TOTAL: {{ total }}</span
                            >
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style="text-align: right" colspan="5">
                          <h4>
                            <span style="font-weight: bold"
                              >Balance:
                              {{ (tcInPayment.amount - total) | round2 }}</span
                            >
                          </h4>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <!-- /Row -->
            </div>
            <!-- /Main Content -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              @click="closeTCPaymentStatementModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- </div> -->
  <!-- /Row -->
  <!-- </div>
  </div> -->
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import html2pdf from "html2pdf.js";
import Invoice from "@/views/tc-ins/payments/invoice.vue";

export default {
  name: "InvoicePDF",
  data() {
    return {
      tcIns: [],
      test: [],
      form: {
        tc_in_payments: [],
      },
      tcpr: {},
      tcInPayment: {},
      tcInPayments: [],
      tcprs: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      changeStatus: "",
      total: 0,
      total1: 0,
      balance: 0,
      banks: [],
      reject_reason: "",
      //: {},
    };
  },
  components: {
    Invoice: Invoice,
  },
  mounted() {
    this.getData();
    this.getMasters();
  },

  methods: {
    async getData() {
      this.isLoading = true;
      let tcIns = await axios.get(`/tc_ins/payments?type=tcin`);
      this.tcIns = tcIns.data.data;
      this.tcInPayments = [];
      this.tcIns.forEach((tcin) => {
        tcin.tc_in_payments.forEach((tcInPayment) => {
          tcInPayment.tc_code = tcin.tc_code;
          tcInPayment.vessel_name = tcin.vessel ? tcin.vessel.vessel_name : "";
          this.tcInPayments.push(tcInPayment);
        });
      });
      // console.log(this.total1);
      this.isLoading = false;
      let tcInPayment = this.tcInPayments.find(
        (tcInPayment) => tcInPayment.id == this.$route.params.payableListId
      );
      // console.log(tcInPayment);
      this.form = this.tcIns.find((tcIn) => tcIn.id == tcInPayment.tc_in_id);
      this.tcInPayment = tcInPayment;

      let tcprs = await axios.get(
        `/tc_in_payment_receipts?tc_in_payment_id=${this.$route.params.payableListId}`
      );
      this.tcprs = tcprs.data.data;
      this.tcprs.forEach((tcpr) => {
        this.total1 += parseInt(tcpr.amount);
      });
    },
    generatePDF() {
      var element = document.getElementById("downloadPDF");
      var opt = {
        margin: 0,
        filename: "Hire-Payment.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [280, 350],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
    async getMasters() {
      let masters = await axios.get("tc_in_payment_receipts/masters");
      masters = masters.data.data;
      masters.forEach((bank) => {
        this.banks.push({
          id: bank.id,
          name: bank.bank_name,
        });
      });
    },
    async updateTcInPaymentStatus(tcp, changeStatus) {
      if (changeStatus == "Rejected") {
        tcp["reject_reason"] = this.reject_reason;
      }
      tcp["is_approved"] = changeStatus;
      this.form.tc_in_payments = [tcp];
      console.log(this.form.tc_in_payments);
      try {
        await axios.post("/tc_ins", this.form);
        this.isLoading = false;

        alert(`Status Changed to ${changeStatus} successfully`);
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
        this.isLoading = false;
      }
    },
    async openTCPaymentReceiptModal() {
      var modal = document.getElementById("myTCPaymentReceiptModal");
      modal.style.display = "block";
    },
    closeTCPaymentModal() {
      var modal = document.getElementById("myTCPaymentReceiptModal");
      modal.style.display = "none";
    },
    async openTCPaymentStatementModal() {
      let tcprs = await axios.get(
        `/tc_in_payment_receipts?tc_in_payment_id=${this.$route.params.payableListId}`
      );
      // let tcprs = await axios.get(`tc_in_payment_receipts/masters`);
      this.tcprs = tcprs.data.data;
      this.tcprs.forEach((tcpr) => {
        this.total += parseInt(tcpr.amount);
      });
      var modal = document.getElementById("myTCPaymentStatementModal");
      modal.style.display = "block";
    },
    closeTCPaymentStatementModal() {
      this.total = 0;
      var modal = document.getElementById("myTCPaymentStatementModal");
      modal.style.display = "none";
    },

    async openTCPaymentRejectModal() {
      var modal = document.getElementById("myTCPaymentRejectReasonModal");
      modal.style.display = "block";
    },
    closeTCPaymentRejectModal() {
      var modal = document.getElementById("myTCPaymentRejectReasonModal");
      modal.style.display = "none";
    },

    async save() {
      try {
        this.tcpr.tc_in_payment_id = this.$route.params.payableListId;
        this.tcpr.type = "TCIN";
        this.tcpr.payment_type = "DEBIT";
        await axios.post(`/tc_in_payment_receipts`, this.tcpr);
        this.tcpr = [];
        this.closeTCPaymentModal();
        this.isLoading = false;
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
