<template>
  <section>
    <a href="javascript:void" @click="opentcInPaymentsModal" title="Delays"
      >Make Payment</a
    >

    <div id="myTcInPaymentsModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title ng-binding">
            <span class="glyphicon glyphicon-indent-left"></span> Time Charter
            Payments
            <a title="Close" @click="onDelayUpdated"
              ><i
                ng-click="CloseModal()"
                class="glyphicon glyphicon-remove pull-right"
              ></i>
            </a>
          </h4>
        </div>
        <div class="modal-body">
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th colspan="2">Sr. No</th>
                  <th>Invoice No</th>
                  <th>Job No</th>
                  <th>Period From</th>
                  <th>Period To</th>
                  <th>Type</th>
                  <th>Days</th>
                  <th>Status</th>
                  <th>Invoice No</th>
                  <!-- <th>Issue Date</th> -->
                  <th>Due Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(tcInPayment, tip) in form.tc_in_payments"
                  :key="`tcInPayment${tip}`"
                >
                  <td colspan="2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-4">
                            <span @click="deleteTCInPayment(tcInPayment)">
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div class="col-md-4">
                            <TCPayment
                              :form="form"
                              :count="tip"
                              :tcInPayment="tcInPayment"
                              :tcInPayments="form.tc_in_payments"
                              @updateTcInPayment="updateTcInPayment"
                              :fuelTypes="fuelTypes"
                              :expenseRevenueCodes="expenseRevenueCodes"
                              :voyages="voyages"
                              :delayTypes="delayTypes"
                              :paymentTypes="paymentTypes"
                              :approvalTypes="approvalTypes"
                            ></TCPayment>
                          </div>
                          <div class="col-md-4">{{ tip + 1 }}.</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{{ tcInPayment.id ? "INV-" + tcInPayment.id : "" }}</td>
                  <td>{{ tcInPayment.id ? "INV-" + tcInPayment.id : "" }}</td>
                  <td>
                    <span
                      v-if="
                        tcInPayment.is_approved == 'Approved' ||
                        tcInPayment.is_approved == 'Posted' ||
                        tcInPayment.is_approved == 'Paid'
                      "
                      >{{ tcInPayment.period_from | ddmmyyyy }}</span
                    >
                    <my-datepicker
                      v-else
                      :disableFormControl="true"
                      :date.sync="tcInPayment.period_from"
                      w
                      placeholder="Enter date and time"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>
                    <span
                      v-if="
                        tcInPayment.is_approved == 'Approved' ||
                        tcInPayment.is_approved == 'Posted' ||
                        tcInPayment.is_approved == 'Paid'
                      "
                      >{{ tcInPayment.period_to | ddmmyyyy }}</span
                    >
                    <my-datepicker
                      v-else
                      :disableFormControl="true"
                      :date.sync="tcInPayment.period_to"
                      placeholder="Enter date and time"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>{{ tcInPayment.payment_type }}</td>
                  <td>
                    <span
                      v-if="
                        tcInPayment.is_approved == 'Approved' ||
                        tcInPayment.is_approved == 'Posted' ||
                        tcInPayment.is_approved == 'Paid'
                      "
                      >{{ tcInPayment.days }}</span
                    >
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Days"
                      v-model="tcInPayment.days"
                      v-else
                    />
                  </td>
                  <td>
                    {{ tcInPayment.is_approved }}
                  </td>
                  <td>
                    <span
                      v-if="
                        tcInPayment.is_approved == 'Approved' ||
                        tcInPayment.is_approved == 'Posted' ||
                        tcInPayment.is_approved == 'Paid'
                      "
                      >{{ tcInPayment.invoice_no }}</span
                    >
                    <input
                      v-else
                      style="width: 200px !important"
                      v-uppercase
                      placeholder="No."
                      v-model="tcInPayment.invoice_no"
                    />
                  </td>
                  <!-- <td>
                    <my-datepicker
                      :disableFormControl="true"
                      :date.sync="tcInPayment.issue_date"
                      placeholder="Enter date and time"
                      myWidth="200px"
                    ></my-datepicker>
                  </td> -->
                  <td>
                    <span
                      v-if="
                        tcInPayment.is_approved == 'Approved' ||
                        tcInPayment.is_approved == 'Posted' ||
                        tcInPayment.is_approved == 'Paid'
                      "
                      >{{ tcInPayment.due_date | ddmmyyyy }}</span
                    >
                    <my-datepicker
                      v-else
                      :disableFormControl="true"
                      :date.sync="tcInPayment.due_date"
                      placeholder="Enter date and time"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td v-if="tcInPayment.id">
                    {{ tcInPayment.amount | round2 }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="14"
                    align="center"
                    @click="addEmptyTcInPayment()"
                  >
                    Add New Row
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onDelayUpdated">
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            
          >
            Close
          </button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import TCPayment from "./payment.vue";

export default {
  name: "tcInPayments",
  data() {
    return {};
  },
  props: [
    "form",
    "fuelTypes",
    "expenseRevenueCodes",
    "voyages",
    "delayTypes",
    "paymentTypes",
    "approvalTypes",
  ],
  components: {
    TCPayment,
  },
  methods: {
    // attachmentAdded(attachmentData) {
    //   console.log(attachmentData);
    //   this.$emit("attachmentNewAdded", attachmentData);
    // },
    async opentcInPaymentsModal() {
      var modal = document.getElementById("myTcInPaymentsModal");
      modal.style.display = "block";
    },
    closeMyTcInPaymentsModal() {
      var modal = document.getElementById("myTcInPaymentsModal");
      modal.style.display = "none";
    },
    onDelayUpdated() {
      this.$emit("onDelayUpdated");
      this.updateTcInPayment();
      this.closeMyTcInPaymentsModal();
    },
    addEmptyTcInPayment() {
      let tcInPayment = {
        remarks: "",
        period_from:
          this.form.tc_in_payments.length > 0
            ? this.form.tc_in_payments[this.form.tc_in_payments.length - 1]
                .period_to
            : "",
        period_to: "",
        days: 0,
        payment_type: "Period",
        contact_name: "",
        invoice_no: "",
        invoice_status: "",
        issue_date: "",
        due_date: "",
        voyage_no: "",
        tc_in_payment_details: [],
        include_in_pand_l: 1,
      };

      this.form.tc_in_payments.push(tcInPayment);
    },
    deleteTCInPayment(tcInPayment) {
      this.form.tc_in_payments.splice(
        this.form.tc_in_payments.indexOf(tcInPayment),
        1
      );
    },
    updateTcInPayment() {
      this.$emit("updateTcInPayment");
    },
  },
};
</script>
