<template>
  <section>
    <my-select @change="changeSite" disableFormControl="true" :selection.sync="site_id" :suggestions="sites" placeholder="Select company"></my-select>
  </section>
</template>

<script type="text/javascript">
import axios from 'axios'
import store from '@/store'

export default {
  data() {
    return {
      site_id: '',
      sites: [],
      all_sites: [],
    }
  },
  mounted() {
    let site = store.getters['auth/site']
    if(Object.keys(site).length > 0)
    {
      this.site_id = site.id
    }
    this.getSites()
  },
  methods: {
    async getSites() {
      let sites = await axios.get('/sites')
      this.all_sites = sites.data.data
      sites.data.data.forEach(site => {
        this.sites.push({
          id: site.id,
          name: site.name,
        })
      })
    },
    changeSite() {
      let site = this.all_sites.find(s => s.id == this.site_id)
      if(site) {
        store.dispatch('auth/setSite', site)
        // store.dispatch('employee/setEmployee', {})
        this.$router.push('/')
      }
    }
  }
}
</script>