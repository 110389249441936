<template>
    <Editinvoice></Editinvoice>
</template>

<script>
import Editinvoice from '../../transactions/create.vue'
export default {
    components : {
        Editinvoice
    }
}
</script>