<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Time Charter Invoice List
            <add-button link="/invoices/tc-invoice/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Time Charter Invoice List</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- Time Charter Invoice List -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Action</th>
                        <th>Contract Code</th>
                        <th>Parent TC Code</th>
                        <th>Invoice Type</th>
                        <th>Counterparty</th>
                        <th>Redelivery Date</th>
                        <th>Status</th>
                        <th>Actual Amount Base</th>
                        <th>Invoice Amount Base</th>
                        <th>Paid Amount Base</th>
                        <th>Company Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="12"
                      ></loading-table>
                      <router-link
                        v-for="(tc_voice, at) in tc_voices"
                        :key="`tc_voice${at}`"
                        :to="`/estimates/${tc_voice.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ tc_voice.code }}</td>
                        <td>{{ tc_voice.contract_code }}</td>
                        <td>{{ tc_voice.parent_tc_code }}</td>
                        <td>{{ tc_voice.invoice_type }}</td>
                        <td>{{ tc_voice.counterparty }}</td>
                        <td>{{ tc_voice.redelivery_date }}</td>
                        <td>{{ tc_voice.status }}</td>
                        <td>{{ tc_voice.actual_amount_base }}</td>
                        <td>{{ tc_voice.invoice_amount_base }}</td>
                        <td>{{ tc_voice.paid_amount_base }}</td>
                        <td>{{ tc_voice.company_code }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "tc_voices",
  data() {
    return {
      tc_voices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let tc_voices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.tc_voices = tc_voices.data.data;
      this.count = tc_voices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
