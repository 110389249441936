<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Company Bank Links
            <add-button
              link="/financial/statement/company-bank-links/create"
            ></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financial</li>
            <li>Statement</li>
            <li class="active">Company Bank Links</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Company Name</th>
                        <th>Bank Name</th>
                        <th>Bank Account</th>
                        <th>Currency</th>
                        <th>ledger Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="11"
                      ></loading-table>
                      <router-link
                        v-for="(company_bank_link, at) in company_bank_links"
                        :key="`company_bank_link${at}`"
                        :to="`/financial/statement/company-bank-links/${company_bank_link.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ company_bank_link.company_name }}</td>
                        <td>{{ company_bank_link.bank_name }}</td>
                        <td>{{ company_bank_link.bank_account }}</td>
                        <td>{{ company_bank_link.currency.short_name + " - " + company_bank_link.currency.name }}</td>
                        <td>{{ company_bank_link.ledger_code.code }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "company_bank_links",
  data() {
    return {
      company_bank_links: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let company_bank_links = await axios.get(`/company_bank_links`);
      this.company_bank_links = company_bank_links.data.data;
      this.count = company_bank_links.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
