<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/opa-rates"></back-button>
            Create a new OPA Rate
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Operations</li>
            <li><router-link to="/opa-rates">OPA Rate</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Years</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.year"
                                :suggestions="yaers"
                                placeholder="Select Years"
                              ></my-select>

                              <span class="error-block">{{ errors.year }}</span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_active"
                                placeholder="Is Active"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>OPA Rate Details</h5>
                      <br />
                      <div class="col-md-12 editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Description</th>
                              <th>CRate</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                opa_rate_detail, ar
                              ) in form.opa_rate_details"
                              :key="`opa_rate${ar}`"
                            >
                              <td
                                @click="deleteOpaRateDetails(opa_rate_detail)"
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Description"
                                  v-model="opa_rate_detail.description"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Rate"
                                  v-model="opa_rate_detail.rate"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="4"
                                align="center"
                                @click="addEmptyOpaRateDetails()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "lateReasonUpdate",
  data() {
    return {
      form: {
        year: "",
        opa_rate_id: "",
        description: "",
        rate: "",
        opa_rate_details: [],
      },
      yaers: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
      ],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/opa_rates/${this.$route.params.opaRateId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        await axios.post(
          `/opa_rates/${this.$route.params.opaRateId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push("/opa-rates");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyOpaRateDetails() {
      this.form.opa_rate_details.push({
        opa_rate_id: "",
        description: "",
        rate: "",
      });
    },
    deleteOpaRateDetails(opa_rate) {
      this.form.opa_rate_details = this.form.opa_rate_details.filter(
        (ar) => ar != opa_rate
      );
    },
  },
};
</script>
