<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Estimated Demurrage
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/laytime/laytime-estimated-demurrage"
                >Estimated Demurrage</router-link
              >
            </li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel</label>
                            <div class="col-sm-6">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="JALVAI"
                                v-model="a"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="JALVAI"
                                v-model="a"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Voyage No</label>
                            <div class="col-sm-9">
                              <input
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="1010"
                                v-model="voyage_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                              <label class="control-label"
                                >Initial Estimate
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                              <label class="control-label"
                                >Net P&L Amount </label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Estimated On</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.estimated_on"
                                placeholder="Enter Estimated On"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Estimate</label
                            >
                            <div class="col-md-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="estimate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Rate Per Day</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="0.00"
                                v-model="rate_per_day"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Calculated Amount</label
                            >
                            <div class="col-md-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="calculated_amount"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Days Allowed</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="0.00"
                                v-model="days_allowed"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Settled Amount</label
                            >
                            <div class="col-md-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="settled_amount"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Days Used</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="0.00"
                                v-model="days_used"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Estimate</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="0.00"
                                v-model="days_used"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                            <my-checkbox
                            :selection.sync="form.estimate_is_provisional"
                            placeholder="Estimate_is_provisional"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Accrual %</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="0.00"
                                v-model="days_used"
                              />
                            </div>
                          </div>
                        </div>
                      </div>


                      <errors :data="errors" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "freightCommission",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/class_societies", this.form);
        this.isLoading = false;
        this.$router.push("/estimates");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
