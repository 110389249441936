<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Payments and Receipts</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Payments and Receipts</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_commenced"
                                placeholder="Make Payment"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_commenced"
                                placeholder="Enter Receipt"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-2 control-label"
                              >Transaction No</label
                            >
                            <div class="col-sm-10">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Transaction No"
                                v-model="form.transaction_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >User Initials</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="User Initials"
                                v-model="form.transaction_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Value Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.value_date"
                                placeholder="Enter Value Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Memo</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Memo"
                                v-model="form.memo"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" style="overflow-x:scroll">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Inv Trans No</th>
                              <th>LOB</th>
                              <th>Date</th>
                              <th>Invoice No</th>
                              <th>Description</th>
                              <th>Due Date</th>
                              <th>Curr</th>
                              <th>Amt Outstanding</th>
                              <th>Amount to Pay</th>
                              <th>Exch Diff</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Remaining to Pay/Total Pay Amount</label
                            >
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="form.remaining_to_pay_total_pay_amount"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="
                                  form.remaining_to_pay_total_pay_amount_2
                                "
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="0.00"
                                v-model="
                                  form.remaining_to_pay_total_pay_amount_1
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Mode</label>
                            <div class="col-sm-9">
                              <my-select
                                disableFormControl="true"
                                :suggestions="activity"
                                placeholder="Select mode"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Date</label>
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.date"
                                placeholder="Enter Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Inv Curr/Base Curr Exch Rate</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Inv Curr/Base Curr Exch Rate"
                                v-model="form.amount_paid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-2 control-label"
                              >Company</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Company"
                                v-model="form.company"
                              />
                            </div>
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Company"
                                v-model="form.company"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Base Curr Amount</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Amount Paid"
                                v-model="form.amount_paid"
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Amount Base"
                                v-model="form.amount_base"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-5">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Remittance Bank</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Remittance Bank"
                                v-model="form.amount_paid"
                              />
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vendor/Customer</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Vendor/Customer"
                                v-model="form.payment_bank_code"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Inv Curr/Bank Curr Exch Rate</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Inv Curr/Bank Curr Exch Rate"
                                v-model="form.payment_bank_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Details</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Details"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bank Curr Amount</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Bank Curr Amount"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Bank Curr Amount"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              ></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Details"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bank Charge</label
                            >
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Bank Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Bank Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Bank Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              ></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Details"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Other Charge</label
                            >
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Other Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Other Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Other Charge"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              ></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Details"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Total Bank Amount</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Total Bank Amount"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Total Bank Amount"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              ></label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Details"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_commenced"
                                placeholder="Advance"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Cheque No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Cheque No"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-2 control-label"
                              >Bank Name</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Bank Name"
                                v-model="form.payment_mode"
                              />
                            </div>
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Bank Name"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_commenced"
                                placeholder="Third-Party Transaction"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_commenced"
                                placeholder="Final"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Reference</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Reference"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <errors :data="errors" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "rebill_invoices",
  data() {
    return {
      rebill_invoices: [],
      form: {},
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let rebill_invoices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.rebill_invoices = rebill_invoices.data.data;
      this.count = rebill_invoices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
