<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Payables</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Payables</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        v-model="searchData"
                      />
                    </div>
                    <div class="col-md-2">
                      <button
                        style="margin-top: 8px"
                        class="btn btn-primary btn-xs"
                        @click="search(searchData)"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12">
                    <a
                      href="#"
                      style="float: right; margin-bottom: 15px"
                      class="btn btn-default"
                      v-if="ExporttcInPayments.length == 0"
                      @click="addToExcelData"
                    >
                      <span v-if="isExcelloading" style="float: right">
                        Generating Excel...
                      </span>
                      <span v-else>Generate Excel</span>
                    </a>
                    <download-excel
                      :title="title"
                      v-else
                      style="float: right; margin-bottom: 15px"
                      class="btn btn-success"
                      :data="excel_payables"
                      :fields="excel_fields"
                      worksheet="Payables"
                      name="Payables.xls"
                      >Download Excel
                    </download-excel>
                    <!-- </a> -->
                  </div>
                </div>
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>TC Code</th>
                        <th>Vessel Name</th>
                        <th>Invoice No</th>
                        <th>PERIOD FROM</th>
                        <th>PERIOD TO</th>
                        <th>Status</th>
                        <th>TYPE</th>
                        <th>DAYS</th>
                        <th>INVOICE NO</th>
                        <th>DUE DATE</th>
                        <th>AMOUNT</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="2"
                      ></loading-table>
                      <!-- <router-link
                        v-for="(tcInPayment, at) in tcInPayments"
                        :key="`tcInPayment${at}`"
                        :to="`/financials/payables/${tcInPayment.payments.tc_in_id}`"
                        tag="tr"
                      > -->
                      <tr
                        v-for="(tcInPayment, at) in tcInPayments"
                        :key="`tcInPayment${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>
                          <a
                            :href="`/financials/payables/${tcInPayment.payments.id}`"
                            ><u>{{ tcInPayment.tc_code }}</u></a
                          >
                        </td>
                        <td>
                          {{
                            tcInPayment.vessel_name
                              ? tcInPayment.vessel_name
                              : ""
                          }}
                        </td>
                        <td>{{ tcInPayment.payments.invoice_no }}</td>
                        <td>{{ tcInPayment.payments.period_to }}</td>
                        <td>{{ tcInPayment.payments.period_from }}</td>
                        <td>{{ tcInPayment.payments.is_approved }}</td>
                        <td>{{ tcInPayment.payments.payment_type }}</td>
                        <td>{{ tcInPayment.payments.days | round2 }}</td>
                        <td>{{ tcInPayment.payments.invoice_no }}</td>
                        <td>{{ tcInPayment.payments.due_date }}</td>
                        <td>{{ tcInPayment.payments.amount | round2 }}</td>
                        <td>
                          {{
                            (tcInPayment.payments.amount - tcInPayment.total)
                              | round2
                          }}
                        </td>
                      </tr>
                      <!-- </router-link> -->
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Payables",
  data() {
    return {
      tcIns: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: "",
      searchData: "",
      tcInPayments: [],
      excel_payables: [],
      ExportPayables: [],
      ExporttcInPayments: [],
      isExcelloading: false,
      excel_fields: {
        "Sr No": "sr_no",
        "TC Code": "tc_code",
        "Vessel Name": "vessel_name",
        "Period From": "perod_from",
        "Period To": "perod_to",
        Type: "type",
        Days: "days",
        "Invoice No": "invoice_no",
        "Due Date": "due_date",
        Amount: "amount",
      },
      total: 0,
    };
  },
  mounted() {
    if (
      this.$route.query.querySearch != "" &&
      this.$route.query.querySearch != null
    ) {
      this.search(this.$route.query.querySearch);
    } else {
      this.getData();
    }
  },

  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      // let tcIns = await axios.get(
      //   `/tc_ins?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      // );
      let tcIns = await axios.get(`/tc_ins/payments?type=tcin`);
      this.tcIns = tcIns.data.data;
      this.tcInPayments = [];
      this.tcIns.forEach((tcin) => {
        tcin.tc_in_payments.forEach((tcInPayment) => {
          if (tcInPayment.is_approved) {
            let total = 0;
            tcInPayment.tc_in_payment_receipts.forEach((tcpr) => {
              total += parseInt(tcpr.amount);
            });
            this.tcInPayments.push({
              tc_code: tcin.tc_code,
              vessel_name: tcin.vessel.vessel_name,
              payments: tcInPayment,
              total: total,
            });
          }
        });
      });

      console.log(this.tcInPayments);
      this.count = tcIns.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;

      this.isLoading = false;
    },
    async search(searchData, page = 1) {
      this.isLoading = true;
      let tcIns = await axios.get(
        `/tc_ins/search?search=${searchData}&type=tcin`
      );
      this.tcIns = tcIns.data.data;
      this.tcInPayments = [];
      this.tcIns.forEach((tcin) => {
        tcin.tc_in_payments.forEach((tcInPayment) => {
          if (tcInPayment.is_approved) {
            this.tcInPayments.push({
              tc_code: tcin.tc_code,
              vessel_name: tcin.vessel.vessel_name,
              payments: tcInPayment,
            });
          }
        });
      });
      this.count = tcIns.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async addToExcelData() {
      this.isExcelloading = true;
      let ExportPayables = await axios.get(
        `/tc_ins/search?type=tcin&search=${this.searchData}`
      );
      this.ExportPayables = ExportPayables.data.data;
      this.ExporttcInPayments = [];
      this.ExportPayables.forEach((tcin) => {
        tcin.tc_in_payments.forEach((tcInPayment) => {
          this.ExporttcInPayments.push({
            tc_code: tcin.tc_code,
            vessel_name: tcin.vessel.vessel_name,
            payments: tcInPayment,
          });
        });
      });
      // let ExportPayables = await axios.get(`/tc_ins/payments?type=tcin`);
      // this.ExportPayables = ExportPayables.data.data;
      this.title = "Payables";
      this.excel_payables = [];
      this.ExporttcInPayments.forEach((payable, a) => {
        this.excel_payables.push({
          sr_no: a + 1,
          tc_code: payable.tc_code,
          vessel_name: payable.vessel_name ? payable.vessel_name : "",
          perod_from: payable.payments.period_to,
          perod_to: payable.payments.period_from,
          type: payable.payments.payment_type,
          days: payable.payments.days,
          invoice_no: payable.payments.invoice_no,
          due_date: payable.payments.due_date,
          amount: payable.payments.amount,
        });
      });
      console.log(this.ExportPayables);
      this.isExcelloading = false;
    },
    // async search() {
    //   // alert("reached");
    //   this.isLoading = true;
    //   let tcIns = await axios.get(
    //     `/tc_ins/payments?type=tcin&search=${searchData}`
    //   );
    //   this.tcIns = tcIns.data.data;
    //   // let tcIns = await axios.get(
    //   //   `/tc_ins/payments?search=${searchData}`
    //   // );
    //   // this.tcIns = tcIns.data.data;
    //   // this.exportToExcel();

    //   this.isLoading = false;
    // },
  },
};
</script>
