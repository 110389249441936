<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/financial/statement/debit-accounts"
            ></back-button>
            Create a new Debit Account
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/financial/statement/debit-accounts"
                >Debit Account</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Code"
                                v-model="form.code"
                              />
                              <span class="error-block">{{ errors.code }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Description</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Description"
                                v-model="form.description"
                              />
                              <span class="error-block">{{ errors.description }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "debitAccountCreate",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        let debitAccount = await axios.post(`/debit_accounts`, this.form);
        this.form = debitAccount.data.data;
        this.isLoading = false;
        this.$router.push("/financial/statement/debit-accounts");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
