<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Final Freight Statement
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Final Freight Statement</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- Final Freight Statement Form -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="table-wrap">
                      <table class="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Vessel Name</th>
                            <th>Vessel Code</th>
                            <th>Voyage No</th>
                            <th>Counterparty</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(vesselSpeedConsumption,
                            ar) in form.vessel_speed_consumptions"
                            :key="`contact${ar}`"
                          >
                            <td
                              @click="
                                deleteVesselSpeedConsumption(
                                  vesselSpeedConsumption
                                )
                              "
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                              {{ ar + 1 }}.
                            </td>
                            <td>
                              <my-select
                                :selection.sync="form.grade_id"
                                :suggestions="grades"
                                placeholder="Select grade"
                              ></my-select>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.req_qty"
                              />
                            </td>
                            <td>
                              <my-select
                                :selection.sync="form.grade_id"
                                :suggestions="grades"
                                placeholder="Select grade"
                              ></my-select>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.req_qty"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="5"
                              align="center"
                              @click="addEmptyVesselSpeedConsumption()"
                            >
                              Add New Row
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <my-checkbox
                      :selection.sync="form.display_base_currency"
                      placeholder="For Relet"
                    ></my-checkbox>
                    <my-checkbox
                      :selection.sync="form.display_base_currency"
                      placeholder="Show Payment Details"
                    ></my-checkbox>
                    <button class="btn btn-primary">
                      View Final Freight Statement
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="table-wrap">
                    <table class="table table-hover table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Vessel Code</th>
                          <th>Voyage No</th>
                          <th>Description</th>
                          <th>Invoice No</th>
                          <th>Date</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Amount Paid</th>
                          <th>Amount Posted</th>
                          <th>Status</th>
                          <th>S</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <td colspan="6">
                            <p style="float:right">Selected Total</p>
                          </td>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Amount Paid</th>
                          <th>Amount Posted</th>
                        </tr>
                        <tr>
                          <td>Invoice Date</td>
                          <td>
                            <my-datepicker
                              :date.sync="form.requested_delivery_date"
                              placeholder="Enter Requseted Delivery Date"
                            ></my-datepicker>
                          </td>
                          <td>Due Date</td>
                          <td>
                            <my-datepicker
                              :date.sync="form.requested_delivery_date"
                              placeholder="Enter Requseted Delivery Date"
                            ></my-datepicker>
                          </td>
                        </tr>
                        <tr>
                          <td>Term</td>
                          <td colspan="3">
                            <input
                              v-uppercase
                              type="text"
                              class="form-control"
                              v-model="form.req_qty"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-"></div>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "tc_voices",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      tc_voices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let tc_voices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.tc_voices = tc_voices.data.data;
      this.count = tc_voices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
