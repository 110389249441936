<template>
    <BunkerDeliveryType></BunkerDeliveryType>
</template>

<script>
import BunkerDeliveryType from '../../masters/bunker-delivery-types.vue'
export default {
    components : {
        BunkerDeliveryType
    }
}
</script>