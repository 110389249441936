<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Calculate Distance</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-6 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Distances</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="editable-table-wrapper">
                  <table
                    class="table table-striped"
                    style="display: inline-table"
                  >
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Port Name</th>
                        <th>Distance</th>
                        <th>Weather</th>
                        <th>Speed</th>
                        <th>Sea</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(distance, at) in distances"
                        :key="`distance${at}`"
                      >
                        <td>{{ at + 1 }}.</td>
                        <td>
                          <my-select
                            :selection.sync="distance.port_name"
                            :suggestions="port_names"
                            placeholder="Select port"
                            @change="updateDistance"
                          ></my-select>
                        </td>
                        <td>{{ distance.distance }}</td>
                        <td>
                          <input
                            v-uppercase
                            type="number"
                            placeholder="Weather Factor"
                            v-model="distance.weather_factor"
                            @change="updateDays"
                          />
                        </td>
                        <td>
                          <input
                            v-uppercase
                            type="number"
                            placeholder="Speed"
                            v-model="distance.speed"
                            @change="updateDays"
                          />
                        </td>
                        <td>
                          {{ distance.sea | round2 }}
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td>
                          <h5>Total</h5>
                        </td>
                        <td>
                          <h5>{{ totalDistance }}</h5>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <h5>{{ totalSeaDays | round2 }}</h5>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="6"
                          align="center"
                          @click="addEmptyDistance()"
                        >
                          Add New Row
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "Distances",
  data() {
    return {
      distances: [],
      port_names: [
        {
          id: "Aabenraa",
          name: "Aabenraa",
        },
        {
          id: "Aahus",
          name: "Aahus",
        },
        {
          id: "Aasgard",
          name: "Aasgard",
        },
        {
          id: "Aggersund",
          name: "Aggersund",
        },
        {
          id: "Agnefest",
          name: "Agnefest",
        },
        {
          id: "Aioi",
          name: "Aioi",
        },
        {
          id: "Ajos",
          name: "Ajos",
        },
        {
          id: "Akinum",
          name: "Akinum",
        },
        {
          id: "Akitsu",
          name: "Akitsu",
        },
        {
          id: "Al Hoceima",
          name: "Al Hoceima",
        },
        {
          id: "Alborg",
          name: "Alborg",
        },
        {
          id: "Alemoa Terminal ",
          name: "Alemoa Terminal ",
        },
        {
          id: "Algiers",
          name: "Algiers",
        },
        {
          id: "Amio",
          name: "Amio",
        },
        {
          id: "Ancona",
          name: "Ancona",
        },
        {
          id: "Antalaha",
          name: "Antalaha",
        },
        {
          id: "Antofagasta",
          name: "Antofagasta",
        },
        {
          id: "Arco Ardjuna",
          name: "Arco Ardjuna",
        },
      ],
      responseDistances: [],
    };
  },
  computed: {
    totalDistance() {
      let totalDistance = 0;
      this.distances.forEach((distance) => {
        totalDistance = totalDistance + parseFloat(distance.distance);
      });
      return totalDistance;
    },
    totalSeaDays() {
      let totalSeaDays = 0;
      this.distances.forEach((distance) => {
        totalSeaDays = totalSeaDays + parseFloat(distance.sea);
      });
      return totalSeaDays;
    },
  },
  mounted() {
    this.addInitialEmptyDistances();
  },
  methods: {
    addInitialEmptyDistances() {
      for (var i = 0; i < 5; i++) {
        this.distances.push({
          port_name: "",
          distance: 0,
          qty: 0,
          weather_factor: 0,
          speed: 0,
          sea: 0,
        });
      }
    },
    addEmptyDistance() {
      this.distances.push({
        port_name: "",
        distance: 0,
        qty: 0,
        weather_factor: 0,
        speed: 0,
        sea: 0,
      });
    },
    async updateDistance() {
      var response;
      var requestDistances = [];
      for (var distance of this.distances) {
        let indexOfDistance = this.distances.indexOf(distance);
        if (indexOfDistance == 0 && distance.port_name == "") break;
        if (indexOfDistance == 1 && distance.port_name == "") break;
        if (
          distance.port_name != "" &&
          indexOfDistance < this.distances.length - 1
        ) {
          if (indexOfDistance == 0) requestDistances.push(distance);

          let nextDistance = this.distances[indexOfDistance + 1];
          if (nextDistance.port_name != "") {
            requestDistances.push(nextDistance);
          }
        }
      }
      console.log(requestDistances);
      if (requestDistances.length >= 2) {
        let response = await axios.post("netpass_distance", requestDistances);
        console.log(response.data);
        response = response.data;
        // response = {
        //   distances: [
        //     {
        //       port_name: "Aabenraa",
        //       distance: null,
        //       weather_factor: 0,
        //       speed: 0,
        //       sea: 0,
        //     },
        //     {
        //       port_name: "Aahus",
        //       distance: 241.5945,
        //       weather_factor: 0,
        //       speed: 0,
        //       sea: 0,
        //       distance_seca: 241.5945,
        //     },
        //   ],
        //   totalDistance: 1095.166,
        //   totalSecaDistance: 900.5873,
        // };

        this.responseDistances = response.distances;
        this.updateDays();
      }
    },
    updateDays() {
      console.log("Update");
      for (var distance of this.distances) {
        let indexOfDistance = this.distances.indexOf(distance);
        if (indexOfDistance > 0) {
          if (this.responseDistances.length > indexOfDistance) {
            distance.distance =
              this.responseDistances[indexOfDistance].distance;
            var sea_days =
              distance.speed != 0 && !isNaN(distance.speed)
                ? (parseFloat(distance.distance) / parseFloat(distance.speed)) *
                  0.0416667
                : 0;
            var extra_sea_days = !isNaN(distance.weather_factor)
              ? (parseFloat(distance.weather_factor) * sea_days) / 100
              : 0;
            distance.sea = sea_days + extra_sea_days;
          }
        }
      }
    },
  },
};
</script>
