<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            {{ type == "tcin" ? "Tc In - List" : "Tc Out - List" }}
            <add-button :link="`/tc-ins/create?type=${type}`"></add-button>
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Tc In - List</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>TC Code</th>
                        <th>Vessel Name</th>
                        <th>Counter Party</th>
                        <th>CP Date</th>
                        <th>No of invoices / No of Approved invoices</th>
                        <th>Broker</th>
                        <th>Vessel Age</th>
                        <th>Vessel Draft</th>
                        <th>Delivery Port</th>
                        <th>Delivery Date</th>
                        <th>Redelivery Date</th>
                        <th>Redelivery Port</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="2"
                      ></loading-table>
                      <router-link
                        v-for="(tcIn, at) in tcIns"
                        :key="`tcIn${at}`"
                        :to="`/tc-ins/${tcIn.id}?type=${type}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ tcIn.tc_code }}</td>
                        <td>
                          {{ tcIn.vessel ? tcIn.vessel.vessel_name : "" }}
                        </td>
                        <td>{{ tcIn.owner ? tcIn.owner.full_name : "" }}</td>
                        <td>{{ tcIn.date }}</td>
                        <td>
                          {{
                            tcIn.tc_in_payments.length +
                            " / " +
                            tcIn.tc_in_approved_payments.length
                          }}
                        </td>
                        <td>
                          {{
                            tcIn.tc_in_brokers.map((b) => b.broker.full_name)
                          }}
                        </td>
                        <td></td>
                        <td>
                          {{ tcIn.vessel ? tcIn.vessel.sw_summer_draft_m : "" }}
                        </td>
                        <td>
                          {{
                            tcIn.delivery_port
                              ? tcIn.delivery_port.port_name
                              : ""
                          }}
                        </td>
                        <td>{{ tcIn.delivery_est_gmt | ddmmyyyy }}</td>
                        <td>{{ tcIn.redelivery_est_gmt | ddmmyyyy }}</td>
                        <td>
                          {{
                            tcIn.redelivery_port
                              ? tcIn.redelivery_port.port_name
                              : ""
                          }}
                        </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "tcIns",
  data() {
    return {
      tcIns: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: "",
      type: "",
    };
  },
  mounted() {
    this.getData();
    this.type = this.$route.query.type;
    console.log(this.type);
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let tcIns = await axios.get(
        `/tc_ins?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}&type=${this.$route.query.type}`
      );
      this.tcIns = tcIns.data.data;
      this.count = tcIns.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
