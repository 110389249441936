<template>
  <TcIn></TcIn>
</template>

<script type="text/javascript">
import TcIn from "./tc_in.vue";
// import axios from "axios";

export default {
  name: "TcInCreate",
  data() {
    return {
      // form: {
      //   vessel_id: "",
      //   tc_code: "",
      //   owner_id: "",
      //   beneficiary_id: "",
      //   laycan_from: "",
      //   laycan_to: "",
      //   date: "",
      //   fixed_by: "",
      //   company_id: "",
      //   department_id: "",
      //   trade_area_id: "",
      //   ref_no: "",
      //   payment_term_id: "",
      //   contract_type_id: "",
      //   status_id: "",
      //   billing_period: "",
      //   billing_schedule: "",
      //   rebillable_admin_fee_percent: 0,
      //   exc_currency_id: 26,
      //   exc_currency_rate: 1,
      //   ref_contract: "",
      //   conf_date_gmt: "",
      //   min_duration: 0,
      //   min_duration_unit: "Days",
      //   min_duration_plus_days: 0,
      //   min_duration_minus_days: 0,
      //   min_estimated_redelivery: "",
      //   max_duration: 0,
      //   max_duration_unit: "Days",
      //   max_duration_plus_days: 0,
      //   max_duration_minus_days: 0,
      //   max_estimated_redelivery: "",
      //   delivery_port_id: "",
      //   delivery_est_gmt: "",
      //   delivery_actual_gmt: "",
      //   delivery_difference: 0,
      //   delivery_remarks: "",
      //   redelivery_port_id: "",
      //   redelivery_est_gmt: "",
      //   redelivery_actual_gmt: "",
      //   redelivery_difference: 0,
      //   redelivery_remarks: "",
      //   est_duration: 0,
      //   actual_duration: 0,
      //   use_local_time: "",
      //   remarks: "",
      //   notes_to_operations: "",
      //   tc_in_pricings: [],
      //   tc_in_brokers: [],
      //   tc_in_expenses: [],
      // },
    };
  },
  components: {
    TcIn,
  },
  mounted() {
    // this.getData();
  },
  methods: {
    // async getData() {
    //   this.isLoading = true;
    //   let form = await axios.get(`/tc_ins/${this.$route.params.tcInId}`);
    //   this.form = form.data.data;
    //   (this.form.vessel_id = this.form.vessel_id || ""),
    //     (this.form.owner_id = this.form.owner_id || ""),
    //     (this.form.billing_period = this.form.billing_period || ""),
    //     (this.form.billing_schedule = this.form.billing_schedule || ""),
    //     (this.form.beneficiary_id = this.form.beneficiary_id || ""),
    //     (this.form.fixed_by = this.form.fixed_by || ""),
    //     (this.form.company_id = this.form.company_id || ""),
    //     (this.form.department_id = this.form.department_id || ""),
    //     (this.form.trade_area_id = this.form.trade_area_id || ""),
    //     (this.form.payment_term_id = this.form.payment_term_id || ""),
    //     (this.form.contract_type_id = this.form.contract_type_id || ""),
    //     (this.form.status_id = this.form.status_id || ""),
    //     (this.form.exc_currency_id = this.form.exc_currency_id || ""),
    //     (this.form.delivery_port_id = this.form.delivery_port_id || ""),
    //     (this.form.redelivery_port_id = this.form.redelivery_port_id || ""),
    //     (this.form.min_duration_unit = this.form.min_duration_unit || ""),
    //     (this.form.max_duration_unit = this.form.max_duration_unit || ""),
    //     this.form.tc_in_pricings.forEach((tc_in_pricing) => {
    //       tc_in_pricing.rate_type = tc_in_pricing.rate_type || "";
    //       tc_in_pricing.pricing_code_id = tc_in_pricing.pricing_code_id || "";
    //       tc_in_pricing.lock = tc_in_pricing.lock || "";
    //       tc_in_pricing.duration_unit = tc_in_pricing.duration_unit || "";
    //     });
    //   this.form.tc_in_brokers.forEach((tc_in_broker) => {
    //     tc_in_broker.broker_id = tc_in_broker.broker_id || "";
    //     tc_in_broker.rate_type = tc_in_broker.rate_type || "";
    //     tc_in_broker.payment_method = tc_in_broker.payment_method || "";
    //     tc_in_broker.is_claim_commissionable =
    //       tc_in_broker.is_claim_commissionable || "";
    //   });
    //   this.form.tc_in_expenses.forEach((tc_in_expense) => {
    //     tc_in_expense.expense_revenue_code_id =
    //       tc_in_expense.expense_revenue_code_id || "";
    //     tc_in_expense.rate_type = tc_in_expense.rate_type || "";
    //   });
    //   this.isLoading = false;
    // },
  },
};
</script>