<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/bunkers"></back-button>
            Create a new bunker
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/bunkers">Bunker</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-7">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                            <div class="col-md-2">
                              <input
                                v-uppercase
                                disabled
                                type="text"
                                class="form-control"
                                placeholder=""
                                v-model="form.dwt_mt"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                placeholder="Voyage Number"
                                v-model="form.voyage_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Req Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.requested_delivery_date"
                                placeholder="Enter Requseted Delivery Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port*</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.port_id"
                                :suggestions="ports"
                                placeholder="Select port"
                              ></my-select>
                              <span class="error-block">{{
                                errors.port_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETA*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="ETA"
                                v-model="form.eta"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.delivery_date"
                                placeholder="Enter Delivery Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Charterer*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Charterer"
                                v-model="form.charterer"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETB*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="ETB"
                                v-model="form.etb"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Window From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.window_from"
                                placeholder="Enter Window From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Owner*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Owner"
                                v-model="form.owner"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETD*</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="ETD"
                                v-model="form.etd"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Window To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.window_to"
                                placeholder="Enter Window To"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Request Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.request_date"
                                placeholder="Enter Request Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Delivery Type*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.delivery_type"
                                :suggestions="Delivery_types"
                                placeholder="Select Delivery Type"
                              ></my-select>
                              <span class="error-block">{{
                                errors.delivery_type
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Last Update</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Last Update"
                                v-model="form.last_update"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Last Updated By*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Last Updated By"
                                v-model="form.last_updated_by"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Agent</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.agent"
                                :suggestions="Agent"
                                placeholder="Select Agent"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.agent"
                                :suggestions="Company"
                                placeholder="Select Company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <h5>Itenary</h5> -->
                      <br />
                      <div class="row">
                        <div class="col-md-8">
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Type</th>
                                  <th>Grade</th>
                                  <th>Req Qty</th>
                                  <th>Min Qty</th>
                                  <th>Max Qty</th>
                                  <th>Opr Qty</th>
                                  <th>Alt Qty</th>
                                  <th>Alt Unit</th>
                                  <th>Sulfur %</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.type
                                      "
                                      :suggestions="type"
                                      placeholder="Select Type"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.grade
                                      "
                                      :suggestions="grade"
                                      placeholder="Select Grade"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Req Qty"
                                      v-model="vesselInPortConsumption.req_qty"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Min Qty"
                                      v-model="vesselInPortConsumption.min_qty"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Max Qty"
                                      v-model="vesselInPortConsumption.max_qty"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Opr Qty"
                                      v-model="vesselInPortConsumption.opr_qty"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Alt Qty"
                                      v-model="vesselInPortConsumption.alt_qty"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.alt_unit
                                      "
                                      :suggestions="AltUnit"
                                      placeholder="Select Alt Unit"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Sulfer %"
                                      v-model="
                                        vesselInPortConsumption.sulfer_percent
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bunkering Details*</label
                            >
                            <div class="col-sm-9">
                              <textarea
                                class="form-control"
                                placeholder="Bunkering Details"
                                v-model="form.bunkering_details"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <h5>Inquiries & Purchases</h5>
                        </div>
                        <div class="col-md-3">
                          <my-checkbox
                            :selection.sync="form.display_base_currency"
                            placeholder="Display in Base Currency"
                          ></my-checkbox>
                        </div>
                      </div>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port</th>
                              <th>Vendor</th>
                              <th>S</th>
                              <th>Status</th>
                              <th>Curr</th>
                              <th>Exch Rate</th>
                              <th>Tax %</th>
                              <th>Range</th>
                              <th>Pay Terms</th>
                              <th>Window From</th>
                              <th>Window To</th>
                              <th>Comments</th>
                              <th>Ext Ref</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(vesselSpeedConsumption,
                              ar) in form.vessel_speed_consumptions"
                              :key="`contact${ar}`"
                            >
                              <td
                                @click="
                                  deleteVesselSpeedConsumption(
                                    vesselSpeedConsumption
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.port_id
                                  "
                                  :suggestions="Port"
                                  placeholder="Select Port"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.vendor
                                  "
                                  :suggestions="Vendor"
                                  placeholder="Select Vendor"
                                ></my-select>
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.display_base_currency"
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  disabled
                                  placeholder="Inquiry"
                                  v-model="vesselSpeedConsumption.inquiry"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.currency
                                  "
                                  :suggestions="Currency"
                                  placeholder="Enter Currency"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Exch Rate"
                                  v-model="vesselSpeedConsumption.exch_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Tax %"
                                  v-model="vesselSpeedConsumption.tax_percent"
                                />
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.range"
                                ></my-checkbox>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselSpeedConsumption.payment_terms
                                  "
                                  :suggestions="PaymentTerms"
                                  placeholder="Enter Pay Terms"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.window_from"
                                  placeholder="Enter Window From"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.window_to"
                                  placeholder="Enter Window To"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Comments"
                                  v-model="
                                    vesselSpeedConsumption.comments
                                  "
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Ext Ref"
                                  v-model="vesselSpeedConsumption.ext_ref"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="14"
                                align="center"
                                @click="addEmptyVesselSpeedConsumption()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="14"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
