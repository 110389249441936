<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/cargo-names"></back-button>
            Create a new cargo name
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/cargo-names">Class Societies</router-link>
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Cargo Group</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.cargo_group_id"
                                :suggestions="cargoGroups"
                                placeholder="Enter Cargo Group"
                              ></my-select>
                              <span class="error-block">{{
                                errors.cargo_group_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Full Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Full Name"
                                v-model="form.full_name"
                              />
                              <span class="error-block">{{
                                errors.full_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Short Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Short Name"
                                v-model="form.short_name"
                              />
                              <span class="error-block">{{
                                errors.short_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Stow Factor M3/MT</label
                            >
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="M3/MT"
                                v-model="form.stow_factor_m3_mt"
                              />
                            </div>
                            <div class="col-sm-5">
                              <label class="col-sm-3 control-label"
                                >FT3/MT</label
                              >
                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="FT3/MT"
                                  v-model="form.stow_factor_ft3_mt"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Cargo Class</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Cargo Class"
                                v-model="form.cargo_class"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >UN Number</label
                            >
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="UN Number"
                                v-model="form.un_number"
                              />
                            </div>
                            <div class="col-sm-5">
                              <label class="col-sm-3 control-label"
                                >Class</label
                              >
                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="text"
                                  class="form-control"
                                  placeholder="Class"
                                  v-model="form.class"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >IMO Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="IMO Name"
                                v-model="form.imo_name"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Product Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Product Code"
                                v-model="form.product_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >IBC Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="IBC Code"
                                v-model="form.ibc_code"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Default CP Unit</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.default_cp_unit_id"
                                :suggestions="units"
                                placeholder="Enter Default CP Unit ID"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >IMSBC Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="IMSBC Code"
                                v-model="form.imsbc_code"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Capacity Basis</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.capacity_basis"
                                :suggestions="capacityBasis"
                                placeholder="Enter Capacity Basis"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bill By</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.bill_by"
                                :suggestions="billBy"
                                placeholder="Enter Bill By"
                              ></my-select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Description</label
                            >
                            <div class="col-sm-9">
                              <textarea
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Description"
                                v-model="form.description"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <my-checkbox
                              :selection.sync="form.pre_clearance_for_us_canada"
                              placeholder="Pre-clearance for US and canada"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="form.dangerous"
                              placeholder="Dangerous"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="form.inactive"
                              placeholder="Inactive"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="form.special_handling_required"
                              placeholder="Special Handling Required"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="form.is_active"
                              placeholder="Is Active"
                            ></my-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "cargoNameCreate",
  data() {
    return {
      form: {
        cargo_group_id: "",
        default_cp_unit_id: "",
        capacity_basis: "",
        bill_by: "",
        is_active: 1,
      },
      cargoGroups: [],
      units: [],
      capacityBasis: [],
      billBy: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("cargo_names/masters");
      masters = masters.data;
      masters.cargo_groups.forEach((cargo_group) => {
        this.cargoGroups.push({
          id: cargo_group.id,
          name: cargo_group.code + " - " + cargo_group.description,
          code: cargo_group.code,
          description: cargo_group.description,
          class: cargo_group.class,
          cp_unit_id: cargo_group.cp_unit_id,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit + " - " + unit.description,
        });
      });
      masters.capacity_basis.forEach((basis) => {
        this.capacityBasis.push({
          id: basis.id,
          name: basis.name,
        });
      });
      masters.bill_by.forEach((by) => {
        this.billBy.push({
          id: by.id,
          name: by.name,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/cargo_names", this.form);
        this.isLoading = false;
        this.$router.push("/cargo-names");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
