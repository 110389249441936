<template>
  <section>
    <a href="javascript:void" @click="openVoyageLaytimesModal" title="Laytimes"
      >Laytime</a
    >

    <div :id="`myVoyageLaytimesModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Laytime Calculations</h4>
          <!-- <VoyageLaytimeCreate
            :count="0"
            :form="form"
            :portActivities="portActivities"
            @onLaytimeUpdated="onLaytimeUpdated"
          ></VoyageLaytimeCreate> -->
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Sr. No.</th>
                <th>Counterparty</th>
                <!-- <th>Type</th> -->
                <th>Ports</th>
                <th>Dem/Des</th>
                <th>Calculation Type</th>
                <th>Amount (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(voyageLaytime, vl) in form.voyage_laytimes"
                :key="`voyageLaytime${vl}`"
              >
                <td
                  v-if="
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                  "
                >
                  <VoyageLaytimeCreate
                    :count="voyageLaytime.position"
                    :form="form"
                    :portActivities="portActivities"
                    @onLaytimeUpdated="onLaytimeUpdated"
                    :voyageItenariesLD="voyageItenariesLD"
                  ></VoyageLaytimeCreate>
                </td>
                <td v-else></td>
                <td>
                  <!-- <span @click="deleteVoyageLaytime(laytime)">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                  </span>
                  &nbsp;&nbsp; -->

                  {{
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                      ? voyageLaytime.position
                      : ""
                  }}.
                </td>
                <td>
                  {{
                    form.voyage_cargos.length > 0
                      ? form.voyage_cargos[0].charterer.short_name
                      : ""
                  }}
                </td>
                <td>
                  {{
                    voyageLaytime.port_name
                      ? voyageLaytime.port_name.port_name
                      : ""
                  }}
                </td>
                <td>
                  {{
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                      ? voyageLaytime.total_demurrage_discharge
                      : ""
                  }}
                </td>
                <!-- <td>
                  {{
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                      ? voyageLaytime.invoice_date
                      : ""
                  }}
                </td> -->
                <td>
                  {{
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                      ? voyageLaytime.calculation_type
                      : "" | round2
                  }}
                </td>
                <td>
                  {{
                    vl == 0 ||
                    voyageLaytime.position !=
                      form.voyage_laytimes[vl - 1].position
                      ? voyageLaytime.total_amount
                      : "" | round2
                  }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="12"
                  align="center"
                  @click="addEmptyVoyageLaytime()"
                >
                  Add New Row
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveMyVoyageLaytimes"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyVoyageLaytimesModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import VoyageLaytimeCreate from "./create.vue";

export default {
  data() {
    return {
      voyageItenariesLD: [],
    };
  },
  props: [
    "form",
    "portActivities",
    "voyageItenaries",
    "portFunctions",
    "count",
  ],
  watch: {
    voyageItenaries: {
      handler() {
        this.voyageItenariesWatcher();
      },
      deep: true,
    },
  },
  components: {
    VoyageLaytimeCreate,
  },
  methods: {
    async openVoyageLaytimesModal() {
      var modal = document.getElementById("myVoyageLaytimesModal" + this.count);
      modal.style.display = "block";
    },
    closeMyVoyageLaytimesModal() {
      var modal = document.getElementById("myVoyageLaytimesModal" + this.count);
      modal.style.display = "none";
    },
    saveMyVoyageLaytimes() {
      this.$emit("calculateCost");
      this.closeMyVoyageLaytimesModal();
    },
    onLaytimeUpdated() {
      this.$emit("onLaytimeUpdated");
    },
    voyageItenariesWatcher() {
      console.log("Laytime Watcher");
      let loadingPortFunction = this.portFunctions.find((pf) => pf.code == "L");
      let dischargingPortFunction = this.portFunctions.find(
        (pf) => pf.code == "D"
      );
      let voyageItenariesLD = [];
      if (loadingPortFunction) {
        let loadingVIs = this.voyageItenaries.filter(
          (voyageItenary) =>
            voyageItenary.port_function_id == loadingPortFunction.id
        );
        if (loadingVIs)
          loadingVIs.forEach((lvi) => {
            voyageItenariesLD.push(lvi);
          });
      }
      if (dischargingPortFunction) {
        let dischargingVIs = this.voyageItenaries.filter(
          (voyageItenary) =>
            voyageItenary.port_function_id == dischargingPortFunction.id
        );
        if (dischargingVIs)
          dischargingVIs.forEach((dvi) => {
            voyageItenariesLD.push(dvi);
          });
      }
      this.voyageItenariesLD = voyageItenariesLD;
      console.log("Laytime Watcher 1");
      // To delete the voyageLaytime if it is deleted from the voyageItenary
      this.form.voyage_laytimes.forEach((voyageLaytime) => {
        let checkIfPortExists = false;
        voyageItenariesLD.forEach((voyageItenary) => {
          if (voyageItenary.port_name_id == voyageLaytime.port_name_id) {
            checkIfPortExists = true;
            voyageLaytime.port_name = voyageItenary.port_name;
            voyageLaytime.cargo_name = voyageItenary.cargo_name;
            voyageLaytime.cargo_name_id = voyageItenary.cargo_name_id;
          }
        });
        if (!checkIfPortExists) {
          this.form.voyage_laytimes.splice(
            this.form.voyage_laytimes.indexOf(voyageLaytime),
            1
          );
        }
      });
      // To add the new voyageLaytime if it is added in the voyageItenary
      // voyageItenariesLD.forEach((voyageItenary) => {
      //   let checkIfPortExists = false;
      //   this.form.voyage_laytimes.forEach((voyageLaytime) => {
      //     if (voyageItenary.port_name_id == voyageLaytime.port_name_id) {
      //       checkIfPortExists = true;
      //     }
      //   });
      //   if (!checkIfPortExists) {
      //     // console.log(voyageItenary);
      //     this.form.voyage_laytimes.push({
      //       port_name: voyageItenary.port_name,
      //       port_name_id: voyageItenary.port_name_id,
      //       cargo_name: voyageItenary.cargo_name,
      //       cargo_name_id: voyageItenary.cargo_id,
      //       demurrage_discharge: "",
      //       invoice_date: "",
      //       amount: 0,
      //       laytime_status_id: "",
      //       allowed: 0,
      //       used: 0,
      //       deduction: 0,
      //       balance: 0,
      //       show_demurrage_start: "",
      //       include_in_p_and_l: "",
      //       continuous_laytime: "",
      //       hh_mm_format: "",
      //       once_on_always_on_demurrage: "",
      //       actual_qty: 0,
      //       voyage_laytime_port_activities: [],
      //       voyage_laytime_deductions: [],
      //     });
      //   }
      // });
    },
    deleteVoyageLaytime(laytime) {
      let r = confirm("Are you sure you want to delete this laytime");
      if (r) {
        this.form.voyage_laytimes.splice(
          this.form.voyage_laytimes.indexOf(laytime),
          1
        );
      }
    },
    addEmptyVoyageLaytime() {
      let voyageLaytime = {
        position:
          this.form.voyage_laytimes.length > 0
            ? this.form.voyage_laytimes[this.form.voyage_laytimes.length - 1]
                .position + 1
            : 1,
        port_name:
          this.voyageItenariesLD.length > 0
            ? this.voyageItenariesLD[0].port_name
            : "",
        port_name_id:
          this.voyageItenariesLD.length > 0
            ? this.voyageItenariesLD[0].port_name_id
            : "",
        cargo_name:
          this.voyageItenariesLD.length > 0
            ? this.voyageItenariesLD[0].cargo_name
            : "",
        cargo_name_id:
          this.voyageItenariesLD.length > 0
            ? this.voyageItenariesLD[0].cargo_name_id
            : "",
        demurrage_discharge: "",
        invoice_date: "",
        amount: 0,
        laytime_status_id: "",
        allowed: 0,
        used: 0,
        deduction: 0,
        balance: 0,
        show_demurrage_start: "",
        include_in_p_and_l: "",
        continuous_laytime: "",
        hh_mm_format: "",
        once_on_always_on_demurrage: "",
        actual_qty: 0,
        voyage_laytime_port_activities: [],
        voyage_laytime_deductions: [],
      };
      this.form.voyage_laytimes.push(voyageLaytime);
    },
  },
};
</script>
