<template>
  <div>
    <Nav></Nav>

    <LeftSideBar></LeftSideBar>

    <RightSideBar></RightSideBar>

    <router-view />
  </div>
</template>

<script type="text/javascript">
import Nav from "@/views/auth-layout/nav.vue";
import LeftSideBar from "@/views/auth-layout/left-side-bar.vue";
import RightSideBar from "@/views/auth-layout/right-side-bar.vue";

export default {
  name: "AuthLayout",
  components: {
    Nav,
    LeftSideBar,
    RightSideBar,
  },
};
</script>