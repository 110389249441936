<template>
    <BunkerInvoice></BunkerInvoice>
</template>

<script>
import BunkerInvoice from '../../bunker/create.vue'
export default {
    components : {
        BunkerInvoice
    }
}
</script>