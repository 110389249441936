<template>
  <section>
    <h5>Expenses</h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Description</th>
            <th>Rate Type</th>
            <th>Rate</th>
            <th>Comm. Applicable</th>
            <th>From GMT</th>
            <th>To GMT</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tc_in_expense, ar) in form.tc_in_expenses"
            :key="`contact${ar}`"
          >
            <td @click="deleteTcInExpense(tc_in_expense)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ ar + 1 }}.
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_expense.expense_revenue_code_id"
                :suggestions="expenseRevenueCodes"
                placeholder="Description"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_expense.rate_type"
                :suggestions="expenseRateTypes"
                placeholder="Rate Type"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Rate"
                v-model="tc_in_expense.rate"
              />
            </td>
            <td>
              <my-checkbox
                :selection.sync="form.is_commission_applicable"
                placeholder=""
              ></my-checkbox>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_expense.from_gmt"
                placeholder="From GMT"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_expense.to_gmt"
                placeholder="To GMT"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Remarks"
                v-model="tc_in_expense.remarks"
              />
            </td>
          </tr>
          <tr>
            <td colspan="9" align="center" @click="addEmptyTcInExpense()">
              Add New Row
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: ["form", "expenseRevenueCodes", "expenseRateTypes"],
  watch: {
    form: {
      handler() {
        this.addInitialExpenses();
      },
      deep: true,
    },
    expenseRevenueCodes: {
      handler() {
        this.addInitialExpenses();
      },
      deep: true,
    },
  },
  mounted() {
    this.addInitialExpenses();
  },
  methods: {
    addInitialExpenses() {
      if (this.form.tc_in_expenses.length != 0) return;
      let codes = [
        {
          name: "BALLAST BONUS",
          rate_type: "Lumpsum",
          rate: 0,
        },
        {
          name: "GRATUITIES",
          rate_type: "Average Month",
          rate: 1500,
        },
        {
          name: "VICTUALLING",
          rate_type: "Monthly",
          rate: 0,
        },
        {
          name: "REPR. EXP./ ALLOWANCES",
          rate_type: "Per 30 Days",
          rate: 0,
        },
        {
          name: "LASHING AND STOWAGE",
          rate_type: "Per 30 Days",
          rate: 0,
        },
        {
          name: "ILOHC CHARGES",
          rate_type: "Lumpsum",
          rate: 5000,
        },
        {
          name: "INTHC",
          rate_type: "Lumpsum",
          rate: 0,
        },
      ];
      codes.forEach((code) => {
        let expenseRevenueCode = this.expenseRevenueCodes.find(
          (erc) => erc.name == code.name
        );
        let expenseRevenueCodeId = "";
        if (expenseRevenueCode) {
          expenseRevenueCodeId = expenseRevenueCode.id;
          this.form.tc_in_expenses.push({
            expense_revenue_code_id: expenseRevenueCodeId,
            rate_type: code.rate_type,
            rate: code.rate,
            is_commission_applicable: 0,
            from_gmt: "",
            to_gmt: "",
            remarks: "",
          });
        }
      });
    },
    addEmptyTcInExpense() {
      this.form.tc_in_expenses.push({
        expense_revenue_code_id: "",
        rate_type: "",
        rate: 0,
        is_commission_applicable: 0,
        from_gmt: "",
        to_gmt: "",
        remarks: "",
      });
    },
    deleteTcInExpense(tcInExpense) {
      this.form.tc_in_expenses.splice(
        this.form.tc_in_expenses.indexOf(tcInExpense),
        1
      );
    },
  },
};
</script>
