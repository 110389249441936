<template>
  <!-- Row -->
  <div class="row">
    <div class="col-md-8">
      <div class="panel panel-default card-view">
        <div class="panel-wrapper collapse in">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-wrap form-horizontal">
                  <div class="row">
                    <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Name</label>
                        <div class="col-sm-9">W
                          <input type="text" :value="`Estimate ${count}`">
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Vessel</label>
                        <div class="col-sm-9">
                          <my-select
                            @change="updateVesselDetails"
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            placeholder="Select vessel"
                          ></my-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">TC In Code</label>
                        <div class="col-sm-9">
                          <my-select
                            :disabled="
                              form.vessel_id == null || form.vessel_id == ''
                            "
                            :selection.sync="form.tc_in_id"
                            :suggestions="tcIns"
                            placeholder="Select TC In"
                            @change="onTcInChanged"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Ballast Port</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :disabled="
                              form.vessel_id == null || form.vessel_id == ''
                            "
                            :selection.sync="form.ballast_port_id"
                            :suggestions="portNames"
                            placeholder="Select ballast port"
                            @change="updateBallastPort"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Reposition Port</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :disabled="
                              form.vessel_id == null || form.vessel_id == ''
                            "
                            :selection.sync="form.reposition_port_id"
                            :suggestions="portNames"
                            placeholder="Select reposition port"
                            @change="updateRepositionPort"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Vessel DWT</label>
                        <div class="col-sm-9">
                          <input
                            disabled
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Vessel DWT"
                            v-model="form.vessel_dwt"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Ballast Bonus</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Ballast Bonus"
                            v-model="form.ballast_bonus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Opr Type</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.operation_type_id"
                            :suggestions="operationTypes"
                            placeholder="Select Opr Type"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Daily Hire/Addr</label
                        >
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Daily Hire"
                            v-model="form.daily_hire"
                            @blur="calculateCost"
                          />
                        </div>
                        <div class="col-sm-4">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Addr Comm"
                            v-model="form.daily_addr_comm"
                            @blur="calculateCost"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="form.operation_type_id == 4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >TCO Daily Hire/Comm.</label
                        >
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Daily Hire"
                            v-model="form.tco_daily_hire"
                            @blur="calculateCost"
                          />
                        </div>
                        <div class="col-sm-4">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="TCO Comm"
                            v-model="form.tco_daily_comm"
                            @blur="calculateCost"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">DFW %</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="DFW %"
                            v-model="form.dfw"
                            @blur="updateItenaryDetails"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Chtr Specialist</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.specialist_id"
                            :suggestions="users"
                            placeholder="Select Chtr Specialist"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Speed Bal/Ldn</label
                        >
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Ballast"
                            v-model="form.ballast_speed"
                            @blur="updateItenaryDetails"
                          />
                        </div>
                        <div class="col-sm-4">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="Laden"
                            v-model="form.laden_speed"
                            @blur="updateItenaryDetails"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Company</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.company_id"
                            :suggestions="companies"
                            placeholder="Select Company"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <!-- <label class="col-sm-3 control-label">Category</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Category" v-model="form.add_1">
                        </div> -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Trade Area</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.trade_area_id"
                            :suggestions="tradeAreas"
                            placeholder="Select trade area"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Commencing</label>
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.commencing_date"
                            placeholder="Enter Commencing Date"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Piracy Routing</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.piracy_routing"
                            :suggestions="piracyRoutings"
                            placeholder="Select piracy routing"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Completing</label>
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.completing_date"
                            placeholder="Enter Completing Date"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >ECA Routing</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.eca_routing"
                            :suggestions="ecaRoutings"
                            placeholder="Select eca routing"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6" v-if="form.operation_type_id == 4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >TCO Duration</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="TCO Duration"
                            v-model="form.tco_duration"
                            @change="updateItenaryDetails"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" v-else>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Voyage Days</label
                        >
                        <div class="col-sm-9">
                          <input
                            disabled=""
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Voyage Days"
                            v-model="form.voyage_days"
                            
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <Cargos
                    :form="form"
                    :tcIn="tcIn"
                    :cargos="form.cargos"
                    :cargoNames="cargoNames"
                    :units="units"
                    :freightTypes="freightTypes"
                    :charterers="charterers"
                    :currencies="currencies"
                    :perDays="perDays"
                    @calculateCost="calculateCost"
                    @onEstimateCargoUpdated="onEstimateCargoUpdated"
                    :addresses="addresses"
                    :companies="companies"
                    :brokerRateTypes="brokerRateTypes"
                    :brokerPaymentMethods="brokerPaymentMethods"
                    :brokerCommissionables="brokerCommissionables"
                    :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
                  ></Cargos>

                  <hr />

                  <Itenaries
                    :itenaries="form.itenaries"
                    :cargos="form.cargos"
                    :allCargoNames="cargoNames"
                    :portNames="portNames"
                    :portFunctions="portFunctions"
                    :laytimeTerms="laytimeTerms"
                    @updateMiles="updateMiles"
                    @updateItenaryDetails="updateItenaryDetails"
                  ></Itenaries>

                  <hr />

                  <Bunkers
                    :selectedVessel="selectedVessel"
                    :bunkersMaster="form.bunkersMaster"
                    :bunkerSettings="form.bunkerSettings"
                    :bunkers="form.bunkers"
                    @updateBunkers="updateBunkers"
                    :portNames="portNames"
                    :tcIn="tcIn"
                  ></Bunkers>

                  <Fixture :form="form"> </Fixture>

                  <errors :data="errors" />

                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group mb-0">
                        <div class="col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="createEstimate"
                            :text="
                              estimateId ? 'Update Estimate' : 'Create Estimate'
                            "
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PandL :form="form" @calculateCost="calculateCost"></PandL>
  </div>
  <!-- /Row -->
</template>

<script type="text/javascript">
import axios from "axios";
import PandL from "@/components/estimate/pandl.vue";
import Cargos from "@/components/estimate/estimate_cargos/index.vue";
import Bunkers from "@/components/estimate/bunkers.vue";
import Itenaries from "@/components/estimate/itenaries.vue";
import Fixture from "@/components/estimate/fixture.vue";
// import moment from "moment";

export default {
  name: "estimateComponent",
  data() {
    return {
      form: {
        vessel_id: "",
        tc_in_id: "",
        ballast_port_id: "",
        reposition_port_id: "",
        operation_type_id: "",
        vessel_dwt: "",
        daily_hire: 0,
        tco_daily_hire: 0,
        daily_addr_comm: 0,
        tco_daily_comm: 0,
        ballast_bonus: 0,
        opr_type: "",
        dfw: 5,
        charter_specialist_id: "",
        ballast_speed: 0,
        laden_speed: 0,
        company_id: "",
        trade_area_id: "",
        piracy_routing: "Default",
        eca_routing: "Default",
        voyage_days: 0,
        commencing_date: "",
        completing_date: "",
        freight_cost: 0,
        freight_commission_cost: 0,
        hire_cost: 0,
        hire_commission_cost: 0,
        bunker_cost: 0,
        cve_cost: 0,
        port_expenses_cost: 0,
        other_expenses_cost: 0,
        cargos: [],
        itenaries: [],
        bunkersMaster: [],
        otherExpenses: [],
        bunkerSettings: {
          initialQuantity: 0,
          initialPrice: 0,
        },
        estimate_brokers: [],
        Ohire_cost: 0,
        Ohire_commission_cost: 0,
        Ocve_cost: 0,
        Oother_expenses_cost: 0,
        tco_duration: 0,
      },
      tcIn: {},
      vessels: [],
      portNames: [],
      portFunctions: [],
      oprTypes: [],
      users: [],
      addresses: [],
      companies: [],
      charterers: [],
      tradeAreas: [],
      piracyRoutings: [],
      ecaRoutings: [],
      cargoNames: [],
      units: [],
      freightTypes: [],
      currencies: [],
      laytimeTerms: [],
      isLoading: false,
      commencing_date: "",
      selectedVessel: {},
      perDays: [],
      tcIns: [],
      operationTypes: [],
      brokerRateTypes: [],
      brokerPaymentMethods: [],
      brokerCommissionables: [],
      brokerFreightPaymentMethods: [],
    };
  },
  props: ["count", "estimateId"],
  watch: {
    // estimateId: "updateFormData",
    oprTypes: "onOprTypesUpdated",
  },
  components: {
    PandL,
    Bunkers,
    Itenaries,
    Cargos,
    Fixture,
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async updateFormData() {
      if (this.estimateId != "") {
        let form = await axios.get(
          `/estimates/${this.$route.params.estimateId}`
        );
        // let form1 = await axios.get(
        //   `/estimates/${this.$route.params.estimateId}`
        // );
        // console.log(form1.data.data);
        form = form.data.data;
        form.cargos = form.estimate_cargos;
        form.estimate_itenaries.forEach((ei) => {
          ei.port = ei.port_name;
        });
        form.itenaries = form.estimate_itenaries;
        form.bunkersMaster = form.estimate_bunker_masters;
        form.ballast_port_id = form.ballast_port_id ?? "";
        form.reposition_port_id = form.reposition_port_id ?? "";
        form.vessel_dwt = form.vessel_dwt ?? "";
        form.opr_type = form.opr_type ?? "";
        form.specialist_id = form.specialist_id ?? "";
        form.company_id = form.company_id ?? "";
        form.trade_area_id = form.trade_area_id ?? "";
        form.piracy_routing = form.piracy_routing ?? "";
        form.eca_routing = form.eca_routing ?? "";
        form.Ohire_cost = form.Ohire_cost ?? 0;
        form.Ohire_commission_cost = form.Ohire_commission_cost ?? 0;
        form.Ocve_cost = form.Ocve_cost ?? 0;
        form.Oother_expenses_cost = form.Oother_expenses_cost ?? 0;

        form.bunkersMaster.forEach(
          (bm) => (bm.bunkers = bm.estimate_bunker_master_bunkers)
        );
        // form.bunkersMaster = [], // extra added
        form.otherExpenses = form.estimate_other_expenses;
        this.form = form;

        form.bunkerSettings = {
          initialQuantity: 0,
          initialPrice: 0,
        };
        // this.updateMiles()
      }
    },

    onOprTypesUpdated() {
      let operationType = this.operationTypes.find(
        (operationType) => operationType.type == "TCOV"
      );

      if (operationType) {
        this.form.operation_type_id = operationType.id;
      }
    },
    updateVesselDetails() {
      let selectedVessel = this.vessels.find(
        (vessel) => vessel.id == this.form.vessel_id
      );
      if (selectedVessel) {
        this.form.vessel_dwt = selectedVessel.dwt_mt;
        this.form.ballast_speed = selectedVessel.ballast_speed;
        this.form.laden_speed = selectedVessel.laden_speed;
      }
      this.selectedVessel = selectedVessel;
      if (selectedVessel) {
        selectedVessel.tc_ins.forEach((tcIn) => {
          tcIn.name = tcIn.tc_code;
        });
        this.tcIns = selectedVessel.tc_ins.filter(
          (tcIn) => tcIn.is_tc_in_out == "tcin"
        );
      }
      this.updateBallastPort();
      this.updateRepositionPort();
    },
    onTcInChanged() {
      let tcIn = this.tcIns.find((tcIn) => tcIn.id == this.form.tc_in_id);
      if (tcIn) {
        // tcInPricing.forEach((tcInPricing) => {
        // let fromDate = moment(from_gmt)
        // let toDate = moment(to_gmt)
        // let currentDate = moment()
        // if(currentDate.isBetween(fromDate, toDate, 'days', true)) {
        // }
        // });
        if (!this.form.id) {
          if (tcIn.tc_in_pricings.length > 0) {
            this.form.daily_hire = tcIn.tc_in_pricings[0].hire_rate;
          }
          let daily_addr_comm = 0;
          tcIn.tc_in_brokers.forEach((tcInBroker) => {
            daily_addr_comm =
              parseFloat(daily_addr_comm) + parseFloat(tcInBroker.rate ?? 0);
          });
          this.form.commencing_date = tcIn.delivery_est_gmt;
          this.form.completing_date = tcIn.redelivery_est_gmt;
          this.form.trade_area_id = tcIn.trade_area_id;
          this.form.company_id = tcIn.company_id;
          this.form.ballast_port_id = tcIn.delivery_port_id;
          this.form.reposition_port_id = tcIn.redelivery_port_id;
          this.form.daily_addr_comm = daily_addr_comm;
        }

        this.tcIn = tcIn;
      }
    },
    updateBallastPort() {
      if (this.form.id == null) {
        let portName = this.portNames.find(
          (pn) => pn.id == this.form.ballast_port_id
        );
        let portFunction = this.portFunctions.find((pf) => pf.code == "C");
        // let laytimeTerm = this.laytimeTerms.find(lt => lt.factor == 1)
        if (portName) {
          let itenary = {
            position: 1,
            port: portName,
            // port: portName ? portName.name : "",
            port_name_id: portName ? portName.id : "",
            port_name: portName,
            miles: 0,
            speed: this.form.ballast_speed,
            sea_days: 0,
            extra_sea_days: 0,
            port_function_id: portFunction ? portFunction.id : "",
            cargo: "",
            cargo_id: "",
            load_discharge_qty: 0,
            load_discharge_rate: 0,
            laytime_term_id: "",
            port_days: 0,
            extra_port_days: 0,
            port_expenses: 0,
            other_expenses: 0,
            demurrage: 0,
          };
          if (this.form.itenaries.length == 0) {
            this.form.itenaries.push(itenary);
          } else {
            if (this.form.itenaries[0]["port_function_id"] == portFunction.id)
              this.form.itenaries.shift();
            this.form.itenaries.unshift(itenary);
          }
          this.updateMiles();
        }
      }
    },
    updateRepositionPort() {
      console.log("Rep port");
      if (this.form.id == null) {
        let portName = this.portNames.find(
          (pn) => pn.id == this.form.reposition_port_id
        );
        let portFunction = this.portFunctions.find((pf) => pf.code == "T");
        // let laytimeTerm = this.laytimeTerms.find(lt => lt.factor == 1)
        if (portName) {
          let itenary = {
            position: 2,
            port: portName,
            // port: portName ? portName.name : "",
            port_name_id: portName ? portName.id : "",
            port_name: portName,
            miles: 0,
            speed: this.form.ballast_speed,
            sea_days: 0,
            extra_sea_days: 0,
            port_function_id: portFunction ? portFunction.id : "",
            cargo: "",
            cargo_id: "",
            load_discharge_qty: 0,
            load_discharge_rate: 0,
            laytime_term_id: "",
            port_days: 0,
            extra_port_days: 0,
            port_expenses: 0,
            other_expenses: 0,
            demurrage: 0,
          };
          if (this.form.itenaries.length == 0) {
            this.form.itenaries.push(itenary);
          } else {
            if (
              this.form.itenaries[this.form.itenaries.length - 1][
                "port_function_id"
              ] == portFunction.id
            )
              this.form.itenaries.pop();
            this.form.itenaries.push(itenary);
          }
          this.updateMiles();
        }
      }
    },
    async updateMiles() {
      // if(this.form.estimate_itenaries || this.form.estimate_itenaries.length > 0)
      // let commencingPortFunction = this.portFunctions.find(
      //   (pf) => pf.code == "C"
      // );
      // let terminatingPortFunction = this.portFunctions.find(
      //   (pf) => pf.code == "T"
      // );
      // let ballastPortItenary = this.form.itenaries.find(
      //   (it) => it.port_function_id == commencingPortFunction.id
      // );
      // let terminatingPortItenary = this.form.itenaries.find(
      //   (it) => it.port_function_id == terminatingPortFunction.id
      // );
      let firstPortItenary =
        this.form.itenaries.length > 0 ? this.form.itenaries[0] : null;
      let lastPortItenary =
        this.form.itenaries.length > 0
          ? this.form.itenaries[this.form.itenaries.length - 1]
          : null;
      console.log("Update Miles 4");
      if (
        firstPortItenary != null &&
        lastPortItenary != null &&
        this.form.itenaries.length >= 2
      ) {
        let payload = [];
        payload.push({
          StartLon: parseFloat(
            this.form.itenaries[0].port
              ? this.form.itenaries[0].port.longitude
              : 0
          ),
          StartLat: parseFloat(
            this.form.itenaries[0].port
              ? this.form.itenaries[0].port.latitude
              : 0
          ),
          StartPortCode: this.form.itenaries[0].port
            ? this.form.itenaries[0].port.port_code
            : "",
          EndLon: parseFloat(
            this.form.itenaries[1].port
              ? this.form.itenaries[1].port.longitude
              : 0
          ),
          EndLat: parseFloat(
            this.form.itenaries[1].port
              ? this.form.itenaries[1].port.latitude
              : 0
          ),
          EndPortCode: this.form.itenaries[1].port
            ? this.form.itenaries[1].port.port_code
            : "",
          GreatCircleInterval: 0,
          AllowedAreas: [2, 5, 7, 14, 10003],
          SecaAvoidance: 0,
          AslCompliance: 0,
        });
        for (var i = 1; i <= this.form.itenaries.length - 2; i++) {
          payload.push({
            StartLon: parseFloat(
              this.form.itenaries[i].port
                ? this.form.itenaries[i].port.longitude
                : 0
            ),
            StartLat: parseFloat(
              this.form.itenaries[i].port
                ? this.form.itenaries[i].port.latitude
                : 0
            ),
            StartPortCode: this.form.itenaries[i].port
              ? this.form.itenaries[i].port.port_code
              : "",
            EndLon: parseFloat(
              this.form.itenaries[i + 1].port
                ? this.form.itenaries[i + 1].port.longitude
                : 0
            ),
            EndLat: parseFloat(
              this.form.itenaries[i + 1].port
                ? this.form.itenaries[i + 1].port.latitude
                : 0
            ),
            EndPortCode: this.form.itenaries[i + 1].port
              ? this.form.itenaries[i + 1].port.port_code
              : "",
            GreatCircleInterval: 0,
            AllowedAreas: [2, 5, 7, 14, 10003],
            SecaAvoidance: 0,
            AslCompliance: 0,
          });
        }
        let response = await axios.post(
          "/third-party-route-api/saveRoute",
          payload
        );
        response = response.data.data;
        for (i = 1; i <= this.form.itenaries.length - 1; i++) {
          this.form.itenaries[i]["miles"] =
            response[i - 1]["result_json"]["totalDistance"] ?? 0;
        }
      }
      this.updateItenaryDetails();
    },
    updateItenaryDetails() {
      let cargoQty = 0;
      this.form.voyage_days = 0;
      let voyage_days = 0;
      this.form.itenaries.forEach((itenary) => {
        // let commencingPortFunction = this.portFunctions.find(pf => pf.code == 'C')
        console.log("Port Functions");
        console.log(this.portFunctions);
        let loadingPortFunction = this.portFunctions.find(
          (pf) => pf.code == "L"
        );
        let dischargePortFunction = this.portFunctions.find(
          (pf) => pf.code == "D"
        );
        console.log(loadingPortFunction);
        // let terminatingPortFunction = this.portFunctions.find(pf => pf.code == 'T')
        // Speed
        if (cargoQty == 0) itenary.speed = this.form.ballast_speed;
        else itenary.speed = this.form.laden_speed;
        // Sea Days
        itenary.sea_days =
          itenary.speed != 0 ? (itenary.miles / itenary.speed) * 0.0416667 : 0;
        itenary.extra_sea_days =
          itenary.sea_days != 0
            ? (parseInt(this.form.dfw) * itenary.sea_days) / 100
            : 0;
        // Port Days for loading
        if (
          itenary.port_function_id == loadingPortFunction.id ||
          itenary.port_function_id == dischargePortFunction.id
        ) {
          let laytimeTerm = this.laytimeTerms.find(
            (lt) => lt.id == itenary.laytime_term_id
          );
          let factor = laytimeTerm ? laytimeTerm.factor : 1;
          if (
            itenary.load_discharge_qty != 0 &&
            itenary.load_discharge_rate != 0
          )
            itenary.port_days =
              factor *
              (itenary.load_discharge_qty / itenary.load_discharge_rate);
        }
        if (itenary.port_function_id == loadingPortFunction.id) {
          cargoQty += itenary.load_discharge_qty;
        }
        // Port Days for discharge
        if (itenary.port_function_id == dischargePortFunction.id) {
          cargoQty -= itenary.load_discharge_qty;
        }
        voyage_days +=
          parseFloat(itenary.sea_days) +
          parseFloat(itenary.extra_sea_days) +
          parseFloat(itenary.port_days) +
          parseFloat(itenary.extra_port_days);
      });
      if (this.form.operation_type_id == 4) {
        if (
          this.form.tco_duration == 0 ||
          this.form.tco_duration == null ||
          this.form.tco_duration < voyage_days
        )
          this.form.tco_duration = voyage_days;
        else voyage_days = this.form.tco_duration;
      }

      this.form.voyage_days = voyage_days;
      this.updateBunkers();
    },
    updateBunkers() {
      // console.log('Update Bunkers')
      let cargoQty = 0;
      let total = this.form.bunkerSettings.initialQuantity;
      // this.form.bunkers = []
      this.form.bunkersMaster.forEach((bunkerMaster) => {
        total = bunkerMaster.initialQuantity;
        bunkerMaster.totalSeaConsumption = 0;
        bunkerMaster.totalPortConsumption = 0;
        bunkerMaster.totalConsumption = 0;

        let tempBunkers = bunkerMaster.bunkers;
        let finalBunkerMasterBunkers = [];
        // bunkerMaster.bunkers = []
        this.form.itenaries.forEach((itenary) => {
          // console.log(itenary.port_name);
          let portFunction = this.portFunctions.find(
            (pf) => pf.id == itenary.port_function_id
          );
          let seaConsumption = 0;
          let portConsumption = 0;

          if (cargoQty == 0)
            seaConsumption =
              (parseFloat(itenary.sea_days) +
                parseFloat(itenary.extra_sea_days)) *
              parseFloat(bunkerMaster.sea_ballast_consumption);
          else
            seaConsumption =
              (parseFloat(itenary.sea_days) +
                parseFloat(itenary.extra_sea_days)) *
              parseFloat(bunkerMaster.sea_laden_consumption);
          bunkerMaster.totalSeaConsumption += seaConsumption;
          bunkerMaster.totalConsumption += seaConsumption;

          let robArr = total - seaConsumption;
          total = robArr;
          // console.log('Temp Bunkers')
          // console.log(bunkerMaster.bunkers);
          // console.log('Port Function')
          // console.log(portFunction)
          // console.log('Itenary')
          // console.log(itenary)
          if (portFunction) {
            if (portFunction.code == "L") {
              portConsumption =
                parseFloat(itenary.port_days) *
                parseFloat(bunkerMaster.port_loading_consumption);
            } else if (portFunction.code == "D") {
              portConsumption =
                parseFloat(itenary.port_days) *
                parseFloat(bunkerMaster.port_discharge_consumption);
            } else {
              portConsumption =
                parseFloat(itenary.port_days) *
                parseFloat(bunkerMaster.port_idle_consumption);
            }
            portConsumption = portConsumption =
              parseFloat(portConsumption) +
              parseFloat(itenary.extra_port_days) *
                bunkerMaster.port_idle_consumption;
          } else {
            portConsumption =
              (parseFloat(itenary.port_days) +
                parseFloat(itenary.extra_port_days)) *
              bunkerMaster.port_loading_consumption;
          }
          bunkerMaster.totalPortConsumption += portConsumption;
          bunkerMaster.totalConsumption += portConsumption;
          let tempBunker = null;
          if (portFunction)
            tempBunker = tempBunkers.find(
              (bunker) =>
                bunker.port_name_id == itenary.port_name_id &&
                bunker.port_function_id == itenary.port_function_id
            );
          // tempBunker = tempBunkers.find(bunker => bunker.port_name_id == itenary.port_name_id && portFunction.id == itenary.port_function_id)
          else
            tempBunker = tempBunkers.find(
              (bunker) => bunker.port_name_id == itenary.port_name_id
            );
          // console.log('Temp Bunker')
          // console.log(tempBunker)
          let receive = tempBunker ? parseFloat(tempBunker.receive) : 0;
          let robDpt = total - portConsumption + receive;
          total = robDpt;
          // let bunker = bunkerMaster.bunkers.find(bunker => bunker.port_name_id == itenary.port_name_id)
          // if(bunker == null)
          finalBunkerMasterBunkers.push({
            port_name_id: itenary.port_name_id,
            port_name: itenary.port_name,
            port_function_id: portFunction ? portFunction.id : "",
            port_function: portFunction ? portFunction.code : "",
            sea_consumption: seaConsumption,
            rob_arr: robArr,
            port_consumption: portConsumption,
            receive: receive,
            price: tempBunker ? tempBunker.price : 0,
            rob_dpt: robDpt,
          });
          // else
          //   finalBunkerMasterBunkers.push(bunker)
          finalBunkerMasterBunkers.forEach(
            (finalBunkerMasterBunker, i) =>
              (finalBunkerMasterBunker.position = i + 1)
          );
          // bunkerMaster.bunkers.push()

          let loadingPortFunction = this.portFunctions.find(
            (pf) => pf.code == "L"
          );
          let dischargePortFunction = this.portFunctions.find(
            (pf) => pf.code == "D"
          );
          if (itenary.port_function_id == loadingPortFunction.id) {
            cargoQty += itenary.load_discharge_qty;
          }
          if (itenary.port_function_id == dischargePortFunction.id) {
            cargoQty -= itenary.load_discharge_qty;
          }
        });
        // console.log('Update Bunkers 2')
        bunkerMaster.bunkers = finalBunkerMasterBunkers;
      });
      this.calculateCost();
    },
    onEstimateCargoUpdated() {
      this.form.cargos.forEach((cargo) => {
        if (cargo.option_percent != null && cargo.qty != null) {
          cargo.min_qty =
            parseFloat(cargo.qty) -
            (parseFloat(cargo.option_percent) * parseFloat(cargo.qty)) / 100;
          cargo.max_qty =
            parseFloat(cargo.qty) +
            (parseFloat(cargo.option_percent) * parseFloat(cargo.qty)) / 100;
        }
      });
    },
    calculateCost() {
      // Revenue
      if (this.form.operation_type_id != 4) {
        this.form.freight_cost = 0;
        this.form.freight_commission_cost = 0;
        this.form.cargos.forEach((cargo) => {
          this.form.freight_cost +=
            parseFloat(cargo.qty) * parseFloat(cargo.freight_rate);
          this.form.freight_commission_cost =
            (this.form.freight_cost * parseFloat(cargo.freight_commission)) /
            100;
        });
      } else {
        this.form.Ohire_cost =
          parseFloat(this.form.tco_daily_hire) *
          parseFloat(this.form.voyage_days);
        this.form.Ohire_commission_cost =
          (this.form.Ohire_cost * parseFloat(this.form.tco_daily_comm)) / 100;
        // CVE Cost
        this.form.Ocve_cost = (parseFloat(this.form.voyage_days) * 1500) / 30;
        // Other Expenses
        this.form.Oother_expenses_cost = 0;
        if (this.form.otherExpenses != null)
          this.form.otherExpenses.forEach((expense) => {
            if (expense.expense_type == "REVENUE")
              this.form.Oother_expenses_cost += parseFloat(expense.cost);
          });
      }

      // Expense
      this.form.hire_cost =
        parseFloat(this.form.daily_hire) * parseFloat(this.form.voyage_days);
      this.form.hire_commission_cost =
        (this.form.hire_cost * parseFloat(this.form.daily_addr_comm)) / 100;
      // Bunkers
      this.form.bunker_cost = 0;
      this.form.bunkersMaster.forEach((bunkerMaster) => {
        let totalInitialBunkerCPPrice =
          parseFloat(bunkerMaster.initialQuantity) *
          parseFloat(bunkerMaster.initialPrice);
        let receivedBunkerPrice = 0;
        let robBunkerPrice = 0;
        bunkerMaster.bunkers.forEach((bunker) => {
          if (bunker.receive != 0)
            receivedBunkerPrice +=
              parseFloat(bunker.receive) * parseFloat(bunker.price);
          robBunkerPrice =
            parseFloat(bunker.rob_dpt) * parseFloat(bunkerMaster.initialPrice);
        });
        this.form.bunker_cost +=
          totalInitialBunkerCPPrice + receivedBunkerPrice - robBunkerPrice;
      });
      // CVE Cost
      this.form.cve_cost = (parseFloat(this.form.voyage_days) * 1500) / 30;
      // Port Expenses
      this.form.port_expenses_cost = 0;
      this.form.itenaries.forEach((itenary) => {
        this.form.port_expenses_cost += parseFloat(itenary.port_expenses);
      });
      // Other Expenses
      this.form.other_expenses_cost = 0;
      this.form.itenaries.forEach((itenary) => {
        this.form.other_expenses_cost +=
          parseFloat(itenary.other_expenses) + parseFloat(itenary.demurrage);
      });
      if (this.form.otherExpenses != null)
        this.form.otherExpenses.forEach((expense) => {
          if (expense.expense_type == "EXPENSE")
            this.form.other_expenses_cost += parseFloat(expense.cost);
        });
      // console.log('Update Bunkers 5')
    },
    async createEstimate() {
      try {
        this.isLoading = true;
        await axios.post("/estimates", this.form);
        this.isLoading = false;
        this.$router.push("/estimates");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/class_societies", this.form);
        this.isLoading = false;
        this.$router.push("/estimates");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getMasters() {
      let masters = await axios.get("estimates/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        vessel.name = vessel.vessel_name;
        this.vessels.push(vessel);
      });
      masters.port_names.forEach((portName) => {
        this.portNames.push({
          id: portName.id,
          port_code: portName.port_code,
          latitude: portName.latitude,
          longitude: portName.longitude,
          name: portName.port_name,
        });
      });
      masters.opr_types.forEach((oprType) => {
        this.oprTypes.push({
          id: oprType,
          name: oprType,
        });
      });
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          name: user.first_name + " " + user.last_name,
        });
      });
      masters.addresses.forEach((address) => {
        this.addresses.push({
          id: address.id,
          name: address.short_name + " -" + address.full_name,
        });
      });
      masters.companies.forEach((company) => {
        this.companies.push({
          id: company.id,
          name: company.short_name + " -" + company.full_name,
        });
      });
      masters.charterers.forEach((company) => {
        this.charterers.push({
          id: company.id,
          name: company.short_name + " -" + company.full_name,
        });
      });
      masters.trade_areas.forEach((tradeArea) => {
        this.tradeAreas.push({
          id: tradeArea.id,
          name: tradeArea.code + " -" + tradeArea.description,
        });
      });
      masters.piracy_routings.forEach((piracyRouting) => {
        this.piracyRoutings.push({
          id: piracyRouting,
          name: piracyRouting,
        });
      });
      masters.eca_routings.forEach((ecaRouting) => {
        this.ecaRoutings.push({
          id: ecaRouting,
          name: ecaRouting,
        });
      });
      masters.cargo_names.forEach((cargoName) => {
        this.cargoNames.push({
          id: cargoName.id,
          name: cargoName.short_name + " - " + cargoName.full_name,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit + " - " + unit.description,
        });
      });
      masters.freight_types.forEach((freighType) => {
        this.freightTypes.push({
          id: freighType.id,
          name: freighType.type + " - " + freighType.description,
        });
      });
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.name + " - " + currency.description,
        });
      });
      masters.port_functions.forEach((port_function) => {
        this.portFunctions.push({
          id: port_function.id,
          code: port_function.code,
          name: port_function.code + " - " + port_function.port_operation,
        });
      });
      masters.laytime_terms.forEach((laytime_term) => {
        this.laytimeTerms.push({
          id: laytime_term.id,
          factor: laytime_term.factor,
          name: laytime_term.term,
        });
      });
      masters.perDays.forEach((perDay) => {
        this.perDays.push({
          id: perDay.id,
          name: perDay.name,
        });
      });
      masters.operation_types.forEach((operation_type) => {
        this.operationTypes.push({
          id: operation_type.id,
          type: operation_type.type,
          name: operation_type.type + " - " + operation_type.description,
        });
      });
      masters.broker_rate_types.forEach((brokerRateType) => {
        this.brokerRateTypes.push({
          id: brokerRateType,
          name: brokerRateType,
        });
      });
      masters.broker_payment_methods.forEach((brokerPaymentMethod) => {
        this.brokerPaymentMethods.push({
          id: brokerPaymentMethod,
          name: brokerPaymentMethod,
        });
      });
      masters.brokerFreightPaymentMethods.forEach(
        (brokerFreightPaymentMethod) => {
          this.brokerFreightPaymentMethods.push({
            id: brokerFreightPaymentMethod,
            name: brokerFreightPaymentMethod,
          });
        }
      );
      masters.broker_commissionables.forEach((brokerCommissionable) => {
        this.brokerCommissionables.push({
          id: brokerCommissionable,
          name: brokerCommissionable,
        });
      });
      if (this.estimateId) this.updateFormData();
    },
  },
};
</script>

<style>
@media only screen and (min-width: 600px) {
  .pandl {
    position: fixed;
    width: 27%;
    top: 90px;
  }
}
</style>