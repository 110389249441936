<template>
    <LaytimeCalculation></LaytimeCalculation>
</template>

<script>
import LaytimeCalculation from '../../laytimes/laytime-calculation-setup.vue'
export default {
    components : {
        LaytimeCalculation
    }
}
</script>