<template>
  <section>
    <h5><u>Cargoes</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Sr. No.</th>
            <th>Cargo</th>
            <th>C/P Qty</th>
            <th>Unit</th>
            <th>Freight Type</th>
            <th>Freight Rate</th>
            <th>Freight Commission</th>
            <th>Charter</th>
            <th>USD</th>
            <th>Exchange Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cargo, ar) in cargos" :key="`cargo${ar}`">
            <td>
              <CPTerms
                :count="ar"
                :form="form"
                :tcIn="tcIn"
                :estimateCargo="cargo"
                :cargoNames="cargoNames"
                :addresses="addresses"
                :companies="companies"
                :charterers="charterers"
                :perDays="perDays"
                :freightTypes="freightTypes"
                @onEstimateCargoUpdated="onEstimateCargoUpdated"
                @calculateCost="calculateCost"
                :brokerRateTypes="brokerRateTypes"
                :brokerPaymentMethods="brokerPaymentMethods"
                :brokerCommissionables="brokerCommissionables"
                :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
              ></CPTerms>
            </td>
            <td @click="deleteCargo(cargo)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ ar + 1 }}.
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.cargo_name_id"
                :suggestions="cargoNames"
                placeholder="Select cargo"
                @change="updateCargo(cargo)"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="CP Qty"
                v-model="cargo.qty"
                @blur="calculateCost"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.unit_id"
                :suggestions="units"
                placeholder="Select unit"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.freight_type_id"
                :suggestions="freightTypes"
                placeholder="Enter freight type"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Freight rate"
                v-model="cargo.freight_rate"
                @blur="calculateCost"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Freight commission"
                v-model="cargo.freight_commission"
                @blur="calculateCost"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.charterer_id"
                :suggestions="charterers"
                placeholder="Enter charterer"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.exc_currency_id"
                :suggestions="currencies"
                placeholder="Enter currency"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Exch. rate"
                v-model="cargo.exc_currency_rate"
              />
            </td>
          </tr>
          <tr>
            <td colspan="11" align="center" @click="addEmptyCargo()">
              Add New Row
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
import CPTerms from "./cp_terms.vue";
export default {
  name: "estimateCargos",
  data() {
    return {
      myCargos: [],
    };
  },
  components: {
    CPTerms,
  },
  props: [
    "form",
    "tcIn",
    "cargos",
    "cargoNames",
    "units",
    "freightTypes",
    "charterers",
    "currencies",
    "addresses",
    "companies",
    "perDays",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerCommissionables",
    "brokerFreightPaymentMethods",
  ],
  watch: {
    cargos: "getMyCargos",
  },
  mounted() {
    this.getMyCargos();
  },
  methods: {
    getMyCargos() {
      this.myCargos = this.cargos;
    },
    addEmptyCargo() {
      this.cargos.push({
        name: "",
        cargo_name_id: "",
        qty: 0,
        unit_id: "1",
        freight_type_id: "1",
        freight_rate: 0,
        freight_commission: 0,
        charterer_id: "",
        exc_currency_id: "1",
        exc_currency_rate: "1",
        exc_exchange_rate: "1",
        option_percent: 0,
        option_type_id: 1,
        min_qty: 0,
        max_qty: 0,
        demurrage_amount: 0,
        despatch_amount: 0,
        per_day_per_hr: 1,
        load_demurrage_amount: 0,
        load_despatch_amount: 0,
        discharge_demurrage_amount: 0,
        discharge_despatch_amount: 0,
        laycan_from: "",
        laycan_to: "",
        company_id: "",
      });
    },
    updateCargo(cargo) {
      let cargoName = this.cargoNames.find((c) => c.id == cargo.cargo_name_id);
      if (cargoName) cargo.name = cargoName.name;
    },
    deleteCargo(cargo) {
      this.cargos.splice(this.cargos.indexOf(cargo), 1);
      // this.cargos = this.cargos.filter(ar => ar != cargo)
    },
    calculateCost() {
      this.$emit("calculateCost");
    },
    onEstimateCargoUpdated() {
      this.$emit("onEstimateCargoUpdated");
    },
  },
};
</script>