<template>
  <section>
    <a href="javascript:void" @click="openVoyageDelaysModal" title="Delays"
      >Delays</a
    >

    <div id="myVoyageDelaysModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Delay</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Vessel</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Vessel"
                        v-model="form.vessel.vessel_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Voy no.</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Voyage no"
                        v-model="form.voyage_no"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Commencing</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Commencing"
                        v-model="form.voyage_commencing_date"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Completing</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Completing"
                        v-model="form.voyage_completing_date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5><u>Activities</u></h5>
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Activity</th>
                  <th>Reason</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Hours</th>
                  <th>Ded Adj (TCI)</th>
                  <th>Miles</th>
                  <th
                    v-for="(
                      voyageBunkerMaster, vbm
                    ) in form.voyage_bunker_masters"
                    :key="`voyageBunkerMaster${vbm}`"
                  >
                    {{ voyageBunkerMaster.fuel_name }} Qty
                  </th>
                  <!-- <th
                    v-for="(
                      voyageBunkerMaster, vbm
                    ) in form.voyage_bunker_masters"
                    :key="`voyageBunkerMaster${vbm}`"
                  >
                    {{ voyageBunkerMaster.fuel_name }} ROB
                  </th> -->
                  <th>TCI %</th>
                  <th>TCO %</th>
                  <!-- <th>TCI Daily Cost</th> -->
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(voyageDelay, vd) in form.voyage_delays"
                  :key="`voyageDelay${vd}`"
                >
                  <td @click="deleteVoyageDelay(voyageDelay)">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    {{ vd + 1 }}.
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageDelay.delay_type_id"
                      :suggestions="delayTypes"
                      placeholder="Activity"
                    ></my-select>
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageDelay.delay_reason_id"
                      :suggestions="delayReasons"
                      placeholder="Select reason"
                    ></my-select>
                  </td>
                  <td>
                    <my-datepicker
                      :disableFormControl="true"
                      :date.sync="voyageDelay.from"
                      placeholder="From"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>
                    <my-datepicker
                      :disableFormControl="true"
                      :date.sync="voyageDelay.to"
                      placeholder="To"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>
                    <input
                      v-uppercase
                      placeholder="hours"
                      v-model="voyageDelay.hours"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      placeholder="Ded Adj (TCI)"
                      v-model="voyageDelay.ded_adj_tci"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      placeholder="Miles"
                      v-model="voyageDelay.miles"
                    />
                  </td>
                  <td
                    v-for="(
                      voyageDelayBunker, vdb
                    ) in voyageDelay.voyage_delay_bunkers"
                    :key="`voyageDelayBunker${vdb}`"
                  >
                    <input
                      v-uppercase
                      placeholder="Qty"
                      v-model="voyageDelayBunker.qty"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      placeholder="TCI %"
                      v-model="voyageDelay.tci_percent"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      placeholder="TCO %"
                      v-model="voyageDelay.tco_percent"
                    />
                  </td>
                  <!-- <td>
                    <input
                      v-uppercase
                      placeholder="TCI Daily Cost"
                      v-model="voyageDelay.tci_daily_rate"
                    />
                  </td> -->
                  <td>
                    <input
                      style="width: 200px !important"
                      v-uppercase
                      placeholder="Remarks"
                      v-model="voyageDelay.remarks"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="14"
                    align="center"
                    @click="addEmptyVoyageDelay()"
                  >
                    Add New Row
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onDelayUpdated">
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyVoyageDelaysModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "voyageDelays",
  data() {
    return {};
  },
  props: ["form", "delayReasons", "delayTypes", "counterPartyTypes"],
  components: {},
  methods: {
    async openVoyageDelaysModal() {
      var modal = document.getElementById("myVoyageDelaysModal");
      modal.style.display = "block";
    },
    closeMyVoyageDelaysModal() {
      var modal = document.getElementById("myVoyageDelaysModal");
      modal.style.display = "none";
    },
    onDelayUpdated() {
      this.$emit("onDelayUpdated");
      this.closeMyVoyageDelaysModal();
    },
    addEmptyVoyageDelay() {
      let voyageDelay = {
        delay_type_id: "",
        delay_reason_id: "",
        from: 0,
        to: 0,
        hours: 0,
        ded_adj_tci: 0,
        miles: 0,
        tci_percent: 0,
        tco_percent: 0,
        tci_daily_rate: 0,
        remarks: "",
        voyage_delay_bunkers: [],
      };
      this.form.voyage_bunker_masters.forEach((vbm) => {
        voyageDelay.voyage_delay_bunkers.push({
          fuel_type_id: vbm.fuel_type_id,
          qty: 0,
        });
      });

      this.form.voyage_delays.push(voyageDelay);
    },
    deleteVoyageDelay(voyageDelay) {
      this.form.voyage_delays.splice(
        this.form.voyage_delays.indexOf(voyageDelay),
        1
      );
    },
  },
};
</script>
