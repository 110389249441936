<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/estimates"></back-button> 
            Create a new estimate
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/estimates">Class Societies</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-8">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Vessel" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Ballast Port</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Ballast Port" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">TC In Code</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="TC In Code" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Reposition Port</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Reposition Port" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel DWT</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Vessel DWT" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Ballast Bonus</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Ballast Bonus" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Daily Hire/Addr</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Daily Hire/Addr" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Opr Type</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Opr Type" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">DFW %</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="DFW %" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Chtr Specialist</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Chtr Specialist" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Speed Bal/Ldn</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Speed Bal/Ldn" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Company</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Company" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Category</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Category" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Trade Area</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Trade Area" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Commencing</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Commencing" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Piracy Routing</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Piracy Routing" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Completing</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Completing" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ECA Routing</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="ECA Routing" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Voyage Days</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Voyage Days" v-model="form.add_1">
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5>Bunkers</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Ballast Speed</th>
                              <th>Laden Speed</th>
                              <th>Port Loading</th>
                              <th>Port Discharging</th>
                              <th>Port Idle</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Cargoes</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>N</th>
                              <th>Cargo</th>
                              <th>C/P Qty</th>
                              <th>Unit</th>
                              <th>Freight Type</th>
                              <th>Freight Rat</th>
                              <th>Lumpsum</th>
                              <th>Charter</th>
                              <th>USD</th>
                              <th>Exchange Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                            </tr>
                            <tr>
                              <td colspan="11" align="center" @click="addEmptyAddressContact()">Add New Row</td>
                            </tr>
                            <tr><td></td></tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Itenerary</h5>
                      <h6>(Cargo)</h6>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port</th>
                              <th>Miles</th>
                              <th>Spd</th>
                              <th>SDays</th>
                              <th>XSD</th>
                              <th>F</th>
                              <th>Cargo</th>
                              <th>L/D Qty</th>
                              <th>L/D Rate</th>
                              <th>Terms</th>
                              <th>PDays</th>
                              <th>XPD</th>
                              <th>PortExp</th>
                              <th>OExp$/t</th>
                              <th>Dem</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                            </tr>
                            <tr>
                              <td colspan="11" align="center" @click="addEmptyAddressContact()">Add New Row</td>
                            </tr>
                            <tr><td></td></tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />
                      
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
        <div class="col-md-4">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap">

                      <table class="table table-hover table-striped">
                        <caption>P&L</caption>
                        <thead>
                          <tr>
                            <th>All</th>
                            <th>Estimated</th>
                          </tr>
                        </thead>
                        <tbody>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'estimateCreate',
  data () {
    return {
      form: {},
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
  },
  methods: {
    async save() {
      try {
        this.isLoading = true
        await axios.post('/class_societies', this.form)
        this.isLoading = false
        this.$router.push('/estimates')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>