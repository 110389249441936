<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
          <h5 class="txt-dark">
            Voyage: {{ form.vessel ? form.vessel.vessel_name : "" }}
            {{ form.id }} <loading :isLoading="isLoading"></loading>
          </h5>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <!-- <div class="col-md-2">Estimate</div> -->
                <div class="col-md-2">
                  <VoyageFreightInvoices
                    :form="form"
                    :voyageCargos="form.voyage_cargos"
                    :paymentTerms="paymentTerms"
                    :freightTypes="freightTypes"
                    :portFunctions="portFunctions"
                    :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
                    @onSaveVoyageFreightInvoiceCreate="
                      onSaveVoyageFreightInvoiceCreate
                    "
                    @calculateFreightInvoices="calculateFreightInvoices"
                    :voyageBrokers="form.voyage_brokers"
                    :addresses="addresses"
                    :brokerRateTypes="brokerRateTypes"
                    :brokerPaymentMethods="brokerPaymentMethods"
                  ></VoyageFreightInvoices>
                </div>
                <!-- <div class="col-md-2">Commission</div> -->
                <div class="col-md-2">
                  <VoyageOthers
                    :form="form"
                    :addresses="addresses"
                    :revExpenses="revExpenses"
                    :currencies="currencies"
                    :expenseRevenueCodes="expenseRevenueCodes"
                  ></VoyageOthers>
                </div>
                <div class="col-md-2">
                  <VoyageLaytimes
                    count="0"
                    :form="form"
                    :portActivities="portActivities"
                    :portFunctions="portFunctions"
                    @onLaytimeUpdated="onLaytimeUpdated"
                    :voyageItenaries="form.voyage_itenaries"
                  ></VoyageLaytimes>
                </div>
                <div class="col-md-2">
                  <VoyageDelays
                    :form="form"
                    :delayReasons="delayReasons"
                    :delayTypes="delayTypes"
                    :counterPartyTypes="counterPartyTypes"
                    @onDelayUpdated="onDelayUpdated"
                  ></VoyageDelays>
                </div>
                <div class="col-md-2">
                  <VoyageFreightCommissions
                    :form="form"
                    :voyageFreightInvoices="form.voyage_freight_invoices"
                    :delayTypes="delayTypes"
                    :counterPartyTypes="counterPartyTypes"
                    @onDelayUpdated="onDelayUpdated"
                  ></VoyageFreightCommissions>
                </div>
                <!-- <div class="col-md-2">
                  <div class="dropdown pull-left">
                    <a
                      class="dropdown-toggle weight-500"
                      data-toggle="dropdown"
                      href="#"
                      aria-expanded="false"
                      role="button"
                    >
                      <span>Commission</span>
                      &nbsp;
                      <i class="zmdi zmdi-chevron-down caret-down"></i>
                    </a>
                    <ul
                      class="dropdown-menu bullet dropdown-menu-right"
                      role="menu"
                    >
                      <VoyageFreightCommissions
                        :form="form"
                        :delayReasons="delayReasons"
                        :delayTypes="delayTypes"
                        :counterPartyTypes="counterPartyTypes"
                        @onDelayUpdated="onDelayUpdated"
                      ></VoyageFreightCommissions>
                      <li role="presentation">
                        <a href="javascript:void(0)" role="menuitem"
                          ><i class="icon wb-reply" aria-hidden="true"></i> Tc
                          In Commission</a
                        >
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- <div class="col-md-2">Bunker Liftings</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <!-- Row -->
          <div class="row">
            <div class="col-md-8">
              <div class="panel panel-default card-view">
                <div class="panel-wrapper collapse in">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-sm-12 col-xs-12">
                        <div class="form-wrap form-horizontal">
                          <!-- Internal Row -->
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Vessel*</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.vessel_id"
                                    :suggestions="vessels"
                                    placeholder="Select vessel"
                                    @change="onVesselChange"
                                  ></my-select>
                                  <span class="error-block">{{
                                    errors.vessel_id
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Voyage Status</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.voyage_status_id"
                                    :suggestions="voyage_statuses"
                                    placeholder="Select Voyage Status"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Fixture</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.estimate_id"
                                    :suggestions="estimates"
                                    placeholder="Select estimate"
                                    @change="onEstimateChange"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >TC Code*</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.tc_in_id"
                                    :suggestions="tc_ins"
                                    placeholder="Select TC Code"
                                  ></my-select>
                                  <span class="error-block">{{
                                    errors.tc_in_id
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >TCO Code*</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.tc_out_id"
                                    :suggestions="tc_outs"
                                    placeholder="Select TCO Code"
                                  ></my-select>
                                  <span class="error-block">{{
                                    errors.tc_out_id
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Voyage No.*</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    disabled
                                    v-uppercase
                                    class="form-control"
                                    placeholder="Voyage Number"
                                    v-model="form.voyage_no"
                                  />
                                  <span class="error-block">{{
                                    errors.voyage_no
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Daily Hire*</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    class="form-control"
                                    placeholder="Daily Hire"
                                    v-model="form.daily_hire"
                                  />
                                  <span class="error-block">{{
                                    errors.daily_hire
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Operation Type</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.operation_type_id"
                                    :suggestions="operation_types"
                                    placeholder="Select Operation Type"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Trade Area</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.trade_area_id"
                                    :suggestions="tradeAreas"
                                    placeholder="Select trade area"
                                  ></my-select>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Commencing Date</label
                                >
                                <div class="col-sm-9">
                                  <my-datepicker
                                    :date.sync="form.voyage_commencing_date"
                                    placeholder="Enter Voyage Commencing Date"
                                  ></my-datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Completing Date</label
                                >
                                <div class="col-sm-9">
                                  <my-datepicker
                                    :date.sync="form.voyage_completing_date"
                                    placeholder="Enter Voyage Compleing Date"
                                  ></my-datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Specialist</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.specialist_id"
                                    :suggestions="users"
                                    placeholder="Select specialist"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Piracy Routing</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.piracy_routing"
                                    :suggestions="piracyRoutings"
                                    placeholder="Select piracy routing"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >ECA Routing</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.eca_routing"
                                    :suggestions="ecaRoutings"
                                    placeholder="Select ECA Routing"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >DWF</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="DWF"
                                    v-model="form.dwf"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Bunker Calculation Type</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="
                                      form.bunker_calculation_type
                                    "
                                    :suggestions="bunkerCalculationTypes"
                                    placeholder="Select Calculation Type"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />

                          <VoyageItineraries
                            :form="form"
                            :voyageItenaries="form.voyage_itenaries"
                            :voyageCargos="form.voyage_cargos"
                            :voyageBunkerMasters="form.voyage_bunker_masters"
                            :portNames="portNames"
                            :portFunctions="portFunctions"
                            :laytimeTerms="laytimeTerms"
                            :portActivities="portActivities"
                            :reasonTypes="reasonTypes"
                            :allCargoNames="cargoNames"
                            :agents="agents"
                            :currencies="currencies"
                            @updateItenaryDetails="updateItenaryDetails"
                            @onPortExpensesChanged="onPortExpensesChanged"
                            @onPortCargosChanged="onPortCargosChanged"
                            @updateMiles="updateMiles"
                            @onBunkerChanged="onBunkerChanged"
                          >
                          </VoyageItineraries>

                          <hr />

                          <VoyageCargos
                            :form="form"
                            :voyageCargos="form.voyage_cargos"
                            :cargoNames="cargoNames"
                            :units="units"
                            :freightTypes="freightTypes"
                            :addresses="addresses"
                            :currencies="currencies"
                            :perDays="perDays"
                            :brokerRateTypes="brokerRateTypes"
                            :brokerPaymentMethods="brokerPaymentMethods"
                            :brokerCommissionables="brokerCommissionables"
                            :brokerFreightPaymentMethods="
                              brokerFreightPaymentMethods
                            "
                            @onVoyageCargoUpdated="onVoyageCargoUpdated"
                          ></VoyageCargos>

                          <hr />

                          <!-- <VoyageBunkerMasters
                    :selectedVessel="selectedVessel"
                    :bunkersMaster="form.voyage_bunker_masters"
                  >
                  </VoyageBunkerMasters>

                  <hr /> -->

                          <errors :data="errors" />

                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group mb-0">
                                <div class="col-sm-9">
                                  <submit-button
                                    :isLoading="isLoading"
                                    @submit="save('submit')"
                                    :text="'Save Voyage'"
                                  ></submit-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <VoyagePandL :form="form" :fuelTypes="fuelTypes"></VoyagePandL>
          </div>
          <!-- /Row -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";
import VoyageCargos from "@/views/voyage/voyage_cargos/index.vue";
import VoyageItineraries from "@/views/voyage/voyage_itenaries/index.vue";
// import VoyageBunkerMasters from "@/views/voyage/voyage_bunker_masters.vue";
import VoyageLaytimes from "@/views/voyage/voyage_laytimes/index.vue";
import VoyageDelays from "@/views/voyage/voyage_delays/index.vue";
import VoyageOthers from "@/views/voyage/voyage_others/index.vue";
import VoyageFreightInvoices from "@/views/voyage/voyage_freight_invoices/index.vue";
import VoyageFreightCommissions from "@/views/voyage/voyage_freight_commissions/index.vue";
import VoyagePandL from "@/views/voyage/voyage_pandl.vue";
import moment from "moment";

export default {
  name: "VoyageComponent",
  data() {
    return {
      form: {
        estimate_id: "",
        tc_in_id: "",
        vessel_id: "",
        vessel: {},
        voyage_status_id: "",
        operation_type_id: "",
        trade_area_id: "",
        specialist_id: "",
        voyage_commencing_date: "",
        voyage_completing_date: "",
        piracy_routing: "Default",
        eca_routing: "Default",
        bunker_calculation_type: "FIFO",
        dwf: 5,
        voyage_other_rev_expenses: [],
        voyage_cargos: [],
        voyage_itenaries: [],
        voyage_bunker_masters: [],
        voyage_delays: [],
        voyage_laytimes: [],
        voyage_brokers: [],
        voyage_freight_invoices: [],
      },
      vessels: [],
      estimates: [],
      tc_ins: [],
      voyage_statuses: [],
      operation_types: [],
      tradeAreas: [],
      users: [],
      piracyRoutings: [],
      ecaRoutings: [],
      cargoNames: [],
      units: [],
      freightTypes: [],
      addresses: [],
      currencies: [],
      perDays: [],
      portNames: [],
      portFunctions: [],
      laytimeTerms: [],
      portActivities: [],
      reasonTypes: [],
      delayReasons: [],
      delayTypes: [],
      selectedVessel: {},
      counterPartyTypes: [],
      revExpenses: [],
      expenseRevenueCodes: [],
      brokerRateTypes: [],
      brokerPaymentMethods: [],
      brokerFreightPaymentMethods: [],
      brokerCommissionables: [],
      agents: [],
      paymentTerms: [],
      fuelTypes: [],
      bunkerCalculationTypes: [],
      tc_outs: [],
      isLoading: false,
    };
  },
  components: {
    VoyageCargos,
    VoyageItineraries,
    // VoyageBunkerMasters,
    VoyageLaytimes,
    VoyageDelays,
    VoyageOthers,
    VoyagePandL,
    VoyageFreightInvoices,
    VoyageFreightCommissions,
  },
  watch: {
    voyage_statuses: "onVoyageStatusChanged",
  },
  mounted() {
    this.form.site_id = this.site.id;

    this.getMasters();
  },
  methods: {
    async updateFormData() {
      if (this.$route.params.voyageListId != null) {
        let form = await axios.get(
          `/voyages/${this.$route.params.voyageListId}`
        );
        form = form.data.data;
        form.operation_type_id = form.operation_type_id || "";
        form.trade_area_id = form.trade_area_id || "";
        form.specialist_id = form.specialist_id || "";
        form.piracy_routing = form.piracy_routing || "";
        form.eca_routing = form.eca_routing || "";
        this.form = form;
      }
    },
    async onVesselChange() {
      let estimates = await axios.get(
        `/estimates?vesselId=${this.form.vessel_id}`
      );
      estimates = estimates.data.data;
      estimates.forEach((estimate) => {
        estimate.name = estimate.fixture_no;
      });
      this.estimates = estimates;
      let tc_ins = await axios.get(`/tc_ins?vesselId=${this.form.vessel_id}`);
      tc_ins = tc_ins.data.data;
      tc_ins.forEach((tc_in) => {
        if (tc_in.is_tc_in_out == "tcin") {
          this.tc_ins.push({
            id: tc_in.id,
            name: tc_in.tc_code,
          });
        } else {
          this.tc_outs.push({
            id: tc_in.id,
            name: tc_in.tc_code,
          });
        }
      });
      if (this.form.id != null) return;
      // if (this.estimates.length > 0) {
      //   this.form.estimate_id = this.estimates[0].id;
      //   this.form.tc_in_id = this.estimates[0].tc_in_id ?? "";
      //   if (this.estimates[0].tc_in) {
      //     if (this.estimates[0].tc_in.tc_in_pricings.length > 0) {
      //       this.form.daily_hire =
      //         this.estimates[0].tc_in.tc_in_pricings[0].hire_rate;
      //     }
      //   }

      //   this.form.voyage_no = this.estimates[0].fixture_no;
      //   this.form.operation_type_id = this.estimates[0].operation_type_id ?? "";
      //   this.form.trade_area_id = this.estimates[0].trade_area_id ?? "";
      //   this.form.specialist_id = this.estimates[0].specialist_id ?? "";
      //   this.form.voyage_commencing_date =
      //     this.estimates[0].commencing_date ?? "";
      //   this.form.voyage_completing_date =
      //     this.estimates[0].completing_date ?? "";
      //   this.form.piracy_routing = this.estimates[0].piracy_routing ?? "";
      //   this.form.eca_routing = this.estimates[0].eca_routing ?? "";
      // }
      let selectedVessel = this.vessels.find(
        (vessel) => vessel.id == this.form.vessel_id
      );
      if (selectedVessel) {
        this.form.vessel_dwt = selectedVessel.dwt_mt;
        this.form.ballast_speed = selectedVessel.ballast_speed;
        this.form.laden_speed = selectedVessel.laden_speed;
      }
      this.selectedVessel = selectedVessel;
      this.onEstimateChange();
    },
    onVoyageStatusChanged() {
      let voyageStatus = this.voyage_statuses.find(
        (voyageStatus) => voyageStatus.name == "SCHEDULED"
      );
      if (voyageStatus) {
        this.form.voyage_status_id = voyageStatus.id;
      }
    },
    onEstimateChange() {
      let estimate = this.estimates.find(
        (estimate) => estimate.id == this.form.estimate_id
      );
      if (estimate) {
        this.form.tc_in_id = estimate.tc_in_id ?? "";

        // Update tc in and other details as per estimate
        if (estimate.tc_in) {
          if (estimate.tc_in.tc_in_pricings.length > 0) {
            this.form.daily_hire = estimate.tc_in.tc_in_pricings[0].hire_rate;
          }
        }

        this.form.voyage_no = estimate.fixture_no;
        this.form.operation_type_id = estimate.operation_type_id ?? "";
        this.form.trade_area_id = estimate.trade_area_id ?? "";
        this.form.specialist_id = estimate.specialist_id ?? "";
        this.form.voyage_commencing_date = estimate.commencing_date ?? "";
        this.form.voyage_completing_date = estimate.completing_date ?? "";
        this.form.piracy_routing = estimate.piracy_routing ?? "";
        this.form.eca_routing = estimate.eca_routing ?? "";
        // End Update tc in and other details as per estimate

        this.form.fixture_no = estimate.fixture_no;
        // this.form.vessel = estimate.vessel;
        // this.form.operation_type_id = estimate.operation_type_id
        if (this.form.voyage_cargos.length == 0) {
          let cargos = [];
          estimate.estimate_cargos.forEach((cargo) => {
            cargos.push({
              cargo_name: cargo.cargo_name,
              cargo_name_id: cargo.cargo_name_id,
              qty: cargo.qty,
              unit: cargo.unit,
              unit_id: cargo.unit_id,
              cp_date: cargo.cp_date,
              freight_type_id: cargo.freight_type_id,
              freight_rate: cargo.freight_rate,
              freight_commission: cargo.freight_commission,
              charterer: cargo.charterer,
              charterer_id: cargo.charterer_id,
              exc_currency_id: cargo.exc_currency_id,
              exc_exchange_rate: cargo.exc_exchange_rate,
              option_percent: cargo.option_percent,
              option_type: cargo.option_type,
              option_type_id: cargo.option_type_id,
              min_qty: cargo.min_qty,
              max_qty: cargo.max_qty,
              demurrage_amount: cargo.demurrage_amount ?? 0,
              despatch_amount: cargo.despatch_amount ?? 0,
              per_day_per_hr: cargo.per_day_per_hr,
              load_demurrage_amount: cargo.load_demurrage_amount ?? 0,
              load_despatch_amount: cargo.load_despatch_amount ?? 0,
              discharge_demurrage_amount: cargo.discharge_demurrage_amount ?? 0,
              discharge_despatch_amount: cargo.discharge_despatch_amount ?? 0,
              laycan_from: cargo.laycan_from,
              laycan_to: cargo.laycan_to,
              company_id: cargo.company_id,
            });
          });
          this.form.voyage_cargos = cargos;
        }

        if (this.form.voyage_brokers.length == 0) {
          let brokers = [];
          estimate.estimate_brokers.forEach((estimateBroker) => {
            brokers.push({
              broker_id: estimateBroker.broker_id,
              rate: estimateBroker.rate,
              rate_type: estimateBroker.rate_type,
              payment_method: estimateBroker.payment_method,
              is_claim_commissionable: estimateBroker.is_claim_commissionable,
              remarks: estimateBroker.remarks,
            });
          });
          this.form.voyage_brokers = brokers;
        }

        if (this.form.voyage_itenaries.length == 0) {
          let itenaries = [];
          estimate.estimate_itenaries.forEach((itenary) => {
            itenaries.push({
              position: itenary.position,
              port_name_id: itenary.port_name_id,
              port_name: itenary.port_name,
              port_function_id: itenary.port_function_id,
              port_function: itenary.port_function,
              miles: itenary.miles,
              speed: itenary.speed,
              weather_factor: this.form.dwf,
              sea_days: 0,
              extra_sea_days: 0,
              total_sea_days: 0,
              cargo_name: itenary.cargo_name,
              cargo_id: itenary.cargo_id || "",
              load_discharge_qty: 0,
              load_discharge_rate: itenary.load_discharge_rate,
              port_days: 0,
              extra_port_days: 0,
              total_port_days: 0,
              port_expenses: 0,
              arrival_date: "",
              departure_date: "",
              laytime_term: itenary.laytime_term,
              laytime_term_id: itenary.laytime_term_id || "",
              voyage_itenary_port_activities: [],
              voyage_itenary_port_expenses: [],
              voyage_itenary_cargos: [],
            });
          });
          this.form.voyage_itenaries = itenaries;
        }

        // if (this.form.voyage_laytimes.length == 0) {
        //   let laytimes = [];
        //   estimate.estimate_itenaries
        //     .filter((vI) => vI.cargo_name != null)
        //     .forEach((itenary) => {
        //       laytimes.push({
        //         port_name: itenary.port_name,
        //         port_name_id: itenary.port_name_id,
        //         cargo_name: itenary.cargo_name,
        //         cargo_name_id: itenary.cargo_name.id,
        //         demurrage_discharge: "",
        //         invoice_date: "",
        //         amount: 0,
        //         laytime_status_id: "",
        //         allowed: 0,
        //         used: 0,
        //         deduction: 0,
        //         balance: 0,
        //         show_demurrage_start: "",
        //         include_in_p_and_l: "",
        //         continuous_laytime: "",
        //         hh_mm_format: "",
        //         once_on_always_on_demurrage: "",
        //         actual_qty: 0,
        //         voyage_laytime_port_activities: [],
        //         voyage_laytime_deductions: [],
        //       });
        //     });
        //   this.form.voyage_laytimes = laytimes;
        // }

        if (this.form.voyage_bunker_masters.length == 0) {
          estimate.estimate_bunker_masters.forEach((estimateBunkerMaster) => {
            let voyageBunkerMaster = {
              fuel_type_id: estimateBunkerMaster.fuel_type_id,
              fuel_name: estimateBunkerMaster.fuel_name,
              initial_qty: estimateBunkerMaster.initialQty,
              initial_price: estimateBunkerMaster.initialPrice,
              sea_ballast_consumption:
                estimateBunkerMaster.sea_ballast_consumption,
              sea_laden_consumption: estimateBunkerMaster.sea_laden_consumption,
              port_loading_consumption:
                estimateBunkerMaster.port_loading_consumption,
              port_discharge_consumption:
                estimateBunkerMaster.port_discharge_consumption,
              port_idle_consumption: estimateBunkerMaster.port_idle_consumption,
              voyage_bunker_master_bunkers: [],
            };

            estimateBunkerMaster.estimate_bunker_master_bunkers.forEach(
              (estimateBunkerMasterBunker) => {
                voyageBunkerMaster.voyage_bunker_master_bunkers.push({
                  position: estimateBunkerMasterBunker.position,
                  port_name_id: estimateBunkerMasterBunker.port_name_id,
                  port_name: estimateBunkerMasterBunker.port_name,
                  port_function_id: estimateBunkerMasterBunker.port_function_id,
                  port_function: estimateBunkerMasterBunker.port_function,
                  rob_prev: 0,
                  sea_consumption: 0,
                  sea_consumption_rate: 0,
                  rob_arrival: 0,
                  received_qty: 0,
                  received_price: 0,
                  port_consumption: 0,
                  rob_departure: 0,
                });
              }
            );
            this.form.voyage_bunker_masters.push(voyageBunkerMaster);
          });
        }
      }
    },
    async updateMiles() {
      // console.log("Update Miles 3");
      if (this.portFunctions.length > 0) {
        // let commencingPortFunction = this.portFunctions.find(
        //   (pf) => pf.code == "C"
        // );
        // let terminatingPortFunction = this.portFunctions.find(
        //   (pf) => pf.code == "T"
        // );
        // let ballastPortItenary = this.form.voyage_itenaries.find(
        //   (it) => it.port_function_id == commencingPortFunction.id
        // );
        // let terminatingPortItenary = this.form.voyage_itenaries.find(
        //   (it) => it.port_function_id == terminatingPortFunction.id
        // );
        let firstPortItenary =
          this.form.voyage_itenaries.length > 0
            ? this.form.voyage_itenaries[0]
            : null;
        let lastPortItenary =
          this.form.voyage_itenaries[this.form.voyage_itenaries.length - 1];
        console.log("Update Miles 4");
        if (
          firstPortItenary != null &&
          lastPortItenary != null &&
          this.form.voyage_itenaries.length >= 2
        ) {
          let payload = [];
          payload.push({
            StartLon: parseFloat(
              this.form.voyage_itenaries[0].port_name
                ? this.form.voyage_itenaries[0].port_name.longitude
                : 0
            ),
            StartLat: parseFloat(
              this.form.voyage_itenaries[0].port_name
                ? this.form.voyage_itenaries[0].port_name.latitude
                : 0
            ),
            StartPortCode: this.form.voyage_itenaries[0].port_name
              ? this.form.voyage_itenaries[0].port_name.port_code
              : "",
            EndLon: parseFloat(
              this.form.voyage_itenaries[1].port_name
                ? this.form.voyage_itenaries[1].port_name.longitude
                : 0
            ),
            EndLat: parseFloat(
              this.form.voyage_itenaries[1].port_name
                ? this.form.voyage_itenaries[1].port_name.latitude
                : 0
            ),
            EndPortCode: this.form.voyage_itenaries[1].port_name
              ? this.form.voyage_itenaries[1].port_name.port_code
              : "",
            GreatCircleInterval: 0,
            AllowedAreas: [2, 5, 7, 14, 10003],
            SecaAvoidance: 0,
            AslCompliance: 0,
          });
          for (var i = 1; i <= this.form.voyage_itenaries.length - 2; i++) {
            payload.push({
              StartLon: parseFloat(
                this.form.voyage_itenaries[i].port_name
                  ? this.form.voyage_itenaries[i].port_name.longitude
                  : 0
              ),
              StartLat: parseFloat(
                this.form.voyage_itenaries[i].port_name
                  ? this.form.voyage_itenaries[i].port_name.latitude
                  : 0
              ),
              StartPortCode: this.form.voyage_itenaries[i].port_name
                ? this.form.voyage_itenaries[i].port_name.port_code
                : "",
              EndLon: parseFloat(
                this.form.voyage_itenaries[i + 1].port_name
                  ? this.form.voyage_itenaries[i + 1].port_name.longitude
                  : 0
              ),
              EndLat: parseFloat(
                this.form.voyage_itenaries[i + 1].port_name
                  ? this.form.voyage_itenaries[i + 1].port_name.latitude
                  : 0
              ),
              EndPortCode: this.form.voyage_itenaries[i + 1].port_name
                ? this.form.voyage_itenaries[i + 1].port_name.port_code
                : "",
              GreatCircleInterval: 0,
              AllowedAreas: [2, 5, 7, 14, 10003],
              SecaAvoidance: 0,
              AslCompliance: 0,
            });
          }
          let response = await axios.post(
            "/third-party-route-api/saveRoute",
            payload
          );
          response = response.data.data;
          for (i = 1; i <= this.form.voyage_itenaries.length - 1; i++) {
            // console.log(response[i - 1]["result_json"]["totalDistance"]);
            this.form.voyage_itenaries[i]["miles"] =
              response[i - 1]["result_json"]["totalDistance"];
          }
        }
      }
    },
    onVoyageCargoUpdated() {
      this.form.voyage_cargos.forEach((cargo) => {
        if (cargo.option_percent != null && cargo.qty != null) {
          cargo.min_qty =
            parseFloat(cargo.qty) -
            (parseFloat(cargo.option_percent) * parseFloat(cargo.qty)) / 100;
          cargo.max_qty =
            parseFloat(cargo.qty) +
            (parseFloat(cargo.option_percent) * parseFloat(cargo.qty)) / 100;
        }
      });
    },
    updateItenaryDetails() {
      // console.log('Update Itenary Details')
      this.form.voyage_itenaries.forEach((itenary, i) => {
        let portFunction = this.portFunctions.find(
          (portFunction) => portFunction.id == itenary.port_function_id
        );
        // arrival date and dischare date
        let itenaryPortActivitiesLength =
          itenary.voyage_itenary_port_activities.length;
        if (
          portFunction &&
          (portFunction.code == "P" ||
            portFunction.code == "I" ||
            portFunction.code == "O")
        ) {
          itenary.arrival_date =
            this.form.voyage_itenaries[i - 1]["departure_date"];
          itenary.departure_date =
            this.form.voyage_itenaries[i - 1]["departure_date"];
        } else if (itenaryPortActivitiesLength > 0) {
          if (itenaryPortActivitiesLength == 1) {
            itenary.arrival_date =
              itenary.voyage_itenary_port_activities[0].date_from;
            itenary.departure_date =
              itenary.voyage_itenary_port_activities[0].date_from;
          } else {
            itenary.arrival_date =
              itenary.voyage_itenary_port_activities[0].date_from;
            itenary.departure_date =
              itenary.voyage_itenary_port_activities[
                itenaryPortActivitiesLength - 1
              ].date_from;
          }
        }
        // Port Days
        if (
          itenary.arrival_date != "" &&
          itenary.arrival_date != null &&
          itenary.departure_date != "" &&
          itenary.departure_date != null
        ) {
          let arr = moment(itenary.arrival_date);
          let dep = moment(itenary.departure_date);
          let diffInSeconds = dep.diff(arr, "seconds");
          itenary.total_port_days = parseFloat(diffInSeconds) / 86400;
        }
        if (
          itenary.load_discharge_qty != 0 &&
          itenary.load_discharge_rate != 0
        ) {
          let qty = parseFloat(itenary.load_discharge_qty);
          let rate = parseFloat(itenary.load_discharge_rate);
          let port_days = qty / rate;
          itenary.port_days = port_days;
          itenary.extra_port_days = itenary.total_port_days - itenary.port_days;
        }
        // Sea Days
        if (i != 0) {
          if (
            this.form.voyage_itenaries[i - 1].departure_date != "" &&
            this.form.voyage_itenaries[i - 1].departure_date != null &&
            itenary.arrival_date != "" &&
            itenary.arrival_date != null
          ) {
            let departureFromPrev = moment(
              this.form.voyage_itenaries[i - 1].departure_date
            );
            let arrivalToCurrent = moment(itenary.arrival_date);
            let diffInSeconds = arrivalToCurrent.diff(
              departureFromPrev,
              "seconds"
            );
            itenary.total_sea_days = parseFloat(diffInSeconds) / 86400;
          }
        }
        let sea_days = 0;
        let dwf = parseFloat(this.form.dwf);
        let total_sea_days = parseFloat(itenary.total_sea_days);
        sea_days = (total_sea_days * 100) / (100 + dwf);
        itenary.sea_days = sea_days;
        itenary.extra_sea_days = (dwf * sea_days) / 100;
        this.onBunkerChanged();
      });
      this.updateMiles();
    },
    onBunkerChanged() {
      this.form.voyage_itenaries.forEach((itenary, i) => {
        // Bunker Liftings
        // console.log("Bunker Liftings");
        if (i == 0) {
          this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
            voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
              (voyageBunkerMasterBunker) => {
                if (
                  voyageBunkerMasterBunker.port_name_id ==
                    itenary.port_name_id &&
                  voyageBunkerMasterBunker.port_function_id ==
                    itenary.port_function_id
                ) {
                  voyageBunkerMasterBunker.rob_prev =
                    parseFloat(voyageBunkerMasterBunker.received_qty ?? 0) +
                    parseFloat(voyageBunkerMasterBunker.rob_departure ?? 0);
                  voyageBunkerMasterBunker.sea_consumption = 0;
                  voyageBunkerMasterBunker.sea_consumption_rate = 0;
                  voyageBunkerMasterBunker.rob_arrival =
                    voyageBunkerMasterBunker.rob_prev;
                  voyageBunkerMasterBunker.port_consumption = 0;
                  voyageBunkerMasterBunker.port_consumption_rate = 0;
                }
              }
            );
          });
        } else {
          this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
            voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
              (voyageBunkerMasterBunker) => {
                if (
                  voyageBunkerMasterBunker.port_name_id ==
                    itenary.port_name_id &&
                  voyageBunkerMasterBunker.port_function_id ==
                    itenary.port_function_id
                ) {
                  //  Get rob_departure of previous leg
                  voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                    (vbmb) => {
                      // let passingPortFunction = this.portFunctions.find(
                      //   (portFunction) => portFunction.code == "P"
                      // );
                      if (
                        vbmb.port_name_id ==
                          this.form.voyage_itenaries[i - 1].port_name_id &&
                        vbmb.port_function_id ==
                          this.form.voyage_itenaries[i - 1].port_function_id
                        // && vbmb.port_function_id == passingPortFunction.id
                      ) {
                        voyageBunkerMasterBunker.rob_prev = vbmb.rob_departure;
                      }
                    }
                  );

                  if (voyageBunkerMasterBunker.rob_arrival == 0) {
                    voyageBunkerMasterBunker.sea_consumption = 0;
                    voyageBunkerMasterBunker.sea_consumption_rate = 0;
                  } else if (voyageBunkerMasterBunker.rob_arrival != null) {
                    voyageBunkerMasterBunker.sea_consumption =
                      parseFloat(voyageBunkerMasterBunker.rob_prev ?? 0) -
                      parseFloat(voyageBunkerMasterBunker.rob_arrival ?? 0);
                    voyageBunkerMasterBunker.sea_consumption_rate =
                      itenary.total_sea_days != 0
                        ? parseFloat(voyageBunkerMasterBunker.sea_consumption) /
                          parseFloat(itenary.total_sea_days)
                        : 0;
                  }
                  // if (
                  //   voyageBunkerMasterBunker.rob_arrival == 0 ||
                  //   !voyageBunkerMasterBunker.rob_arrival
                  // ) {
                  //   voyageBunkerMasterBunker.rob_arrival =
                  //     voyageBunkerMasterBunker.rob_prev;
                  // }

                  // This is if the port is passing port
                  if (
                    voyageBunkerMasterBunker.rob_departure == 0 ||
                    !voyageBunkerMasterBunker.rob_departure
                  ) {
                    voyageBunkerMaster.voyage_bunker_master_bunkers.forEach(
                      (vbmb) => {
                        let passingPortFunction = this.portFunctions.find(
                          (portFunction) => portFunction.code == "P"
                        );
                        if (
                          vbmb.port_name_id ==
                            this.form.voyage_itenaries[i - 1].port_name_id &&
                          vbmb.port_function_id ==
                            this.form.voyage_itenaries[i - 1]
                              .port_function_id &&
                          voyageBunkerMasterBunker.port_function_id ==
                            passingPortFunction.id
                        ) {
                          voyageBunkerMasterBunker.rob_arrival =
                            vbmb.rob_arrival;
                          voyageBunkerMasterBunker.rob_departure =
                            vbmb.rob_departure;
                        }
                      }
                    );

                    // voyageBunkerMasterBunker.port_consumption = 0;
                    // voyageBunkerMasterBunker.port_consumption_rate = 0;
                    // voyageBunkerMasterBunker.rob_departure =
                    //   parseFloat(voyageBunkerMasterBunker.rob_prev ?? 0) +
                    //   parseFloat(voyageBunkerMasterBunker.received_qty ?? 0);
                  }

                  voyageBunkerMasterBunker.port_consumption =
                    parseFloat(voyageBunkerMasterBunker.rob_arrival ?? 0) +
                    parseFloat(voyageBunkerMasterBunker.received_qty ?? 0) -
                    parseFloat(voyageBunkerMasterBunker.rob_departure ?? 0);

                  voyageBunkerMasterBunker.port_consumption_rate =
                    itenary.total_port_days != 0
                      ? parseFloat(voyageBunkerMasterBunker.port_consumption) /
                        parseFloat(itenary.total_port_days)
                      : 0;
                }
              }
            );
          });
        }
      });
    },
    onPortExpensesChanged() {
      this.form.voyage_itenaries.forEach((voyageItenary) => {
        let portExpenses = 0;
        voyageItenary.voyage_itenary_port_expenses.forEach(
          (voyageItenaryPortExpense) => {
            portExpenses =
              parseFloat(portExpenses) +
              parseFloat(
                voyageItenaryPortExpense.advanced_amount ??
                  voyageItenaryPortExpense.fda_amount
              );
          }
        );
        voyageItenary.port_expenses = portExpenses;
      });
    },
    onPortCargosChanged() {
      this.form.voyage_itenaries.forEach((voyageItenary) => {
        let portCargos = 0;
        voyageItenary.voyage_itenary_cargos.forEach((voyageItenaryCargo) => {
          portCargos =
            parseFloat(portCargos) +
            parseFloat(voyageItenaryCargo.bl_discharge_qty);
        });
        voyageItenary.load_discharge_qty = portCargos;
      });
    },
    onDelayUpdated() {
      this.form.voyage_delays.forEach((voyageDelay) => {
        let fromDateTime = moment(voyageDelay.from);
        let toDateTime = moment(voyageDelay.to);
        voyageDelay.hours =
          parseFloat(toDateTime.diff(fromDateTime, "seconds")) / (60 * 60);
      });
      this.save();
    },
    onLaytimeUpdated() {
      this.form.voyage_laytimes.forEach((voyageLaytime) => {
        let voyageItenary = this.form.voyage_itenaries.find(
          (vI) => vI.port_name_id == voyageLaytime.port_name_id
        );
        if (voyageItenary) {
          let factor = 1;
          if (voyageItenary.laytime_term_id != null) {
            factor = voyageItenary.laytime_term.factor;
          }
          if (voyageLaytime.actual_qty != null) {
            voyageLaytime.allowed =
              (parseFloat(voyageLaytime.actual_qty) /
                parseFloat(voyageItenary.load_discharge_rate)) *
              parseFloat(factor);
          }
        }

        // Port activities
        console.log(voyageLaytime.calculation_type);
        if (voyageLaytime.calculation_type == "Standard") {
          voyageLaytime.used = 0;
          voyageLaytime.voyage_laytime_port_activities.forEach((vlpa) => {
            let fromDateTime = moment(vlpa.from_date_time);
            let toDateTime = moment(vlpa.to_date_time);
            let diffInSeconds = toDateTime.diff(fromDateTime, "seconds");
            vlpa.duration = parseFloat(diffInSeconds) / 86400;
            vlpa.deduction =
              (parseFloat(vlpa.percent) * parseFloat(vlpa.duration)) / 100;
            voyageLaytime.used =
              parseFloat(voyageLaytime.used) + parseFloat(vlpa.deduction);
            voyageLaytime.balance =
              parseFloat(voyageLaytime.allowed) -
              parseFloat(voyageLaytime.used);
          });

          if (voyageLaytime.balance > 0) {
            voyageLaytime.demurrage_discharge = "Despatch";
            let despatchAmount =
              this.form.voyage_cargos.length > 0
                ? this.form.voyage_cargos[0].despatch_amount
                : 0;
            voyageLaytime.amount =
              parseFloat(despatchAmount) * parseFloat(voyageLaytime.balance);
          } else if (voyageLaytime.balance < 0) {
            voyageLaytime.demurrage_discharge = "Demurrage";
            let demurrageAmount =
              this.form.voyage_cargos.length > 0
                ? this.form.voyage_cargos[0].demurrage_amount
                : 0;
            voyageLaytime.amount =
              parseFloat(demurrageAmount) * parseFloat(voyageLaytime.balance);
          } else {
            voyageLaytime.demurrage_discharge = "";
            voyageLaytime.amount = 0;
          }
          // Get the total of same positions
          // console.log("Highest");
          let highestPosition =
            this.form.voyage_laytimes.length > 0
              ? this.form.voyage_laytimes[this.form.voyage_laytimes.length - 1]
                  .position
              : 0;
          for (let i = 1; i <= highestPosition; i++) {
            let totalAllowed = 0;
            let totalUsed = 0;
            let totalDeduction = 0;
            let totalBalance = 0;
            let totalAmount = 0;
            this.form.voyage_laytimes
              .filter(
                (voyageLaytime) =>
                  voyageLaytime.position == i &&
                  voyageLaytime.calculation_type == "Standard"
              )
              .forEach((samePositionVoyageLaytime) => {
                totalAllowed =
                  parseFloat(totalAllowed) +
                  parseFloat(samePositionVoyageLaytime.allowed);
                totalUsed =
                  parseFloat(totalUsed) +
                  parseFloat(samePositionVoyageLaytime.used);
                totalDeduction =
                  parseFloat(totalDeduction) +
                  parseFloat(samePositionVoyageLaytime.deduction);
                totalBalance =
                  parseFloat(totalBalance) +
                  parseFloat(samePositionVoyageLaytime.balance);
                totalAmount =
                  parseFloat(totalAmount) +
                  parseFloat(samePositionVoyageLaytime.amount);
              });
            this.form.voyage_laytimes
              .filter(
                (voyageLaytime) =>
                  voyageLaytime.position == i &&
                  voyageLaytime.calculation_type == "Standard"
              )
              .forEach((samePositionVoyageLaytime) => {
                samePositionVoyageLaytime.total_allowed = totalAllowed;
                samePositionVoyageLaytime.total_used = totalUsed;
                samePositionVoyageLaytime.total_deduction = totalDeduction;
                samePositionVoyageLaytime.total_balance = totalBalance;
                if (totalBalance > 0)
                  samePositionVoyageLaytime.total_demurrage_discharge =
                    "Despatch";
                if (totalBalance < 0)
                  samePositionVoyageLaytime.total_demurrage_discharge =
                    "Demurrage";
                samePositionVoyageLaytime.total_amount = totalAmount;
              });
          }
        }
        if (voyageLaytime.calculation_type == "Reversible") {
          voyageLaytime.used = 0;
          voyageLaytime.voyage_laytime_port_activities.forEach((vlpa) => {
            let fromDateTime = moment(vlpa.from_date_time);
            let toDateTime = moment(vlpa.to_date_time);
            let diffInSeconds = toDateTime.diff(fromDateTime, "seconds");
            vlpa.duration = parseFloat(diffInSeconds) / 86400;
            vlpa.deduction =
              (parseFloat(vlpa.percent) * parseFloat(vlpa.duration)) / 100;
            voyageLaytime.used =
              parseFloat(voyageLaytime.used) + parseFloat(vlpa.deduction);
            voyageLaytime.balance =
              parseFloat(voyageLaytime.allowed) -
              parseFloat(voyageLaytime.used);
            // if (vL == 0) {

            // } else {
            //   voyageLaytime.balance =
            //     parseFloat(voyageLaytime.allowed) +
            //     parseFloat(this.form.voyage_laytimes[vL - 1].balance) -
            //     parseFloat(voyageLaytime.used);
            // }
          });
          voyageLaytime.demurrage_discharge = "";
          voyageLaytime.amount = 0;
          // Get the total of same positions
          // console.log("Highest");
          let highestPosition =
            this.form.voyage_laytimes.length > 0
              ? this.form.voyage_laytimes[this.form.voyage_laytimes.length - 1]
                  .position
              : 0;
          for (let i = 1; i <= highestPosition; i++) {
            let totalAllowed = 0;
            let totalUsed = 0;
            let totalDeduction = 0;
            let totalBalance = 0;
            let totalAmount = 0;
            this.form.voyage_laytimes
              .filter(
                (voyageLaytime) =>
                  voyageLaytime.position == i &&
                  voyageLaytime.calculation_type == "Reversible"
              )
              .forEach((samePositionVoyageLaytime) => {
                totalAllowed =
                  parseFloat(totalAllowed) +
                  parseFloat(samePositionVoyageLaytime.allowed);
                totalUsed =
                  parseFloat(totalUsed) +
                  parseFloat(samePositionVoyageLaytime.used);
                totalDeduction =
                  parseFloat(totalDeduction) +
                  parseFloat(samePositionVoyageLaytime.deduction);
                totalBalance =
                  parseFloat(totalBalance) +
                  parseFloat(samePositionVoyageLaytime.balance);
              });

            if (totalBalance > 0) {
              let despatchAmount =
                this.form.voyage_cargos.length > 0
                  ? this.form.voyage_cargos[0].despatch_amount
                  : 0;
              totalAmount =
                parseFloat(totalBalance) * parseFloat(despatchAmount);
            } else if (totalBalance < 0) {
              let demurrageAmount =
                this.form.voyage_cargos.length > 0
                  ? this.form.voyage_cargos[0].demurrage_amount
                  : 0;
              totalAmount =
                parseFloat(totalBalance) * parseFloat(demurrageAmount);
            } else {
              totalAmount = 0;
            }
            this.form.voyage_laytimes
              .filter(
                (voyageLaytime) =>
                  voyageLaytime.position == i &&
                  voyageLaytime.calculation_type == "Reversible"
              )
              .forEach((samePositionVoyageLaytime) => {
                samePositionVoyageLaytime.total_allowed = totalAllowed;
                samePositionVoyageLaytime.total_used = totalUsed;
                samePositionVoyageLaytime.total_deduction = totalDeduction;
                samePositionVoyageLaytime.total_balance = totalBalance;
                if (totalBalance > 0)
                  samePositionVoyageLaytime.total_demurrage_discharge =
                    "Despatch";
                if (totalBalance < 0)
                  samePositionVoyageLaytime.total_demurrage_discharge =
                    "Demurrage";
                samePositionVoyageLaytime.total_amount = totalAmount;
              });
          }
        }
      });
    },
    onSaveVoyageFreightInvoiceCreate(voyageFreightInvoice) {
      if (voyageFreightInvoice.position == "") {
        let totalInvoiceAmount = 0;
        let vfi = {
          position: this.form.voyage_freight_invoices.length + 1,
          counter_party_id: voyageFreightInvoice.counter_party_id,
          currency_id: voyageFreightInvoice.currency_id,
          exchange_rate: voyageFreightInvoice.exchange_rate,
          contact_name: voyageFreightInvoice.contact_name,
          invoice_no: voyageFreightInvoice.invoice_no,
          invoice_date: voyageFreightInvoice.invoice_date,
          payment_term_id: voyageFreightInvoice.payment_term_id,
          due_date: voyageFreightInvoice.due_date,
          remarks: voyageFreightInvoice.remarks,
          voyage_freight_invoice_details: [],
          voyage_freight_invoice_brokers: [],
          voyage_freight_invoice_adjustments: [],
        };
        voyageFreightInvoice.voyage_freight_invoice_details.forEach((vfid) => {
          totalInvoiceAmount =
            parseFloat(vfid.invoice_qty) * parseFloat(vfid.freight_rate ?? 0);
          vfi.voyage_freight_invoice_details.push({
            cargo_name_id: vfid.cargo_name_id,
            cargo_name: vfid.cargo_name,
            cp_qty: vfid.cp_qty,
            nominated_qty: vfid.nominated_qty,
            bl_qty: vfid.bl_qty,
            invoice_qty: vfid.invoice_qty,
            freight_type_id: vfid.freight_type_id,
            freight_rate: vfid.freight_rate,
            lumpsum_daily: vfid.lumpsum_daily,
            invoice_percent: vfid.invoice_percent,
            invoice_amount:
              (parseFloat(vfid.invoice_qty) *
                parseFloat(vfid.freight_rate ?? 0) *
                parseFloat(vfid.invoice_percent ?? 0)) /
              100,
            total_percent_billed_to_date: vfid.total_percent_billed_to_date,
          });
        });
        voyageFreightInvoice.voyage_freight_invoice_brokers.forEach((vfib) => {
          let ifCommissionAlreadyPaid = false;
          if (this.form.voyage_freight_invoices.length > 0) {
            this.form.voyage_freight_invoices.forEach((vfi) => {
              let vfib = vfi.voyage_freight_invoice_brokers.find(
                (vfib) => vfib.broker_id == vfib.broker_id
              );
              if (vfib) ifCommissionAlreadyPaid = true;
            });
          }
          if (!ifCommissionAlreadyPaid)
            vfi.voyage_freight_invoice_brokers.push({
              broker_id: vfib.broker_id,
              rate: vfib.rate,
              rate_type: vfib.rate_type,
              payment_method: vfib.payment_method,
              amount: (parseFloat(vfib.rate) * totalInvoiceAmount) / 100,
            });
        });
        this.form.voyage_freight_invoices.push(vfi);
      }
      this.calculateFreightInvoices();
    },
    calculateFreightInvoices() {
      this.form.voyage_freight_invoices.forEach((voyFreightInv, v) => {
        let invoicePer = 0;
        let invoiceAmount = 0;
        let invoiceQty = 0;

        // Add Details
        voyFreightInv.voyage_freight_invoice_details.forEach((vfid) => {
          vfid.invoice_amount =
            (parseFloat(vfid.invoice_qty) *
              parseFloat(vfid.freight_rate ?? 0) *
              parseFloat(vfid.invoice_percent ?? 0)) /
            100;
          invoicePer =
            parseFloat(invoicePer) + parseFloat(vfid.invoice_percent);
          invoiceAmount =
            parseFloat(invoiceAmount) + parseFloat(vfid.invoice_amount);
          invoiceQty = parseFloat(invoiceQty) + parseFloat(vfid.invoice_qty);
        });

        // Remove Adjustment
        voyFreightInv.voyage_freight_invoice_adjustments.forEach((vfia) => {
          invoiceAmount = parseFloat(invoiceAmount) + parseFloat(vfia.amount);
        });

        voyFreightInv.invoice_percent = invoicePer;
        voyFreightInv.invoice_amount = invoiceAmount;

        voyFreightInv.voyage_freight_invoice_descriptions = [];

        // Voyage Freight Invoice Descriptions
        let cargoName;
        let freightRate;
        this.form.voyage_cargos.forEach((voyageCargo) => {
          cargoName = voyageCargo.cargo_name;
          freightRate = voyageCargo.freight_rate;
        });
        voyFreightInv.voyage_freight_invoice_descriptions.push({
          description:
            (cargoName ? cargoName.short_name : "") +
            " : Freight " +
            invoiceQty +
            " MT * " +
            freightRate +
            " USD @ " +
            invoicePer +
            "%",
          amount: invoiceAmount,
        });

        // Voyage Freight Invoice Descriptions
        let commissionAmount = 0;
        let totalInvoiceAmount =
          parseFloat(invoiceQty) * parseFloat(freightRate);

        if (v == 0) {
          voyFreightInv.voyage_freight_invoice_brokers
            .filter((vfib) => vfib.payment_method == "Deduct from freight")
            .forEach((vfib) => {
              let broker = this.addresses.find(
                (address) => address.id == vfib.broker_id
              );
              vfib.is_invoice_generated = true;
              voyFreightInv.voyage_freight_invoice_descriptions.push({
                description:
                  (broker ? broker.short_name + " " : "") +
                  "Commission on " +
                  totalInvoiceAmount +
                  " @ " +
                  vfib.rate +
                  "%",
                amount:
                  -(parseFloat(vfib.rate) * parseFloat(totalInvoiceAmount)) /
                  100,
              });
              commissionAmount = -(
                parseFloat(commissionAmount) +
                parseFloat(
                  parseFloat(vfib.rate) * parseFloat(totalInvoiceAmount)
                ) /
                  100
              );
            });
        }

        voyFreightInv.voyage_freight_invoice_descriptions.push({
          description: "Total Amount:",
          amount: parseFloat(invoiceAmount) + parseFloat(commissionAmount),
        });
      });
    },
    calculateCost() {},
    async save(but = "") {
      // console.log(this.form);
      try {
        this.isLoading = true;
        // let formTest = {};
        // Object.assign(formTest, this.form);
        // let formData = this.jsonToFormData(formTest);
        // await axios.post("/voyages", formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // });
        await axios.post("/voyages", this.form);
        this.isLoading = false;
        if (but == "submit") this.$router.push("/voyages");
      } catch (e) {
        this.isLoading = false;
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },
    async getMasters() {
      this.isLoading = true;
      let masters = await axios.get("/voyages/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        vessel.name = vessel.vessel_name;
      });
      this.vessels = masters.vessels;
      masters.voyage_statuses.forEach((voyage_status) => {
        this.voyage_statuses.push({
          id: voyage_status.id,
          name: voyage_status.status,
        });
      });
      masters.operation_types.forEach((operation_type) => {
        this.operation_types.push({
          id: operation_type.id,
          name: operation_type.description,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.users.forEach((user) => {
        this.users.push({
          id: user.id,
          name: user.first_name + " " + user.last_name,
        });
      });
      masters.piracy_routings.forEach((piracyRouting) => {
        this.piracyRoutings.push({
          id: piracyRouting,
          name: piracyRouting,
        });
      });
      masters.eca_routings.forEach((ecaRouting) => {
        this.ecaRoutings.push({
          id: ecaRouting,
          name: ecaRouting,
        });
      });
      masters.cargo_names.forEach((cargoName) => {
        this.cargoNames.push({
          id: cargoName.id,
          short_name: cargoName.short_name,
          name: cargoName.short_name + " - " + cargoName.full_name,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit + " - " + unit.description,
        });
      });
      masters.freight_types.forEach((freighType) => {
        this.freightTypes.push({
          id: freighType.id,
          name: freighType.type + " - " + freighType.description,
        });
      });
      masters.addresses.forEach((address) => {
        this.addresses.push({
          id: address.id,
          short_name: address.short_name,
          name: address.short_name + " -" + address.full_name,
        });
      });
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.name,
        });
      });
      masters.perDays.forEach((perDay) => {
        this.perDays.push({
          id: perDay.id,
          name: perDay.name,
        });
      });
      masters.port_names.forEach((portName) => {
        this.portNames.push({
          id: portName.id,
          port_code: portName.port_code,
          latitude: portName.latitude,
          longitude: portName.longitude,
          name: portName.port_name,
          port_name: portName.port_name,
        });
      });
      masters.port_functions.forEach((port_function) => {
        this.portFunctions.push({
          id: port_function.id,
          code: port_function.code,
          name: port_function.code + " - " + port_function.port_operation,
        });
      });
      masters.laytime_terms.forEach((laytime_term) => {
        this.laytimeTerms.push({
          id: laytime_term.id,
          factor: laytime_term.factor,
          name: laytime_term.term,
        });
      });
      masters.port_activities.forEach((port_activity) => {
        this.portActivities.push({
          id: port_activity.id,
          name: port_activity.activity,
        });
      });
      masters.reason_types.forEach((reasonType) => {
        this.reasonTypes.push({
          id: reasonType.id,
          code: reasonType.reason_code,
          name: reasonType.description,
        });
      });
      masters.delay_reasons.forEach((delayReason) => {
        this.delayReasons.push({
          id: delayReason.id,
          code: delayReason.code,
          name: delayReason.reason,
        });
      });
      masters.delay_types.forEach((delayType) => {
        this.delayTypes.push({
          id: delayType.id,
          code: delayType.code,
          name: delayType.description,
        });
      });
      masters.counterPartyTypes.forEach((counterPartyType) => {
        this.counterPartyTypes.push({
          id: counterPartyType,
          code: counterPartyType,
          name: counterPartyType,
        });
      });
      masters.revExpenses.forEach((revExpense) => {
        this.revExpenses.push({
          id: revExpense,
          code: revExpense,
          name: revExpense,
        });
      });
      masters.expense_revenue_codes.forEach((expenseRevenueCode) => {
        this.expenseRevenueCodes.push({
          id: expenseRevenueCode.id,
          code: expenseRevenueCode.code,
          name: expenseRevenueCode.description,
        });
      });
      masters.agents.forEach((agent) => {
        this.agents.push({
          id: agent.id,
          name: agent.short_name + " -" + agent.full_name,
        });
      });
      masters.broker_rate_types.forEach((brokerRateType) => {
        this.brokerRateTypes.push({
          id: brokerRateType,
          name: brokerRateType,
        });
      });
      masters.broker_payment_methods.forEach((brokerPaymentMethod) => {
        this.brokerPaymentMethods.push({
          id: brokerPaymentMethod,
          name: brokerPaymentMethod,
        });
      });
      masters.brokerFreightPaymentMethods.forEach(
        (brokerFreightPaymentMethod) => {
          this.brokerFreightPaymentMethods.push({
            id: brokerFreightPaymentMethod,
            name: brokerFreightPaymentMethod,
          });
        }
      );
      masters.broker_commissionables.forEach((brokerCommissionable) => {
        this.brokerCommissionables.push({
          id: brokerCommissionable,
          name: brokerCommissionable,
        });
      });
      masters.payment_terms.forEach((paymentTerm) => {
        this.paymentTerms.push({
          id: paymentTerm.id,
          name: paymentTerm.description,
        });
      });
      masters.bunkerCalculationTypes.forEach((bct) => {
        this.bunkerCalculationTypes.push({
          id: bct,
          name: bct,
        });
      });
      this.fuelTypes = masters.fuel_types;
      this.isLoading = false;
      this.updateFormData();
    },
  },
};
</script>

<style>
@media only screen and (min-width: 600px) {
  .pandl {
    position: fixed;
    width: 27%;
    top: 90px;
  }
}
</style>
