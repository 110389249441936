<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/addresses"></back-button> Upload Addresses
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Addresses</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/addresses-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />

                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudeAddresses.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>
                <div v-if="crudeAddresses.length != 0">
                  <div class="form-footer">
                    <button
                      class="btn btn-success btn-block"
                      @click="processFile"
                    >
                      Process file
                    </button>
                  </div>
                </div>
                <!-- <div
                  class="form-footer"
                  v-if="conflicTargets.length != 0"
                  
                > -->
                <!-- <download-excel
                    :title="title"
                    class="btn btn-success btn-block"
                    :data="conflict_targets_excel"
                    :fields="excel_fields"
                    worksheet="ConflictUsers"
                    name="Conflict_Target.xls"
                  >
                    <a href="#" class="download">Export Conflics</a>
                  </download-excel> -->
                <!-- <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Reference Code</th>
                        <th>Full Name</th>
                        <th>Address Type</th>
                        <th>Short Name</th>
                        <th>Country</th>
                        <th>Country Code</th>
                        <th>Address</th>
                        <th>Currency</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Fax</th>
                        <th>Company No</th>
                        <th>Parent Company</th>
                        <th>Credit Line</th>
                        <th>Credit Term</th>
                        <th>Credit Rating</th>
                        <th>Dem TB Days</th>
                        <th>Cross Reference</th>
                        <th>Main Contact</th>
                        <th>Reference No</th>
                        <th>Telex</th>
                        <th>Web Page</th>
                        <th>Vat No</th>
                        <th>Ports Serviced</th>
                        <th>Last Modified by</th>
                        <th>Last Modified on</th>
                        <th>Created on</th>
                        <th>Update Times</th>
                        <th>Last Transaction Date</th>
                        <th>Status</th>
                        <th>Alerts</th>
                        <th>Contract Sub Status</th>
                        <th>Margining - Physical</th>
                        <th>Margining - Paper</th>
                        <th>Netting - Physical</th>
                        <th>Netting - Paper</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <tr
                        v-for="(crudeAddress, at) in crudeAddresses"
                        :key="`crudeAddress${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudeAddress.reference_code }}</td>
                        <td>{{ crudeAddress.full_name }}</td>
                        <td>{{ crudeAddress.address_type }}</td>
                        <td>{{ crudeAddress.short_name }}</td>
                        <td>{{ crudeAddress.country }}</td>
                        <td>{{ crudeAddress.country_code }}</td>
                        <td>{{ crudeAddress.address }}</td>
                        <td>{{ crudeAddress.currency }}</td>
                        <td>{{ crudeAddress.phone_number }}</td>
                        <td>{{ crudeAddress.email }}</td>
                        <td>{{ crudeAddress.fax }}</td>
                        <td>{{ crudeAddress.company_no }}</td>
                        <td>{{ crudeAddress.parent_company }}</td>
                        <td>{{ crudeAddress.credit_line }}</td>
                        <td>{{ crudeAddress.credit_term }}</td>
                        <td>{{ crudeAddress.credit_rating }}</td>
                        <td>{{ crudeAddress.dem_tB_days }}</td>
                        <td>{{ crudeAddress.cross_reference }}</td>
                        <td>{{ crudeAddress.main_contact }}</td>
                        <td>{{ crudeAddress.reference_no }}</td>
                        <td>{{ crudeAddress.telex }}</td>
                        <td>{{ crudeAddress.web_page }}</td>
                        <td>{{ crudeAddress.vat_no }}</td>
                        <td>{{ crudeAddress.ports_serviced }}</td>
                        <td>{{ crudeAddress.last_modified_by }}</td>
                        <td>{{ crudeAddress.last_modified_on }}</td>
                        <td>{{ crudeAddress.created_on }}</td>
                        <td>{{ crudeAddress.update_times }}</td>
                        <td>{{ crudeAddress.last_transaction_date }}</td>
                        <td>{{ crudeAddress.status }}</td>
                        <td>{{ crudeAddress.alerts }}</td>
                        <td>{{ crudeAddress.contract_sub_status }}</td>
                        <td>{{ crudeAddress.margining_physical }}</td>
                        <td>{{ crudeAddress.margining_paper }}</td>
                        <td>{{ crudeAddress.netting_physical }}</td>
                        <td>{{ crudeAddress.netting_paper }}</td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "uploadTargets",
  data() {
    return {
      // crudeAddresses:{
      //
      // },
      crudeAddresses: [],
      // conflicTargets: [],
      company_id: "",
      month: "",
      year: "",
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      months: [],
      years: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   Source: "source",
      //   "Employee Code": "employee_code",
      // },
      // conflict_targets_excel: [],
    };
  },
  mounted() {
    this.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudeAddresses = await axios.get(`/crude_addresses`);
      this.crudeAddresses = crudeAddresses.data.data;
      this.count = crudeAddresses.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.addresses = this.$refs.file.files[0];
      // this.month = this.crudeAddresses.month;
      // this.year = this.crudeAddresses.year;

      let formData = new FormData();

      // formData.append("month", this.month);
      formData.append("site_id", this.site_id);
      formData.append("AddressData", this.addresses);
      await axios
        .post("upload_address", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudeAddresses = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_address");
        // if (conflicTargets != "") {
        //   // console.log('Conflicts');
        //   this.conflicTargets = conflicTargets.data.data;
        //   this.exportToExcel();
        alert("Processed Successfully");
        // }
        await axios.get("truncate_addresses");
        this.crudeAddresses = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_addresses");
      alert("Truncated Successfully");
      this.crudeAddresses = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_addresses/${$id}`);
        this.crudeAddresses = this.crudeAddresses.filter((d) => d.id != $id);
      }
    },
    // exportToExcel() {
    //   this.title = "Conflict User Master";
    //   this.conflict_targets_excel = [];
    //   let sr_no = 1;
    //   this.conflicTargets.forEach((user) => {
    //     this.conflict_targets_excel.push({
    //       sr_no: sr_no,
    //       source: user.source,
    //       employee_code: user.store_code ? user.store_code : user.employee_code,
    //     });
    //     sr_no++;
    //   });
    //   console.log(this.conflict_targets_excel);
    // },
  },
};
</script>
