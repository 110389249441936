<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/financial/statement/company-bank-links"
            ></back-button>
            Update Compank Bank Link
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financial</li>
            <li>Statement</li>
            <li>
              <router-link to="/financial/statement/company-bank-links"
                >Company Bank Link</router-link
              >
            </li>
            <li class="active">Upadte</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Company Name*</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Company Name"
                            v-model="form.company_name"
                          />
                          <span class="error-block">{{
                            errors.company_name
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Bank Name*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Bank Name"
                            v-model="form.bank_name"
                          />
                          <span class="error-block">{{
                            errors.bank_name
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Bank Account*</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Bank Account"
                            v-model="form.bank_account"
                          />
                          <span class="error-block">{{
                            errors.bank_account
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Currency</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.currency_id"
                            :suggestions="currencies"
                            placeholder="Enter Currency"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Ledger Code</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.ledger_code_id"
                            :suggestions="ledgerCodes"
                            placeholder="Enter Ledger Code"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "siteUpdate",
  data() {
    return {
      form: {
        company_name: "",
        bank_name: "",
        bank_account: "",
        currency_id: "",
        ledger_code_id: "",
      },
      currencies: [],
      ledgerCodes: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("company_bank_links/masters");
      masters = masters.data;
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.short_name + " - " + currency.name,
        });
      });
      masters.ledgerCodes.forEach((ledger_codes) => {
        this.ledgerCodes.push({
          id: ledger_codes.id,
          name: ledger_codes.code,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/company_bank_links/${this.$route.params.companyBankLinkId}`);
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        await axios.patch(`/company_bank_links/${this.$route.params.companyBankLinkId}`, this.form);
        this.isLoading = false;
        this.$router.push("/financial/statement/company-bank-links");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
