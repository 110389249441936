<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/vessels"></back-button> 
            Make Payment
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <!-- <li><router-link to="/vessels"></router-link></li> -->
            <li class="active">Make Payment</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel*</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="Vessel Name" v-model="form.vessel_name" disabled>
                              <span class="error-block">{{ errors.vessel_name }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Period From</label>
                            <div class="col-sm-7">
                                <my-datepicker disableFormControl="true" :date.sync="period_from" placeholder="Period From"></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Payment Type</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="payment_type" :suggestions="paymentTypes" placeholder="Enter payment Type"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Invoice No</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="Vessel DWT" v-model="form.dwt_mt">
                              <span class="error-block">{{ errors.dwt_mt }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Tc Code</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="tc_code" :suggestions="tcCode" placeholder="Enter tc code"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Period To</label>
                            <div class="col-sm-7">
                                <my-datepicker disableFormControl="true" :date.sync="period_to" placeholder="Period To"></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Payment Type</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="payment_type" :suggestions="paymentTypes" placeholder="Enter payment Type"></my-select>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Status</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="status" :suggestions="statuses" placeholder="Enter status"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Chartered From</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="Chartered From" v-model="form.chartered_from" disabled>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Days</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="0.0000" v-model="form.days" >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Contarct Name</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="contract_name" :suggestions="contractNames" placeholder="Enter contract name"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Issue Date</label>
                            <div class="col-sm-7">
                                <my-datepicker disableFormControl="true" :date.sync="issue_date" placeholder="Issue Date"></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Chartered From</label>
                              <input v-uppercase type="text" class="form-control" placeholder="Chartered From" v-model="form.chartered_from" disabled>
                            <div class="col-sm-7">
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Daily Rate</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="0.0000" v-model="form.daily_rate" disabled>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Remittance Bank</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="remittance_bank" :suggestions="remittanceBank" placeholder="Enter Remittance Bank"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Payment Term</label>
                            <div class="col-sm-3">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="paymentTerm" :suggestions="paymentTerm" placeholder="Enter payment Term"></my-select>
                            </div>
                            <div class="col-sm-4">
                              <input v-uppercase type="number" class="form-control" placeholder="" v-model="form.payment_term">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Delivery</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="09/06/21" v-model="form.delivery" disabled>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Currency</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="USD" v-model="form.daily_rate" disabled>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">PO Number</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="" v-model="form.po_number">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">VAT/GST %</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="" v-model="form.vat_gst_no">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Redelivery</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="09/06/21" v-model="form.redelivery" disabled>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Currency</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="USD" v-model="form.daily_rate" disabled>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Exchange Rate</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="1.000" v-model="form.po_number">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">PST %</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="" v-model="form.pst">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Remark</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="09/06/21" v-model="form.redelivery" disabled>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Currency</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="USD" v-model="form.daily_rate" disabled>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Exchange Rate</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="1.000" v-model="form.po_number">
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Person In Charge</label>
                            <div class="col-sm-7">
                              <my-select @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="person_in_charge" :suggestions="personInCharge" placeholder="Enter Person In Charge"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Remark</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="text" class="form-control" placeholder="09/06/21" v-model="form.redelivery" disabled>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Currency</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="USD" v-model="form.daily_rate" disabled>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label">Exchange Rate</label>
                            <div class="col-sm-7">
                              <input v-uppercase type="number" class="form-control" placeholder="1.000" v-model="form.po_number">
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Received Date</label>
                            <div class="col-sm-7">
                                <my-datepicker disableFormControl="true" :date.sync="receive_date" placeholder="Receive Date"></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      

                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Code</th>
                              <th>Description</th>
                              <th>Voy No</th>
                              <th>Invoice No</th>
                              <th>From</th>
                              <th>Days/Qty</th>
                              <th>To</th>
                              <th>Rate</th>
                              <th>Retain</th>
                              <th>Amount</th>
                              <th>Amt USD</th>
                              <th>Port</th>
                              <th>SubCode</th>
                              <th>Tc Rebill</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Invoice Total</td>
                                <td>0.00</td>
                                <td>0.00</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'vesselCreate',
  data () {
    return {
      form: {
        type_code_id: '',
        ownership_id: '',
        vessel_type_id: '',
        vessel_fleet_id: '',
        trade_area_id: '',
        owner_id: '',
        class_society_id: '',
        scrubber: '',
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
    this.getMasters()
  },
  methods: {
   async getMasters() {
      let masters = await axios.get('/vessels/masters')
      masters = masters.data
      masters.type_codes.forEach(type_code => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + ' - ' + type_code.name
        })
      })
      masters.ownerships.forEach(ownership => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + ' - ' + ownership.name
        })
      })
      masters.vessel_types.forEach(vessel_type => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type
        })
      })
      masters.vessel_fleets.forEach(vessel_fleet => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet
        })
      })
      masters.trade_areas.forEach(trade_area => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + ' - ' + trade_area.description
        })
      })
      masters.owners.forEach(owner => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + ' - ' + owner.full_name
        })
      })
      masters.class_societies.forEach(class_society => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name
        })
      })
      masters.fuel_types.forEach(fuel_type => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type
        })
      })
      masters.units.forEach(unit => {
        this.units.push({
          id: unit.id,
          name: unit.unit
        })
      })
      masters.fuel_grades.forEach(fuel_grade => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade
        })
      })
      masters.consumption_types.forEach(consumption_type => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type
        })
      })
      masters.bls.forEach(b_l => {
        this.b_ls.push({
          id: b_l,
          name: b_l
        })
      })
      masters.scrubbers.forEach(scrubber => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber
        })
      })
    },
    async save() {
      try {
        this.isLoading = true
        await axios.post('/vessels', this.form)
        this.isLoading = false
        this.$router.push('/vessels')
      } catch(e) {
        this.isLoading = false
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find(unit => unit.name == 'MT');
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: '',
        unit_id: unit ? unit.id : '',
        fuel_grade_id: '',
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      })
      this.updateVesselSpeedConsumptionFuels()
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(ar => ar != vesselInPortConsumption)
      this.updateVesselSpeedConsumptionFuels()
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: '',
        engine_load: 0,
        consumption_type_id: '',
        vessel_speed_consumption_fuels: [],
      })
      this.updateVesselSpeedConsumptionFuels()
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(ar => ar != vesselSpeedConsumption)
      this.updateVesselSpeedConsumptionFuels()
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach(vsc => {
        // To check if the specific fuel is deleted from the vessel_in_port_consumptions
        for(const vscf of vsc.vessel_speed_consumption_fuels) {
          let vipc = this.form.vessel_in_port_consumptions.find(vipc => vipc.fuel_type_id == vscf.fuel_type_id)
          if(!vipc) 
            vsc.vessel_speed_consumption_fuels = vsc.vessel_speed_consumption_fuels.filter(selectedVscf => selectedVscf != vscf)
        }
        this.form.vessel_in_port_consumptions.forEach(vipc => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(vscf => vscf.fuel_type_id == vipc.fuel_type_id)
          if(!vscf) {
            let fuel_type = this.fuelTypes.find(fuelType => fuelType.id == vipc.fuel_type_id)
            if(fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id, 
                fuel_type: fuel_type.name,
                value: 0,
              })
          }
        })
      })
    },
  }
}
</script>