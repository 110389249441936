export default {
  namespaced: true,
  state: {
    baseURL: null,
    mediaURL: null,
    rowsPerPage: 100,
    state: {
      value: "",
      text: "",
      logo_path: "",
    },
    site: {},
    
  },
  getters: {
    getMediaUrl(state) {
      return state.mediaURL;
    },
    getRowsPerPage(state) {
      return state.rowsPerPage;
    },
    site(state) {
      return state.site;
    },
    getBaseUrl(state) {
      return state.baseURL;
    },
  },
  mutations: {
    SET_MEDIA_URL(state, data) {
      state.mediaURL = data;
    },
    SET_SITE(state, site) {
      state.site = site;
    },
    SET_BASE_URL(state, data) {
      state.baseURL = data;
    },
  },
  actions: {
    setMediaUrl({ commit }, url) {
      commit("SET_MEDIA_URL", url);
    },
    setSite({ commit }, site) {
      commit("SET_SITE", site);
    },
    setBaseUrl({ commit }, url) {
      commit("SET_BASE_URL", url);
    },
  },
};
