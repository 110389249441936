<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Receivables
            <add-button link="/financials/receivables/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Receivables</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        v-model="searchData"
                      />
                    </div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-primary btn-xs"
                        @click="search(searchData)"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-12">
                    <a
                      href="#"
                      style="float: right; margin-bottom: 15px"
                      class="btn btn-default"
                      v-if="ExporttoReceivables.length == 0"
                      @click="addToExcelData"
                    >
                      <span v-if="isExcelloading" style="float: right">
                        Generating Excel...
                      </span>
                      <span v-else>Generate Excel</span>
                    </a>
                    <download-excel
                      :title="title"
                      v-else
                      style="float: right; margin-bottom: 15px"
                      class="btn btn-success"
                      :data="excel_receivables"
                      :fields="excel_fields"
                      worksheet="Receivables"
                      name="Receivables.xls"
                      >Download Excel
                    </download-excel>
                    <!-- </a> -->
                  </div>
                </div>
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Vessel Name</th>
                        <th>Voyage No</th>
                        <th>Counterparty</th>
                        <th>Invoice Date</th>
                        <th>Invoice No.</th>
                        <th>Invoice %</th>
                        <th>Amount (USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="10"
                      ></loading-table>
                      <tr
                        v-for="(
                          VoyageFreightInvoice, at
                        ) in VoyageFreightInvoices"
                        :key="`VoyageFreightInvoice${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>
                          <a
                            :href="`/financials/receivables/${VoyageFreightInvoice.FreightInvoice.voyage_id}`"
                            ><u> {{ VoyageFreightInvoice.vessel_name }}</u></a
                          >
                        </td>
                        <td>{{ VoyageFreightInvoice.voyage_no }}</td>
                        <td>
                          {{
                            VoyageFreightInvoice.FreightInvoice.counter_party_id
                          }}
                        </td>
                        <td>
                          {{ VoyageFreightInvoice.FreightInvoice.invoice_date }}
                        </td>
                        <td>
                          {{ VoyageFreightInvoice.FreightInvoice.invoice_no }}
                        </td>
                        <td>
                          {{
                            VoyageFreightInvoice.FreightInvoice.invoice_percent
                          }}
                        </td>
                        <td>
                          {{
                            VoyageFreightInvoice.FreightInvoice.invoice_amount
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "receivables",
  data() {
    return {
      receivables: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      tcInPayments: [],
      searchData: "",
      VoyageFreightInvoices: [],
      excel_receivables: [],
      ExportReceivables: [],
      ExporttoReceivables: [],
      isExcelloading: false,
      excel_fields: {
        "Sr No": "sr_no",
        "Vessel Name": "vessel_name",
        "Voyage No": "voage_no",
        Counterparty: "counter_party",
        "Invoice Date": "invoice_date",
        "Invoice No": "invoice_no",
        "Invoice %": "invoice_percentage",
        Amount: "amount",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let receivables = await axios.get(`voyages/freightinvoice`);
      this.receivables = receivables.data.data;
      this.VoyageFreightInvoices = [];
      this.receivables.forEach((receivable) => {
        receivable.voyage_freight_invoices.forEach((voyage_freight_invoice) => {
          this.VoyageFreightInvoices.push({
            voyage_no: receivable.voyage_no,
            vessel_name: receivable.vessel.vessel_name,
            FreightInvoice: voyage_freight_invoice,
          });
        });
      });
      // console.log(this.receivables);
      this.count = receivables.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async search(searchData, page = 1) {
      this.isLoading = true;
      let receivables = await axios.get(`voyages/search?search=${searchData}`);
      this.receivables = receivables.data.data;
      // console.log(this.receivables);
      this.VoyageFreightInvoices = [];
      this.receivables.forEach((receivable) => {
        receivable.voyage_freight_invoices.forEach((voyage_freight_invoice) => {
          this.VoyageFreightInvoices.push({
            voyage_no: receivable.voyage_no,
            vessel_name: receivable.vessel.vessel_name,
            FreightInvoice: voyage_freight_invoice,
          });
        });
      });
      // console.log(this.receivables);
      this.count = receivables.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async addToExcelData() {
      this.isExcelloading = true;
      let Exportreceivables = await axios.get(
        `voyages/search?search=${this.searchData}`
      );
      this.Exportreceivables = Exportreceivables.data.data;
      this.ExporttoReceivables = [];
      this.Exportreceivables.forEach((receivable) => {
        receivable.voyage_freight_invoices.forEach((voyage_freight_invoice) => {
          this.ExporttoReceivables.push({
            voyage_no: receivable.voyage_no,
            vessel_name: receivable.vessel.vessel_name,
            FreightInvoice: voyage_freight_invoice,
          });
        });
      });

      this.title = "Receivales";
      this.excel_receivables = [];
      this.ExporttoReceivables.forEach((receivable, a) => {
        this.excel_receivables.push({
          sr_no: a + 1,
          vessel_name: receivable.vessel_name,
          voage_no: receivable.voyage_no,
          counter_party: receivable.FreightInvoice.counter_party_id,
          invoice_date: receivable.FreightInvoice.invoice_date,
          invoice_no: receivable.FreightInvoice.invoice_no,
          invoice_percentage: receivable.FreightInvoice.invoice_percent,
          amount: receivable.FreightInvoice.invoice_amount,
        });
      });
      // console.log(this.ExportReceivables);
      this.isExcelloading = false;
    },
  },
};
</script>
