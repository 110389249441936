import Vue from "vue";
import moment from "moment";

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY HH:mm");
});

Vue.filter("dmy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});

Vue.filter('round2', function (value) {
  if (value == null || value == '' || isNaN(value)) return value
  return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // 6.71
})

Vue.filter('round3', function (value) {
  if (value == null || value == '' || isNaN(value)) return value
  return parseFloat(value).toFixed(7).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // 6.71
})

// Vue.filter("dhm", function (value) {
//   if (value == null || value == '' || isNaN(value)) return value
//   value = value.toString();
//   let days = value.split(".");
//   let formatted_days = 0
//   if (days.length != 0)
//     formatted_days = moment.duration(days[0], "days").days();
//   let raw_hr = 0
//   let formatted_hrs = 0
//   let formatted_min = 0
//   if (days.length == 2) {
//     raw_hr = (("0." + days[1]) * 24).toString().split(".");
//     if (raw_hr.length != 0)
//       formatted_hrs = raw_hr[0];
//     if (raw_hr.length == 2)
//       formatted_min = Math.round(("0." + raw_hr[1]) * 60)
//   }
//   formatted_days = formatted_days + ""
//   formatted_hrs = formatted_hrs + ""
//   formatted_min = formatted_min + ""
//   while (formatted_days.length < 2) formatted_days = "0" + formatted_days;
//   while (formatted_hrs.length < 2) formatted_hrs = "0" + formatted_hrs;
//   while (formatted_min.length < 2) formatted_min = "0" + formatted_min;
//   return formatted_days + "d" + formatted_hrs + ":" + formatted_min;
// });

Vue.filter("dhm", function (value) {
  if (value == null || value == "" || isNaN(value)) return value;
  value = value.toString();
  let days = value.split(".");
  let formatted_days = days[0];
  let raw_hr = 0;
  let formatted_hrs = 0;
  let formatted_min = 0;
  if (days.length == 2) {
    raw_hr = (("0." + days[1]) * 24).toString().split(".");
    if (raw_hr.length != 0) formatted_hrs = raw_hr[0];
    if (raw_hr.length == 2) formatted_min = Math.round(("0." + raw_hr[1]) * 60);
  }
  formatted_days = formatted_days + "";
  formatted_hrs = formatted_hrs + "";
  formatted_min = formatted_min + "";
  while (formatted_days.length < 2) formatted_days = "0" + formatted_days;
  while (formatted_hrs.length < 2) formatted_hrs = "0" + formatted_hrs;
  while (formatted_min.length < 2) formatted_min = "0" + formatted_min;
  return (
    formatted_days +
    "d" +
    formatted_hrs +
    ":" +
    formatted_min
  );
});
