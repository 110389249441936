<template>
    <TcOutList></TcOutList>
</template>

<script>
import TcOutList from '../../tc-outs/create.vue'
export default {
    components : {
        TcOutList
    }
}
</script>