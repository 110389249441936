<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Owner's Commission Summary
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <!-- <li><router-link to="/tc-in">TC in</router-link></li> -->
            <li>Commission</li>
            <li class="active">
               Owner's Commission Summary
            </li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel</label>
                            <div class="col-sm-6">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="JALVAI"
                                v-model="a"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >TC Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="1010"
                                v-model="tc_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-7">
                              <div class="form-group">
                                <label class="col-sm-5 control-label"
                                  >To Pay</label
                                >
                                <div class="col-sm-7">
                                  <input
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="FREX"
                                    v-model="broker"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group">
                                <label class="col-sm-4 control-label"
                                  >Currency</label
                                >
                                <div class="col-sm-8">
                                  <input
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="USD"
                                    v-model="currency"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Effective to</label
                            >
                            <div class="col-md-9">
                             <my-datepicker
                                :date.sync="form.effective_to"
                                placeholder="Enter Effective Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" style="overflow-x:scroll">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Invoice No.</th>
                              <th>Date</th>
                              <th>Remarks</th>
                              <th>Commission</th>
                              <th>Curr</th>
                              <th>St</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <errors :data="errors" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "freightCommission",
  data() {
    return {
      form: {
          vessel_id: ''
      },
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/class_societies", this.form);
        this.isLoading = false;
        this.$router.push("/estimates");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
