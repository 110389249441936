<template>
  <section>
    <h5><u>Cargoes</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Cargo</th>
            <th>C/P Qty</th>
            <th>Unit</th>
            <th>CP Date</th>
            <th>Freight Type</th>
            <th>Freight Rate</th>
            <th>Freight Commission</th>
            <th>Charter</th>
            <th>USD</th>
            <th>Exchange Rate</th>
            <th>Demurrage</th>
            <th>Dispatch</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cargo, ar) in voyageCargos" :key="`cargo${ar}`">
            <td>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <CPTerms
                        :count="ar"
                        :form="form"
                        :voyageCargo="cargo"
                        :cargoNames="cargoNames"
                        :freightTypes="freightTypes"
                        :perDays="perDays"
                        :addresses="addresses"
                        :brokerRateTypes="brokerRateTypes"
                        :brokerPaymentMethods="brokerPaymentMethods"
                        :brokerCommissionables="brokerCommissionables"
                        @onVoyageCargoUpdated="onVoyageCargoUpdated"
                        :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
                      ></CPTerms>
                    </div>
                    <div class="col-md-4">{{ ar + 1 }}.</div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.cargo_name_id"
                :suggestions="cargoNames"
                placeholder="Select cargo"
                @change="updateVoyageCargo(cargo)"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="CP Qty"
                v-model="cargo.qty"
                @blur="calculateCost"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.unit_id"
                :suggestions="units"
                placeholder="Select unit"
              ></my-select>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="cargo.cp_date"
                placeholder="Enter CP Date"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.freight_type_id"
                :suggestions="freightTypes"
                placeholder="Enter freight type"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Freight rate"
                v-model="cargo.freight_rate"
                @blur="calculateCost"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Freight commission"
                v-model="cargo.freight_commission"
                @blur="calculateCost"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.charterer_id"
                :suggestions="addresses"
                placeholder="Enter charterer"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="cargo.exc_currency_id"
                :suggestions="currencies"
                placeholder="Enter currency"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Exch. rate"
                v-model="cargo.exc_exchange_rate"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Demurrage"
                v-model="cargo.demurrage_amount"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Despatch"
                v-model="cargo.despatch_amount"
              />
            </td>
          </tr>
          <!-- <tr>
            <td colspan="10" align="center" @click="addEmptyVoyageCargo()">
              Add New Row
            </td>
          </tr> -->
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
import CPTerms from "./cp_terms.vue";

export default {
  name: "voyageCargos",
  data() {
    return {
      myVoyageCargos: [],
    };
  },
  props: [
    "form",
    "voyageCargos",
    "cargoNames",
    "units",
    "freightTypes",
    "addresses",
    "currencies",
    "perDays",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerCommissionables",
    "brokerFreightPaymentMethods",
  ],
  components: {
    CPTerms,
  },
  watch: {
    voyageCargos: "getMyVoyageCargos",
  },
  mounted() {
    this.getMyVoyageCargos();
  },
  methods: {
    getMyVoyageCargos() {
      this.myVoyageCargos = this.voyageCargos;
      this.myVoyageCargos.forEach((voyageCargo) => {
        voyageCargo.per_day_per_hr = voyageCargo.per_day_per_hr || "";
      });
    },
    addEmptyVoyageCargo() {
      this.voyageCargos.push({
        name: "",
        cargo_name_id: "",
        qty: 0,
        unit_id: "1",
        freight_type_id: "1",
        freight_rate: 0,
        freight_commission: 0,
        charterer_id: "",
        exc_currency_id: "1",
        exc_exchange_rate: "1",
        cp_date: "",
        option_percent: 10,
        min_qty: 0,
        max_qty: 0,
        demurrage_amount: 0,
        despatch_amount: 0,
        per_day_per_hr: 1,
      });
    },
    updateVoyageCargo(cargo) {
      let cargoName = this.cargoNames.find((c) => c.id == cargo.cargo_name_id);
      if (cargoName) cargo.name = cargoName.name;
    },
    calculateCost() {
      // this.$emit('calculateCost')
    },
    onVoyageCargoUpdated() {
      this.$emit("onVoyageCargoUpdated");
    },
  },
};
</script>
