<template>
    <TcInvoice></TcInvoice>
</template>

<script>
import TcInvoice from '../../tc-ins/commissions/tc-commission.vue'
export default {
    components : {
        TcInvoice
    }
}
</script>