<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Laytime Calculation Setup</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Laytime Calculation Setup</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="row">
                    <div class="col-sm-12 col-xs-12">
                      <div class="form-wrap form-horizontal">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Vessel</label
                              >
                              <div class="col-sm-9">
                                <my-select
                                  :selection.sync="vessel_id"
                                  :suggestions="vessles"
                                  placeholder="Select vessel"
                                ></my-select>
                                <span class="error-block">{{
                                  errors.vessel_id
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Voyage No</label
                              >
                              <div class="col-sm-9">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Voyage Number"
                                  v-model="voyage_no"
                                />
                                <span class="error-block">{{
                                  errors.voyage_no
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Counterparty type</label
                              >
                              <div class="col-sm-9">
                                <my-select
                                  :selection.sync="counter_party_type"
                                  :suggestions="counterPartyTypes"
                                  placeholder="Select counterparty type"
                                ></my-select>
                                <span class="error-block">{{
                                  errors.counter_party_type
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Methods</label
                              >
                              <div class="col-sm-9">
                                <my-select
                                  :selection.sync="methods"
                                  :suggestions="methods"
                                  placeholder="Select method"
                                ></my-select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-3">
                            <table class="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Charterer</th>
                                  <th>S</th>
                                </tr>
                              </thead>
                              <tbody>
                                <loading-table
                                  :isLoading="isLoading"
                                  cols="2"
                                ></loading-table>
                                <tr>
                                  <td>OPG</td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="s"
                                    ></my-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-6">
                            <table class="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Port Name</th>
                                  <th>Cargo</th>
                                  <th>BL Qty</th>
                                  <th>Unit</th>
                                  <th>F</th>
                                  <th>Status</th>
                                  <th>
                                    <my-checkbox
                                      :selection.sync="s"
                                    ></my-checkbox>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <loading-table
                                  :isLoading="isLoading"
                                  cols="7"
                                ></loading-table>
                                <tr>
                                  <td>TABONEO</td>
                                  <td>COAL</td>
                                  <td>82,400</td>
                                  <td>MT</td>
                                  <td>L</td>
                                  <td>SA</td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="s"
                                    ></my-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-3">
                            <my-checkbox
                              :selection.sync="is_transit_port"
                              placeholder="Include Transit Port"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_purging_port"
                              placeholder="Include Purging Port"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_cleaning_port"
                              placeholder="Include Cleaning Port"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_waiting_port"
                              placeholder="Include Waiting Port"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_reversible_selected_port"
                              placeholder="Reversible Selected Ports"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_demurrage_port"
                              placeholder="Show Demurrage Ports"
                            ></my-checkbox>
                            <my-checkbox
                              :selection.sync="is_once_on_always_on_dem"
                              placeholder="Once On/Always On Dem"
                            ></my-checkbox>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="form-group mb-0">
                                  <div class="col-sm-9">
                                    <submit-button
                                      :isLoading="isLoading"
                                      @submit="ok"
                                      text="OK"
                                    ></submit-button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group mb-0">
                                  <div class="col-sm-9">
                                    <submit-button
                                      :isLoading="isLoading"
                                      text="Cancel"
                                    ></submit-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <br />
                        <div class="col-md-12">
                          <table class="table table-hover table-striped">
                            <thead>
                              <tr>
                                <th>Claim ID</th>
                                <th>Counterparty</th>
                                <th>Type</th>
                                <th>Ports</th>
                                <th>Dem/Des</th>
                                <th>Invoice Date</th>
                                <th>Amount USD</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <loading-table
                                :isLoading="isLoading"
                                cols="8"
                              ></loading-table>
                              <tr>
                                <td>26</td>
                                <td>OPG</td>
                                <td>Charterer</td>
                                <td>TABONEO;ENNORE</td>
                                <td>Despatch</td>
                                <td>04/05/20</td>
                                <td>14,345.14</td>
                                <td>Under Review</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
// import axios from "axios";

export default {
  name: "LaytimeCalculationSetup",
  data() {
    return {
      vessel_id: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {},
  methods: {
    async ok() {
      try {
        this.isLoading = true;
        this.$router.push("/laytime/calculation-ar-claim");
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>