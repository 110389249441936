<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/tc-outs"></back-button>
            Bunker Purchase
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Operation</li>
            <li>Bunker</li>
            <li class="active">Bunker Purchase</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-9 col-xs-9">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel/Voyage</label
                            >
                            <div class="col-sm-5">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                v-model="form.voyage_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port</label>
                            <div class="col-sm-9">
                              <my-select
                                :suggestions="Port"
                                placeholder="Select Port"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vendor</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vendor"
                                :suggestions="Vendor"
                                placeholder="Select Vendor"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.include_commission"
                                placeholder="Trader"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Broker</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.broker"
                                :suggestions="Broker"
                                placeholder="Select Broker"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Requirement ID</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Requirement ID"
                                v-model="form.company"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Request Status</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company"
                                :suggestions="RequestStatus"
                                placeholder="Select Request Status"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Company"
                                v-model="form.company"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Req Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.req_delivery"
                                placeholder="Enter Req Delivery"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vendor Remark</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Vendor Remark"
                                v-model="form.vendor_remark"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Owner</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="Owner"
                                v-model="form.owner"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Delivery</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laytime_to"
                                placeholder="Enter Delivery"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Addr Commission</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="0.000"
                                v-model="form.tc_code"
                              />
                            </div>
                            <div class="col-sm-4">
                              <my-select
                                :selection.sync="form.company"
                                :suggestions="Company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Agent</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company"
                                :suggestions="Agent"
                                placeholder="Select Agent"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Supplier</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.tc_code"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">ETB</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.tc_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" style="overflow-x:scroll">
                        <table class="table table-responsive">
                          <thead>
                            <tr>
                              <th>Sr no</th>
                              <th>Type</th>
                              <th>Grade</th>
                              <th>Ord Qty</th>
                              <th>Opr Qty</th>
                              <th>Basic Prc</th>
                              <th>Fuel Cost</th>
                              <th>Barge Cost</th>
                              <th>Oth Cost</th>
                              <th>Sales Cost</th>
                              <th>Port Chrgs</th>
                              <th>Net Cost</th>
                              <th>Sulfur %</th>
                            </tr>
                          </thead>
                          <tfoot>
                            <tr>
                              <td colspan="5" rowspan="2">
                                <my-checkbox
                                  :selection.sync="form.include_commission"
                                  placeholder="Hide Lines With Zero Quantity"
                                ></my-checkbox>
                              </td>
                              <th>
                                <p style="float:right">Total</p>
                              </th>
                              <td>0.00</td>
                              <td>0.00</td>
                              <td>0.00</td>
                              <td>0.00</td>
                              <td>0.00</td>
                              <td>0.00</td>
                            </tr>
                            <tr>
                              <th colspan="6">
                                <p style="float:right">Net Cost USD</p>
                              </th>
                              <td>0.00</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Port Remark: General</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              disabled
                              v-model="form.bunkering_details"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Port Remark: Products</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              disabled
                              v-model="form.bunkering_details"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Port Remark: Barges</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              disabled
                              v-model="form.bunkering_details"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Special Instructions</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              v-model="form.bunkering_details"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Comments</label>
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              v-model="form.bunkering_details"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-xs-3">
                    <div class="row">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Purchase Status</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="PurchaseStatus"
                            placeholder="Select Purchase Status"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Purchase Type</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="PurchaseType"
                            placeholder="Select Purchase Type"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Delivery Type</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="DeliveryType"
                            placeholder="Select Delivery Type"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PO Number</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Ordered By</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="OrderedBy"
                            placeholder="Select Ordered By"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Order Date</label>
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.laytime_to"
                            placeholder="Enter Order Date"
                          ></my-datepicker>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Ordered By</label>
                        <div class="col-sm-4">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="OrderedBy"
                            placeholder="Select Ordered By"
                          ></my-select>
                        </div>
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <br />
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Port Charges</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Other Cost</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Barging Rate</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Tax %</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Payment Terms</label
                        >
                        <div class="col-sm-4">
                          <my-select
                            :selection.sync="form.company"
                            :suggestions="PaymentTerms"
                            placeholder="Select Payment Terms"
                          ></my-select>
                        </div>
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Invoice No.</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Invoice Status</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Invoice Amount</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Invoice Date</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="0.0000"
                            v-model="form.company"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
