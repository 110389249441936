<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/estimates"></back-button>
            Create quick estimate
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/estimates">Quick Estimate</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="tab-struct custom-tab-1">
            <ul role="tablist" class="nav nav-tabs" id="myTabs_7">
              <li
                :class="i == 1 ? 'active' : ''"
                role="presentation"
                v-for="i in estimates"
                :key="`estimate${i}`"
              >
                <a
                  aria-expanded="true"
                  data-toggle="tab"
                  role="tab"
                  :id="`home_tab_${i}`"
                  :href="`#home_${i}`"
                  >Estimate {{ i }}
                </a>
              </li>
              <li>
                <button
                  class="btn btn-link"
                  style="color: black"
                  @click="addNewEstimate"
                >
                  Add New
                </button>
              </li>
            </ul>
            <div class="tab-content" id="`myTabContent_7`">
              <div
                class="tab-pane fade active in"
                role="tabpanel"
                v-for="i in estimates"
                :key="`estimate${i}`"
                :id="`home_${i}`"
              >
                <estimate :count="i"></estimate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import Estimate from "@/components/estimate/estimate.vue";

export default {
  name: "QuickEstimateCreate",
  data() {
    return {
      estimates: 1,
      isLoading: false,
    };
  },
  components: {
    Estimate,
  },
  methods: {
    addNewEstimate() {
      this.estimates++;
    },
  },
};
</script>

