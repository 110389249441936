<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-6 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Freight Types
            <add-button link="/freight-types/create"></add-button>
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-6 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Freight Types</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Is Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="2"
                      ></loading-table>
                      <router-link
                        v-for="(freightType, at) in freightTypes"
                        :key="`freightType${at}`"
                        :to="`/freight-types/${freightType.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ freightType.type }}</td>
                        <td>{{ freightType.description }}</td>
                        <td>{{ freightType.is_active == 1 ? "YES" : "NO" }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "freightTypes",
  data() {
    return {
      freightTypes: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let freightTypes = await axios.get(
        `/freight_types?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.freightTypes = freightTypes.data.data;
      this.count = freightTypes.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
