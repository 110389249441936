<template>
  <section>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Dur.</th>
            <th>Unit</th>
            <th>-Days</th>
            <th>+Days</th>
            <th>E/L Redel.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Min</td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Duration"
                v-model="form.min_duration"
              />
            </td>
            <td>
              <my-select
                disableFormControl="true"
                :selection.sync="form.min_duration_unit"
                :suggestions="durationUnits"
                placeholder="Duration Unit"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="-Days"
                v-model="form.min_duration_minus_days"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="+Days"
                v-model="form.min_duration_plus_days"
              />
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.min_estimated_redelivery"
                placeholder="E/L Redel."
                myWidth="200px"
              ></my-datepicker>
            </td>
          </tr>
          <tr>
            <td>Max</td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Duration"
                v-model="form.max_duration"
              />
            </td>
            <td>
              <my-select
                disableFormControl="true"
                :selection.sync="form.max_duration_unit"
                :suggestions="durationUnits"
                placeholder="Duration Unit"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="-Days"
                v-model="form.max_duration_minus_days"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="+Days"
                v-model="form.max_duration_plus_days"
              />
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.max_estimated_redelivery"
                placeholder="E/L Redel."
                myWidth="200px"
              ></my-datepicker>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: ["form", "durationUnits"],
};
</script>
