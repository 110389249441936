<template>
  <section>
    <span @click="openVoyageLaytimeCreateModal" title="Port Expenses">
      <i class="fa fa-external-link-square" aria-hidden="true"></i>
      <!-- Laytime Setup -->
    </span>

    <div :id="`createVoyageLaytimeCreateModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Laytime Setup</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- Vessel -->
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-4">Charterer</div>
                <div class="col-md-8">
                  {{
                    form.voyage_cargos.length > 0
                      ? form.voyage_cargos[0].charterer.short_name
                      : ""
                  }}
                </div>
              </div>
              <!--  -->
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-4">Vessel</div>
                <div class="col-md-8">
                  <span class="myBold">{{
                    form.vessel ? form.vessel.vessel_name : ""
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">Voyage No</div>
                <div class="col-md-8">
                  <span class="myBold">{{
                    form.vessel ? form.voyage_no : ""
                  }}</span>
                </div>
              </div>
              <!--  -->
            </div>
            <!-- PDA -->
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-4">Opr Type</div>
                <div class="col-md-8">
                  <span class="myBold">{{
                    form.operation_type ? form.operation_type.type : ""
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">CP Date</div>
                <div class="col-md-8">
                  {{
                    form.voyage_cargos.length > 0
                      ? form.voyage_cargos[0].cp_date
                      : ""
                  }}
                </div>
              </div>
              <!--  -->
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-4">Ports</div>
                <div class="col-md-8">
                  <div
                    v-for="(selectedPort, sp) in selectedPorts"
                    :key="`selectedPort${sp}`"
                  >
                    <my-checkbox
                      :selection.sync="selectedPort.isSelected"
                      :placeholder="
                        selectedPort.port_name
                          ? selectedPort.port_name.port_name
                          : ''
                      "
                      @change="onSelectedPortsChanged"
                    ></my-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <my-checkbox
                :selection.sync="includeInPandL"
                placeholder="Include In P&L"
                @change="onFiltersChanged"
              ></my-checkbox>
            </div>
            <div class="col-md-3">
              <div class="col-md-4">Calculation</div>
              <div class="col-md-8">
                <my-select
                  maxWidth="YES"
                  disableFormControl="true"
                  :selection.sync="selectedCalculationType"
                  :suggestions="calculationTypes"
                  placeholder="Select calculation type"
                  @change="onFiltersChanged"
                ></my-select>
              </div>
            </div>
          </div>
          <h5><u>Details</u></h5>
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Cargo</th>
                  <th>Function</th>
                  <!-- <th>BL Code.</th> -->
                  <th>Quantity</th>
                  <th>L/D Rate</th>
                  <th>Terms</th>
                  <th>Dem Rate/D</th>
                  <th>Des Rate/D</th>
                  <th>Allowed</th>
                  <th>Used</th>
                  <th>Deduction</th>
                  <th>Balance</th>
                  <th>Dem/Des</th>
                  <th>Amount</th>
                  <th>Laycan From</th>
                  <th>Laycan To</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(voyageLaytime, vl) in form.voyage_laytimes.filter(
                    (voyageLaytime) =>
                      voyageLaytime.position == count &&
                      voyageLaytime.port_name_id
                  )"
                  :key="`voyageLaytime${vl}`"
                >
                  <td>
                    {{
                      voyageLaytime.port_name
                        ? voyageLaytime.port_name.port_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      voyageLaytime.cargo_name
                        ? voyageLaytime.cargo_name.short_name
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_itenaries.find(
                        (vI) => vI.port_name_id == voyageLaytime.port_name_id
                      )
                        ? form.voyage_itenaries.find(
                            (vI) =>
                              vI.port_name_id == voyageLaytime.port_name_id
                          ).port_function
                          ? form.voyage_itenaries.find(
                              (vI) =>
                                vI.port_name_id == voyageLaytime.port_name_id
                            ).port_function.code
                          : ""
                        : ""
                    }}
                  </td>
                  <!-- <td></td> -->
                  <td>
                    <input
                      v-uppercase
                      placeholder="Qty"
                      v-model="voyageLaytime.actual_qty"
                      @change="onLaytimeUpdated"
                    />
                  </td>
                  <td>
                    {{
                      form.voyage_itenaries.find(
                        (vI) => vI.port_name_id == voyageLaytime.port_name_id
                      )
                        ? form.voyage_itenaries.find(
                            (vI) =>
                              vI.port_name_id == voyageLaytime.port_name_id
                          ).load_discharge_rate
                        : 0
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_itenaries.find(
                        (vI) => vI.port_name_id == voyageLaytime.port_name_id
                      )
                        ? form.voyage_itenaries.find(
                            (vI) =>
                              vI.port_name_id == voyageLaytime.port_name_id
                          ).laytime_term
                          ? form.voyage_itenaries.find(
                              (vI) =>
                                vI.port_name_id == voyageLaytime.port_name_id
                            ).laytime_term.term
                          : ""
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_cargos.length > 0
                        ? form.voyage_cargos[0].demurrage_amount
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_cargos.length > 0
                        ? form.voyage_cargos[0].despatch_amount
                        : ""
                    }}
                  </td>
                  <td>
                    {{ voyageLaytime.allowed | dhm }}
                  </td>
                  <td>
                    {{ voyageLaytime.used | dhm }}
                  </td>
                  <td>
                    {{ voyageLaytime.deduction | dhm }}
                  </td>
                  <td>
                    {{ voyageLaytime.balance | dhm }}
                  </td>
                  <td>
                    {{
                      selectedCalculationType == "Standard"
                        ? voyageLaytime.demurrage_discharge
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      selectedCalculationType == "Standard"
                        ? voyageLaytime.amount
                        : "" | round2
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_cargos.length > 0
                        ? form.voyage_cargos[0].laycan_from
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_cargos.length > 0
                        ? form.voyage_cargos[0].laycan_to
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_allowed
                        : 0 | dhm
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_used
                        : 0 | dhm
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_deduction
                        : 0 | dhm
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_balance
                        : 0 | dhm
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_demurrage_discharge
                        : 0
                    }}
                  </td>
                  <td>
                    {{
                      form.voyage_laytimes.filter(
                        (voyageLaytime) =>
                          voyageLaytime.position == count &&
                          voyageLaytime.port_name_id
                      ).length > 0
                        ? form.voyage_laytimes.filter(
                            (voyageLaytime) =>
                              voyageLaytime.position == count &&
                              voyageLaytime.port_name_id
                          )[0].total_amount
                        : 0 | round2
                    }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <Activities
            :form="form"
            :count="count"
            :portActivities="portActivities"
            @onLaytimeUpdated="onLaytimeUpdated"
          ></Activities>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveVoyageLaytimeCreate"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeVoyageLaytimeCreateModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Activities from "./activities.vue";

export default {
  data() {
    return {
      cp_date: "",
      selectedPorts: [],
      includeInPandL: false,
      selectedCalculationType: "Standard",
      calculationTypes: [
        {
          id: "Standard",
          name: "Standard",
        },
        {
          id: "Reversible",
          name: "Reversible",
        },
      ],
    };
  },
  components: {
    Activities,
  },
  watch: {
    voyageItenariesLD: "voyageItenariesLDWatcher",
  },
  props: ["form", "portActivities", "count", "voyageItenariesLD"],
  mounted() {
    this.voyageItenariesLDWatcher();
  },
  methods: {
    async openVoyageLaytimeCreateModal() {
      var modal = document.getElementById(
        "createVoyageLaytimeCreateModal" + this.count
      );
      modal.style.display = "block";
    },
    closeVoyageLaytimeCreateModal() {
      var modal = document.getElementById(
        "createVoyageLaytimeCreateModal" + this.count
      );
      modal.style.display = "none";
    },
    saveVoyageLaytimeCreate() {
      this.$emit("onLaytimeUpdated");
      this.closeVoyageLaytimeCreateModal();
    },
    onLaytimeUpdated() {
      this.$emit("onLaytimeUpdated");
    },
    voyageItenariesLDWatcher() {
      this.selectedPorts = [];
      this.voyageItenariesLD.forEach((voyageItenary) => {
        this.selectedPorts.push({
          id: voyageItenary.port_name_id,
          port_name: voyageItenary.port_name,
          isSelected: false,
        });
      });
      this.selectedPorts.forEach((selectedPort) => {
        let voyageLaytime = this.form.voyage_laytimes.find(
          (voyageLaytime) =>
            voyageLaytime.port_name_id == selectedPort.id &&
            voyageLaytime.position == this.count
        );
        if (voyageLaytime) {
          selectedPort.isSelected = true;
          this.includeInPandL = voyageLaytime.include_in_p_and_l || false;
          this.selectedCalculationType =
            voyageLaytime.calculation_type || "Standard";
        }
      });
      this.onSelectedPortsChanged();
    },
    onSelectedPortsChanged() {
      // let presentVoyageLaytimes = []
      // this.form.voyage_laytimes
      //   .filter((voyageLaytime) => voyageLaytime.position == this.count)
      //   .forEach((voyageLaytime) => {
      //     presentVoyageLaytimes.push(voyageLaytime);
      //   });
      // presentVoyageLaytimes.forEach((presentVoyageLaytime) => {
      //   this.form.voyage_laytimes.splice(
      //     this.form.voyage_laytimes.indexOf(presentVoyageLaytime),
      //     1
      //   );
      // });
      // To add a voyage laytime if the post is not there
      this.selectedPorts
        .filter((selectedPort) => selectedPort.isSelected)
        .forEach((selectedPort) => {
          let voyageLaytime = this.form.voyage_laytimes.find(
            (vL) =>
              vL.port_name_id == selectedPort.id && vL.position == this.count
          );
          if (!voyageLaytime) {
            let voyageItenary = this.voyageItenariesLD.find(
              (voyageItenary) =>
                voyageItenary.port_name_id == selectedPort.port_name.id
            );
            let vl = {
              position: this.count,
              port_name: selectedPort.port_name,
              port_name_id: selectedPort.id,
              cargo_name: voyageItenary ? voyageItenary.cargo_name : "",
              cargo_name_id: voyageItenary ? voyageItenary.cargo_name_id : "",
              demurrage_discharge: "",
              invoice_date: "",
              amount: 0,
              laytime_status_id: "",
              allowed: 0,
              used: 0,
              deduction: 0,
              balance: 0,
              show_demurrage_start: "",
              include_in_p_and_l: "",
              continuous_laytime: "",
              hh_mm_format: "",
              once_on_always_on_demurrage: "",
              actual_qty: 0,
              voyage_laytime_port_activities: [],
              voyage_laytime_deductions: [],
            };
            this.form.voyage_laytimes.push(vl);
          }
          // else {
          //   this.form.voyage_laytimes.push(voyageLaytime);
          // }
        });

      // To delete a voyageLaytime if port is unselected and voyageLaytime of current position is already there
      let voyageLaytimesToBeDeleted = [];
      this.form.voyage_laytimes
        .filter((voyageLaytime) => voyageLaytime.position == this.count)
        .forEach((voyageLaytime) => {
          let selectedPort = this.selectedPorts.find(
            (selectedPort) =>
              selectedPort.id == voyageLaytime.port_name_id &&
              selectedPort.isSelected == true
          );
          if (!selectedPort) voyageLaytimesToBeDeleted.push(voyageLaytime);
        });
      voyageLaytimesToBeDeleted.forEach((voyageLaytime) => {
        this.form.voyage_laytimes.splice(
          this.form.voyage_laytimes.indexOf(voyageLaytime),
          1
        );
      });
      this.onFiltersChanged();
    },
    onFiltersChanged() {
      this.form.voyage_laytimes
        .filter((voyageLaytime) => voyageLaytime.position == this.count)
        .forEach((voyageLaytime) => {
          if (this.includeInPandL) voyageLaytime.include_in_p_and_l = true;
          else voyageLaytime.include_in_p_and_l = false;
          voyageLaytime.calculation_type = this.selectedCalculationType;
        });
      this.onLaytimeUpdated();
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}

table td input {
  width: 70px !important;
}
.myBold {
  font-weight: bold;
}
</style>
