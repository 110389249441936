<template>
  <section>
    <a href="javascript:void" @click="openVoyageCommissionsModal" title="Delays"
      >Freight Commission</a
    >

    <div id="myVoyageCommissionModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Freight Commission</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Vessel</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Vessel"
                        v-model="form.vessel.vessel_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Voy no.</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Voyage no"
                        v-model="form.voyage_no"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Commencing</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Commencing"
                        v-model="form.voyage_commencing_date"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Completing</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        placeholder="Completing"
                        v-model="form.voyage_completing_date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5><u>Commissions</u></h5>
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Broker</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(commission, c) in commissions"
                  :key="`commission${c}`"
                >
                  <td>{{ c + 1 }}.</td>
                  <td>{{ commission.broker }}</td>
                  <td>
                    <input
                      style="width: 200px !important"
                      v-uppercase
                      placeholder="Invoice no."
                      v-model="commission.invoice_no"
                    />
                  </td>
                  <td>
                    <input
                      style="width: 200px !important"
                      v-uppercase
                      placeholder="Invoice date."
                      v-model="commission.invoice_date"
                    />
                  </td>
                  <td>{{ commission.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onVoyageCommissionUpdated">
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyVoyageCommissionsModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "voyageDelays",
  data() {
    return {
      commissions: [],
    };
  },
  props: [
    "form",
    "delayReasons",
    "delayTypes",
    "counterPartyTypes",
    "voyageFreightInvoices",
  ],
  watch: {
    voyageFreightInvoices: {
      handler() {
        this.onVoyageFreightInvoicesUpdated();
      },
      deep: true,
    },
  },
  components: {},
  methods: {
    async openVoyageCommissionsModal() {
      var modal = document.getElementById("myVoyageCommissionModal");
      modal.style.display = "block";
    },
    closeMyVoyageCommissionsModal() {
      var modal = document.getElementById("myVoyageCommissionModal");
      modal.style.display = "none";
    },
    onVoyageFreightInvoicesUpdated() {
      let commissions = [];
      this.form.voyage_freight_invoices.forEach((vfi) => {
        vfi.voyage_freight_invoice_brokers.forEach((vfib) => {
          if (vfib.payment_method != "Deduct from freight")
            commissions.push({
              id: vfib.id,
              broker_id: vfib.broker_id,
              broker: vfib.broker.short_name,
              invoice_no: "",
              invoice_date: "",
              amount: vfib.amount ?? 0,
            });
        });
      });
      this.commissions = commissions;
    },
    onVoyageCommissionUpdated() {
      this.$emit("onVoyageCommissionUpdated");
      this.closeMyVoyageCommissionsModal();
    },
  },
};
</script>
