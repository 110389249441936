<template>
  <section>
    <span @click="openVoyageFreightInvoiceCreateModal" title="Port Expenses">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
    </span>

    <div :id="`createVoyageFreightInvoiceCreateModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Create/Update Freight Invoice</h4>
          <router-link to="/" target="_blank">Invoice</router-link>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Vessel</div>
                    <div class="col-md-8">
                      <span class="myBold">{{
                        form.vessel ? form.vessel.vessel_name : ""
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Voyage no</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ form.voyage_no }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Counter Party</div>
                    <div class="col-md-8">
                      {{
                        form.voyage_cargos.length > 0
                          ? form.voyage_cargos[0].charterer.short_name
                          : ""
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Fixture no</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ form.fixture_no }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">CP Date</div>
                    <div class="col-md-8">
                      {{
                        form.voyage_cargos.length > 0
                          ? form.voyage_cargos[0].cp_date
                          : "" | ddmmyyyy
                      }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4 control-label">Invoice no</div>
                    <div class="col-md-8">
                      <input
                        v-uppercase
                        class="form-control"
                        placeholder="Invoice no"
                        v-model="voyageFreightInvoice.invoice_no"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 control-label">Payment Terms</div>
                    <div class="col-md-8">
                      <my-select
                        disableFormControl="true"
                        :selection.sync="voyageFreightInvoice.payment_term_id"
                        :suggestions="paymentTerms"
                        placeholder="Select payment term"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 control-label">Remarks</div>
                    <div class="col-md-8">
                      <input
                        v-uppercase
                        class="form-control"
                        placeholder="Remarks"
                        v-model="voyageFreightInvoice.remarks"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4 control-label">Invoice Date</div>
                    <div class="col-md-8">
                      <my-datepicker
                        :date.sync="voyageFreightInvoice.invoice_date"
                        placeholder="Enter date"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 control-label">Due Date</div>
                    <div class="col-md-8">
                      <my-datepicker
                        :date.sync="voyageFreightInvoice.due_date"
                        placeholder="Enter date"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Details
            :form="form"
            :freightTypes="freightTypes"
            :portFunctions="portFunctions"
            :voyageCargos="voyageCargos"
            :voyageFreightInvoice="voyageFreightInvoice"
            :voyageFreightInvoices="voyageFreightInvoices"
          ></Details>

          <FreightCommision
            :form="form"
            :addresses="addresses"
            :brokerRateTypes="brokerRateTypes"
            :brokerPaymentMethods="brokerPaymentMethods"
            :voyageBrokers="voyageBrokers"
            :voyageFreightInvoice="voyageFreightInvoice"
            :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
          ></FreightCommision>

          <FreightAdjustment
            :form="form"
            :voyageFreightInvoice="voyageFreightInvoice"
          ></FreightAdjustment>

          <h5><u>Freight Calculation</u></h5>

          <table class="table table-striped">
            <thead>
              <tr>
                <th colspan="10">Description</th>
                <th colspan="3">Freight Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  voyageFreightInvoiceDescripton, vfib
                ) in voyageFreightInvoice.voyage_freight_invoice_descriptions"
                :key="`voyageFreightInvoiceDescripton${vfib}`"
              >
                <td colspan="10">{{ voyageFreightInvoiceDescripton.description }}</td>
                <td colspan="3">{{ voyageFreightInvoiceDescripton.amount | round2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveVoyageFreightInvoiceCreate(voyageFreightInvoice)"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeVoyageFreightInvoiceCreateModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Details from "./details.vue";
import FreightCommision from "./freight_commission.vue";
import FreightAdjustment from "./freight_adjustment.vue";

export default {
  data() {
    return {
      voyageFreightInvoice: {
        position: "",
        counter_party_id: "",
        currency_id: "",
        exchange_rate: 1,
        contact_name: "",
        invoice_no: "",
        invoice_date: "",
        payment_term_id: "",
        due_date: "",
        remarks: "",
        voyage_freight_invoice_details: [
          {
            cargo_name_id: "",
            cp_qty: 0,
            nominated_qty: 0,
            bl_qty: 0,
            invoice_qty: 0,
            freight_type_id: "",
            freight_rate: 0,
            lumpsum_daily: 0,
            invoice_percent: 0,
            invoice_amount: 0,
            total_percent_billed_to_date: 0,
          },
        ],
        voyage_freight_invoice_brokers: [],
        voyage_freight_invoice_adjustments: [],
      },
      cargoId: "",
      cargoName: "",
      cargoQty: 0,
      freightRate: 0,
      invoicePercent: 0,
    };
  },
  props: [
    "form",
    "count",
    "paymentTerms",
    "freightTypes",
    "portFunctions",
    "voyageCargos",
    "voyageFreightInvEdit",
    "voyageFreightInvoices",
    "voyageBrokers",
    "addresses",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerFreightPaymentMethods",
  ],
  watch: {
    // voyageCargos: {
    //   handler() {
    //     this.updateVoyageFreightInvoice();
    //   },
    //   deep: true,
    // },
    // voyageFreightInvoices: {
    //   handler() {
    //     this.updateVoyageFreightInvoice();
    //   },
    //   deep: true,
    // },
    voyageFreightInvEdit: {
      handler() {
        this.updateVoyageFreightInvoice();
      },
      deep: true,
    },
  },
  components: {
    Details,
    FreightCommision,
    FreightAdjustment,
  },
  mounted() {
    this.updateVoyageFreightInvoice();
  },

  methods: {
    updateVoyageFreightInvoice() {
      if (this.voyageFreightInvEdit != null) {
        this.voyageFreightInvoice = this.voyageFreightInvEdit;
        // return;
      }

      this.voyageFreightInvoice.counter_party_id =
        this.form.voyage_cargos.length > 0
          ? this.form.voyage_cargos[0].charterer_id
          : "";

      this.form.voyage_cargos.forEach((voyageCargo) => {
        this.cargoId = voyageCargo.cargo_name_id;
        this.cargoName = voyageCargo.cargo_name;
        this.freightRate = voyageCargo.freight_rate;
      });

      let cargoQty = 0;
      let invoicePercent = 0;
      this.voyageFreightInvoice.voyage_freight_invoice_details.forEach(
        (vfid) => {
          cargoQty = parseFloat(cargoQty) + parseFloat(vfid.invoice_qty);
          invoicePercent =
            parseFloat(invoicePercent) + parseFloat(vfid.invoice_percent);
        }
      );
      this.cargoQty = cargoQty;
      this.invoicePercent = invoicePercent;
    },
    async openVoyageFreightInvoiceCreateModal() {
      var modal = document.getElementById(
        "createVoyageFreightInvoiceCreateModal" + this.count
      );
      modal.style.display = "block";
    },
    closeVoyageFreightInvoiceCreateModal() {
      var modal = document.getElementById(
        "createVoyageFreightInvoiceCreateModal" + this.count
      );
      modal.style.display = "none";
    },
    saveVoyageFreightInvoiceCreate(voyageFreightInvoice) {
      this.$emit("onSaveVoyageFreightInvoiceCreate", voyageFreightInvoice);
      this.closeVoyageFreightInvoiceCreateModal();
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>
