<template>
  <Voyage></Voyage>
</template>

<script type="text/javascript">
import Voyage from './voyage.vue'

export default {
  name: 'VoyageCreate',
  components: {
    Voyage
  }
}
</script>

