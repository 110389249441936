<template>
  <section>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">Distance to Go from Dep</div>
          <div class="col-md-6">
            <input
              v-uppercase
              disabled
              class="form-control"
              placeholder="Vessel"
              v-model="distanceToGo"
            />
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="row">
          <div class="col-md-4">Projected Spd</div>
          <div class="col-md-8"></div>
        </div>
      </div> -->
    </div>
    <!--  -->
    <h5><u>Bunker Liftings</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Type</th>
            <th>ROB Prev</th>
            <th>Sea Cons</th>
            <th>Cons Rate</th>
            <th>ROB Arr</th>
            <th>Received</th>
            <th>Price</th>
            <th>Port Cons</th>
            <th>Cons Rate</th>
            <th>ROB Dep</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(bunkerMaster, ar) in voyageBunkerMasters"
            :key="`bunkerMaster${ar}`"
          >
            <td>{{ bunkerMaster.fuel_name }}</td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  disabled
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="ROB Prev"
                  v-model="voyageBunkerMasterBunker.rob_prev"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  disabled
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Sea Cons."
                  v-model="voyageBunkerMasterBunker.sea_consumption"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  disabled
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Sea Cons. Rate"
                  v-model="voyageBunkerMasterBunker.sea_consumption_rate"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="ROB Arr."
                  v-model="voyageBunkerMasterBunker.rob_arrival"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Rec. Qty"
                  v-model="voyageBunkerMasterBunker.received_qty"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Rec. Price"
                  v-model="voyageBunkerMasterBunker.received_price"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  disabled
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Port Cons."
                  v-model="voyageBunkerMasterBunker.port_consumption"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  disabled
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="Port Cons. Rate"
                  v-model="voyageBunkerMasterBunker.port_consumption_rate"
                />
              </span>
            </td>
            <td>
              <span
                v-for="(
                  voyageBunkerMasterBunker, vbmb
                ) in bunkerMaster.voyage_bunker_master_bunkers"
                :key="`voyageBunkerMasterBunker${vbmb}`"
              >
                <input
                  v-if="
                    voyageBunkerMasterBunker.port_name_id ==
                      voyageItenary.port_name_id &&
                    voyageBunkerMasterBunker.port_function_id ==
                      voyageItenary.port_function_id
                  "
                  v-uppercase
                  placeholder="ROB Dept."
                  v-model="voyageBunkerMasterBunker.rob_departure"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      distanceToGo: 0,
    };
  },
  props: ["voyageItenary", "voyageItenaries", "voyageBunkerMasters"],
  mounted() {
    this.updateData();
  },
  methods: {
    onBunkerChanged() {
      this.$emit("onBunkerChanged");
    },
    updateData() {
      let nextItenary = this.voyageItenaries.find(
        (vI) => vI.position == this.voyageItenary.position + 1
      );
      if (nextItenary) {
        this.distanceToGo = nextItenary.miles;
      }
      // Get the index of voyage_bunker_master_bunkers of current port
      this.voyageBunkerMasters.forEach((vBM) => {
        vBM.voyage_bunker_master_bunkers.forEach((vbmb, index) => {
          if (vbmb.port_name_id == this.voyageItenary.port_name_id) {
            vBM.indexOfCurrentPort = index;
          }
        });
      });
    },
  },
};
</script>

<style scoped>
table td input {
  widows: 70px !important;
}
</style>