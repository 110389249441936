<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/financials/transactions"></back-button>
            Transaction Data Entry
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>

            <li>
              <router-link to="/financials/transactions"
                >Transactions</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice AP/AR</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.invoice_ap_ar"
                                :suggestions="invoice_ap_ar"
                                placeholder="Select Invoice AP/AR"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Status*</label
                            >
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                v-model="form.status_1"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                v-model="form.status_2"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trans No.*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                v-model="form.trans_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Operation Trans No.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                v-model="form.operation_trans_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Accounting Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.accounting_date"
                                placeholder="Enter Accounting Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice Type</label
                            >
                            <div class="col-sm-6">
                              <my-select
                                :selection.sync="form.invoice_type"
                                :suggestions="invoice_type"
                                placeholder="Select Invoice Type"
                              ></my-select>
                              <span class="error-block">{{
                                errors.invoice_type
                              }}</span>
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                disabled
                                type="number"
                                class="form-control"
                                v-model="form.invoice_type_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">PO No</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="PO No"
                                v-model="form.po_no"
                              />
                              <span class="error-block">{{
                                errors.po_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vendor</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vendor"
                                :suggestions="vendor"
                                placeholder="Select Vendor"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Invoice Amount</label
                            >
                            <div class="col-sm-6">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Invoice Amount"
                                v-model="form.invoice_amount"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                v-model="form.invoice_amount_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Received Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.received_date"
                                placeholder="Enter Received Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bill Via
                            </label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.bill_via"
                                :suggestions="bill_via"
                                placeholder="Select Bill Via"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Exch Rate/Date</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                v-model="form.exch_rate"
                              />
                            </div>
                            <div class="col-sm-4">
                              <my-datepicker
                                :date.sync="form.exch_date"
                                placeholder="Enter Exch Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >AP/AR Account No.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="AP/AR Account No."
                                v-model="form.ap_ar_account_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="col-md-6">
                                <label class="col-sm-3 control-label"
                                  >Invoice Date</label
                                >
                                <div class="col-sm-9">
                                  <my-datepicker
                                    :date.sync="form.invoice_date"
                                    placeholder="Enter Invoice Date"
                                  ></my-datepicker>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <label class="col-sm-3 control-label"
                                  >Due Date</label
                                >
                                <div class="col-sm-9">
                                  <my-datepicker
                                    :date.sync="form.due_date"
                                    placeholder="Enter Due Date"
                                  ></my-datepicker>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Amount Base</label
                            >
                            <div class="col-sm-6">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Amount Base"
                                v-model="form.amount_base"
                              />
                            </div>
                            <div class="col-sm-3">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="USD"
                                v-model="form.amount_base_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Approval</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Approval"
                                v-model="form.approval"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Late Reason</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.late_reason"
                                :suggestions="LateReason"
                                placeholder="Select Late Reason"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >VAT Currency</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vat_currency"
                                :suggestions="VATCurrency"
                                placeholder="Select VAT Currency"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >IC Trans No.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="IC Trans No."
                                v-model="form.ic_trans_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="col-md-6">
                                <label class="col-sm-3 control-label"
                                  >Invoice No</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    placeholder="Invoice No."
                                    v-model="form.invoice_no"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <label class="col-sm-3 control-label"
                                  >Terms</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.terms"
                                    :suggestions="Terms"
                                    placeholder="Enter Terms"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >VAT Exch Rate</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="VAT Exch Rate"
                                v-model="form.vat_exch_rate"
                              />
                              <span class="error-block">{{
                                errors.vat_exch_rate
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Terms Description</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Terms Description"
                                v-model="form.vat_exch_rate"
                              />
                              <span class="error-block">{{
                                errors.vat_exch_rate
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Reference No.*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Reference No."
                                v-model="form.reference_no"
                              />
                              <span class="error-block">{{
                                errors.reference_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Note</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Note"
                                v-model="form.note"
                              />
                              <span class="error-block">{{ errors.note }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Rebill Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.rebill_type"
                                :suggestions="rebill_types"
                                placeholder="Select Rebill Type"
                              ></my-select>
                              <span class="error-block">{{
                                errors.rebill_type
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Contact</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.contact"
                                :suggestions="contacts"
                                placeholder="Select Contact"
                              ></my-select>
                              <span class="error-block">{{
                                errors.contact
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Invoice Details</h5>
                      <br />
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="Accounting_View-tab"
                            data-toggle="tab"
                            href="#Accounting_View"
                            role="tab"
                            aria-controls="Accounting_View"
                            aria-selected="true"
                            >Accounting View</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Operations_View-tab"
                            data-toggle="tab"
                            href="#Operations_View"
                            role="tab"
                            aria-controls="Operations_View"
                            aria-selected="false"
                            >Operations View</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Remarks-tab"
                            data-toggle="tab"
                            href="#Remarks"
                            role="tab"
                            aria-controls="Remarks"
                            aria-selected="false"
                            >Remarks</a
                          >
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade"
                          id="Accounting_View"
                          role="tabpanel"
                          aria-labelledby="Accounting_View-tab"
                        >
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Comp</th>
                                  <th>LOB</th>
                                  <th>Vessel</th>
                                  <th>Vessel Name</th>
                                  <th>Account</th>
                                  <th>AP/AR Acct</th>
                                  <th>Voyage</th>
                                  <th>Dept</th>
                                  <th>Port</th>
                                  <th>IC</th>
                                  <th>Prod</th>
                                  <th>Description</th>
                                  <th>Amount</th>
                                  <th>Base Equiv</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.comp
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Comp"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="LOB"
                                      v-model="vesselInPortConsumption.lob"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.vessel
                                      "
                                      :suggestions="vessel"
                                      placeholder="Select Vessel"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.vessel_name
                                      "
                                      :suggestions="vesselNames"
                                      placeholder="Select Vessel Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Account"
                                      v-model="vesselInPortConsumption.account"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="ap_ar_acct"
                                      v-model="
                                        vesselInPortConsumption.ap_ar_account_no
                                      "
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.Voyage
                                      "
                                      :suggestions="Voyage"
                                      placeholder="Select Voyage"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Dept"
                                      v-model="
                                        vesselInPortConsumption.department
                                      "
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="IC"
                                      v-model="vesselInPortConsumption.ic"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Prod"
                                      v-model="vesselInPortConsumption.prod"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="Description"
                                      v-model="
                                        vesselInPortConsumption.extra_sea_days
                                      "
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Amount"
                                      v-model="vesselInPortConsumption.amount"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                      v-model="
                                        vesselInPortConsumption.base_equiv
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="15"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="15"></td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="3">Opr Last User</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Opr Last User"
                                    />
                                  </td>

                                  <td colspan="2">Acct Last User</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Acct Last User"
                                    />
                                  </td>

                                  <td colspan="2">Acct Last Update</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Acct Last Update"
                                    />
                                  </td>
                                  <td colspan="3">Total</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                    />
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>

                        </div>
                        <div
                          class="tab-pane fade"
                          id="Operations_View"
                          role="tabpanel"
                          aria-labelledby="Operations_View-tab"
                        >
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Acct Date</th>
                                  <th>Qty</th>
                                  <th>Rate</th>
                                  <th>UOM</th>
                                  <th>Tax Code</th>
                                  <th>Trade Area</th>
                                  <th>Cargo</th>
                                  <th>Code</th>
                                  <th>Item Description</th>
                                  <th>Amount</th>
                                  <th>Base Equiv</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <my-datepicker :date.sync="form.acct_date" placeholder="Enter Acct Date"></my-datepicker>
                                    <!-- <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.comp
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Comp"
                                    ></my-select> -->
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="0.000"
                                      v-model="vesselInPortConsumption.qty"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="0.000"
                                      v-model="vesselInPortConsumption.rate"
                                    />
                                    <!-- <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.vessel
                                      "
                                      :suggestions="vessel"
                                      placeholder="Select Vessel"
                                    ></my-select> -->
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.uom
                                      "
                                      :suggestions="uoms"
                                      placeholder="Select uom"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.taxcode
                                      "
                                      :suggestions="taxcodes"
                                      placeholder="Select tax code"
                                    ></my-select>
                                  </td>
                                  <td>
                                   <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.tradeArea
                                      "
                                      :suggestions="tradeAreas"
                                      placeholder="Select trade area"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Cargo"
                                      v-model="vesselInPortConsumption.cargo"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.code
                                      "
                                      :suggestions="codes"
                                      placeholder="Select code"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="Description"
                                      v-model="
                                        vesselInPortConsumption.descriptions
                                      "
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Amount"
                                      v-model="vesselInPortConsumption.amount"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                      v-model="
                                        vesselInPortConsumption.base_equiv
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="13"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="13"></td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="3">Opr Last User</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Opr Last User"
                                    />
                                  </td>

                                  <td colspan="2">Acct Last User</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Acct Last User"
                                    />
                                  </td>

                                  <td colspan="2">Acct Last Update</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Acct Last Update"
                                    />
                                  </td>
                                  <td colspan="3">Total</td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Base Equiv"
                                    />
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Remarks"
                          role="tabpanel"
                          aria-labelledby="Remarks-tab"
                        >
                          <br />
                          <textarea
                            class="form-control"
                            v-model="form.bunkering_details"
                          ></textarea>
                          <h5>Addition Remarks</h5>
                          <textarea
                            class="form-control"
                            v-model="form.bunkering_details"
                          ></textarea>
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Opr Last User</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    placeholder="Opr Last User"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Acct Last User</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    placeholder="Acct Last User"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Acct Last Update</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    placeholder="Acct Last Update"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Payment Information</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Bank Code</th>
                              <th>Approval</th>
                              <th>Date Paid</th>
                              <th>By</th>
                              <th>Check/Wt No</th>
                              <th>Amount Paid</th>
                              <th>Amount Base</th>
                              <th>Pay Trans No.</th>
                              <th>Memo</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.display_base_currency"
                            placeholder="Release to Interface"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Total</label>
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Amount Paid"
                                v-model="form.amount_paid"
                              />
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Amount Base"
                                v-model="form.amount_base"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Remittance Bank</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Remittance Bank"
                                v-model="form.amount_paid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7"></div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Payment Bank/Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Payment Bank/Code"
                                v-model="form.payment_bank_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7"></div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Payment Mode</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Payment Mode"
                                v-model="form.payment_mode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
