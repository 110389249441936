<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Create Trade Commission Invoices List
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Create Trade Commission Invoices List</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- Create Trade Commission Invoices List -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Action</th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Index</th>
                        <th>Route</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Counterparty</th>
                        <th>Period</th>
                        <th>Buy/Sell</th>
                        <th>Broker Conf. No</th>
                        <th>Settlement</th>
                        <th>Company</th>
                        <th>Basis</th>
                        <th>Settlement Period</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="12"
                      ></loading-table>
                      <router-link
                        v-for="(trade_commission, at) in trade_commissions"
                        :key="`trade_commission${at}`"
                        :to="`/estimates/${trade_commission.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ trade_commission.code }}</td>
                        <td>{{ trade_commission.id }}</td>
                        <td>{{ trade_commission.type }}</td>
                        <td>{{ trade_commission.index }}</td>
                        <td>{{ trade_commission.route }}</td>
                        <td>{{ trade_commission.start_date }}</td>
                        <td>{{ trade_commission.end_date }}</td>
                        <td>{{ trade_commission.counterparty }}</td>
                        <td>{{ trade_commission.period }}</td>
                        <td>{{ trade_commission.buy_sell }}</td>
                        <td>{{ trade_commission.broker_conf_no }}</td>
                        <td>{{ trade_commission.settlement }}</td>
                        <td>{{ trade_commission.company }}</td>
                        <td>{{ trade_commission.basis }}</td>
                        <td>{{ trade_commission.settlement_period }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "trade_commissions",
  data() {
    return {
      trade_commissions: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let trade_commissions = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.trade_commissions = trade_commissions.data.data;
      this.count = trade_commissions.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
