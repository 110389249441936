<template>
  <section>
    <h5><u>Freight Adjustment</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Excl. From P&L</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              voyageFreightInvoiceAdjustment, vb
            ) in voyageFreightInvoice.voyage_freight_invoice_adjustments"
            :key="`voyageFreightInvoiceAdjustment${vb}`"
          >
            <td @click="deleteFreightAdjustment(adjustment)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ vb + 1 }}.
            </td>
            <td>
              <input
                v-uppercase
                style="width: 300px !important;"
                type="number"
                placeholder="Description"
                v-model="voyageFreightInvoiceAdjustment.description"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Amount"
                v-model="voyageFreightInvoiceAdjustment.amount"
              />
            </td>
            <td>
              <my-checkbox
                :selection.sync="
                  voyageFreightInvoiceAdjustment.is_exclude_from_pl
                "
              ></my-checkbox>
            </td>
          </tr>
          <tr>
            <td
              colspan="10"
              align="center"
              @click="addEmptyFreightAdjustment()"
            >
              Add New Row
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "freightInvoiceAdjustment",
  data() {
    return {};
  },
  components: {},
  props: ["form", "voyageFreightInvoice"],
  methods: {
    deleteFreightAdjustment(adjustment) {
      this.voyageFreightInvoice.voyage_freight_invoice_adjustments.splice(
        this.voyageFreightInvoice.voyage_freight_invoice_adjustments.indexOf(
          adjustment
        ),
        1
      );
    },
    addEmptyFreightAdjustment() {
      this.voyageFreightInvoice.voyage_freight_invoice_adjustments.push({
        description: "",
        amount: 0,
        is_exclude_from_pl: false,
      });
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>