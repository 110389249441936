<template>
    <Transaction></Transaction>
</template>

<script>
import Transaction from '../../../transactions/create.vue'
export default {
    components : {
        Transaction
    }
}
</script>