<template>
  <!-- <div id="app"> -->
  <div id="app" style="padding: 317px; margin-left: 404px"  v-if="isLoading" >
    <fingerprint-spinner
      :animation-duration="1500"
      :size="60"
      color="#ff1d5e"
    />
  </div>
</template>

<script>
// To use minified css and js files instead of .vue single file components:
// import 'epic-spinners/dist/lib/epic-spinners.min.css'
// import {FingerprintSpinner} from 'epic-spinners/dist/lib/epic-spinners.min.js'

import { FingerprintSpinner } from "epic-spinners";
export default {
  props: ["isLoading"],
  components: {
    FingerprintSpinner,
  },
// computed:{
//   loading(){ return true;} 
//    }
  // methods: {
  // openLoading() {
  //   // this.isLoading = true;
  //   setTimeout(() => {
  //     this.isLoading = false;
  //   }, 10 * 1000);
  // },
  // },
  // async mounted() {
  //   await new Promise((r) => setTimeout(r, 2000));
  //   this.isLoading = false;
  // },
  //  watch: {
  //       $route() {
  //           this.$nextTick(this.openLoading);
  //        }
  //   },
  // mounted() {
  //   this.$router.onReady(() => this.openLoading());
  // },
  // template: `<router-view/>`
  // watch: {
  //   $route() {
  //     this.openLoading();
  //   },
  // },
};
</script>
