<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/sites"></back-button>
            Create a new Account Period Open Month
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/financial/statement/account-period-open-months"
                >Account Period Open Month</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Account Periods</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.account_period_id"
                              :suggestions="accountPeriods"
                              disabled
                              placeholder="Select Account Periods"
                            ></my-select>
                            <span class="error-block">{{
                              errors.account_period_id
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Open Months</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.open_month_id"
                              :suggestions="openMonths"
                              disabled
                              placeholder="Select Open Months"
                            ></my-select>
                            <span class="error-block">{{
                              errors.open_month_id
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "siteCreate",
  data() {
    return {
      form: {
        account_period_id: "",
        open_month_id: "",
      },
      accountPeriods: [],
      openMonths: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`account_period_open_months/masters`);
      masters = masters.data;
      this.accountPeriods = [];
      masters.account_periods.forEach((ap) => {
        this.accountPeriods.push({
          id: ap.id,
          name: ap.year,
        });
      });
      this.openMonths = [];
      masters.open_months.forEach((om) => {
        this.openMonths.push({
          id: om.id,
          name: om.name,
        });
      });
    },
    async save() {
      try {
        let accountPeriodOpenMonths = await axios.post(`/account_period_open_months`, this.form);
        this.form = accountPeriodOpenMonths.data.data;
        this.isLoading = false;
        this.$router.push("/financial/statement/account-period-open-months");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
