<template>
  <div class="dropdown" style="position: relative;" v-bind:class="{'open':openSuggestion}">
    <input v-uppercase class="form-control" type="text" v-model="value"
      placeholder="Select Grade" 
      @keydown.enter = 'enter'
      @keydown.down = 'down'
      @keydown.up = 'up'
      @input="change"
      @focus="focus"
      @blur="blurFocus"
    />
    <ul class="dropdown-menu" style="width:100%">
      <li 
        v-for="(suggestion, s) in matches"
        :key="`suggestion${s}`"
        v-bind:class="{'active': isActive(s)}"
        @click="suggestionClick(s)"
      >
        <a href="javascript:void(0)">
          <div class="row">
            <div class="col-md-12">
              {{ suggestion }}
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'AutocompleteWidget',
  data () {
    return {
      open: false,
      current: 0,
      value: '',
    }
  },
  props: {
    suggestions: {
      type: Array,
      required: true
    },
    selection: {
      type: String,
      required: true,
      twoWay: true
    }
  },
  watch: {
    'selection': function() {
      this.value = this.selection
    }
  },
  computed: {
    //Filtering the suggestion based on the input
    matches() {
      let suggestions = this.suggestions.filter((str) => {
        return str.indexOf(this.value) >= 0;
      });

      return this.value != '' ? suggestions : this.suggestions
    },
    //The flag
    openSuggestion() {
      // return this.value !== "" && this.matches.length != 0 && this.open === true;
      return this.matches.length != 0 && this.open === true;
    }
  },
  mounted() {
    this.value = this.selection
  },
  methods: {
    // When focussed on input
    focus() {
      this.open = true
    },

    blurFocus() {
      // if(this.current == 0)
        // this.open = false
    },

    //When enter pressed on the input
    enter() {
      this.value = this.matches[this.current];
      this.$emit('update:selection', this.value)
      this.open = false;
    },

    //When up pressed while suggestions are open
    up() {
      if(this.current > 0)
        this.current--;
    },

    //When down pressed while suggestions are open
    down() {
      if(this.current < this.suggestions.length - 1)
        this.current++;
    },

    //For highlighting element
    isActive(index) {
      return index === this.current;
    },

    //When the user changes input
    change() {
      if (this.open == false) {
        this.open = true;
        this.current = 0;
      }
    },

    //When one of the suggestion is clicked
    suggestionClick(index) {
      this.value = this.matches[index];
      this.open = false;
      this.$emit('update:selection', this.value)
    },
  }
}
</script>

<style>
.dropdown::before {
  position: absolute;
  content: " \2193";
  top: 11px;
  right: -8px;
  height: 20px;
  width: 20px;
}
</style>