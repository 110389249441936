<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Estimates
            <add-button link="/quick-estimate/create"></add-button>
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Estimates</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Estimate ID</th>
                        <th>Vessel Name</th>
                        <th>CP Date</th>
                        <th>Fixture No</th>
                        <th>Fixture Date</th>
                        <th>Opr Typ</th>
                        <th>Status</th>
                        <th>First Load</th>
                        <th>Last Discharge</th>
                        <th>Cargo</th>
                        <th>Conterparty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="12"
                      ></loading-table>
                      <router-link
                        v-for="(estimate, at) in estimates"
                        :key="`estimate${at}`"
                        :to="`/estimates/${estimate.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>EST-{{ estimate.id }}</td>
                        <td>
                          {{
                            estimate.vessel ? estimate.vessel.vessel_name : ""
                          }}
                        </td>
                        <td>{{ estimate.cp_date }}</td>
                        <td>{{ estimate.fixture_no }}</td>
                        <td>{{ estimate.fixture_date | ddmmyyyy }}</td>
                        <td>
                          {{
                            estimate.operation_type
                              ? estimate.operation_type.type
                              : ""
                          }}
                        </td>
                        <td></td>
                        <td>
                          {{
                            estimate.ballast_port
                              ? estimate.ballast_port.port_name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            estimate.reposition_port
                              ? estimate.reposition_port.port_name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            estimate.estimate_cargos.map(
                              (est_cargo) => est_cargo.cargo_name.full_name
                            )
                          }}
                        </td>
                        <td>
                          {{
                            estimate.estimate_cargos.map((est_cargo) =>
                              est_cargo.charterer
                                ? est_cargo.charterer.full_name
                                : ""
                            )
                          }}
                        </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "estimates",
  data() {
    return {
      estimates: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let estimates = await axios.get(
        `/estimates?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.estimates = estimates.data.data;
      this.count = estimates.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
