<template>
    <TcOutHireStatement></TcOutHireStatement>
</template>

<script>
import TcOutHireStatement from '../time-charter-ins/hire-statement.vue'
export default {
    components : {
        TcOutHireStatement
    }
}
</script>