<template>
  <v-date-picker
    v-model="value"
    :masks="masks"
    @input="$emit('update:date', value)"
    mode="dateTime"
    is24hr
    :popover="{ visibility: 'click' }"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :class="
          disableFormControl
            ? ''
            : 'bg-white border px-2 py-1 rounded form-control'
        "
        :value="inputValue"
        v-on="inputEvents"
        :placeholder="placeholder"
        :style="myWidth ? 'width:' + myWidth + ' !important' : 'max-content'"
      />
    </template>
  </v-date-picker>
</template>

<script type="text/javascript">
import moment from "moment";

export default {
  data() {
    return {
      // value: new Date(new Date().setHours(0,0,0,0)),
      value: "",
      masks: {
        inputDateTime24hr: ["L HH:mm", "YYYY-MM-DD HH:mm", "YYYY/MM/DD HH:mm"],
      },
      // modelConfig: {
      //   type: "string",
      //   mask: "YYYY-MM-DD HH:mm:ss", // Uses 'iso' if missing
      // },
    };
  },
  props: ["date", "placeholder", "disableFormControl", "myWidth"],
  watch: {
    date: "getValue",
    value: "onValueChanged",
  },
  mounted() {
    this.getValue();
  },
  methods: {
    getValue() {
      this.value = this.date;
    },
    onValueChanged() {
      this.$emit("change");
    },
    updateValuePattern(value) {
      if (value) return moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>