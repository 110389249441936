<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <button @click="generatePDF" style="float: right">
            Download PDF
          </button>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <section id="downloadPDF">
                  <div id="page_1">
                    <!-- Laytime Calculation Starts -->
                    <p class="header-style">Laytime Calculations</p>
                    <br />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Vessel:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">JASMINE 201</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">C/P Date:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">20/4/2021</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">DWT:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">73,800.00</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">By:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Voyage:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">2021014</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Charterer:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">BALAJI MALTS</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Fixture:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">20210013</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Charterer Contact:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Terms:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">
                              SHINC - SUN. & HOLIDAYS INCL.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Calculating:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">Reversible</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Report Date (GMT):</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">16/6/2021 13:34</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Laytime Calculation Ends -->
                    <br />
                    <!-- Discharing Starts -->
                    <p class="discharging-style">DISCHARGING at ENNORE</p>
                    <hr />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Cargo:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">COAL</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">DISCHARGING Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">25,000.00 (MT/Day)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Quantity:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">40,700.000 MT</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Demurrage Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">19,500.00 (USD/Day)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Terms:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">
                              SHINC - SUN. & HOLIDAYS INCL.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Despatch Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">9,750.00 (USD/Day)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!-- Activity Table -->
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Activities</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="activity-table"
                        >
                          <tr>
                            <td class="font-bold">FR</td>
                            <td class="font-bold">28/5/2021</td>
                            <td class="font-bold">13:48</td>
                            <td class="font-bold">
                              NOR TENDERED / 12 hrs turn time
                            </td>
                          </tr>
                          <tr>
                            <td class="font-bold">SA</td>
                            <td class="font-bold">29/5/2021</td>
                            <td class="font-bold">01:48</td>
                            <td class="font-bold">
                              LAYTIME COMMENCED / commence discharging on
                              28/05/21 19:45 hrs
                            </td>
                          </tr>
                          <tr>
                            <td class="font-bold">SA</td>
                            <td class="font-bold">29/5/2021</td>
                            <td class="font-bold">01:48</td>
                            <td class="font-bold"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <br />
                    <!-- Laytime Table -->
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Laytimes</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="laytime-table"
                        >
                          <tr>
                            <td class="laytime-th">
                              <p class="th-font">Day</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Date</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Start Time</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">End Time</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Description/Remarks</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Duration</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">%</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Time Used</p>
                            </td>
                          </tr>
                          <tr>
                            <td class="laytime-td-gray">
                              <p class="td-font">SA</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">29/5/2021</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">
                                LAYTIME COMMENCED /commence discharging on
                                28/05/21 19:45 hrs
                              </p>
                            </td>
                            <td class="tr4 laytime-td-gray">
                              <p class="p22 td-font">00:00</p>
                            </td>
                            <td class="tr4 laytime-td-gray">
                              <p class="p22 td-font">100.00</p>
                            </td>
                            <td class="tr4 laytime-td-gray">
                              <p class="p22 td-font">00:00</p>
                            </td>
                          </tr>
                          <tr>
                            <td class="laytime-td">
                              <p class="td-font">SA</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">29/5/2021</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">
                                LAYTIME COMMENCED /commence discharging on
                                28/05/21 19:45 hrs
                              </p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">00:00</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">100.00</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">00:00</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Grace Time:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">00d00:00 (0.00000000 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Time Used:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">00d15:12 (0.63333333 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Balance:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">03d01:28 (3.06111111 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Discharing Ends -->
                    <br />
                    <!-- Discharing Starts -->
                    <p class="discharging-style">DISCHARGING at HALDIA</p>
                    <hr />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Cargo:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">COAL</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">DISCHARGING Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">15,000.00 (MT/Day)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Quantity:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">31,000.000 MT</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Demurrage Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">31,000.000 MT</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Terms:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">
                              SHINC - SUN. & HOLIDAYS INCL.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Despatch Rate:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">9,750.00 (USD/Day)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <!-- Activity Table -->
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Activities</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="activity-table"
                        >
                          <tr>
                            <td class="font-bold">TU</td>
                            <td class="font-bold">1/6/2021</td>
                            <td class="font-bold">15:30</td>
                            <td class="font-bold">
                              NOR TENDERED / 12 hrs turn time
                            </td>
                          </tr>
                          <tr>
                            <td class="font-bold">WE</td>
                            <td class="font-bold">2/6/2021</td>
                            <td class="font-bold">03:30</td>
                            <td class="font-bold">
                              / vsl sifting + custom + hold inspection
                            </td>
                          </tr>
                          <tr>
                            <td class="font-bold">WE</td>
                            <td class="font-bold">2/6/2021</td>
                            <td class="font-bold">07:05</td>
                            <td class="font-bold">LAYTIME COMMENCED</td>
                          </tr>
                          <tr>
                            <td class="font-bold">FR</td>
                            <td class="font-bold">4/6/2021</td>
                            <td class="font-bold">13:00</td>
                            <td class="font-bold">LAYTIME COMPLETED</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <br />
                    <!-- Laytime Table -->
                    <div class="row">
                      <div class="col-md-12">
                        <h5>Laytimes</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          class="laytime-table"
                        >
                          <tr>
                            <td class="laytime-th">
                              <p class="th-font">Day</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Date</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Start Time</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">End Time</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Description/Remarks</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Duration</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">%</p>
                            </td>
                            <td class="laytime-th">
                              <p class="th-font">Time Used</p>
                            </td>
                          </tr>
                          <tr>
                            <td class="laytime-td-gray">
                              <p class="td-font">SA</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">29/5/2021</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">
                                LAYTIME COMMENCED /commence discharging on
                                28/05/21 19:45 hrs
                              </p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">00:00</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">100.00</p>
                            </td>
                            <td class="laytime-td-gray">
                              <p class="td-font">00:00</p>
                            </td>
                          </tr>
                          <tr>
                            <td class="laytime-td">
                              <p class="td-font">SA</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">29/5/2021</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">01:48</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">
                                LAYTIME COMMENCED /commence discharging on
                                28/05/21 19:45 hrs
                              </p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">00:00</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">100.00</p>
                            </td>
                            <td class="laytime-td">
                              <p class="td-font">00:00</p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Grace Time:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">00d00:00 (0.00000000 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Time Used:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">00d15:12 (0.63333333 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-4">
                            <p style="text-align: right">Balance:</p>
                          </div>
                          <div class="col-md-8">
                            <p class="font-bold">03d01:28 (3.06111111 days)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Discharing Ends -->
                    <!-- Summary Starts -->
                    <p class="summary_style">Summary</p>
                    <hr />
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Total Time Allowed:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">88.67</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Total Time Used:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold underlined">69.12</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Balanced:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold">19.55</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-6">
                            <p style="text-align: right">Despatch Due:</p>
                          </div>
                          <div class="col-md-6">
                            <p class="font-bold underlined">7,942.19 (USD)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import html2pdf from "html2pdf.js";

export default {
  name: "LaytimeCalcutionPDF",
  data() {
    return {};
  },
  methods: {
    generatePDF() {
      var element = document.getElementById("downloadPDF");
      var opt = {
        margin: 0,
        filename: "LaytimeCalcution.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [350, 400],
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>

<style type="text/css">
body {
  margin-top: 0px;
  margin-left: 0px;
}
.header-style {
  text-align: left;
  padding-left: 3px;
  margin-top: 0px;
  margin-bottom: 0px;
  font: italic 23px "Verdana";
  color: #5f5f5f;
  line-height: 28px;
}
.discharging-style {
  text-align: left;
  padding-left: 3px;
  margin-top: 22px;
  margin-bottom: 0px;
  font: italic 14px "Verdana";
  color: #5f5f5f;
  line-height: 17px;
}
.activity-table {
  width: 900px;
}
.font-bold {
  font-weight: 700;
}
.laytime-table {
  width: 1100px;
  margin-top: 2px;
}
.underlined {
  border-bottom: #020202 1px solid;
}
.laytime-th {
  border: #020202 1px solid;
  font: bold 11px "Tahoma";
  color: #5f5f5f;
  line-height: 13px;
}
.laytime-td-gray {
  border-left: #808080 1px solid;
  border-right: #808080 1px solid;
  border-bottom: #808080 1px solid;
  padding: 0px;
  margin: 0px;
  width: 27px;
  vertical-align: bottom;
  background: #f3f1f3;
}
.laytime-td {
  border-left: #808080 1px solid;
  border-right: #808080 1px solid;
  border-bottom: #808080 1px solid;
  padding: 0px;
  margin: 0px;
  width: 27px;
  vertical-align: bottom;
}
.td-font {
  line-height: 12px;
  font-weight: 700;
  text-align: center;
}
.th-font {
  font-weight: 900;
  text-align: center;
  color: #5f5f5f;
  line-height: 13px;
}
.summary_style {
  font: italic 14px "Verdana";
  color: #5f5f5f;
  line-height: 17px;
}
#page_1 {
  position: relative;
  overflow: hidden;
  margin: 40px 0px 24px 33px;
  padding: 0px;
  border: none;
}
</style>
