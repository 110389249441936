<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Voyage Payables
            <!-- <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search> -->
            <!-- <add-button link="/voyages/create"></add-button> -->
          </h5>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Vendor</th>
                        <th>Description</th>
                        <th>Rev/Exp</th>
                        <th>Inv No.</th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <!-- <th>Curr</th> -->
                        <th>Exch. Rate</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Balance</th>
                        <!-- <th>Code</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="10"
                      ></loading-table>
                      <router-link
                        v-for="(voyage, at) in voyageOtherExpanses"
                        :key="`voyage${at}`"
                        :to="`/financials/voyage-other-rev-expanse-payable/${voyage.expenses.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ voyage.expenses.vendor.full_name }}</td>
                        <td>{{ voyage.expenses.description }}</td>
                        <td>{{ voyage.expenses.is_revenue_expense }}</td>
                        <td>{{ voyage.expenses.invoice_no }}</td>
                        <td>{{ voyage.expenses.invoice_date | dmy }}</td>
                        <td>{{ voyage.expenses.due_date }}</td>
                        <td>{{ voyage.expenses.exchange_rate }}</td>
                        <td>{{ voyage.expenses.quantity }}</td>
                        <td>{{ voyage.expenses.price }}</td>
                        <td>{{ voyage.expenses.is_approved }}</td>
                        <td>{{ voyage.expenses.amount }}</td>
                        <td>
                          {{ (voyage.expenses.amount - voyage.total) | round2 }}
                        </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "voyages",
  data() {
    return {
      voyages: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: "",
      voyageOtherExpanses: [],
      total: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let voyages = await axios.get(
        `/voyages?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.voyages = voyages.data.data;
      this.voyageOtherExpanses = [];
      this.voyages.forEach((voyage) => {
        voyage.voyage_other_rev_expenses.forEach((voyage_other_rev_expense) => {
          if (
            voyage_other_rev_expense.is_approved &&
            voyage_other_rev_expense.is_revenue_expense == "Expense"
          ) {
            let total = 0;
            voyage_other_rev_expense.tc_in_payment_receipts.forEach((tcpr) => {
              total += parseInt(tcpr.amount);
            });
            this.voyageOtherExpanses.push({
              expenses: voyage_other_rev_expense,
              total: total,
            });
          }
        });
      });

      console.log(this.voyageOtherExpanses);

      this.count = voyages.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
