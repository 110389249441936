<template>
  <Voyage></Voyage>
</template>

<script type="text/javascript">
import Voyage from "./voyage.vue";

export default {
  name: "VoyageEdit",
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    Voyage,
  },
  methods: {},
};
</script>

