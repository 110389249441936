<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Credit Accounts
            <add-button link="/financial/statement/credit-accounts/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Credit Accounts</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>code</th>
                        <th>description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table :isLoading="isLoading" cols="11"></loading-table>
                      <router-link 
                        v-for="(creditAccount, at) in creditAccounts"
                        :key="`creditAccount${at}`"
                        :to="`/financial/statement/credit-accounts/${creditAccount.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ creditAccount.code }}</td>
                        <td>{{ creditAccount.description }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'creditAccounts',
  data () {
    return {
      creditAccounts: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      let creditAccounts = await axios.get(`/credit_accounts`)
      this.creditAccounts = creditAccounts.data.data
      this.count = creditAccounts.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    }
  }
}
</script>