<template>
<div id="app" v-if="isLoading">
    <fingerprint-spinner
      :animation-duration="1500"
      :size="60"
      color="#ff1d5e"
    />
  </div>
  <!-- <tr v-if="isLoading">
    <td :colspan="cols">
      Loading Data...
    </td>
  </tr> -->
</template>

<script type="text/javascript">
import { FingerprintSpinner } from "epic-spinners";

export default {
  name: 'LoadingTableWidget',
  props: ['isLoading', 'cols'],
  components: {
    FingerprintSpinner,
  },
}
</script>