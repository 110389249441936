<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/other_revenues/deviation-analysis"
            ></back-button>
            Deviation Analysis
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li><router-link to="/voyages">Voyages</router-link></li>
            <li>Other Revenue & Expenses</li>
            <li class="/other_revenues/deviation-analysis">
              Deviation Analysis
            </li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>D.</th>
                              <th>Port Name</th>
                              <th>F</th>
                              <th>Miles</th>
                              <th>DToGo</th>
                              <th>Speed</th>
                              <th>WF%</th>
                              <th>XS</th>
                              <th>B/L</th>
                              <th>Arrival</th>
                              <th>Departure</th>
                              <th>P Days</th>
                              <th>ID No</th>
                              <th>St</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.is_consecutive_voyage"
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>

                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.is_consecutive_voyage"
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>

                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.is_consecutive_voyage"
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <my-checkbox
                                  :selection.sync="form.is_consecutive_voyage"
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>

                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="1212.00"
                                  v-model="form.add_2"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-md-8">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Price</th>
                                <th>Current Cons</th>
                                <th>Direct Cons</th>
                                <th>Current Exp</th>
                                <th>Direct Exp</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    disabled
                                    class="form-control"
                                    placeholder="LSF"
                                    v-model="form.add_1"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="1212.00"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="1212.00"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="1212.00"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="1212.00"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="1212.00"
                                    v-model="form.add_2"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-4">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Daily Cost</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="0.00"
                                    v-model="form.daily_cost"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Vessel Spd</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="0.00"
                                    v-model="form.daily_cost"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                  <label class="control-label"
                                    >Deviation
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                  <label class="control-label">Direct </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Sea Days</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Port Days</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Bunker Exp</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Vessel Exp</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Port Exp</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Total Exp</label
                              >
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                              <div class="col-sm-4">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="col-md-6">
                              <label
                                class="col-sm-3 control-label"
                                style="float:center"
                                >Difference</label
                              >
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <input
                                  v-uppercase
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder="0.00"
                                  v-model="form.daily_cost"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
