<template>
    <FreightCommission></FreightCommission>
</template>

<script>
import FreightCommission from '../../commissions/freight-commission.vue'
export default {
    components : {
        FreightCommission
    }
}
</script>