<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Fuel Types
            <add-button link="/fuel-types/create"></add-button>
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Fuel Types</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Code</th>
                        <th>Fuel Type</th>
                        <th>Description</th>
                        <th>Grade</th>
                        <th>Group</th>
                        <th>Backup Fuel</th>
                        <th>Track Sulfur Percent</th>
                        <th>Calorific Value</th>
                        <th>CO2 Factor</th>
                        <th>Is Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="10"
                      ></loading-table>
                      <router-link
                        v-for="(fuelType, at) in fuelTypes"
                        :key="`fuelType${at}`"
                        :to="`/fuel-types/${fuelType.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ fuelType.code }}</td>
                        <td>{{ fuelType.fuel_type }}</td>
                        <td>{{ fuelType.description }}</td>
                        <td>
                          <u>
                            <router-link
                              :to="`/fuel-grades/${fuelType.grade_id}`"
                            >
                              {{
                                fuelType.fuel_grade
                                  ? fuelType.fuel_grade.grade
                                  : ""
                              }}
                            </router-link>
                          </u>
                        </td>
                        <td>{{ fuelType.group }}</td>
                        <td>{{ fuelType.backup_fuel }}</td>
                        <td>
                          {{
                            fuelType.track_sulfur_percent == 1 ? "YES" : "NO"
                          }}
                        </td>
                        <td>{{ fuelType.calorific_value }}</td>
                        <td>{{ fuelType.co2_factor }}</td>
                        <td>{{ fuelType.is_active == 1 ? "YES" : "NO" }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "FuelTypes",
  data() {
    return {
      fuelTypes: [],
      count: 0,
      serialNoStarting: 1,
      searchText: "",
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let fuelTypes = await axios.get(
        `/fuel_types?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.fuelTypes = fuelTypes.data.data;
      this.count = fuelTypes.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>

<style>
th {
}
</style>
