<template>
  <span>
    <v-select
      :disabled=disabled
      class="style-chooser"
      :options="options"
      label="label"
      v-model="value"
      :reduce="(label) => label.code"
      @input="$emit('update:selection', value)"
      @blur="blurEvent"
      :style="`width: ${maxWidth == 'YES' ? 'max-content' : ''}`"
    >
      <!-- max-content -->
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="!value"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
    <script type="application/javascript" defer src="/custom.js"></script>
  </span>
</template>

<script type="text/javascript">
export default {
  name: "MySelectWidget",
  data() {
    return {
      value: "",
      matches: [],
      width: "",
    };
  },
  computed: {
    options() {
      var options = [];
      options.push({
        label: this.placeholder,
        code: "",
      });
      this.matches.forEach((match) => {
        options.push({
          label: match.name,
          code: match.id,
        });
      });
      return options;
    },
  },
  props: [
    "selection",
    "suggestions",
    "placeholder",
    "disableFormControl",
    "maxWidth",
    "disabled",
  ],
  watch: {
    selection: "getValue",
    suggestions: "getMatches",
    value: "changeEvent",
  },
  mounted() {
    this.getValue();
    this.getMatches();
  },
  methods: {
    getValue() {
      this.value = this.isInt(this.selection)
        ? parseInt(this.selection)
        : this.selection;
    },
    getMatches() {
      this.matches = this.suggestions;
    },
    changeEvent() {
      this.$emit("change");
    },
    blurEvent() {
      this.$emit("blur", this.value);
    },
    isInt(value) {
      var x;
      if (isNaN(value)) {
        return false;
      }
      x = parseFloat(value);
      return (x | 0) === x;
    },
  },
};
</script>

<style>
select {
  color: grey !important;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  /* z-index: 10030 !important; */
  position: relative;
}
</style>