<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/financial/statement/account-periods"
            ></back-button>
            Create a new Account Period
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/financial/statement/account-periods"
                >Account Periods</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Year</label>
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.year"
                              :suggestions="years"
                              disabled
                              placeholder="Select year"
                            ></my-select>
                            <span class="error-block">{{ errors.year }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Closing Date*</label
                          >
                          <div class="col-sm-9">
                            <my-datepicker
                              :date.sync="form.closing_date"
                              placeholder="Enter Closing Date"
                            ></my-datepicker>
                            <span class="error-block">{{
                              errors.closing_date
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Status</label>
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.status"
                              :suggestions="statuses"
                              disabled
                              placeholder="Select Status"
                            ></my-select>
                            <span class="error-block">{{ errors.status }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Last Month Closed</label
                          >
                          <div class="col-sm-9">
                            <my-datepicker
                              :date.sync="form.last_month_closed"
                              placeholder="Enter Last Month Closed"
                            ></my-datepicker>
                            <span class="error-block">{{
                              errors.last_month_closed
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Account Payable</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.ap_id"
                              :suggestions="accountPayables"
                              disabled
                              placeholder="Select Account Payable"
                            ></my-select>
                            <span class="error-block">{{ errors.ap_id }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Account Receivable</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.ar_id"
                              :suggestions="accountReceivables"
                              disabled
                              placeholder="Select Account Receivable"
                            ></my-select>
                            <span class="error-block">{{ errors.ar_id }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Clearance</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.clearance_id"
                              :suggestions="clearances"
                              disabled
                              placeholder="Select Clearance"
                            ></my-select>
                            <span class="error-block">{{
                              errors.clearance_id
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Inter Bank</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.interbank_id"
                              :suggestions="interBanks"
                              disabled
                              placeholder="Select Inter Bank"
                            ></my-select>
                            <span class="error-block">{{
                              errors.interbank_id
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Retained Earnings</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.re_id"
                              :suggestions="retainedEarnings"
                              disabled
                              placeholder="Select Retained Earnings"
                            ></my-select>
                            <span class="error-block">{{ errors.re_id }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >Exchange Differances</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.exch_diff_id"
                              :suggestions="exchangeDifferances"
                              disabled
                              placeholder="Select Exchange Differances"
                            ></my-select>
                            <span class="error-block">{{
                              errors.exch_diff_id
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Inter Companies</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.intercompany_id"
                                :suggestions="intercompanies"
                                disabled
                                placeholder="Select Inter Companies"
                              ></my-select>
                              <span class="error-block">{{
                                errors.intercompany_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "siteCreate",
  data() {
    return {
      form: {
        year: "",
        status: "",
        ap_id: "",
        ar_id: "",
        re_id: "",
        clearance_id: "",
        interbank_id: "",
        intercompany_id: "",
        exch_diff_id: "",
      },
      years: [
        { id: 2020, name: 2020 },
        { id: 2021, name: 2021 },
      ],
      statuses: [
        { id: 1, name: "Finalized" },
        { id: 2, name: "Partial" },
      ],
      accountPayables : [],
      accountReceivables : [],
      clearances : [],
      interBanks : [],
      retainedEarnings : [],
      exchangeDifferances : [],
      intercompanies : [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`account_periods/masters`);
      masters = masters.data;
      this.accountPayables = [];
      masters.account_payables.forEach((ab) => {
        this.accountPayables.push({
          id: ab.id,
          name: ab.name,
        });
      });
      this.accountReceivables = [];
      masters.account_receivables.forEach((ar) => {
        this.accountReceivables.push({
          id: ar.id,
          name: ar.name,
        });
      });
      this.clearances = [];
      masters.clearances.forEach((clear) => {
        this.clearances.push({
          id: clear.id,
          name: clear.name,
        });
      });
      this.interBanks = [];
      masters.inter_banks.forEach((ib) => {
        this.interBanks.push({
          id: ib.id,
          name: ib.name,
        });
      });
      this.retainedEarnings = [];
      masters.retained_earnings.forEach((re) => {
        this.retainedEarnings.push({
          id: re.id,
          name: re.name,
        });
      });
      this.exchangeDifferances = [];
      masters.exchange_diffs.forEach((ed) => {
        this.exchangeDifferances.push({
          id: ed.id,
          name: ed.name,
        });
      });
      this.intercompanies = [];
      masters.inter_companies.forEach((ed) => {
        this.intercompanies.push({
          id: ed.id,
          name: ed.name,
        });
      });
    },
    async save() {
      try {
        console.log(this.form);
        let account_periods = await axios.post(`/account_periods`, this.form);
        this.form = account_periods.data.data;
        this.isLoading = false;
        this.$router.push("/financial/statement/account-periods");
      } catch (e) {
        this.isLoading = false;
      }
    },
    
  },
};
</script>
