<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/speed-consumption-types"></back-button>
            Update speed consumption type: {{ form.id
            }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/speed-consumption-types"
                >Class Societies</router-link
              >
            </li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Type*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Type"
                            v-model="form.type"
                          />
                          <span class="error-block">{{ errors.type }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_active"
                            placeholder="Is Active"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="update"
                            text="Update"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "speedConsumptionTypeUpdate",
  data() {
    return {
      form: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/speed_consumption_types/${this.$route.params.speedConsumptionTypeId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/speed_consumption_types/${this.$route.params.speedConsumptionTypeId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push("/speed-consumption-types");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
