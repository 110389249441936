<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Charts Of Accounts
            <add-button
              link="/financial/statement/charts-of-accounts/create"
            ></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Charts Of Accounts</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>code</th>
                        <th>account name</th>
                        <th>category</th>
                        <th>subcategory</th>
                        <th>is lob</th>
                        <th>is ves</th>
                        <th>is voyage</th>
                        <th>is port</th>
                        <th>is dept</th>
                        <th>is ic</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="11"
                      ></loading-table>
                      <router-link
                        v-for="(chartsOfAccount, at) in chartsOfAccounts"
                        :key="`chartsOfAccount${at}`"
                        :to="`/financial/statement/charts-of-accounts/${chartsOfAccount.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ chartsOfAccount.code }}</td>
                        <td>{{ chartsOfAccount.account_name }}</td>
                        <td>{{ chartsOfAccount.category }}</td>
                        <td>{{ chartsOfAccount.subcategory }}</td>
                        <td>{{ chartsOfAccount.is_lob == 1 ? 'Yes' : 'No' }}</td>
                        <td>{{ chartsOfAccount.is_ves == 1 ? 'Yes' : 'No' }}</td>
                        <td>{{ chartsOfAccount.is_voyage == 1 ? 'Yes' : 'No' }}</td>
                        <td>{{ chartsOfAccount.is_port == 1 ? 'Yes' : 'No' }}</td>
                        <td>{{ chartsOfAccount.is_dept == 1 ? 'Yes' : 'No' }}</td>
                        <td>{{ chartsOfAccount.is_ic == 1 ? 'Yes' : 'No' }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "chartsOfAccounts",
  data() {
    return {
      chartsOfAccounts: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let chartsOfAccounts = await axios.get(`/charts_of_accounts`);
      this.chartsOfAccounts = chartsOfAccounts.data.data;
      this.count = chartsOfAccounts.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
