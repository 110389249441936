<template>
  <section>
    <h5><u>Details</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Cargo</th>
            <th>CP Qty</th>
            <th>Nom. Qty</th>
            <th>BL Qty</th>
            <th>Inv. Qty</th>
            <th>Freight Type</th>
            <th>Freight Rate</th>
            <th>Invoice %</th>
            <th>Invoice Amount</th>
            <th>Total Billed Invoice %</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              voyageFreightInvoiceDetail, vfid
            ) in voyageFreightInvoice.voyage_freight_invoice_details"
            :key="`voyageFreightInvoiceDetail${vfid}`"
          >
            <td>{{ vfid + 1 }}.</td>
            <td>
              {{
                voyageFreightInvoiceDetail.cargo_name
                  ? voyageFreightInvoiceDetail.cargo_name.short_name
                  : ""
              }}
            </td>
            <td>{{ voyageFreightInvoiceDetail.cp_qty | round2 }}</td>
            <td>{{ voyageFreightInvoiceDetail.nominated_qty | round2 }}</td>
            <td>{{ voyageFreightInvoiceDetail.bl_qty | round2 }}</td>
            <td>
              <input
                v-uppercase
                placeholder="Qty"
                v-model="voyageFreightInvoiceDetail.invoice_qty"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="voyageFreightInvoiceDetail.freight_type_id"
                :suggestions="freightTypes"
                placeholder="Select freight type"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                placeholder="Qty"
                v-model="voyageFreightInvoiceDetail.freight_rate"
              />
            </td>
            <td>
              <input
                v-uppercase
                placeholder="Invoice %"
                v-model="voyageFreightInvoiceDetail.invoice_percent"
              />
            </td>
            <td>{{ voyageFreightInvoiceDetail.invoice_amount | round2 }}</td>
            <td>
              <input
                disabled
                v-uppercase
                placeholder="Invoice %"
                v-model="
                  voyageFreightInvoiceDetail.total_percent_billed_to_date
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    "form",
    "freightTypes",
    "portFunctions",
    "voyageCargos",
    "voyageFreightInvoice",
    "voyageFreightInvoices",
  ],
  watch: {
    voyageCargos: {
      handler() {
        this.updateVoyageFreightInvoiceDetails();
      },
      deep: true,
    },
    voyageFreightInvoices: {
      handler() {
        this.updateVoyageFreightInvoiceDetails();
      },
      deep: true,
    },
  },
  methods: {
    updateVoyageFreightInvoiceDetails() {
      if (this.voyageFreightInvoice.id) return;
      this.voyageFreightInvoice.voyage_freight_invoice_details = [];
      let voyageFreightInvoiceDetail = {
        cargo_name_id: "",
        cargo_name: {},
        cp_qty: 0,
        nominated_qty: 0,
        bl_qty: 0,
        invoice_qty: 0,
        freight_type_id: "",
        freight_rate: 0,
        lumpsum_daily: 0,
        invoice_percent: 0,
        total_percent_billed_to_date: 0,
      };
      this.form.voyage_cargos.forEach((voyageCargo) => {
        voyageFreightInvoiceDetail.cargo_name_id = voyageCargo.cargo_name_id;
        voyageFreightInvoiceDetail.cargo_name = voyageCargo.cargo_name;
        voyageFreightInvoiceDetail.cp_qty = voyageCargo.qty;
        voyageFreightInvoiceDetail.nominated_qty = voyageCargo.qty;
        voyageFreightInvoiceDetail.freight_type_id =
          voyageCargo.freight_type_id;
        voyageFreightInvoiceDetail.freight_rate = voyageCargo.freight_rate;
      });
      let loadingPortFunction = this.portFunctions.find((pf) => pf.code == "L");
      this.form.voyage_itenaries.forEach((voyageItenary) => {
        if (loadingPortFunction)
          if (voyageItenary.port_function_id == loadingPortFunction.id) {
            voyageItenary.voyage_itenary_cargos.forEach(
              (voyageItenaryCargo) => {
                voyageFreightInvoiceDetail.invoice_qty =
                  parseFloat(voyageFreightInvoiceDetail.invoice_qty) +
                  parseFloat(voyageItenaryCargo.bl_discharge_qty);
                voyageFreightInvoiceDetail.bl_qty =
                  voyageFreightInvoiceDetail.invoice_qty;
              }
            );
          }
      });
      let totalPercentBilledTillDate = 0;
      this.form.voyage_freight_invoices.forEach((voyageFreightInvoice) => {
        if (voyageFreightInvoice.voyage_freight_invoice_details)
          voyageFreightInvoice.voyage_freight_invoice_details.forEach(
            (voyageFreightInvoiceDetail) => {
              totalPercentBilledTillDate =
                parseFloat(totalPercentBilledTillDate) +
                parseFloat(voyageFreightInvoiceDetail.invoice_percent);
            }
          );
      });
      voyageFreightInvoiceDetail.invoice_percent =
        100 - parseFloat(totalPercentBilledTillDate);
      voyageFreightInvoiceDetail.total_percent_billed_to_date =
        totalPercentBilledTillDate;

      this.voyageFreightInvoice.voyage_freight_invoice_details.push(
        voyageFreightInvoiceDetail
      );
    },
  },
};
</script>
