<template>
  <section
    v-if="voyageFreightInvoice.voyage_freight_invoice_brokers.length > 0"
  >
    <h5><u>Freight Commission</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Broker</th>
            <th>Rate</th>
            <th>Type</th>
            <th>Payment Method</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              voyageBroker, vb
            ) in voyageFreightInvoice.voyage_freight_invoice_brokers"
            :key="`voyageBroker${vb}`"
          >
            <td>{{ vb + 1 }}.</td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="voyageBroker.broker_id"
                :suggestions="addresses"
                placeholder="Broker"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Rate"
                v-model="voyageBroker.rate"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="voyageBroker.rate_type"
                :suggestions="brokerRateTypes"
                placeholder="Rate Type"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="voyageBroker.payment_method"
                :suggestions="brokerFreightPaymentMethods"
                placeholder="Payment Method"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Remarks"
                v-model="voyageBroker.remarks"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "freightInvoiceCommission",
  data() {
    return {};
  },
  components: {},
  props: [
    "form",
    "addresses",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "voyageBrokers",
    "voyageFreightInvoice",
    "brokerFreightPaymentMethods",
  ],
  watch: {
    voyageBrokers: {
      handler() {
        this.onVoyageBrokersUpdated();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    onVoyageBrokersUpdated() {
      if (this.voyageFreightInvoice.voyage_freight_invoice_brokers.length > 0)
        return;
      this.voyageBrokers.forEach((voyageBroker) => {
        // console.log('Commission')
        // let ifCommissionAlreadyPaid = false;
        // if (this.form.voyage_freight_invoices.length > 0) {
        //   this.form.voyage_freight_invoices.forEach((vfi) => {
        //     let vfib = vfi.voyage_freight_invoice_brokers.find(
        //       (vfib) =>
        //         vfib.payment_method == "Deduct from freight" &&
        //         vfib.broker_id == voyageBroker.broker_id
        //     );
        //     if (vfib) ifCommissionAlreadyPaid = true;
        //   });
        // }
        // if (!ifCommissionAlreadyPaid)
        this.voyageFreightInvoice.voyage_freight_invoice_brokers.push({
          broker_id: voyageBroker.broker_id,
          rate: voyageBroker.rate,
          rate_type: voyageBroker.rate_type,
          payment_method: voyageBroker.payment_method,
        });
      });
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>