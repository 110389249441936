<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/sites"></back-button> 
            Update Company
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/sites">Sites</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Company Name*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Company Name" v-model="form.name">
                          <span class="error-block">{{ errors.name }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Email*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Email" v-model="form.email">
                          <span class="error-block">{{ errors.email }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Phone*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Phone" v-model="form.phone">
                          <span class="error-block">{{ errors.phone }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Address*</label>
                        <div class="col-sm-9">
                          <textarea v-uppercase class="form-control" placeholder="Address" v-model="form.address"></textarea>
                          <span class="error-block">{{ errors.address }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PF No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="PF No" v-model="form.pf_no">
                          <span class="error-block">{{ errors.pf_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">TAN No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="TAN No" v-model="form.tan_no">
                          <span class="error-block">{{ errors.tan_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PAN No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="PAN No" v-model="form.pan_no">
                          <span class="error-block">{{ errors.pan_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">ESI No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="ESI No" v-model="form.esi_no">
                          <span class="error-block">{{ errors.esi_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">TIN No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="TIN No" v-model="form.tin_no">
                          <span class="error-block">{{ errors.tin_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">GST No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="GST No" v-model="form.gst_no">
                          <span class="error-block">{{ errors.gst_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Registration No</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Registration No" v-model="form.registration_no">
                          <span class="error-block">{{ errors.registration_no }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Logo Path</label>
                        <div class="col-sm-9">
                          <img v-if="site.logo_path" :src="mediaUrl + site.logo_path" style="width: 200px; height: 200px;" />
                          <input
                            type="file"
                            id="file"
                            name="logo_path"
                            ref="file"
                            accept="image/*"
                            multiple
                          />
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="save" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'siteUpdate',
  data () {
    return {
      form: {
      },
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/sites/${this.$route.params.siteId}`)
      this.form = form.data.data
      this.isLoading = false
    },
    async save() {
      try {
        await axios.patch(`/sites/${this.$route.params.siteId}`, this.form)
        await this.handleFileUpload()
        this.isLoading = false
        this.$router.push('/sites')
      } catch(e) {
        this.isLoading = false
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0];
      const siteid = this.form.id
      let formData = new FormData();
      formData.append("id", siteid);
      formData.append("logo_path", attachment);
      console.log(formData);
      await axios
        .post("upload_site_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
  }
}
</script>