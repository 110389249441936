<template>
    <OtherRevenueExpenses></OtherRevenueExpenses>
</template>

<script>
import OtherRevenueExpenses from '../../other-revenues/other-revenues-expenses.vue'
export default {
    components : {
        OtherRevenueExpenses
    }
}
</script>