<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/tc-in"></back-button>
            Create add Details
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/tc-in">TC in</router-link></li>
            <li class="active">Create add Details</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Time Charter Hire"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Gratuities For Period"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Repr. Exp / Allowances For Period"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <div class="col-md-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Additional TC Hire"
                            ></my-checkbox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="ILOHC"
                            ></my-checkbox>
                          </div>
                          <div class="col-sm-3">
                            <input
                              v-uppercase
                              type="number"
                              class="form-control"
                              placeholder="0"
                              v-model="form.voyage_no"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Broker Commission(s)"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Gratuities For Whole Month"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Repr. Exp / Allowances For Whole Month"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <div class="col-md-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Hire Adjustment"
                            ></my-checkbox>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Intermediate HC"
                            ></my-checkbox>
                          </div>
                          <div class="col-sm-3">
                            <input
                              v-uppercase
                              type="number"
                              class="form-control"
                              placeholder="0"
                              v-model="form.voyage_no"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Address Commission"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Victualling For Period"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Lashing and Stowage For Period"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Misc Adjustment"
                            ></my-checkbox>
                          </div>
                          <div class="col-sm-3">
                            <input
                              v-uppercase
                              type="number"
                              class="form-control"
                              placeholder="0"
                              v-model="form.voyage_no"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Estimated Owner's Expense"
                            ></my-checkbox>
                          </div>
                          <div class="col-sm-3">
                            <input
                              v-uppercase
                              type="number"
                              class="form-control"
                              placeholder="0"
                              v-model="form.voyage_no"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Victualling For Whole Month"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Lashing & Stowage For Whole Month"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Ballast Bonus"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Voyage Expenses"
                            ></my-checkbox>
                          </div>
                          <div class="col-sm-3">
                            <input
                              v-uppercase
                              type="number"
                              class="form-control"
                              placeholder="0"
                              v-model="form.voyage_no"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-12">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="High Adjustment On Delivery"
                            ></my-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Off Hire Adj"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Hire Adjustment On Redelivery"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-2">
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Off Hire"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <div class="col-sm-9">
                            <my-checkbox
                              :selection.sync="form.include_commission"
                              placeholder="Rebillable Owner's Expenses"
                            ></my-checkbox>
                          </div>
                        </div>
                      </div>

                      <br />
                      <h5>Off Hire / Off Hire Reverse</h5>
                      <br />
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>From GMT</th>
                            <th>Hours</th>
                            <th>Deduct</th>
                            <th>Inv</th>
                            <th>LSF qty</th>
                            <th>LSF prc</th>
                            <th>LSM qty</th>
                            <th>LSM prc</th>
                            <th>MDO qty</th>
                            <th>MDO prc</th>
                            <th>%</th>
                            <th>Lumpsum</th>
                            <th>S</th>
                            <th>Reason</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr></tr>
                        </tbody>
                      </table>
                      <div class="row">
                        <div class="col-md-7">
                          <br />
                          <h5>Voyage Rebillables</h5>
                          <br />
                          <table class="table table-responsive">
                            <thead>
                              <tr>
                                <th>Voyage</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>T</th>
                                <th>S</th>
                                <th>AdmFee</th>
                              </tr>
                            </thead>
                          </table>
                          <br />
                          <h5>TC Rebillables</h5>
                          <br />
                          <table class="table table-responsive">
                            <thead>
                              <tr>
                                <th>TC Code</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>T</th>
                                <th>S</th>
                                <th>AdmFee</th>
                              </tr>
                            </thead>
                          </table>
                          <my-checkbox
                            :selection.sync="form.include_commission"
                            placeholder="Expenses Rebill"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-5">
                          <table class="table table-responsive">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>LSF</th>
                                <th>LSM</th>
                                <th>IFO</th>
                                <th>MDO</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Pre-paid</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.prepaid_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.prepaid_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.prepaid_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.prepaid_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Cost On Delivery</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.cost_on_delivery_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.cost_on_delivery_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.cost_on_delivery_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.cost_on_delivery_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Adj On Delivery</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_delivery_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_delivery_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_delivery_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_delivery_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Cost On Redelivery (pre liminary)</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_lsf
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_lsm
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_ifo
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_mdo
                                    "
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Cost On Redelivery</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_lsf
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_lsm
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_ifo
                                    "
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="
                                      form.cost_on_redelivery_mdo
                                    "
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Adj On Redelivery</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_redelivery_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_redelivery_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_redelivery_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.adj_on_redelivery_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Off Hire</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                              <tr>
                                <td>Off Hire Adj</td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_adj_lsf"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_adj_lsm"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_adj_ifo"
                                  ></my-checkbox>
                                </td>
                                <td>
                                  <my-checkbox
                                    :selection.sync="form.off_hire_adj_mdo"
                                  ></my-checkbox>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
