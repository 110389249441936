<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <!-- <back-button link="/cargos"></back-button>  -->
            Advanced Payment
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/transactions/advanced-payment">Advanced Payment</router-link></li>
            <!-- <li class="active">Create</li> -->
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vendors</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.vendor_id" :suggestions="vendors" placeholder="Enter Vendor"></my-select>
                              <span class="error-block">{{ errors.vendor_id }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Companies</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.company_id" :suggestions="companies" placeholder="Enter Company"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Date To</label>
                            <div class="col-sm-9">
                              <my-datepicker :date.sync="form.date_to" placeholder="Enter Date To"></my-datepicker>
                              <!-- <input v-uppercase type="text" class="form-control" placeholder="CP Date" v-model="form.cp_date"> -->
                            </div>
                          </div>
                        </div>
                        
                      </div>

                      <h5>Outstanding Invoices</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Transaction No</th>
                              <th>Vendor</th>
                              <th>AP/AR</th>
                              <th>Act Date</th>
                              <th>Inv No</th>
                              <th>Due Date</th>
                              <th>Curr</th>
                              <th>Amount</th>
                              <th>Outstanding</th>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <h5>Advance Payments/Receipts</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Transaction No</th>
                              <th>Vendor</th>
                              <th>Type</th>
                              <th>Date</th>
                              <th>Curr</th>
                              <th>Amount</th>
                              <th>Available</th>
                            </tr>
                          </thead>
                        </table>
                      </div>

                      <h5>Allocations</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Invoice Trans No</th>
                              <th>Invoice No</th>
                              <th>Advance Trans No</th>
                              <th>Amount</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(Allocation, ar) in form.Allocations"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteAllocations(Allocation)">
                                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="Allocation.inv_trans_no" :suggestions="InvoiceTransNo" placeholder="Enter Invoice Transaction No"></my-select>
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Invoice No" v-model="Allocation.invoice_no">
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="Allocation.advanced_trans_no" :suggestions="AdvancedTransNo" placeholder="Enter Advanced Transaction No"></my-select>
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Amount" v-model="Allocation.amount">
                              </td>
                              <td>
                              <my-datepicker :date.sync="Allocation.date" placeholder="Enter Date"></my-datepicker>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="12" align="center" @click="addEmptyAllocations">Add New Row</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />
                      
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button :isLoading="isLoading" @submit="save" text="Save"></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'cargoCreate',
  data () {
    return {
      form: {
        invoice_no: '',
        inv_trans_no: '',
        company_id: '',
        vendor_id: '',
        dat_to: '',
        Allocations: []
      },
      vendors: [],
      companies: [],
      InvoiceTransNo: [],
      AdvancedTransNo: [],
      isLoading: false,
    }
  },
  mounted() {
    this.form.site_id = this.site.id
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('cargos/masters')
      masters = masters.data
      masters.cargo_names.forEach(cargo_name => {
        this.cargoNames.push({
          id: cargo_name.id,
          name: cargo_name.short_name + ' - ' + cargo_name.full_name
        })
      })
      masters.addresses.forEach(address => {
        this.addresses.push({
          id: address.id,
          name: address.short_name + ' - ' + address.full_name
        })
      })
      masters.trade_areas.forEach(trade_area => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + ' - ' + trade_area.description
        })
      })
      masters.vessel_types.forEach(vessel_type => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type
        })
      })
      masters.vessels.forEach(vessel => {
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_code + ' - ' + vessel.vessel_name
        })
      })
      masters.units.forEach(unit => {
        this.units.push({
          id: unit.id,
          name: unit.unit + ' - ' + unit.description
        })
      })
      masters.freight_types.forEach(freight_type => {
        this.freightTypes.push({
          id: freight_type.id,
          name: freight_type.type + ' - ' + freight_type.description
        })
      })
      masters.currencies.forEach(currency => {
        this.currencies.push({
          id: currency.id,
          name: currency.name + ' - ' + currency.description
        })
      })
      masters.users.forEach(user => {
        this.users.push({
          id: user.id,
          name: user.first_name + ' ' + user.last_name
        })
      })
      masters.cargo_statuses.forEach(cargo_status => {
        this.cargoStatuses.push({
          id: cargo_status.id,
          name: cargo_status.status
        })
      })
      masters.port_functions.forEach(port_function => {
        this.portFunctions.push({
          id: port_function.id,
          name: port_function.code + ' - ' + port_function.port_operation
        })
      })
      masters.port_names.forEach(port_name => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name
        })
      })
      masters.unit_of_measure_of_rates.forEach(unit_of_measure_of_rate => {
        this.unitOfMeasureOfRates.push({
          id: unit_of_measure_of_rate.id,
          name: unit_of_measure_of_rate.rate + ' - ' + unit_of_measure_of_rate.description
        })
      })
      masters.laytime_terms.forEach(laytime_term => {
        this.laytimeTerms.push({
          id: laytime_term.id,
          name: laytime_term.code + ' - ' + laytime_term.term
        })
      })
    },
    async save() {
      try {
        this.isLoading = true
        await axios.post('/cargos', this.form)
        this.isLoading = false
        this.$router.push('/cargos')
      } catch(e) {
        this.isLoading = false
      }
    },
    addEmptyAllocations() {
      this.form.Allocations.push({
        inv_trans_no: '',
        invoice_no: '',
        advanced_trans_no: '',
        amount: 0,
        date: '',
      })
    },
    deleteAllocations(Allocation) {
      this.form.Allocations = this.form.Allocations.filter(ar => ar != Allocation)
    }
  }
}
</script>