<template>
    <BunkerPurchaseType></BunkerPurchaseType>
</template>

<script>
import BunkerPurchaseType from '../../masters/bunker-purchase-types.vue'
export default {
    components : {
        BunkerPurchaseType
    }
}
</script>