import Vue from 'vue'

Vue.directive("uppercase", {
  twoWay: true,
  // TODO: unbind
  unbind: () => {},
  bind: function (el, binding, vnode) {
    const findVModelName = vnode => {
      try {
        // https://forum.vuejs.org/t/solved-how-can-you-update-the-v-model-from-a-custom-directive-in-vue-2/2275
        return vnode.data.directives.find(o => {
          return o.name === "model";
        }).expression;
      } catch (error) {
        error.message = `Unable to find v-model directive at ${
          vnode.tag
        } tag. Please specify the expression: v-uppercase='obj.prop'`;
        throw error;
      }
    };

    const funcByName = (functionName, context, value) => {
      // https://stackoverflow.com/a/359910/8801075
      var namespaces = functionName.split(".");
      var func = namespaces.pop();
      for (var i = 0; i < namespaces.length; i++)
        context = context[namespaces[i]];
      return (context[func] = value);
    };

    var expression = binding.expression ?
      binding.expression :
      findVModelName(vnode);
    vnode.context.$watch(expression, {
      deep: true, // ?
      immediate: true,
      handler(newVal) {
        if (newVal) {
          return funcByName(
            expression,
            vnode.context,
            newVal.toString().toLocaleUpperCase()
          );
        }
      }
    });
  },
  update(el) {
    const sourceValue = el.value;
    const newValue = sourceValue.toUpperCase();

    if (sourceValue !== newValue) {
      el.value = newValue;
      el.dispatchEvent(new Event('input', { bubbles: true }));
    }
  },
});

// function updateFunction (el, binding) {
//   // get options from binding value. 
//   // v-select="THIS-IS-THE-BINDING-VALUE"
//   let options = binding.value || {};

//   // set up select2
//   console.log(el)
//   $(el).select2(options).on("select2:select", (e) => {
//     alert(1)
//     // v-model looks for
//     //  - an event named "change"
//     //  - a value with property path "$event.target.value"
//     e.params.data.element.parentElement.dispatchEvent(event);
//     // el.dispatchEvent(new Event('change', { target: e.target }));
//   });
// }

// Vue.directive('select3', {
//   twoWay: true,
//   bind: updateFunction,
//   inserted: updateFunction ,
//   componentUpdated: updateFunction,
// });

// Vue.directive('select2', {
//   twoWay: true,
//   inserted(el) {
//     alert(1)
//     $(el).on('select2:select', () => {
//       alert(2)
//       const event = new Event('change', { bubbles: true, cancelable: true });
//       el.dispatchEvent(event);
//     });

//     $(el).on('select2:unselect', () => {
//       const event = new Event('change', {bubbles: true, cancelable: true})
//       el.dispatchEvent(event)
//     })
//   },
// });