<template>
  <TcIn></TcIn>
</template>

<script type="text/javascript">
import TcIn from "./tc_in.vue";

export default {
  name: "TcInCreate",
  data() {
    return {
      // form: {
      //   vessel_id: "",
      //   tc_code: "",
      //   owner_id: "",
      //   beneficiary_id: "",
      //   laycan_from: "",
      //   laycan_to: "",
      //   date: "",
      //   fixed_by: "",
      //   company_id: "",
      //   department_id: "",
      //   trade_area_id: "",
      //   ref_no: "",
      //   payment_term_id: "",
      //   contract_type_id: "",
      //   status_id: "",
      //   billing_period: "",
      //   billing_schedule: "",
      //   rebillable_admin_fee_percent: 0,
      //   exc_currency_id: 26,
      //   exc_currency_rate: 1,
      //   ref_contract: "",
      //   conf_date_gmt: "",
      //   min_duration: 0,
      //   min_duration_unit: 'Days',
      //   min_duration_plus_days: 0,
      //   min_duration_minus_days: 0,
      //   min_estimated_redelivery: "",
      //   max_duration: 0,
      //   max_duration_unit: 'Days',
      //   max_duration_plus_days: 0,
      //   max_duration_minus_days: 0,
      //   max_estimated_redelivery: "",
      //   delivery_port_id: "",
      //   delivery_est_gmt: "",
      //   delivery_actual_gmt: "",
      //   delivery_difference: 0,
      //   delivery_remarks: "",
      //   redelivery_port_id: "",
      //   redelivery_est_gmt: "",
      //   redelivery_actual_gmt: "",
      //   redelivery_difference: 0,
      //   redelivery_remarks: "",
      //   est_duration: 0,
      //   actual_duration: 0,
      //   use_local_time: "",
      //   remarks: "",
      //   notes_to_operations: "",
      //   tc_in_pricings: [],
      //   tc_in_brokers: [],
      //   tc_in_expenses: [],
      //   tc_in_bunkers: [],
      //   tc_in_payments: [],
      // },
    };
  },
  components: {
    TcIn,
  },
};
</script>