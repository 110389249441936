<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Ld Rate Standards
            <button class="btn btn-info btn-sm" @click="save">
              <i class="fa fa-save"></i> Save
            </button>
            <!-- <upload-button link="/masters/bunker-purchase-types"></upload-button> -->
          </h5>
          <!-- <my-select style="margin: 9px !important;" @change="searchData" disableFormControl="true" :selection.sync="search" :suggestions="groups" placeholder="Select group"></my-select> -->
          <small v-if="isSaving">Saving...</small>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Ld Rate Standards</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <div class="editable-table-wrapper">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Vessel</th>
                          <th>Vessel Type</th>
                          <th>Port Name</th>
                          <th>Cargo</th>
                          <th>Cargo Group</th>
                          <th>Port Function</th>
                          <th>Rate</th>
                          <th>Rate Unit</th>
                          <th>Quantity Unit</th>
                          <th>Is Active</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(form, ar) in forms" :key="`form${ar}`">
                          <td>
                            <span
                              @click="deleteLdRateStandards(form)"
                              v-if="!form.id"
                            >
                              <i
                                class="fa fa-times-circle-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            {{ ar + 1 }}.
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.vessel_id"
                              :suggestions="vessels"
                              placeholder="Select vessel"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.vessel_type_id"
                              :suggestions="vesselTypes"
                              placeholder="Select vessel type"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.port_name_id"
                              :suggestions="portNames"
                              placeholder="Select port name"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.cargo_id"
                              :suggestions="cargoNames"
                              placeholder="Select cargo"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.cargo_group_id"
                              :suggestions="cargoGroups"
                              placeholder="Select cargo group"
                            ></my-select>
                          </td>
                          <td>
                            <my-select
                              :selection.sync="form.port_function_id"
                              :suggestions="portFunctions"
                              placeholder="Select port functon"
                            ></my-select>
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="number"
                              placeholder="Rate"
                              v-model="form.rate"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="number"
                              placeholder="Rate Unit"
                              v-model="form.rate_unit"
                            />
                          </td>
                          <td>
                            <input
                              v-uppercase
                              type="number"
                              placeholder="Quantity Rate"
                              v-model="form.quantity_unit"
                            />
                          </td>
                          <td>
                            <my-checkbox
                              :selection.sync="form.is_active"
                              placeholder="Is Active"
                            ></my-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="13"
                            align="center"
                            @click="addEmptyLdRateStandards()"
                          >
                            Add New Row
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <errors :data="errors" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "ldRateStandards",
  data() {
    return {
      count: 0,
      vessels: [],
      vesselTypes: [],
      portNames: [],
      cargoNames: [],
      cargoGroups: [],
      portFunctions: [],
      serialNoStarting: 1,
      isLoading: false,
      search: "",
      vessel_id: "",
      vessel_type_id: "",
      port_name_id: "",
      cargo_id: "",
      cargo_group_id: "",
      port_function_id: "",
      forms: [],
      isSaving: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/ld_rate_standards_masters");
      masters = masters.data;

      masters.vessels.forEach((vessel) => {
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_name,
        });
      });
      masters.portNames.forEach((port_name) => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name,
        });
      });
      masters.cargoNames.forEach((cargoName) => {
        this.cargoNames.push({
          id: cargoName.id,
          name: cargoName.full_name,
        });
      });
      masters.cargoGroups.forEach((cargoGroup) => {
        this.cargoGroups.push({
          id: cargoGroup.id,
          name: cargoGroup.code,
        });
      });
      masters.vesselTypes.forEach((vesselType) => {
        this.vesselTypes.push({
          id: vesselType.id,
          name: vesselType.vessel_type,
        });
      });
      masters.portFunctions.forEach((portFunction) => {
        this.portFunctions.push({
          id: portFunction.id,
          name: portFunction.code,
        });
      });
    },

    async getData(page = 1) {
      this.isLoading = true;
      let forms = await axios.get(
        `/ld_rate_standards?page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.forms = forms.data.data;
      this.count = forms.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    searchData() {
      this.getData(1);
    },
    addEmptyLdRateStandards() {
      this.forms.push({
        site_id: this.site.id,
        vessel_id: "",
        vessel_type_id: "",
        port_name_id: "",
        cargo_id: "",
        cargo_group_id: "",
        port_function_id: "",
        rate: "",
        rate_unit: "",
        quantity_unit: "",
        is_active: true,
      });
    },
    deleteLdRateStandards(form) {
      this.forms = this.forms.filter((ar) => ar != form);
    },
    async save() {
      if (this.forms.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.forms,
        };
        await axios.post(`/ld_rate_standard_multiple`, payload);
        this.isSaving = false;
      }
    },
  },
};
</script>

<style>
th {
}
</style>
