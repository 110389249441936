<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Voyage Invoice List
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Voyage Invoice List</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Action</th>
                        <th>Vessel Name</th>
                        <th>Voyage No</th>
                        <th>Invoice Type</th>
                        <th>Counterparty</th>
                        <th>Actual Amount Base</th>
                        <th>Invoice Amount Base</th>
                        <th>Outstanding Amount Base</th>
                        <th>Paid Amount Base</th>
                        <th>Company Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="12"
                      ></loading-table>
                      <router-link
                        v-for="(voyage_invoice, at) in voyage_invoices"
                        :key="`voyage_invoice${at}`"
                        :to="`/estimates/${voyage_invoice.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ voyage_invoice.code }}</td>
                        <td>{{ voyage_invoice.vessel_name }}</td>
                        <td>{{ voyage_invoice.voyage_no }}</td>
                        <td>{{ voyage_invoice.invoice_type }}</td>
                        <td>{{ voyage_invoice.counterparty }}</td>
                        <td>{{ voyage_invoice.actual_amount_base }}</td>
                        <td>{{ voyage_invoice.invoice_amount_base }}</td>
                        <td>{{ voyage_invoice.outstanding_amount_base }}</td>
                        <td>{{ voyage_invoice.paid_amount_base }}</td>
                        <td>{{ voyage_invoice.company_code }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "voyage_invoices",
  data() {
    return {
      voyage_invoices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let voyage_invoices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.voyage_invoices = voyage_invoices.data.data;
      this.count = voyage_invoices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
