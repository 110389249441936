<template>
    <TcPayment></TcPayment>
</template>

<script>
import TcPayment from '../../tc-ins/make-payment.vue'
export default {
    components : {
        TcPayment
    }
}
</script>