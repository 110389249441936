<template>
  <section>
    <span @click="openAddDetailsModal" title="Add Details"> Add Details </span>

    <div :id="`myAddDetailsModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Details</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.timeCharterHire"
                    placeholder="Time Charter Hire"
                  ></my-checkbox>
                </div>
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.addressCommunication"
                    placeholder="Address Communication"
                  ></my-checkbox>
                </div>
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.gratuitiesForPeriod"
                    placeholder="Gratitudes for period"
                  ></my-checkbox>
                </div>
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.bunkers"
                    placeholder="Bunkers"
                  ></my-checkbox>
                </div>
                <!-- <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.miscellaneousExpenses"
                    placeholder="Miscellaneous Expenses"
                  ></my-checkbox>
                </div> -->
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.voyageExpenses"
                    placeholder="Voyage Expenses"
                  ></my-checkbox>
                </div>
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.ilohc"
                    placeholder="ILOHC"
                  ></my-checkbox>
                </div>
                <!-- <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.ballastBonus"
                    placeholder="Ballast Bonus"
                  ></my-checkbox>
                </div> -->
                <div class="col-md-4">
                  <my-checkbox
                    :selection.sync="addDetails.offHire"
                    placeholder="OFF Hire"
                  ></my-checkbox>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <h5>Delays</h5>
                </div>
                <div class="com-md-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Sr. No</th>
                        <th>Status</th>
                        <th>Activity</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Hours</th>
                        <th>TCI %</th>
                        <th>TCO %</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(voyageDelay, vd) in addDetails.delays"
                        :key="`voyageDelay${vd}`"
                      >
                        <td>
                          <input
                            :disabled="voyageDelay.checkIfAlreadyAdded"
                            type="checkbox"
                            v-model="voyageDelay.addToInvoice"
                          />
                        </td>
                        <td @click="selectVoyageDelay(voyageDelay)">
                          {{ vd + 1 }}.
                        </td>
                        <td>
                          {{
                            voyageDelay.checkIfAlreadyAdded
                              ? "Already Added"
                              : "Yet to Add"
                          }}
                        </td>
                        <td>
                          {{ voyageDelay.delay_type }}
                        </td>
                        <td>
                          {{ voyageDelay.from | ddmmyyyy }}
                        </td>
                        <td>
                          {{ voyageDelay.to | ddmmyyyy }}
                        </td>
                        <td>
                          {{ voyageDelay.hours | round3 }}
                        </td>
                        <td>
                          {{ voyageDelay.tci_percent }}
                        </td>
                        <td>
                          {{ voyageDelay.tco_percent }}
                        </td>
                        <td>
                          {{ voyageDelay.remarks }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveAddDetails(true)"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "addDetailsModal",
  data() {
    return {
      addDetails: {
        timeCharterHire: true,
        addressCommunication: true,
        gratuitiesForPeriod: true,
        bunkers: false,
        miscellaneousExpenses: false,
        voyageExpenses: false,
        ilohc: false,
        ballastBonus: false,
        offHire: false,
        addedByUser: false,
        delays: [],
      },
    };
  },
  components: {},
  props: ["count", "form", "tcInPayment", "delayTypes"],
  mounted() {
    this.onFormChanged();
  },
  methods: {
    async openAddDetailsModal() {
      var modal = document.getElementById("myAddDetailsModal" + this.count);
      modal.style.display = "block";
    },
    closeAddDetailsModal() {
      var modal = document.getElementById("myAddDetailsModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyVoyageBroker() {
      this.form.voyage_brokers.push({
        broker_id: "",
        rate: 0,
        rate_type: "%",
        payment_method: "Deduct from hire",
        is_claim_commissionable:
          "Claim comissions will be deducted from claim invoice",
        remarks: "",
      });
    },
    deleteVoyageBroker(voyageBroker) {
      this.form.voyage_brokers.splice(
        this.form.voyage_brokers.indexOf(voyageBroker),
        1
      );
    },
    saveAddDetails(addedByUser) {
      this.addDetails.addedByUser = addedByUser;
      this.$emit("updateTCPaymentAddDetails", this.addDetails);
      this.closeAddDetailsModal();
    },
    onFormChanged() {
      this.delays = [];
      this.form.voyages.forEach((voyage) => {
        voyage.voyage_delays.forEach((voyageDelay) => {
          // To check if the delay is added in any of the previous invoices only for Period invoices
          let checkIfAlreadyAdded = false;
          this.form.tc_in_payments.forEach((tcInPayment) => {
            let vD = tcInPayment.tc_in_payment_details.find(
              (tIPD) =>
                tIPD.element_id == voyageDelay.id &&
                (tIPD.code == "OFFH" || tIPD.code == "OFFHR")
            );
            if (vD && !checkIfAlreadyAdded) {
              checkIfAlreadyAdded = true;
            }
          });
          let delayType = this.delayTypes.find(
            (delayType) => delayType.description == "OFF HIRE"
          );
          if (delayType) {
            if (voyageDelay.delay_type_id == delayType.id) {
              voyageDelay.delay_type = "OFF HIRE";
            }
          }
          delayType = this.delayTypes.find(
            (delayType) => delayType.description == "OFF HIRE REVERSE"
          );
          if (delayType) {
            if (voyageDelay.delay_type_id == delayType.id) {
              voyageDelay.delay_type = "OFF HIRE REVERSE";
            }
          }
          voyageDelay.checkIfAlreadyAdded = checkIfAlreadyAdded;
          if (checkIfAlreadyAdded) voyageDelay.addToInvoice = true;
          else voyageDelay.addToInvoice = true;
          this.addDetails.delays.push(voyageDelay);
        });
      });
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>