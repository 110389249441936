<template>
  <section>
    <a
      href="javascript:void"
      @click="openVoyageOthersModal"
      title="Other Rev/Exp."
      >Other Rev/Exp.</a
    >

    <div id="myVoyageOthersModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Voyage Other Revenues and Expenses</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-3">Vessel</div>
                <div class="col-md-9">
                  <input
                    v-uppercase
                    disabled
                    class="form-control"
                    placeholder="Vessel"
                    v-model="form.vessel.vessel_name"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-3">Voy no.</div>
                <div class="col-md-9">
                  <input
                    v-uppercase
                    disabled
                    class="form-control"
                    placeholder="Voyage no"
                    v-model="form.voyage_no"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-3">Commencing</div>
                <div class="col-md-9">
                  <input
                    v-uppercase
                    disabled
                    class="form-control"
                    placeholder="Commencing"
                    v-model="form.voyage_commencing_date"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-3">Completing</div>
                <div class="col-md-9">
                  <input
                    v-uppercase
                    disabled
                    class="form-control"
                    placeholder="Completing"
                    v-model="form.voyage_completing_date"
                  />
                </div>
              </div>
            </div>
          </div>
          <h5><u>Details</u></h5>
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Invoice</th>
                  <th>Vendor</th>
                  <th>Description</th>
                  <th>Rev/Exp</th>
                  <th>Inv No.</th>
                  <th>Invoice Date</th>
                  <th>Due Date</th>
                  <th>Curr</th>
                  <th>Exch. Rate</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <!-- <th>Amount (in USD)</th> -->
                  <th>Code</th>
                  <th>Attachment</th>
                  <th>Attachment 2</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(voyageOther, vo) in form.voyage_other_rev_expenses"
                  :key="`voyageOther${vo}`"
                >
                  <td @click="deleteVoyageOther(voyageOther)">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    {{ vo + 1 }}.
                  </td>
                  <td>
                    <Invoice
                      :count="vo"
                      :voyageOther="voyageOther"
                      :form="form"
                    >
                    </Invoice>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.vendor.full_name }}</span
                    >
                    <my-select
                      v-else
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageOther.vendor_id"
                      :suggestions="addresses"
                      placeholder="Vendor"
                    ></my-select>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.description }}</span
                    >
                    <input
                      v-else
                      style="width: 150px !important"
                      v-uppercase
                      placeholder="Description"
                      v-model="voyageOther.description"
                    />
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.is_revenue_expense }}</span
                    >
                    <my-select
                      v-else
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageOther.is_revenue_expense"
                      :suggestions="revExpenses"
                      placeholder="Rev/Exp"
                    ></my-select>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.invoice_no }}</span
                    >
                    <input
                      v-else
                      v-uppercase
                      placeholder="Invoice No."
                      v-model="voyageOther.invoice_no"
                    />
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.invoice_date | ddmmyyyy }}</span
                    >
                    <my-datepicker
                      v-else
                      :disableFormControl="true"
                      :date.sync="voyageOther.invoice_date"
                      placeholder="Invoice Date"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.due_date | ddmmyyyy }}</span
                    >
                    <my-datepicker
                      v-else
                      :disableFormControl="true"
                      :date.sync="voyageOther.due_date"
                      placeholder="Due Date"
                      myWidth="200px"
                    ></my-datepicker>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.currency.name }}</span
                    >
                    <my-select
                      v-else
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageOther.currency_id"
                      :suggestions="currencies"
                      placeholder="Currency"
                    ></my-select>
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.exchange_rate }}</span
                    >
                    <input
                      v-else
                      v-uppercase
                      placeholder="Exchange Rate"
                      v-model="voyageOther.exchange_rate"
                    />
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.quantity }}</span
                    >
                    <input
                      v-else
                      v-uppercase
                      placeholder="Quantity"
                      v-model="voyageOther.quantity"
                    />
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.price }}</span
                    >
                    <input
                      v-else
                      v-uppercase
                      placeholder="Price"
                      v-model="voyageOther.price"
                    />
                  </td>
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.amount }}</span
                    >
                    <input
                      v-else
                      v-uppercase
                      placeholder="Amount"
                      v-model="voyageOther.amount"
                    />
                  </td>
                  <td>
                    <my-select
                      :selection.sync="voyageOther.is_approved"
                      :suggestions="approvalTypes"
                      placeholder=""
                    ></my-select>
                  </td>
                  <!-- <td>
                    <input
                      v-uppercase
                      placeholder="Amount (IN USD)"
                      v-model="voyageOther.amount_in_usd"
                    />
                  </td> -->
                  <td>
                    <span
                      v-if="
                        voyageOther.is_approved == 'Approved' ||
                        voyageOther.is_approved == 'Posted' ||
                        voyageOther.is_approved == 'Paid'
                      "
                      >{{ voyageOther.expense_revenue_code.code }}</span
                    >
                    <my-select
                      v-else
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="voyageOther.expense_revenue_code_id"
                      :suggestions="expenseRevenueCodes"
                      placeholder="Code"
                    ></my-select>
                  </td>
                  <td style="min-width: 250px">
                    <a
                      v-if="voyageOther.attachment"
                      :href="mediaUrl + voyageOther.attachment"
                      target="_blank"
                    >
                      {{ voyageOther.attachment }}
                    </a>

                    <input
                      style="min-width: 250px; margin-top: 10px"
                      type="file"
                      id="file"
                      name="attachment"
                      ref="file"
                      accept="image/*"
                      @input="attachmentAdded(vo)"
                    />
                  </td>
                  <td style="min-width: 250px">
                    <a
                      v-if="voyageOther.attachment_1"
                      :href="mediaUrl + voyageOther.attachment_1"
                      target="_blank"
                    >
                      {{ voyageOther.attachment_1 }}
                    </a>
                    <input
                      style="min-width: 250px; margin-top: 10px"
                      type="file"
                      id="file"
                      name="attachment"
                      ref="file1"
                      accept="image/*"
                      @input="attachmentAdded1(vo)"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="14"
                    align="center"
                    @click="addEmptyVoyageOther()"
                  >
                    Add New Row
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveMyVoyageOthers"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyVoyageOthersModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import Invoice from "./invoice.vue";

export default {
  name: "voyageOtherRevExpenses",
  data() {
    return {
      approvalTypes: [
        {
          id: "Approved",
          name: "Approved",
        },
        {
          id: "Not Approved",
          name: "Not Approved",
        },
      ],
    };
  },
  props: [
    "form",
    "addresses",
    "revExpenses",
    "currencies",
    "expenseRevenueCodes",
  ],
  components: {
    Invoice,
  },
  methods: {
    attachmentAdded(index) {
      // console.log(this.$refs.file[index].files[0]);
      this.form.voyage_other_rev_expenses[index].inputFile1 =
        this.$refs.file[index].files[0];
    },
    attachmentAdded1(index) {
      this.form.voyage_other_rev_expenses[index].inputFile2 =
        this.$refs.file1[index].files[0];
      console.log(this.form.voyage_other_rev_expenses);
    },
    async openVoyageOthersModal() {
      var modal = document.getElementById("myVoyageOthersModal");
      modal.style.display = "block";
    },
    closeMyVoyageOthersModal() {
      var modal = document.getElementById("myVoyageOthersModal");
      modal.style.display = "none";
    },
    saveMyVoyageOthers() {
      this.$emit("calculateCost");
      this.closeMyVoyageOthersModal();
    },
    addEmptyVoyageOther() {
      let voyageOther = {
        vendor_id: "",
        description: "",
        is_revenue_expense: "",
        invoice_no: "",
        invoice_date: "",
        due_date: "",
        currency_id: 26,
        exchange_rate: 1,
        quantity: 0,
        price: 0,
        amount: 0,
        amount_in_usd: 0,
        expense_revenue_code_id: "",
      };

      this.form.voyage_other_rev_expenses.push(voyageOther);
    },
    deleteVoyageOther(voyageOther) {
      this.form.voyage_other_rev_expenses.splice(
        this.form.voyage_other_rev_expenses.indexOf(voyageOther),
        1
      );
    },
  },
};
</script>
