<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/cargos"></back-button> 
            Update cargo: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/cargos">Class Societies</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Cargo*</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.cargo_name_id" :suggestions="cargoNames" placeholder="Enter Cargo"></my-select>
                              <span class="error-block">{{ errors.cargo_name_id }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">CP Date</label>
                            <div class="col-sm-9">
                              <my-datepicker :date.sync="form.cp_date" placeholder="Enter CP Date"></my-datepicker>
                              <!-- <input v-uppercase type="text" class="form-control" placeholder="CP Date" v-model="form.cp_date"> -->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Status</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.status_id" :suggestions="cargoStatuses" placeholder="Enter Status"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">M3/MT, Ft3/MT</label>
                            <div class="col-sm-5">
                              <input v-uppercase type="number" class="form-control" placeholder="M3/MT" v-model="form.m3_mt">
                            </div>
                            <div class="col-sm-4">
                              <input v-uppercase type="number" class="form-control" placeholder="FT3/MT" v-model="form.ft3_mt">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">CP Qty/Unit</label>
                            <div class="col-sm-5">
                              <input v-uppercase type="number" class="form-control" placeholder="CP Qty" v-model="form.cp_qty">
                            </div>
                            <div class="col-sm-4">
                              <my-select :selection.sync="form.cp_unit_id" :suggestions="units" placeholder="Enter Unit"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Charterer*</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.charterer_id" :suggestions="addresses" placeholder="Enter Charterer"></my-select>
                              <span class="error-block">{{ errors.charterer_id }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Company</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.company_id" :suggestions="addresses" placeholder="Enter Company"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Min/Max Qty</label>
                            <div class="col-sm-5">
                              <input v-uppercase type="number" class="form-control" placeholder="Min Qty" v-model="form.min_qty">
                            </div>
                            <div class="col-sm-4">
                              <input v-uppercase type="number" class="form-control" placeholder="Max Qty" v-model="form.max_qty">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Department</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.department_id" :suggestions="departments" placeholder="Enter Department"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Freight Type</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.freight_type_id" :suggestions="freightTypes" placeholder="Enter Freight Type"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Coordinator</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.coordinator_id" :suggestions="users" placeholder="Enter Coordinator"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">CP Place</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="CP Place" v-model="form.cp_place">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Freight Rate</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="number" class="form-control" placeholder="Freight Rate" v-model="form.freight_rate">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Reference No.</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Reference No." v-model="form.reference_no">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Laycan From</label>
                            <div class="col-sm-9">
                              <my-datepicker :date.sync="form.laycan_from" placeholder="Enter Laycan From"></my-datepicker>
                              <span class="error-block">{{ errors.laycan_from }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Curr/Exch. Rate</label>
                            <div class="col-sm-5">
                              <my-select :selection.sync="form.exec_currency_id" :suggestions="currencies" placeholder="Enter Currency"></my-select>
                            </div>
                            <div class="col-sm-4">
                              <input v-uppercase type="number" class="form-control" placeholder="Curr/Exch. Rate" v-model="form.exec_currency_rate">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Shipment No</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Shipment No" v-model="form.shipment_no">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Laycan To</label>
                            <div class="col-sm-9">
                              <my-datepicker :date.sync="form.laycan_to" placeholder="Enter Laycan To"></my-datepicker>
                              <span class="error-block">{{ errors.laycan_to }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Min. Invoice Qty</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="number" class="form-control" placeholder="Min. Invoice Qty" v-model="form.min_inv_qty">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Trade Area</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.trade_area_id" :suggestions="tradeAreas" placeholder="Enter Trade Area"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Freight Via</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.freight_via" :suggestions="addresses" placeholder="Enter Freight Via"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Vessel Type</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.vessel_type_id" :suggestions="vesselTypes" placeholder="Enter Vessel Type"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Demurrage Via</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.demurrage_via" :suggestions="addresses" placeholder="Enter Demurrage Via"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Nominated Vessel</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.nominated_vessel_id" :suggestions="vessels" placeholder="Enter Nominated Vessel"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Conf. Date</label>
                            <div class="col-sm-9">
                              <my-datepicker :date.sync="form.conf_date_gmt" placeholder="Enter Conf. Date"></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Contract Link</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Contract Link" v-model="form.contract_link">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">CP Draft (m)</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="number" class="form-control" placeholder="CP Draft (m)" v-model="form.cp_draft_m">
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5>Itenarary/Options</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port Function</th>
                              <th>Port</th>
                              <th>Qty</th>
                              <th>L/D Rate</th>
                              <th>RU</th>
                              <th>Terms</th>
                              <th>Port Expenses</th>
                              <th>$/T Port Expense</th>
                              <th>Draft</th>
                              <th>Unit</th>
                              <th>Salinity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(cargoItenary, ar) in form.cargo_itenaries"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteCargoItenary(cargoItenary)">
                                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="cargoItenary.port_function_id" :suggestions="portFunctions" placeholder="Enter Port Function"></my-select>
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="cargoItenary.port_name_id" :suggestions="portNames" placeholder="Enter Port"></my-select>
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Qty" v-model="cargoItenary.qty">
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="L/D Rate" v-model="cargoItenary.load_discharge_rate">
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="cargoItenary.rate_unit_id" :suggestions="unitOfMeasureOfRates" placeholder="Enter Rate Unit"></my-select>
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="cargoItenary.term_id" :suggestions="laytimeTerms" placeholder="Enter Laytime Term"></my-select>
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Port Expenses" v-model="cargoItenary.port_expenses">
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="$/T Expense" v-model="cargoItenary.dollar_per_ton_port_expense">
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Draft" v-model="cargoItenary.draft">
                              </td>
                              <td>
                                <my-select disableFormControl="true" :selection.sync="cargoItenary.unit_id" :suggestions="units" placeholder="Enter Unit"></my-select>
                              </td>
                              <td>
                                <input v-uppercase type="number" placeholder="Salinity" v-model="cargoItenary.salinity">
                              </td>
                            </tr>
                            <tr>
                              <td colspan="12" align="center" @click="addEmptyCargoItenary">Add New Row</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'cargoUpdate',
  data () {
    return {
      form: {
        cargo_itenaries: []
      },
      cargoNames: [],
      addresses: [],
      departments: [],
      tradeAreas: [],
      vesselTypes: [],
      vessels: [],
      units: [],
      freightTypes: [],
      currencies: [],
      users: [],
      cargoStatuses: [],
      portFunctions: [],
      portNames: [],
      unitOfMeasureOfRates: [],
      laytimeTerms: [],
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('cargos/masters')
      masters = masters.data
      masters.cargo_names.forEach(cargo_name => {
        this.cargoNames.push({
          id: cargo_name.id,
          name: cargo_name.short_name + ' - ' + cargo_name.full_name
        })
      })
      masters.addresses.forEach(address => {
        this.addresses.push({
          id: address.id,
          name: address.short_name + ' - ' + address.full_name
        })
      })
      masters.trade_areas.forEach(trade_area => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + ' - ' + trade_area.description
        })
      })
      masters.vessel_types.forEach(vessel_type => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type
        })
      })
      masters.vessels.forEach(vessel => {
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_code + ' - ' + vessel.vessel_name
        })
      })
      masters.units.forEach(unit => {
        this.units.push({
          id: unit.id,
          name: unit.unit + ' - ' + unit.description
        })
      })
      masters.freight_types.forEach(freight_type => {
        this.freightTypes.push({
          id: freight_type.id,
          name: freight_type.type + ' - ' + freight_type.description
        })
      })
      masters.currencies.forEach(currency => {
        this.currencies.push({
          id: currency.id,
          name: currency.name + ' - ' + currency.description
        })
      })
      masters.users.forEach(user => {
        this.users.push({
          id: user.id,
          name: user.first_name + ' ' + user.last_name
        })
      })
      masters.cargo_statuses.forEach(cargo_status => {
        this.cargoStatuses.push({
          id: cargo_status.id,
          name: cargo_status.status
        })
      })
      masters.port_functions.forEach(port_function => {
        this.portFunctions.push({
          id: port_function.id,
          name: port_function.code + ' - ' + port_function.port_operation
        })
      })
      masters.port_names.forEach(port_name => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name
        })
      })
      masters.unit_of_measure_of_rates.forEach(unit_of_measure_of_rate => {
        this.unitOfMeasureOfRates.push({
          id: unit_of_measure_of_rate.id,
          name: unit_of_measure_of_rate.rate + ' - ' + unit_of_measure_of_rate.description
        })
      })
      masters.laytime_terms.forEach(laytime_term => {
        this.laytimeTerms.push({
          id: laytime_term.id,
          name: laytime_term.code + ' - ' + laytime_term.term
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/cargos/${this.$route.params.cargoId}`)
      this.form = form.data.data
      this.form.cargo_name_id = this.form.cargo_name_id || ''
      this.form.charterer_id = this.form.charterer_id || ''
      this.form.company_id = this.form.company_id || ''
      this.form.department_id = this.form.department_id || ''
      this.form.trade_area_id = this.form.trade_area_id || ''
      this.form.vessel_type_id = this.form.vessel_type_id || ''
      this.form.nominated_vessel_id = this.form.nominated_vessel_id || ''
      this.form.cp_unit_id = this.form.cp_unit_id || ''
      this.form.freight_type_id = this.form.freight_type_id || ''
      this.form.exec_currency_id = this.form.exec_currency_id || ''
      this.form.freight_via = this.form.freight_via || ''
      this.form.demurrage_via = this.form.demurrage_via || ''
      this.form.status_id = this.form.status_id || 1
      this.form.coordinator_id = this.form.coordinator_id || ''
      this.form.cp_date = this.form.cp_date || ''
      this.form.cargo_itenaries.forEach(cargo_itenary => {
        cargo_itenary.port_function_id = cargo_itenary.port_function_id || ''
        cargo_itenary.port_name_id = cargo_itenary.port_name_id || ''
        cargo_itenary.port_berth_id = cargo_itenary.port_berth_id || ''
        cargo_itenary.rate_unit_id = cargo_itenary.rate_unit_id || ''
        cargo_itenary.term_id = cargo_itenary.term_id || ''
        cargo_itenary.unit_id = cargo_itenary.unit_id || ''
      })
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.post(`/cargos`, this.form)
        this.isLoading = false
        this.$router.push('/cargos')
      } catch(e) {
        this.isLoading = false
      }
    },
    addEmptyCargoItenary() {
      this.form.cargo_itenaries.push({
        port_function_id: '',
        port_name_id: '',
        port_berth_id: '',
        qty: 0,
        load_discharge_rate: 0,
        rate_unit_id: '',
        term_id: '',
        port_expenses: '',
        dollar_per_ton_port_expense: 0,
        draft: '',
        unit_id: '',
        salinity: '',
      })
    },
    deleteCargoItenary(cargoItenary) {
      this.form.cargo_itenaries = this.form.cargo_itenaries.filter(ar => ar != cargoItenary)
    }
  }
}
</script>