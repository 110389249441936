<template>
  <section>
    <button class="btn btn-info" v-if="isLoading">Loading...</button>
    <button @click="$emit('submit')" v-else class="btn btn-info btn-anim">
      <i class="icon-rocket"></i><span class="btn-text">{{ text }}</span>
    </button>
  </section>
</template>

<script type="text/javascript">
export default {
  name: 'SubmitButtonWidget',
  props: ['isLoading', 'text']
}
</script>