<template>
    <BunkerRequirement></BunkerRequirement>
</template>

<script>
import BunkerRequirement from '../../bunker/create.vue'
export default {
    components : {
        BunkerRequirement
    }
}
</script>