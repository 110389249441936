<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/port-names"></back-button> Upload Ports
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Ports</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/ports-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />
                
                
                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudePorts.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>
                <div v-if="crudePorts.length != 0">
                  <div class="form-footer">
                    <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button>
                  </div>
                </div>
                <!-- <div
                  class="form-footer"
                  v-if="conflicTargets.length != 0"
                  
                > -->
                  <!-- <download-excel
                    :title="title"
                    class="btn btn-success btn-block"
                    :data="conflict_targets_excel"
                    :fields="excel_fields"
                    worksheet="ConflictUsers"
                    name="Conflict_Target.xls"
                  >
                    <a href="#" class="download">Export Conflics</a>
                  </download-excel> -->
                  <!-- <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Fuel Zone Name</th>
                        <th>Supported Scrubbers</th>
                        <th>Port No</th>
                        <th>UN Code</th>
                        <th>Port Name</th>
                        <th>Country</th>
                        <th>Time Zone Code</th>
                        <th>Time Zone STD</th>
                        <th>Time Zone DST</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                        <th>Port Type</th>
                        <th>Port Area</th>
                        <th>Port Alias To</th>
                        <th>Port Alias(es)</th>
                        <th>Bunkering Port</th>
                        <th>Single Berth</th>
                        <th>Remarks</th>
                        <th>External Ref</th>
                        <th>Country Code</th>
                        <th>Port Agents</th>
                        <th>Last Modified by</th>
                        <th>Status</th>
                        <th>Alerts</th>
                        <th>State</th>
                        <th>Waterway Name</th>
                        <th>Waterway Code</th>
                        <th>Mile Marker</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <tr
                        v-for="(crudePort, at) in crudePorts"
                        :key="`crudePort${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudePort.fuel_zone_name }}</td>
                        <td>{{ crudePort.supported_scrubbers }}</td>
                        <td>{{ crudePort.port_no }}</td>
                        <td>{{ crudePort.un_code }}</td>
                        <td>{{ crudePort.port_name }}</td>
                        <td>{{ crudePort.country }}</td>
                        <td>{{ crudePort.time_zone_code }}</td>
                        <td>{{ crudePort.time_zone_std }}</td>
                        <td>{{ crudePort.time_zone_dst }}</td>
                        <td>{{ crudePort.latitude }}</td>
                        <td>{{ crudePort.longitude }}</td>
                        <td>{{ crudePort.port_type }}</td>
                        <td>{{ crudePort.port_area }}</td>
                        <td>{{ crudePort.port_alias_to }}</td>
                        <td>{{ crudePort.port_aliases }}</td>
                        <td>{{ crudePort.bunkering_port }}</td>
                        <td>{{ crudePort.single_berth }}</td>
                        <td>{{ crudePort.remarks }}</td>
                        <td>{{ crudePort.external_ref }}</td>
                        <td>{{ crudePort.country_code }}</td>
                        <td>{{ crudePort.port_agents }}</td>
                        <td>{{ crudePort.last_modified_by }}</td>
                        <td>{{ crudePort.status }}</td>
                        <td>{{ crudePort.alerts }}</td>
                        <td>{{ crudePort.state }}</td>
                        <td>{{ crudePort.waterway_name }}</td>
                        <td>{{ crudePort.waterway_code }}</td>
                        <td>{{ crudePort.mile_marker }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "uploadTargets",
  data() {
    return {
      // crudePorts:{
      //
      // },
      crudePorts: [],
      // conflicTargets: [],
      company_id: "",
      month: "",
      year: "",
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      months: [],
      years: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   Source: "source",
      //   "Employee Code": "employee_code",
      // },
      // conflict_targets_excel: [],
    };
  },
  mounted() {
    this.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudePorts = await axios.get(`/crude_ports`);
      this.crudePorts = crudePorts.data.data;
      this.count = crudePorts.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.ports = this.$refs.file.files[0];
      // this.month = this.crudePorts.month;
      // this.year = this.crudePorts.year;

      let formData = new FormData();

      // formData.append("month", this.month);
      formData.append("site_id", this.site_id);
      formData.append("PortData", this.ports);
      await axios
        .post("upload_port", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudePorts = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
          await axios.get("process_port");
        // if (conflicTargets != "") {
        //   // console.log('Conflicts');
        //   this.conflicTargets = conflicTargets.data.data;
        //   this.exportToExcel();
        alert("Processed Successfully");
        // }
        await axios.get("truncate_ports");
        this.crudePorts = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_ports");
      alert("Truncated Successfully");
      this.crudePorts = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_targets/${$id}`);
        this.crudePorts = this.crudePorts.filter((d) => d.id != $id);
      }
    },
    // exportToExcel() {
    //   this.title = "Conflict User Master";
    //   this.conflict_targets_excel = [];
    //   let sr_no = 1;
    //   this.conflicTargets.forEach((user) => {
    //     this.conflict_targets_excel.push({
    //       sr_no: sr_no,
    //       source: user.source,
    //       employee_code: user.store_code ? user.store_code : user.employee_code,
    //     });
    //     sr_no++;
    //   });
    //   console.log(this.conflict_targets_excel);
    // },
  },
};
</script>