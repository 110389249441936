<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Account Periods
            <add-button
              link="/financial/statement/account-periods/create"
            ></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Account Periods</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Year</th>
                        <th>Closing Date</th>
                        <th>Status</th>
                        <th>Last Month Closed</th>
                        <th>Account Payable</th>
                        <th>Account Receivable</th>
                        <th>Clearance</th>
                        <th>Interbank</th>
                        <th>Retained Earnings</th>
                        <th>Exchange Differances</th>
                        <th>Intercompany</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="11"
                      ></loading-table>
                      <router-link
                        v-for="(accountPeriod, at) in accountPeriods"
                        :key="`accountPeriod${at}`"
                        :to="`/financial/statement/account-periods/${accountPeriod.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ accountPeriod.year }}</td>
                        <td>{{ accountPeriod.closing_date }}</td>
                        <td>{{ accountPeriod.status == 1 ? "Finalized" : "Partial" }}</td>
                        <td>
                          {{
                            accountPeriod.last_month_closed
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.account_payable.name
                              ? accountPeriod.account_payable.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.account_receivable.name
                              ? accountPeriod.account_receivable.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.clearance.name
                              ? accountPeriod.clearance.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.interbank.name
                              ? accountPeriod.interbank.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.retained_earning.name
                              ? accountPeriod.retained_earning.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.exchange_differance.name
                              ? accountPeriod.exchange_differance.name
                              : ""
                          }}
                        </td>
                        <td>
                          {{
                            accountPeriod.intercompany.name
                              ? accountPeriod.intercompany.name
                              : ""
                          }}
                        </td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "accountPeriods",
  data() {
    return {
      accountPeriods: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let accountPeriods = await axios.get(`/account_periods`);
      this.accountPeriods = accountPeriods.data.data;
      this.count = accountPeriods.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
