<template>
  <span>
    <router-link :to="link">
      <i class="fa fa-arrow-circle-o-left"></i>
    </router-link>
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'BackButtonWidget',
  props: ['link']
}
</script>