<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Task List
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Task List</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- Task List -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Task Name</th>
                        <th>Status</th>
                        <th>Basis</th>
                        <th>Category</th>
                        <th>Priority</th>
                        <th>Object</th>
                        <th>Object Type</th>
                        <th>Due Date</th>
                        <th>Voyage No</th>
                        <th>Port</th>
                        <th>Port Seq</th>
                        <th>Basis Date</th>
                        <th>Start Date</th>
                        <th>Description</th>
                        <th>Remarks</th>
                        <th>Ops Coordinator</th>
                        <th>Owner</th>
                        <th>Vessel Code</th>
                        <th>Vessel Name</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "tc_voices",
  data() {
    return {
      tc_voices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let tc_voices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.tc_voices = tc_voices.data.data;
      this.count = tc_voices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
