<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">Bank Statements</h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Bank Statements</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <my-select
                        :selection.sync="filter.vessel_id"
                        :suggestions="vessels"
                        placeholder="Select vessel"
                      ></my-select>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        :selection.sync="filter.voyage_id"
                        :suggestions="voyages"
                        placeholder="Select voyage"
                      ></my-select>
                    </div>
                    <div class="col-md-3">
                      <my-select
                        :selection.sync="filter.vendor_id"
                        :suggestions="vendors"
                        placeholder="Select vendor"
                      ></my-select>
                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-primary btn-xs" @click="getData">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- /Row -->
        <!-- Row -->
        <div class="row">
          <div class="col-sm-12">
            <div class="panel panel-default card-view">
              <div class="panel-wrapper collapse in">
                <div class="panel-body">
                  <div class="table-wrap">
                    <table class="table table-hover table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Bank</th>
                          <th>Date</th>
                          <th>Description</th>
                          <th>TCIN Amount</th>
                          <th>Voyage Amount</th>
                          <th>Credit</th>
                          <th>Debit</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <loading-table
                          :isLoading="isLoading"
                          cols="8"
                        ></loading-table>
                        <tr
                          v-for="(bankStatement, at) in bss"
                          :key="`bankStatement${at}`"
                        >
                          <td>{{ serialNoStarting + at + 1 }}</td>
                          <td>{{ bankStatement.bs.bank.bank_name }}</td>
                          <td>{{ bankStatement.bs.date | dmy }}</td>
                          <td>{{ bankStatement.bs.description }}</td>
                          <td>
                            {{
                              bankStatement.bs.tc_in_payment
                                ? bankStatement.bs.tc_in_payment.amount
                                : 0 | round2
                            }}
                          </td>
                          <td>
                            {{
                              bankStatement.bs.voyage_other_rev_expense
                                ? bankStatement.bs.voyage_other_rev_expense
                                    .amount
                                : 0 | round2
                            }}
                          </td>

                          <td>
                            {{
                              bankStatement.bs.payment_type == "CREDIT"
                                ? bankStatement.bs.amount
                                : 0 | round2
                            }}
                          </td>
                          <td>
                            {{
                              bankStatement.bs.payment_type == "DEBIT"
                                ? bankStatement.bs.amount
                                : 0 | round2
                            }}
                          </td>
                          <td>{{ bankStatement.total | round2 }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pagination :count="count" @submit="getData"></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "voyage_invoices",
  data() {
    return {
      bankStatements: [],
      bss: [],
      vessels: [],
      voyages: [],
      vendors: [],
      count: 0,
      filter: {
        vessel_id: "",
        vendor_id: "",
        voyage_id: "",
      },
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("tc_in_payment_receipts/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        vessel.name = vessel.vessel_name;
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_name,
        });
      });
      masters.vendors.forEach((address) => {
        this.vendors.push({
          id: address.id,
          name: address.full_name,
        });
      });
      masters.voyages.forEach((voyage) => {
        this.voyages.push({
          id: voyage.id,
          name: voyage.voyage_no,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let bankStatements = await axios.get(
        `/tc_in_payment_receipts?page=${page}&rowsPerPage=${this.rowsPerPage}&vessel_id=${this.filter.vessel_id}&voyage_id=${this.filter.voyage_id}&vendor_id=${this.filter.vendor_id}`
      );
      this.bankStatements = bankStatements.data.data;
      this.bss = [];
      this.bankStatements.forEach((bankStatement) => {
        let total = 0;
        if (bankStatement.payment_type == "CREDIT") {
          if (bankStatement.voyage_other_rev_expense) {
            total =
              parseInt(bankStatement.voyage_other_rev_expense.amount) +
              parseInt(bankStatement.amount);
          }
          if (bankStatement.tc_in_payment) {
            total = parseInt(
              bankStatement.tc_in_payment.amount + bankStatement.amount
            );
          }
        }
        if (bankStatement.payment_type == "DEBIT") {
          if (bankStatement.voyage_other_rev_expense) {
            total = parseInt(
              bankStatement.voyage_other_rev_expense.amount -
                bankStatement.amount
            );
          }
          if (bankStatement.tc_in_payment) {
            total = parseInt(
              bankStatement.tc_in_payment.amount - bankStatement.amount
            );
          }
        }
        this.bss.push({
          bs: bankStatement,
          total: total,
        });
      });
      this.count = bankStatements.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
