<template>
  <section>
    <span @click="openCPTermsModal" title="CP Terms">
      <i class="fa fa-external-link-square" aria-hidden="true"></i>
    </span>

    <div :id="`myCPTermsModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">CP Terms</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3 control-label">Cargo</div>
                    <div class="col-md-9">
                      <my-select
                        :selection.sync="estimateCargo.cargo_name_id"
                        :suggestions="cargoNames"
                        placeholder="Select cargo"
                        @change="updateCargo(estimateCargo)"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Charterer</div>
                    <div class="col-md-9">
                      <my-select
                        :selection.sync="estimateCargo.charterer_id"
                        :suggestions="charterers"
                        placeholder="Enter charterer"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">CP Qty</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        type="number"
                        class="form-control"
                        :placeholder="'CP Qty (in MT)'"
                        v-model="estimateCargo.qty"
                        @blur="calculateCost"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">CP Date</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="estimateCargo.cp_date"
                        placeholder="Enter CP Date"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">
                      Option %
                      <!-- / Option Type -->
                    </div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        type="number"
                        class="form-control"
                        placeholder="Option %"
                        v-model="estimateCargo.option_percent"
                        @change="onEstimateCargoUpdated"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Min/Max Qty</div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            disabled
                            v-uppercase
                            type="number"
                            class="form-control"
                            :placeholder="'Min. Qty'"
                            v-model="estimateCargo.min_qty"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            disabled
                            v-uppercase
                            type="number"
                            class="form-control"
                            :placeholder="'Max. Qty'"
                            v-model="estimateCargo.max_qty"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3">Frt Type</div>
                    <div class="col-md-9">
                      <my-select
                        maxWidth="YES"
                        disableFormControl="true"
                        :selection.sync="estimateCargo.freight_type_id"
                        :suggestions="freightTypes"
                        placeholder="Enter freight type"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Frt Rate</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        type="number"
                        placeholder="Freight rate"
                        v-model="estimateCargo.freight_rate"
                        @blur="calculateCost"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Comm %</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        type="number"
                        placeholder="Freight commission"
                        v-model="estimateCargo.freight_commission"
                        @blur="calculateCost"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Company</div>
                    <div class="col-md-9">
                      <my-select
                        disableFormControl="true"
                        :selection.sync="estimateCargo.company_id"
                        :suggestions="companies"
                        placeholder="Enter company"
                      ></my-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3">Laycan From</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="estimateCargo.laycan_from"
                        placeholder="Laycan From"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Laycan To</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="estimateCargo.laycan_to"
                        placeholder="Laycan To"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Demurrage</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        type="number"
                        placeholder="Demurrage"
                        v-model="estimateCargo.demurrage_amount"
                        @change="onDemurrageChanged"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Despatch</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        type="number"
                        placeholder="Despatch"
                        v-model="estimateCargo.despatch_amount"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Per Day/Hr</div>
                    <div class="col-md-9">
                      <span class="myBold">
                        <my-select
                          disableFormControl="true"
                          :selection.sync="estimateCargo.per_day_per_hr"
                          :suggestions="perDays"
                          placeholder="Select perDay/Hr"
                        ></my-select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5><u>Brokers</u></h5>
          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Broker</th>
                  <th>Rate</th>
                  <th>Type</th>
                  <th>Payment Method</th>
                  <th>Claim</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(estimateBroker, vb) in form.estimate_brokers"
                  :key="`estimateBroker${vb}`"
                >
                  <td @click="deleteEstimateBroker(estimateBroker)">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    {{ vb + 1 }}.
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="estimateBroker.broker_id"
                      :suggestions="addresses"
                      placeholder="Broker"
                    ></my-select>
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      placeholder="Rate"
                      v-model="estimateBroker.rate"
                    />
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="estimateBroker.rate_type"
                      :suggestions="brokerRateTypes"
                      placeholder="Rate Type"
                    ></my-select>
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="estimateBroker.payment_method"
                      :suggestions="brokerFreightPaymentMethods"
                      placeholder="Payment Method"
                    ></my-select>
                  </td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="estimateBroker.is_claim_commissionable"
                      :suggestions="brokerCommissionables"
                      placeholder="Claim"
                    ></my-select>
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder="Remarks"
                      v-model="estimateBroker.remarks"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="9"
                    align="center"
                    @click="addEmptyEstimateBroker()"
                  >
                    Add New Row
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveMyCpTerms">
            Save
          </button>
          <button type="button" class="btn btn-default" @click="closeMyCpTerms">
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  name: "estimateCPTermsModal",
  data() {
    return {};
  },
  components: {},
  props: [
    "form",
    "tcIn",
    "count",
    "estimateCargo",
    "cargoNames",
    "perDays",
    "charterers",
    "freightTypes",
    "addresses",
    "companies",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerCommissionables",
    "brokerFreightPaymentMethods",
  ],
  watch: {
    tcIn: "onTcInChanged",
  },
  mounted() {
    this.onTcInChanged();
  },
  methods: {
    async openCPTermsModal() {
      var modal = document.getElementById("myCPTermsModal" + this.count);
      modal.style.display = "block";
    },
    closeMyCpTerms() {
      var modal = document.getElementById("myCPTermsModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyEstimateBroker() {
      this.form.estimate_brokers.push({
        broker_id: "",
        rate: 0,
        rate_type: "%",
        payment_method: "Deduct from hire",
        is_claim_commissionable:
          "Claim comissions will be deducted from claim invoice",
        remarks: "",
      });
    },
    deleteEstimateBroker(estimateBroker) {
      this.form.estimate_brokers.splice(
        this.form.estimate_brokers.indexOf(estimateBroker),
        1
      );
    },
    saveMyCpTerms() {
      this.closeMyCpTerms();
    },
    updateCargo(cargo) {
      let cargoName = this.cargoNames.find((c) => c.id == cargo.cargo_name_id);
      if (cargoName) cargo.name = cargoName.name;
    },
    calculateCost() {
      this.$emit("calculateCost");
    },
    onEstimateCargoUpdated() {
      this.$emit("onEstimateCargoUpdated");
    },
    onDemurrageChanged() {
      if (this.estimateCargo.demurrage_amount) {
        this.estimateCargo.despatch_amount =
          parseFloat(this.estimateCargo.demurrage_amount) / 2;
      }
    },
    onTcInChanged() {
      if (this.tcIn) {
        // this.estimateCargo.laycan_from = this.tcIn.laycan_from;
        // this.estimateCargo.laycan_to = this.tcIn.laycan_to;
      }
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>