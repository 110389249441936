<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/country-names"></back-button> Upload Countries
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Countries</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/countries-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />
                
                
                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudeCountries.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>
                <div v-if="crudeCountries.length != 0">
                  <div class="form-footer">
                    <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button>
                  </div>
                </div>
                <!-- <div
                  class="form-footer"
                  v-if="conflicTargets.length != 0"
                  
                > -->
                  <!-- <download-excel
                    :title="title"
                    class="btn btn-success btn-block"
                    :data="conflict_targets_excel"
                    :fields="excel_fields"
                    worksheet="ConflictUsers"
                    name="Conflict_Target.xls"
                  >
                    <a href="#" class="download">Excountry Conflics</a>
                  </download-excel> -->
                  <!-- <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Country Name</th>
                        <th>Country Code</th>
                        <th>Last Modified by</th>
                        <th>Last Modified on</th>
                        <th>War Risk</th>
                        <th>ENOA</th>
                        <th>AMS</th>
                        <th>SISCARGA</th>
                        <th>Freight Tax</th>
                        <th>ICS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <tr
                        v-for="(crudeCountry, at) in crudeCountries"
                        :key="`crudeCountry${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudeCountry.country_name }}</td>
                        <td>{{ crudeCountry.country_code }}</td>
                        <td>{{ crudeCountry.last_modified_by }}</td>
                        <td>{{ crudeCountry.last_modified_on }}</td>
                        <td>{{ crudeCountry.war_risk }}</td>
                        <td>{{ crudeCountry.enoa }}</td>
                        <td>{{ crudeCountry.ams }}</td>
                        <td>{{ crudeCountry.siscarga }}</td>
                        <td>{{ crudeCountry.freight_tax }}</td>
                        <td>{{ crudeCountry.ics }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "uploadTargets",
  data() {
    return {
      crudeCountries: [],
      company_id: "",
      month: "",
      year: "",
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      months: [],
      years: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   Source: "source",
      //   "Employee Code": "employee_code",
      // },
      // conflict_targets_excel: [],
    };
  },
  mounted() {
    this.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudeCountries = await axios.get(`/crude_countries`);
      this.crudeCountries = crudeCountries.data.data;
      this.count = crudeCountries.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.countries = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("site_id", this.site_id);
      formData.append("CountryData", this.countries);
      await axios
        .post("upload_country", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudeCountries = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
          await axios.get("process_country");
        alert("Processed Successfully");
        // await axios.get("crude_countries");
        // this.crudeCountries = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("crude_countries");
      alert("Truncated Successfully");
      this.crudeCountries = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_countries/${$id}`);
        this.crudeCountries = this.crudeCountries.filter((d) => d.id != $id);
      }
    },
    // excountryToExcel() {
    //   this.title = "Conflict User Master";
    //   this.conflict_targets_excel = [];
    //   let sr_no = 1;
    //   this.conflicTargets.forEach((user) => {
    //     this.conflict_targets_excel.push({
    //       sr_no: sr_no,
    //       source: user.source,
    //       employee_code: user.store_code ? user.store_code : user.employee_code,
    //     });
    //     sr_no++;
    //   });
    //   console.log(this.conflict_targets_excel);
    // },
  },
};
</script>