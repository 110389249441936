<template>
  <section>
    <h5><u>Fixture</u></h5>

    <div class="panel panel-default card-view">
        <div class="panel-wrapper collapse in">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-wrap form-horizontal">
                  <div class="row">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Fixture no.</label>
                        <div class="col-sm-9">
                            <input v-uppercase type="text" class="form-control" placeholder="Fixture No" v-model="form.fixture_no">
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Date</label>
                        <div class="col-sm-9">
                            <my-datepicker :date.sync="form.fixture_date" placeholder="Enter Fixture Date"></my-datepicker>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                          <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                            <my-checkbox :selection.sync="form.is_fixed" placeholder="Is Voyage fixed" ></my-checkbox>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import myCheckbox from '../my-checkbox.vue'
export default {
  components: { myCheckbox },
  name: 'estimateFixtures',
  props: ['form'],
}
</script>