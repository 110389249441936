<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/addresses"></back-button>
            Create a new address
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/addresses">Address</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Address Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.address_type_id"
                                :suggestions="addressTypes"
                                placeholder="Enter Address Type"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Address</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Address"
                                v-model="form.add_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Country Code</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Country Code"
                                v-model="form.country_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Sh. Name (10 Char.)</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Short Name"
                                v-model="form.short_name"
                              />
                              <span class="error-block">{{
                                errors.short_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Web Page</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Web Page"
                                v-model="form.website"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Currency</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.currency_id"
                                :suggestions="currencies"
                                placeholder="Enter Currency"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Contact</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Main Contact"
                                v-model="form.main_contact_name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Full Name</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Full Name"
                                v-model="form.full_name"
                              />
                              <span class="error-block">{{
                                errors.full_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Phone</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Phone"
                                v-model="form.phone"
                              />
                              <span class="error-block">{{
                                errors.phone
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Country</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.country_id"
                                :suggestions="countries"
                                placeholder="Enter Country"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Fax</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Fax"
                                v-model="form.fax"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Email</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Email"
                                v-model="form.email"
                              />
                              <span class="error-block">{{
                                errors.email
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Telex</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Telex"
                                v-model="form.telex"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_active"
                                placeholder="Is Active"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Image Path</label
                            >
                            <div class="col-sm-9">
                              <input
                                type="file"
                                id="file"
                                name="imagepath"
                                ref="file"
                                accept="image/*"
                                multiple
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Remittance Information</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Is Default</th>
                              <th>Bank Name</th>
                              <th>Branch</th>
                              <th>Currency</th>
                              <th>Account No</th>
                              <th>Swift Code</th>
                              <th>Correspondent Bank</th>
                              <th>CB Swift Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                addressRemittance, ar
                              ) in form.address_remittances"
                              :key="`remittance${ar}`"
                            >
                              <td
                                @click="
                                  deleteAddressRemittance(addressRemittance)
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="addressRemittance.is_default"
                                  placeholder=""
                                ></my-checkbox>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Bank Name"
                                  v-model="addressRemittance.bank_name"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Branch"
                                  v-model="addressRemittance.branch"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    addressRemittance.currency_id
                                  "
                                  :suggestions="currencies"
                                  placeholder="Enter Currency"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Acc. No"
                                  v-model="addressRemittance.acc_no"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Swift Code"
                                  v-model="addressRemittance.swift_code"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Correspondent Bank"
                                  v-model="addressRemittance.correspondent_bank"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="IBAN No."
                                  v-model="addressRemittance.cb_swift_code"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="10"
                                align="center"
                                @click="addEmptyAddressRemittance()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Contacts</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Fax</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                addressContact, ar
                              ) in form.address_contacts"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteAddressContact(addressContact)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="First Name"
                                  v-model="addressContact.first_name"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Last Name"
                                  v-model="addressContact.last_name"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Email"
                                  v-model="addressContact.email"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Phone"
                                  v-model="addressContact.phone"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Fax"
                                  v-model="addressContact.fax"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="9"
                                align="center"
                                @click="addEmptyAddressContact()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "VesselTypesCreate",
  data() {
    return {
      form: {
        address_type_id: "",
        currency_id: "",
        country_id: "",
        is_active: 1,
        address_remittances: [],
        address_contacts: [],
      },
      addressTypes: [],
      currencies: [],
      countries: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("addresses/masters");
      masters = masters.data;
      masters.address_types.forEach((address_type) => {
        this.addressTypes.push({
          id: address_type.id,
          name: address_type.short_name + " - " + address_type.name,
        });
      });
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.short_name + " - " + currency.name,
        });
      });
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.code + " - " + country.name,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
       let address = await axios.post("/addresses", this.form);
       this.address = address.data.data
        await this.handleFileUpload()
        this.isLoading = false;
        this.$router.push("/addresses");
      } catch (e) {
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let attachment = this.$refs.file.files[0]
      const addressid = this.address.id;
      console.log(addressid);
      let formData = new FormData();
      formData.append("id", addressid);
      formData.append('imagepath', attachment);
      await axios.post('upload_address_image', formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .catch(function(){
        console.log('FAILURE!!');
      });
    },
    addEmptyAddressRemittance() {
      this.form.address_remittances.push({
        bank_name: "",
        branch: "",
        currency_id: "",
        acc_no: "",
        swift_code: "",
        correspondent_bank: "",
        cb_swift_code: "",
        is_default: "",
      });
    },
    deleteAddressRemittance(addressRemittance) {
      this.form.address_remittances = this.form.address_remittances.filter(
        (ar) => ar != addressRemittance
      );
    },
    addEmptyAddressContact() {
      this.form.address_contacts.push({
        bank_name: "",
        branch: "",
        currency_id: "",
        acc_no: "",
        swift_code: "",
        correspondent_bank: "",
        cb_swift_code: "",
        is_default: "",
      });
    },
    deleteAddressContact(addressContact) {
      this.form.address_contacts = this.form.address_contacts.filter(
        (ar) => ar != addressContact
      );
    },
  },
};
</script>

<style></style>
