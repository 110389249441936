<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <!-- <back-button link="/tc-outs"></back-button> -->
            Create a new tc-in
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/opeartions/time-charter-ins/tc-in-list">TC In</router-link></li>
            <!-- <li class="active">Create</li> -->
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-9 col-xs-9">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Date/Fixed By*</label
                            >
                            <div class="col-sm-4">
                              <my-datepicker
                                :date.sync="form.voyage_commening_date"
                                placeholder="Enter Voyage Commencing Date"
                              ></my-datepicker>
                            </div>
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Voyage Number"
                                v-model="form.voyage_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Status*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.status"
                                :suggestions="Status"
                                placeholder="Select Status"
                              ></my-select>
                              <span class="error-block">{{
                                errors.status
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >TC Code*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                placeholder="TC Code"
                                v-model="form.tc_code"
                              />
                              <span class="error-block">{{
                                errors.tc_code
                              }}</span>
                            </div>
                            <!-- <div class="col-sm-4">
                              MT
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company"
                                :suggestions="Company"
                                placeholder="Select Company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Period*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_period"
                                :suggestions="BillingPeriod"
                                placeholder="Select Billing Period"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Charterer
                            </label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.charterer"
                                :suggestions="Charterer"
                                placeholder="Select Charterer"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Department</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.department"
                                :suggestions="Department"
                                placeholder="Select Department"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Schedule</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_schedule"
                                :suggestions="BillingSchedule"
                                placeholder="Select Billing Schedule"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Bill Via
                            </label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.bill_via"
                                :suggestions="BillVia"
                                placeholder="Select Bill Via"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trade Area</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.trade_area"
                                :suggestions="TradeArea"
                                placeholder="Select Trade Area"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Rebillable Admin Fee %</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Rebillable Admin Fee %"
                                v-model="form.rebillable_admin_fee_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <my-checkbox
                          :selection.sync="form.include_commission"
                        ></my-checkbox>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laytime_from"
                                placeholder="Enter Laycan From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref No.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Ref No."
                                v-model="form.ref_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Currency/Exchange Rate</label
                            >
                            <div class="col-sm-4">
                              <my-select
                                :selection.sync="form.currency_exchange_rate"
                                :suggestions="CurrencyExchangeRate"
                                placeholder="Select Currency/Exchange Rate"
                              ></my-select>
                            </div>
                            <div class="col-md-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Rebillable Admin Fee %"
                                v-model="form.rebillable_admin_fee_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laytime_to"
                                placeholder="Enter Laycan To"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Payment Terms</label
                            >
                            <div class="col-sm-4">
                              <my-select
                                :selection.sync="form.payment_terms"
                                :suggestions="PaymentTerms"
                                placeholder="Select Payment Terms"
                              ></my-select>
                            </div>
                            <div class="col-md-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Rebillable Admin Fee %"
                                v-model="form.rebillable_admin_fee_percent"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref Contact.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Ref Contact."
                                v-model="form.ref_contact"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <!-- <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Piracy Routing</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Piracy Routing"
                                v-model="form.piracy_routing"
                              />
                              <span class="error-block">{{
                                errors.piracy_routing
                              }}</span>
                            </div>
                          </div> -->
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Contract Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.contarct_type"
                                :suggestions="ontractTypes"
                                placeholder="Select Contract Type"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Conf Date GMT</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                v-model="form.conf_date"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-8">
                        <div class="form-group">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Dur.</th>
                                <th>Unit</th>
                                <th>-Days</th>
                                <th>+Days</th>
                                <th>E/L Redel</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Min</td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <my-select
                                    disableFormControl="true"
                                    :suggestions="Port"
                                    placeholder="Select Port"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <my-datepicker
                                    :date.sync="form.arrival_date"
                                    placeholder="Enter Arrival Date"
                                  ></my-datepicker>
                                </td>
                              </tr>
                              <tr>
                                <td>Max</td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="0"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <my-select
                                    disableFormControl="true"
                                    :suggestions="Port"
                                    placeholder="Select Port"
                                  ></my-select>
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <input
                                    v-uppercase
                                    type="text"
                                    class="form-control"
                                    v-model="form.add_2"
                                  />
                                </td>
                                <td>
                                  <my-datepicker
                                    :date.sync="form.arrival_date"
                                    placeholder="Enter Arrival Date"
                                  ></my-datepicker>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <table class="table table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Port</th>
                            <th>Est GMT</th>
                            <th>Proj/Act GMT</th>
                            <th>Difference</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Delivery</td>
                            <td>
                              <my-select
                                disableFormControl="true"
                                :suggestions="Port"
                                placeholder="Select Port"
                              ></my-select>
                            </td>
                            <td>
                              <my-datepicker
                                :date.sync="form.arrival_date"
                                placeholder="Enter Arrival Date"
                              ></my-datepicker>
                            </td>
                            <td>
                              <my-datepicker
                                :date.sync="form.arrival_date"
                                placeholder="Enter Arrival Date"
                              ></my-datepicker>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Redelivery</td>
                            <td>
                              <my-select
                                disableFormControl="true"
                                :suggestions="Port"
                                placeholder="Select Port"
                              ></my-select>
                            </td>
                            <td>
                              <my-datepicker
                                :date.sync="form.arrival_date"
                                placeholder="Enter Arrival Date"
                              ></my-datepicker>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <p style="float:right">Duration/Basis (days)</p>
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                            <td>
                              <input
                                v-uppercase
                                type="text"
                                disabled
                                class="form-control"
                                v-model="form.add_2"
                              />
                            </td>
                            <td>
                              <my-select
                                disableFormControl="true"
                                :suggestions="Port"
                                placeholder="Select Port"
                              ></my-select>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="row">
                        <div class="col-md-3">
                          <my-checkbox
                            :selection.sync="form.display_base_currency"
                            placeholder="Use Local Time"
                          ></my-checkbox>
                        </div>
                        <div class="col-md-3">
                          <my-checkbox
                            :selection.sync="form.display_base_currency"
                            placeholder="Add All Off Hires"
                          ></my-checkbox>
                        </div>
                      </div>
                      <br />
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="Pricing-tab"
                            data-toggle="tab"
                            href="#Pricing"
                            role="tab"
                            aria-controls="Pricing"
                            aria-selected="true"
                            >Pricing</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Bunkers-tab"
                            data-toggle="tab"
                            href="#Bunkers"
                            role="tab"
                            aria-controls="Bunkers"
                            aria-selected="false"
                            >Bunkers</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Exposure-tab"
                            data-toggle="tab"
                            href="#Exposure"
                            role="tab"
                            aria-controls="Exposure"
                            aria-selected="false"
                            >Exposure</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Performance-tab"
                            data-toggle="tab"
                            href="#Performance"
                            role="tab"
                            aria-controls="Performance"
                            aria-selected="false"
                            >Performance</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Notice-tab"
                            data-toggle="tab"
                            href="#Notice"
                            role="tab"
                            aria-controls="Notice"
                            aria-selected="false"
                            >Notice</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Off_Hire-tab"
                            data-toggle="tab"
                            href="#Off_Hire"
                            role="tab"
                            aria-controls="Off_Hire"
                            aria-selected="false"
                            >Off Hire</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Inv_Items-tab"
                            data-toggle="tab"
                            href="#Inv_Items"
                            role="tab"
                            aria-controls="Inv_Items"
                            aria-selected="false"
                            >Inv Items</a
                          >
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade"
                          id="Pricing"
                          role="tabpanel"
                          aria-labelledby="Pricing-tab"
                        >
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Hire Rate</th>
                                  <th>Rate Type</th>
                                  <th>Code</th>
                                  <th>Lock</th>
                                  <th>From GMT</th>
                                  <th>To GMT</th>
                                  <th>Duraion</th>
                                  <th>Period</th>
                                  <th>TCL Ins</th>
                                  <th>Comments</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.arrival_date"
                                      placeholder="Enter Arrival Date"
                                    ></my-datepicker>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Weather Factor"
                                      v-model="
                                        vesselInPortConsumption.waether_factor
                                      "
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Sea Days"
                                      v-model="vesselInPortConsumption.sea_days"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="Extra Sea Days"
                                      v-model="
                                        vesselInPortConsumption.extra_sea_days
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Broker</th>
                                  <th>Rate</th>
                                  <th>Type</th>
                                  <th>Payment Method</th>
                                  <th>M</th>
                                  <th>From GMT</th>
                                  <th>To GMT</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>

                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.arrival_date"
                                      placeholder="Enter Arrival Date"
                                    ></my-datepicker>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="Extra Sea Days"
                                      v-model="
                                        vesselInPortConsumption.extra_sea_days
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Code</th>
                                  <th>description</th>
                                  <th>Rate Type</th>
                                  <th>Rate</th>
                                  <th>Comm</th>
                                  <th>From GMT</th>
                                  <th>To GMT</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="
                                        form.display_base_currency
                                      "
                                    ></my-checkbox>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.arrival_date"
                                      placeholder="Enter Arrival Date"
                                    ></my-datepicker>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="Extra Sea Days"
                                      v-model="
                                        vesselInPortConsumption.extra_sea_days
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Bunkers"
                          role="tabpanel"
                          aria-labelledby="Bunkers-tab"
                        >
                          <div class="editable-table-wrapper">
                            <table class="table table-responsive">
                              <thead>
                                <tr>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="row">
                            <div class="col-md-4">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Bunkers On Consumption"
                              ></my-checkbox>
                            </div>
                            <div class="col-md-4">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Bunkers On Consumption"
                              ></my-checkbox>
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Bunkers On Consumption"
                              ></my-checkbox>
                            </div>
                            <div class="col-md-4">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Bunker Breakdown"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Exposure"
                          role="tabpanel"
                          aria-labelledby="Exposure-tab"
                        >
                          <br />
                          <div class="row">
                            <div class="col-md-3">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Exclude Running Costs from Voyage P&L"
                              ></my-checkbox>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Route</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.trade_area"
                                    :suggestions="TradeArea"
                                    placeholder="Select Route"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-1">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Split"
                              ></my-checkbox>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label class="col-sm-4 control-label"
                                  >Vessel Type</label
                                >
                                <div class="col-sm-8">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="Vessel Type"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Strategy</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="Strategy"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                                placeholder="Own Vessel"
                              ></my-checkbox>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Turnover Ratio</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="Turnover Ratio"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Vessel Type Corr/Adj</label
                                >
                                <div class="col-sm-4">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="Turnover Ratio"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                                <div class="col-sm-4">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="Turnover Ratio"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-1">
                              <my-checkbox
                                :selection.sync="form.display_base_currency"
                              ></my-checkbox>
                            </div>
                          </div>
                          <div class="editable-table-wrapper col-md-6">
                            <h5>
                              Bunker Exposure
                            </h5>
                            <table class="table table-responsive">
                              <thead>
                                <tr>
                                  <th>Fuel Type</th>
                                  <th>Symbol Delivery</th>
                                  <th>Symbol Redelivery</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div class="editable-table-wrapper col-md-6">
                            <h5>
                              TC Exposure Periods
                            </h5>
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Duration</th>
                                  <th>Unit</th>
                                  <th>Route</th>
                                  <th>Correlation</th>
                                  <th>Start Month</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="
                                        form.display_base_currency
                                      "
                                    ></my-checkbox>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-6"></div>
                          <div class="editable-table-wrapper col-md-6">
                            <h5>
                              Unavailable Days
                            </h5>
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Year</th>
                                  <th>Month</th>
                                  <th>Type</th>
                                  <th>Days</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      placeholder="Speed"
                                      v-model="vesselInPortConsumption.speed"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Performance"
                          role="tabpanel"
                          aria-labelledby="Performance-tab"
                        >
                          <br />
                          <div class="editable-table-wrapper">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Speed</th>
                                  <th>B/L</th>
                                  <th>Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Miles"
                                      v-model="vesselInPortConsumption.miles"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>

                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.port_name
                                      "
                                      :suggestions="portNames"
                                      placeholder="Select Port Name"
                                    ></my-select>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="editable-table-wrapper">
                            <table class="table table-responsive">
                              <thead>
                                <tr>
                                  <th>Type</th>
                                  <th>Grade</th>
                                  <th>Load</th>
                                  <th>Disch</th>
                                  <th>Idle/On</th>
                                  <th>Idle/Off</th>
                                  <th>Heat</th>
                                  <th>Heat +</th>
                                  <th>Heat ++</th>
                                  <th>IGS</th>
                                  <th>Clean</th>
                                  <th>Maneuv</th>
                                  <th>Auxil</th>
                                  <th>Cool</th>
                                  <th>Boiler</th>
                                  <th>Incinerator</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row">
                            <div class="col-md-9"></div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Ballast Speed</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Ballast Speed"
                                    v-model="form.ballast_speed"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-2 control-label"
                                  >Beaufort</label
                                >
                                <div class="col-sm-8">
                                  <my-select
                                    :selection.sync="
                                      form.currency_exchange_rate
                                    "
                                    :suggestions="CurrencyExchangeRate"
                                    placeholder="Select Beaufort"
                                  ></my-select>
                                </div>
                                <div class="col-md-1">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="0"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-2 control-label"
                                  >Sea State</label
                                >
                                <div class="col-sm-8">
                                  <my-select
                                    :selection.sync="
                                      form.currency_exchange_rate
                                    "
                                    :suggestions="CurrencyExchangeRate"
                                    placeholder="Select Sea State"
                                  ></my-select>
                                </div>
                                <div class="col-md-1">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="0"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-2 control-label"
                                  >Swell Force</label
                                >
                                <div class="col-sm-8">
                                  <my-select
                                    :selection.sync="
                                      form.currency_exchange_rate
                                    "
                                    :suggestions="CurrencyExchangeRate"
                                    placeholder="Select Currency/Exchange Rate"
                                  ></my-select>
                                </div>
                                <div class="col-md-1">
                                  <input
                                    v-uppercase
                                    type="number"
                                    disabled
                                    class="form-control"
                                    placeholder="0"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Laden Speed</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Laden Speed"
                                    v-model="form.laden_speed"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Notice"
                          role="tabpanel"
                          aria-labelledby="Notice-tab"
                        >
                          <br />
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Sync All Notice Dates With</label
                                >
                                <div class="col-sm-9">
                                  <my-select
                                    :selection.sync="form.est_Date"
                                    :suggestions="TradeArea"
                                    placeholder="Select Est Date"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-4 control-label"
                                  >Delivery</label
                                >
                                <div class="col-sm-8">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Delivery"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Redelivery</label
                                >
                                <div class="col-sm-9">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Redelivery"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="editable-table-wrapper col-md-6">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>S</th>
                                  <th>No</th>
                                  <th>Notice</th>
                                  <th>Notice Date</th>
                                  <th>Delivery</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="
                                        form.display_base_currency
                                      "
                                    ></my-checkbox>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="No"
                                      v-model="vesselInPortConsumption.no"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Notice"
                                      v-model="vesselInPortConsumption.notice"
                                    />
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.notice_date"
                                      placeholder="Enter Notice Date"
                                    ></my-datepicker>
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.Delivery"
                                      placeholder="Enter Delivery"
                                    ></my-datepicker>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="editable-table-wrapper col-md-6">
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>S</th>
                                  <th>No</th>
                                  <th>F</th>
                                  <th>Notice</th>
                                  <th>Notice Date</th>
                                  <th>Delivery</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(vesselInPortConsumption,
                                  ar) in form.vessel_in_port_consumptions"
                                  :key="`vesselInPortConsumption${ar}`"
                                >
                                  <td
                                    @click="
                                      deleteVesselInPortConsumption(
                                        vesselInPortConsumption
                                      )
                                    "
                                  >
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                    ></i>
                                    {{ ar + 1 }}.
                                  </td>
                                  <td>
                                    <my-checkbox
                                      :selection.sync="
                                        form.display_base_currency
                                      "
                                    ></my-checkbox>
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="text"
                                      placeholder="No"
                                      v-model="vesselInPortConsumption.no"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      v-uppercase
                                      type="number"
                                      placeholder="Notice"
                                      v-model="vesselInPortConsumption.notice"
                                    />
                                  </td>
                                  <td>
                                    <my-select
                                      @blur="updateVesselSpeedConsumptionFuels"
                                      disableFormControl="true"
                                      :selection.sync="
                                        vesselInPortConsumption.f
                                      "
                                      :suggestions="f"
                                      placeholder="Select F"
                                    ></my-select>
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.notice_date"
                                      placeholder="Enter Notice Date"
                                    ></my-datepicker>
                                  </td>
                                  <td>
                                    <my-datepicker
                                      :date.sync="form.Delivery"
                                      placeholder="Enter Delivery"
                                    ></my-datepicker>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colspan="10"
                                    align="center"
                                    @click="addEmptyVesselInPortConsumption()"
                                  >
                                    Add New Row
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="10"></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Off_Hire"
                          role="tabpanel"
                          aria-labelledby="Off_Hire-tab"
                        >
                          <div class="editable-table-wrapper">
                            <table class="table table-responsive">
                              <thead>
                                <tr>
                                  <th>Voy</th>
                                  <th>Reason</th>
                                  <th>From</th>
                                  <th>Zone</th>
                                  <th>Hours</th>
                                  <th>Ded Hrs.</th>
                                  <th>Net Hrs.</th>
                                  <th>Value</th>
                                  <th>Bnk Value</th>
                                  <th>Miles</th>
                                  <th>TCO%</th>
                                  <th>TCO Stmt</th>
                                  <th>Add to Hire</th>
                                  <th>Profit Share</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >CP Off Hire Days</label
                                >
                                <div class="col-md-5">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Rebillable Admin Fee %"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                                <div class="col-sm-4">
                                  <my-select
                                    :selection.sync="
                                      form.currency_exchange_rate
                                    "
                                    :suggestions="CurrencyExchangeRate"
                                    placeholder="Select Currency/Exchange Rate"
                                  ></my-select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="col-md-12">
                                  <label class="col-sm-2 control-label"
                                    >Total</label
                                  >
                                  <div class="col-md-2">
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      class="form-control"
                                      placeholder="Rebillable Admin Fee %"
                                      v-model="
                                        form.rebillable_admin_fee_percent
                                      "
                                    />
                                  </div>
                                  <div class="col-md-2">
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      class="form-control"
                                      placeholder="Rebillable Admin Fee %"
                                      v-model="
                                        form.rebillable_admin_fee_percent
                                      "
                                    />
                                  </div>
                                  <div class="col-md-2">
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      class="form-control"
                                      placeholder="Rebillable Admin Fee %"
                                      v-model="
                                        form.rebillable_admin_fee_percent
                                      "
                                    />
                                  </div>
                                  <div class="col-md-2">
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      class="form-control"
                                      placeholder="Rebillable Admin Fee %"
                                      v-model="
                                        form.rebillable_admin_fee_percent
                                      "
                                    />
                                  </div>
                                  <div class="col-md-2">
                                    <input
                                      v-uppercase
                                      type="number"
                                      disabled
                                      class="form-control"
                                      placeholder="Rebillable Admin Fee %"
                                      v-model="
                                        form.rebillable_admin_fee_percent
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="col-sm-3 control-label"
                                  >Monthly M&R Allowance</label
                                >
                                <div class="col-md-5">
                                  <input
                                    v-uppercase
                                    type="number"
                                    class="form-control"
                                    placeholder="Monthly M&R Allowance"
                                    v-model="form.rebillable_admin_fee_percent"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="Inv_Items"
                          role="tabpanel"
                          aria-labelledby="Inv_Items-tab"
                        >
                          <div class="editable-table-wrapper">
                            <br />
                            <table class="table table-responsive">
                              <thead>
                                <tr>
                                  <th>Invoice No.</th>
                                  <th>Date</th>
                                  <th>INTHC</th>
                                  <th>Voyage</th>
                                  <th>Port</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 col-xs-3">
                    <h4>Summary</h4>
                    <p>Invoices</p>
                    <p>Broker(s)</p>
                    <p>Voyage(s)</p>
                    <p>Attachment(s)</p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div class="row">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="Remarks-tab"
                            data-toggle="tab"
                            href="#Remarks"
                            role="tab"
                            aria-controls="Remarks"
                            aria-selected="true"
                            >Remarks</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="Notes_to_Operations-tab"
                            data-toggle="tab"
                            href="#Notes_to_Operations"
                            role="tab"
                            aria-controls="Notes_to_Operations"
                            aria-selected="false"
                            >Notes to Operations</a
                          >
                        </li>
                      </ul>
                    </div>
                    <br />
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade"
                        id="Remarks"
                        role="tabpanel"
                        aria-labelledby="Remarks-tab"
                      >
                        <textarea
                          class="form-control"
                          v-model="form.bunkering_details"
                        ></textarea>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >User Group</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.user_group"
                              :suggestions="user_group"
                              placeholder="Select User Group"
                            ></my-select>
                            <span class="error-block">{{
                              errors.user_group
                            }}</span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">User</label>
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.user"
                              :suggestions="user"
                              placeholder="Select User"
                            ></my-select>
                            <span class="error-block">{{ errors.user }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Notes_to_Operations"
                        role="tabpanel"
                        aria-labelledby="Notes_to_Operations-tab"
                      >
                        <textarea
                          class="form-control"
                          v-model="form.bunkering_details"
                        ></textarea>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"
                            >User Group*</label
                          >
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.user_group"
                              :suggestions="user_group"
                              placeholder="Select User Group"
                            ></my-select>
                            <span class="error-block">{{
                              errors.user_group
                            }}</span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">User</label>
                          <div class="col-sm-9">
                            <my-select
                              :selection.sync="form.user"
                              :suggestions="user"
                              placeholder="Select User"
                            ></my-select>
                            <span class="error-block">{{ errors.user }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
