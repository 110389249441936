var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_vm._m(0),_c('div',{staticClass:"tab-struct custom-tab-1"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist","id":"myTabs_7"}},_vm._l((_vm.form.voyage_laytimes.filter(
          function (voyageLaytime) { return voyageLaytime.position == _vm.count && voyageLaytime.port_name_id; }
        )),function(voyageLaytime,vl){return _c('li',{key:("voyageLaytime" + _vm.count + vl),class:vl == 0 ? 'active' : '',attrs:{"role":"presentation"}},[_c('a',{attrs:{"aria-expanded":"true","data-toggle":"tab","role":"tab","id":("port_activity_tab_" + _vm.count + vl),"href":("#port_activity_" + _vm.count + vl)}},[_vm._v(_vm._s(voyageLaytime.port_name ? voyageLaytime.port_name.port_name : ""))])])}),0),_c('div',{staticClass:"tab-content",attrs:{"id":"`myTabContent_7`"}},_vm._l((_vm.form.voyage_laytimes.filter(
          function (voyageLaytime) { return voyageLaytime.position == _vm.count && voyageLaytime.port_name_id; }
        )),function(voyageLaytime,vl){return _c('div',{key:("voyageLaytime" + _vm.count + vl),class:vl == 0 ? 'tab-pane fade in active' : 'tab-pane fade in',attrs:{"role":"tabpanel","id":("port_activity_" + _vm.count + vl)}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"editable-table-wrapper"},[_c('table',{staticClass:"table table-striped",staticStyle:{"height":"400px"}},[_vm._m(1,true),_c('tbody',[_vm._l((voyageLaytime.voyage_laytime_port_activities),function(voyageLaytimePortActivity,vlpa){return _c('tr',{key:("voyageLaytimePortActivity" + vlpa)},[_c('td',{on:{"click":function($event){return _vm.deleteActivity(
                          voyageLaytime,
                          voyageLaytimePortActivity
                        )}}},[_c('i',{staticClass:"fa fa-times-circle-o",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(vlpa + 1)+". ")]),_c('td',[_c('my-select',{attrs:{"maxWidth":"YES","disableFormControl":"true","selection":voyageLaytimePortActivity.port_activity_id,"suggestions":_vm.portActivities,"placeholder":"Select activity"},on:{"update:selection":function($event){return _vm.$set(voyageLaytimePortActivity, "port_activity_id", $event)}}})],1),_c('td',[_c('my-datepicker',{attrs:{"disableFormControl":true,"date":voyageLaytimePortActivity.from_date_time,"placeholder":"From date and time","myWidth":"200px"},on:{"update:date":function($event){return _vm.$set(voyageLaytimePortActivity, "from_date_time", $event)},"change":_vm.onLaytimeUpdated}})],1),_c('td',[_c('my-datepicker',{attrs:{"disableFormControl":true,"date":voyageLaytimePortActivity.to_date_time,"placeholder":"To date and time","myWidth":"200px"},on:{"update:date":function($event){return _vm.$set(voyageLaytimePortActivity, "to_date_time", $event)},"change":_vm.onLaytimeUpdated}})],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("dhm")(voyageLaytimePortActivity.duration))+" ")]),_c('td',[_c('input',{directives:[{name:"uppercase",rawName:"v-uppercase"},{name:"model",rawName:"v-model",value:(voyageLaytimePortActivity.percent),expression:"voyageLaytimePortActivity.percent"}],style:(voyageLaytimePortActivity.percent != 100
                            ? 'color: blue'
                            : 'color: black'),attrs:{"placeholder":"%"},domProps:{"value":(voyageLaytimePortActivity.percent)},on:{"change":_vm.onLaytimeUpdated,"input":function($event){if($event.target.composing){ return; }_vm.$set(voyageLaytimePortActivity, "percent", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"uppercase",rawName:"v-uppercase"},{name:"model",rawName:"v-model",value:(voyageLaytimePortActivity.remarks),expression:"voyageLaytimePortActivity.remarks"}],staticStyle:{"width":"150px !important"},attrs:{"placeholder":"Remarks"},domProps:{"value":(voyageLaytimePortActivity.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(voyageLaytimePortActivity, "remarks", $event.target.value)}}})]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dhm")(voyageLaytimePortActivity.deduction))+" ")])])}),_c('tr',[_c('td',{attrs:{"colspan":"12","align":"center"},on:{"click":function($event){return _vm.addEmptyActivity(voyageLaytime)}}},[_vm._v(" Add New Row ")])])],2)])])])])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('u',[_vm._v("Port Activities")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Day")]),_c('th',[_vm._v("Activity")]),_c('th',[_vm._v("From Date/Time")]),_c('th',[_vm._v("To Date/Time")]),_c('th',[_vm._v("Duration")]),_c('th',[_vm._v("%")]),_c('th',[_vm._v("Remarks")]),_c('th',[_vm._v("Deduction")])])])}]

export { render, staticRenderFns }