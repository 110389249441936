<template>
  <section>
    <h5><u>Port Activities</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Activity</th>
            <th>Reason Type</th>
            <th>Remarks</th>
            <th>Date - Time</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              portActivity, pa
            ) in voyageItenary.voyage_itenary_port_activities"
            :key="`portActivity${pa}`"
          >
            <td @click="deletePortActivity(portActivity)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ pa + 1 }}.
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="portActivity.port_activity_id"
                :suggestions="portActivities"
                placeholder="Select activity"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="portActivity.reason_type_id"
                :suggestions="reasonTypes"
                placeholder="Select reason"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                placeholder="Remarks"
                v-model="portActivity.remarks"
              />
            </td>
            <td>
              <my-datepicker
                :disableFormControl="true"
                :date.sync="portActivity.date_from"
                placeholder="Enter date and time"
                myWidth="200px"
              ></my-datepicker>
            </td>
          </tr>
          <tr>
            <td colspan="10" align="center" @click="addEmptyPortActivity()">
              Add New Row
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  mounted() {},
  props: ["voyageItenary", "portActivities", "reasonTypes"],
  methods: {
    addEmptyPortActivity() {
      this.voyageItenary.voyage_itenary_port_activities.push({
        port_activity_id: "",
        reason_type_id: "",
        remarks: "",
        date_from: "",
      });
    },
    deletePortActivity(portActivity) {
      this.voyageItenary.voyage_itenary_port_activities.splice(
        this.voyageItenary.voyage_itenary_port_activities.indexOf(portActivity),
        1
      );
    },
  },
};
</script>

<style scoped>
table td input {
  width: auto !important;
}
</style>