<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/vessels"></back-button>
            Create a new vessel
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/vessels">Vessels</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Name*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Vessel Name"
                                v-model="form.vessel_name"
                              />
                              <span class="error-block">{{
                                errors.vessel_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Code*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Vessel Code"
                                v-model="form.vessel_code"
                              />
                              <span class="error-block">{{
                                errors.vessel_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel DWT*</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Vessel DWT"
                                v-model="form.dwt_mt"
                              />
                              <span class="error-block">{{
                                errors.dwt_mt
                              }}</span>
                            </div>
                            <div class="col-sm-4">MT</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Type Code</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.type_code_id"
                                :suggestions="typeCodes"
                                placeholder="Enter Type Code"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Built Year</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Built Year"
                                v-model="form.built_year"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >SW Summer Draft</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="SW Summer Draft"
                                v-model="form.sw_summer_draft_m"
                              />
                            </div>
                            <div class="col-sm-4">M</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ownsership</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.ownership_id"
                                :suggestions="ownerships"
                                placeholder="Enter Ownership"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Type*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_type_id"
                                :suggestions="vesselTypes"
                                placeholder="Enter Vessel Type"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_type_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">TPC</label>
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="TPC"
                                v-model="form.tpc"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >IMO Number</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="IMO Number"
                                v-model="form.imo_no"
                              />
                              <span class="error-block">{{
                                errors.imo_no
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Fleet</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_fleet_id"
                                :suggestions="vesselFleets"
                                placeholder="Enter Vessel Fleet"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_fleet_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Capacity(Bale)</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Capacity(Bale)"
                                v-model="form.bale_capacity_ft3"
                              />
                              <span class="error-block">{{
                                errors.bale_capacity_ft3
                              }}</span>
                            </div>
                            <div class="col-sm-4">ft3</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Daily Cost</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Daily Cost"
                                v-model="form.daily_cost"
                              />
                              <span class="error-block">{{
                                errors.daily_cost
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trade Area</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.trade_area_id"
                                :suggestions="tradeAreas"
                                placeholder="Enter Trade Area"
                              ></my-select>
                              <span class="error-block">{{
                                errors.trade_area_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Capacity(Grain)</label
                            >
                            <div class="col-sm-5">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Capacity(Grain)"
                                v-model="form.grain_capacity_ft3"
                              />
                              <span class="error-block">{{
                                errors.grain_capacity_ft3
                              }}</span>
                            </div>
                            <div class="col-sm-4">ft3</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laden Speed*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Laden Speed"
                                v-model="form.laden_speed"
                              />
                              <span class="error-block">{{
                                errors.laden_speed
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Owner</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.owner_id"
                                :suggestions="owners"
                                placeholder="Enter Vessel Owner"
                              ></my-select>
                              <span class="error-block">{{
                                errors.owner_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Type Corr.</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Vessel Type Corr."
                                v-model="form.vessel_type_corr"
                              />
                              <span class="error-block">{{
                                errors.vessel_type_corr
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ballast Speed*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Ballast Speed"
                                v-model="form.ballast_speed"
                              />
                              <span class="error-block">{{
                                errors.ballast_speed
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Class Society</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.class_society_id"
                                :suggestions="classSocieties"
                                placeholder="Enter Class Society"
                              ></my-select>
                              <span class="error-block">{{
                                errors.class_society_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Scrubber</label
                            >
                            <div class="col-sm-5">
                              <my-select
                                :selection.sync="form.scrubber"
                                :suggestions="scrubbers"
                                placeholder="Select Scrubber"
                              ></my-select>
                              <span class="error-block">{{
                                errors.scrubber
                              }}</span>
                            </div>
                            <div class="col-sm-4">ft3</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_active"
                                placeholder="Is Active"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5>In Port Consumption Table (per Day)</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Type</th>
                              <th>Unit</th>
                              <th>Grade</th>
                              <th>Capacity</th>
                              <th>Margin</th>
                              <th>Loading</th>
                              <th>Discharge</th>
                              <th>Idle/On</th>
                              <th>Idle/Off</th>
                              <th>Heat</th>
                              <th>Heat+</th>
                              <th>Heat++</th>
                              <th>IGS</th>
                              <th>Clean</th>
                              <th>Maneuver</th>
                              <th>Auxil</th>
                              <th>Cool</th>
                              <th>Boiler</th>
                              <th>Incinerator</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                vesselInPortConsumption, ar
                              ) in form.vessel_in_port_consumptions"
                              :key="`vesselInPortConsumption${ar}`"
                            >
                              <td
                                @click="
                                  deleteVesselInPortConsumption(
                                    vesselInPortConsumption
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select maxWidth="YES" @change="updateVesselSpeedConsumptionFuels" disableFormControl="true" :selection.sync="vesselInPortConsumption.fuel_type_id" :suggestions="fuelTypes" placeholder="Enter Fuel Type"></my-select>
                              </td>
                              <td>
                                <my-select maxWidth="YES" disableFormControl="true" :selection.sync="vesselInPortConsumption.unit_id" :suggestions="units" placeholder="Enter Unit"></my-select>
                              </td>
                              <td>
                                <my-select maxWidth="YES" disableFormControl="true" :selection.sync="vesselInPortConsumption.fuel_grade_id" :suggestions="fuelGrades" placeholder="Enter Fuel Grade"></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Capacity"
                                  v-model="vesselInPortConsumption.capacity"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Margin"
                                  v-model="vesselInPortConsumption.margin"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Loading"
                                  v-model="vesselInPortConsumption.loading"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Discharge"
                                  v-model="vesselInPortConsumption.discharge"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Idle On"
                                  v-model="vesselInPortConsumption.idle_on"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Idle Off"
                                  v-model="vesselInPortConsumption.idle_off"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Heat"
                                  v-model="vesselInPortConsumption.heat"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Heat+"
                                  v-model="vesselInPortConsumption.heat_plus"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Heat++"
                                  v-model="
                                    vesselInPortConsumption.heat_plus_plus
                                  "
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="IGS"
                                  v-model="vesselInPortConsumption.igs"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Clean"
                                  v-model="vesselInPortConsumption.clean"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Maneuver"
                                  v-model="vesselInPortConsumption.maneuver"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Auxil"
                                  v-model="vesselInPortConsumption.auxil"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Cool"
                                  v-model="vesselInPortConsumption.cool"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Boiler"
                                  v-model="vesselInPortConsumption.boiler"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Incinerator"
                                  v-model="vesselInPortConsumption.incinerator"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="10"
                                align="center"
                                @click="addEmptyVesselInPortConsumption()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="10"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Speed Consumption Table (per Day)</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr
                              v-if="form.vessel_speed_consumptions.length == 0"
                            >
                              <th>Sr. No.</th>
                              <th>Speed</th>
                              <th>B/L</th>
                              <th>Engine Load</th>
                              <th>Consumption Type</th>
                            </tr>
                            <tr v-else>
                              <th>Sr. No.</th>
                              <th>Speed</th>
                              <th>B/L</th>
                              <th>Engine Load</th>
                              <th
                                v-for="(vesselSpeedConsumptionFuel, ar) in form
                                  .vessel_speed_consumptions[0]
                                  .vessel_speed_consumption_fuels"
                                :key="`vesselSpeedConsumption${ar}`"
                              >
                                {{ vesselSpeedConsumptionFuel.fuel_type }}
                              </th>
                              <th>Consumption Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                vesselSpeedConsumption, ar
                              ) in form.vessel_speed_consumptions"
                              :key="`contact${ar}`"
                            >
                              <td
                                @click="
                                  deleteVesselSpeedConsumption(
                                    vesselSpeedConsumption
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Capacity"
                                  v-model="vesselSpeedConsumption.speed"
                                />
                              </td>
                              <td>
                                <my-select maxWidth="YES" disableFormControl="true" :selection.sync="vesselSpeedConsumption.b_l" :suggestions="b_ls" placeholder="Ballast/Laden"></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Loading"
                                  v-model="vesselSpeedConsumption.engine_load"
                                />
                              </td>
                              <td
                                v-for="(
                                  vesselSpeedConsumptionFuel, ar
                                ) in vesselSpeedConsumption.vessel_speed_consumption_fuels"
                                :key="`vesselSpeedConsumption${ar}`"
                              >
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="vesselSpeedConsumptionFuel.value"
                                />
                              </td>
                              <td>
                                <my-select maxWidth="YES" disableFormControl="true" :selection.sync="vesselSpeedConsumption.consumption_type_id" :suggestions="consumptionTypes" placeholder="Enter Consumption Type"></my-select>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="10"
                                align="center"
                                @click="addEmptyVesselSpeedConsumption()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="10"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Variables and Safety Margins</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Constants Sea
                              </label>
                              <div class="col-sm-5">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Constants Sea"
                                  v-model="form.sea_constant_mt"
                                />
                                <span class="error-block">{{
                                  errors.sea_constant_mt
                                }}</span>
                              </div>
                              <div class="col-sm-1">MT</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label class="col-sm-3 control-label"
                              >Beaufort</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                disableFormControl="true"
                                :selection.sync="form.beaufort_id"
                                :suggestions="Beauforts"
                                placeholder="Enter Beaufort"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Constants Lakes
                              </label>
                              <div class="col-sm-5">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Constants Lakes"
                                  v-model="form.lake_constant_mt"
                                />
                                <span class="error-block">{{
                                  errors.lake_constant_mt
                                }}</span>
                              </div>
                              <div class="col-sm-1">MT</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label class="col-sm-3 control-label"
                              >Sea State</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                disableFormControl="true"
                                :selection.sync="form.sea_state_id"
                                :suggestions="SeaStates"
                                placeholder="Enter Sea State"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Fresh Water
                              </label>
                              <div class="col-sm-5">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Fresh Water"
                                  v-model="form.fresh_water_mt"
                                />
                                <span class="error-block">{{
                                  errors.fresh_water_mt
                                }}</span>
                              </div>
                              <div class="col-sm-1">MT</div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label class="col-sm-3 control-label"
                              >Sea Swell</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                disableFormControl="true"
                                :selection.sync="form.sea_swell_id"
                                :suggestions="SeaSwells"
                                placeholder="Enter Sea Swell"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Total
                              </label>
                              <div class="col-sm-5">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Total"
                                  v-model="form.total_mt"
                                />
                                <span class="error-block">{{
                                  errors.total_mt
                                }}</span>
                              </div>
                              <div class="col-sm-1">MT</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="col-sm-3 control-label"
                                >Others
                              </label>
                              <div class="col-sm-5">
                                <input
                                  v-uppercase
                                  type="number"
                                  class="form-control"
                                  placeholder="Others"
                                  v-model="form.others_mt"
                                />
                                <span class="error-block">{{
                                  errors.others_mt
                                }}</span>
                              </div>
                              <div class="col-sm-1">MT</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        beaufort_id: "",
        sea_state_id: "",
        sea_swell_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        sea_constant_mt: 0.0,
        lake_constant_mt: 0.0,
        fresh_water_mt: 0.0,
        total_mt: 0.0,
        others_mt: 0.0,
        is_active: true,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      Beauforts: [],
      SeaStates: [],
      SeaSwells: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
      masters.beauforts.forEach((beaufort) => {
        this.Beauforts.push({
          id: beaufort.id,
          name: beaufort.description + " - " + beaufort.code,
        });
      });
      masters.seastates.forEach((seastate) => {
        this.SeaStates.push({
          id: seastate.id,
          name: seastate.description + " - " + seastate.code,
        });
      });
      masters.seaswells.forEach((seaswell) => {
        this.SeaSwells.push({
          id: seaswell.id,
          name: seaswell.description + " - " + seaswell.code,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions =
        this.form.vessel_in_port_consumptions.filter(
          (ar) => ar != vesselInPortConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions =
        this.form.vessel_speed_consumptions.filter(
          (ar) => ar != vesselSpeedConsumption
        );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        // To check if the specific fuel is deleted from the vessel_in_port_consumptions
        for (const vscf of vsc.vessel_speed_consumption_fuels) {
          let vipc = this.form.vessel_in_port_consumptions.find(
            (vipc) => vipc.fuel_type_id == vscf.fuel_type_id
          );
          if (!vipc)
            vsc.vessel_speed_consumption_fuels =
              vsc.vessel_speed_consumption_fuels.filter(
                (selectedVscf) => selectedVscf != vscf
              );
        }
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
