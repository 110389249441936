import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// layout
import AuthLayout from "../views/auth-layout/index.vue";
import UnAuthLayout from "../views/unauth-layout/index.vue";

import Home from "../views/home.vue";

import Settings from "../views/settings.vue";

// Auth
import Login from "@/views/auth/login.vue";
import Register from "@/views/auth/register.vue";
import ForgotPassword from "@/views/auth/forgot-password.vue";

// Profile
import Profile from "@/views/profile/index.vue";

//M1 Address Types
import AddressTypes from "@/views/address-types/index.vue";
import AddressTypeCreate from "@/views/address-types/create.vue";
import AddressTypeId from "@/views/address-types/id.vue";

//M2 Currencies
import Currencies from "@/views/currencies/index.vue";
import CurrencyCreate from "@/views/currencies/create.vue";
import CurrencyId from "@/views/currencies/id.vue";

//M3 Countries
import CountryUpload from "@/views/countries/upload.vue";
import Countries from "@/views/countries/index.vue";
import CountryCreate from "@/views/countries/create.vue";
import CountryId from "@/views/countries/id.vue";

//M4 Units
import Units from "@/views/units/index.vue";
import UnitCreate from "@/views/units/create.vue";
import UnitId from "@/views/units/id.vue";

//M5 Class Societies
import ClassSocieties from "@/views/class-societies/index.vue";
import ClassSocietyCreate from "@/views/class-societies/create.vue";
import ClassSocietyId from "@/views/class-societies/id.vue";

//M6 Fuel Types
import FuelTypes from "@/views/fuel-types/index.vue";
import FuelTypeCreate from "@/views/fuel-types/create.vue";
import FuelTypeId from "@/views/fuel-types/id.vue";

//M7 Vessel Types
import VesselTypes from "@/views/vessel-types/index.vue";
import VesselTypeCreate from "@/views/vessel-types/create.vue";
import VesselTypeId from "@/views/vessel-types/id.vue";

//M8 Fuel Zone Sets
import FuelZoneSets from "@/views/fuel-zone-sets/index.vue";
import FuelZoneSetCreate from "@/views/fuel-zone-sets/create.vue";
import FuelZoneSetId from "@/views/fuel-zone-sets/id.vue";

//M9 Fuel Zones
import FuelZones from "@/views/fuel-zones/index.vue";
import FuelZoneCreate from "@/views/fuel-zones/create.vue";
import FuelZoneId from "@/views/fuel-zones/id.vue";

//M10 Loadline Zones
import LoadlineZones from "@/views/loadline-zones/index.vue";
import LoadlineZoneCreate from "@/views/loadline-zones/create.vue";
import LoadlineZoneId from "@/views/loadline-zones/id.vue";

//M11 Loadline Zone Seasons
import LoadlineZoneSeasons from "@/views/loadline-zone-seasons/index.vue";
import LoadlineZoneSeasonCreate from "@/views/loadline-zone-seasons/create.vue";
import LoadlineZoneSeasonId from "@/views/loadline-zone-seasons/id.vue";

//M12 Reason Types
import ReasonTypes from "@/views/reason-types/index.vue";
import ReasonTypeCreate from "@/views/reason-types/create.vue";
import ReasonTypeId from "@/views/reason-types/id.vue";

//M13 Action Types
import ActionTypes from "@/views/action-types/index.vue";
import ActionTypeCreate from "@/views/action-types/create.vue";
import ActionTypeId from "@/views/action-types/id.vue";

//M14 Port Activities
import PortActivities from "@/views/port-activities/index.vue";
import PortActivityCreate from "@/views/port-activities/create.vue";
import PortActivityId from "@/views/port-activities/id.vue";

//M15 Port Areas
import PortAreas from "@/views/port-areas/index.vue";
import PortAreaCreate from "@/views/port-areas/create.vue";
import PortAreaId from "@/views/port-areas/id.vue";

//M16 Port Functions
import PortFunctions from "@/views/port-functions/index.vue";
import PortFunctionCreate from "@/views/port-functions/create.vue";
import PortFunctionId from "@/views/port-functions/id.vue";

//M17 Trade Areas
import TradeAreas from "@/views/trade-areas/index.vue";
import TradeAreaCreate from "@/views/trade-areas/create.vue";
import TradeAreaId from "@/views/trade-areas/id.vue";

//M18 Beaufort Wind Force Scales
import BeaufortWindForceScales from "@/views/beaufort-wind-force-scales/index.vue";
import BeaufortWindForceScaleCreate from "@/views/beaufort-wind-force-scales/create.vue";
import BeaufortWindForceScaleId from "@/views/beaufort-wind-force-scales/id.vue";

//M19 Delay Types
import DelayTypes from "@/views/delay-types/index.vue";
import DelayTypeCreate from "@/views/delay-types/create.vue";
import DelayTypeId from "@/views/delay-types/id.vue";

//M20 Delay Reasons
import DelayReasons from "@/views/delay-reasons/index.vue";
import DelayReasonCreate from "@/views/delay-reasons/create.vue";
import DelayReasonId from "@/views/delay-reasons/id.vue";

//M21 Sea States
import SeaStates from "@/views/sea-states/index.vue";
import SeaStateCreate from "@/views/sea-states/create.vue";
import SeaStateId from "@/views/sea-states/id.vue";

//M22 State of Swells
import StateOfSwells from "@/views/state-of-swells/index.vue";
import StateOfSwellCreate from "@/views/state-of-swells/create.vue";
import StateOfSwellId from "@/views/state-of-swells/id.vue";

//M23 Fuel Grades
import FuelGrades from "@/views/fuel-grades/index.vue";
import FuelGradeCreate from "@/views/fuel-grades/create.vue";
import FuelGradeId from "@/views/fuel-grades/id.vue";

//M24 Fuel Consumption Categories
import FuelConsumptionCategories from "@/views/fuel-consumption-categories/index.vue";
import FuelConsumptionCategoryCreate from "@/views/fuel-consumption-categories/create.vue";
import FuelConsumptionCategoryId from "@/views/fuel-consumption-categories/id.vue";

//Port Upload
import PortUpload from "@/views/port-names/upload.vue";

//Cargo Name Upload
import CargoNameUpload from "@/views/cargo-names/upload.vue";

//Address Upload
import AddressUpload from "@/views/addresses/upload.vue";

//M25 Pool Point Types
import PoolPointTypes from "@/views/pool-point-types/index.vue";
import PoolPointTypeCreate from "@/views/pool-point-types/create.vue";
import PoolPointTypeId from "@/views/pool-point-types/id.vue";

//M26 Vessel Fleets
import VesselFleets from "@/views/vessel-fleets/index.vue";
import VesselFleetCreate from "@/views/vessel-fleets/create.vue";
import VesselFleetId from "@/views/vessel-fleets/id.vue";

//M27 Port Types
import PortTypes from "@/views/port-types/index.vue";
import PortTypeCreate from "@/views/port-types/create.vue";
import PortTypeId from "@/views/port-types/id.vue";

//M28 Timezones
import Timezones from "@/views/timezones/index.vue";
import TimezoneCreate from "@/views/timezones/create.vue";
import TimezoneId from "@/views/timezones/id.vue";

//M29 Region Codes
import RegionCodes from "@/views/region-codes/index.vue";
import RegionCodeCreate from "@/views/region-codes/create.vue";
import RegionCodeId from "@/views/region-codes/id.vue";

//M30 Type Codes
import TypeCodes from "@/views/type-codes/index.vue";
import TypeCodeCreate from "@/views/type-codes/create.vue";
import TypeCodeId from "@/views/type-codes/id.vue";

//M31 Speed Consumption types
import SpeedConsumptionTypes from "@/views/speed-consumption-types/index.vue";
import SpeedConsumptionTypeCreate from "@/views/speed-consumption-types/create.vue";
import SpeedConsumptionTypeId from "@/views/speed-consumption-types/id.vue";

//M32 Ownerships
import Ownerships from "@/views/ownerships/index.vue";
import OwnershipCreate from "@/views/ownerships/create.vue";
import OwnershipId from "@/views/ownerships/id.vue";

//M33 FreightTypes
import FreightTypes from "@/views/freight-types/index.vue";
import FreightTypeCreate from "@/views/freight-types/create.vue";
import FreightTypeId from "@/views/freight-types/id.vue";

//M34 CargoStatuses
import CargoStatuses from "@/views/cargo-statuses/index.vue";
import CargoStatusCreate from "@/views/cargo-statuses/create.vue";
import CargoStatusId from "@/views/cargo-statuses/id.vue";

//M35 UnitOfMeasureOfRates
import UnitOfMeasureOfRates from "@/views/unit-of-measure-of-rates/index.vue";
import UnitOfMeasureOfRateCreate from "@/views/unit-of-measure-of-rates/create.vue";
import UnitOfMeasureOfRateId from "@/views/unit-of-measure-of-rates/id.vue";

//M36 LaytimeTerms
import LaytimeTerms from "@/views/laytime-terms/index.vue";
import LaytimeTermCreate from "@/views/laytime-terms/create.vue";
import LaytimeTermId from "@/views/laytime-terms/id.vue";

//M37 PaymentTerms
import PaymentTerms from "@/views/payment-terms/index.vue";
import PaymentTermCreate from "@/views/payment-terms/create.vue";
import PaymentTermId from "@/views/payment-terms/id.vue";

//M38 ContractTypes
import ContractTypes from "@/views/contract-types/index.vue";
import ContractTypeCreate from "@/views/contract-types/create.vue";
import ContractTypeId from "@/views/contract-types/id.vue";

//M39 TcInStatuss
import TcInStatuses from "@/views/tc-in-statuses/index.vue";
import TcInStatusCreate from "@/views/tc-in-statuses/create.vue";
import TcInStatusId from "@/views/tc-in-statuses/id.vue";

//M40 PricingCodes
import PricingCodes from "@/views/pricing-codes/index.vue";
import PricingCodeCreate from "@/views/pricing-codes/create.vue";
import PricingCodeId from "@/views/pricing-codes/id.vue";

//M93 ExpenseRevenueGroups
import ExpenseRevenueGroups from "@/views/expense-revenue-groups/index.vue";
import ExpenseRevenueGroupCreate from "@/views/expense-revenue-groups/create.vue";
import ExpenseRevenueGroupId from "@/views/expense-revenue-groups/id.vue";

//M41 ExpenseRevenueCodes
import ExpenseRevenueCodes from "@/views/expense-revenue-codes/index.vue";
import ExpenseRevenueCodeCreate from "@/views/expense-revenue-codes/create.vue";
import ExpenseRevenueCodeId from "@/views/expense-revenue-codes/id.vue";

//M42 OperationTypes
import OperationTypes from "@/views/operation-types/index.vue";
import OperationTypeCreate from "@/views/operation-types/create.vue";
import OperationTypeId from "@/views/operation-types/id.vue";

// ------------------------------------------------------------------

//F1 Users
import Users from "@/views/users/index.vue";
import UserCreate from "@/views/users/create.vue";
import UserId from "@/views/users/id.vue";

//F8 Addresses
import Addresses from "@/views/addresses/index.vue";
import AddressCreate from "@/views/addresses/create.vue";
import AddressId from "@/views/addresses/id.vue";

//F11 Cargo Groups
import CargoGroups from "@/views/cargo-groups/index.vue";
import CargoGroupCreate from "@/views/cargo-groups/create.vue";
import CargoGroupId from "@/views/cargo-groups/id.vue";

//F12 Cargo Names
import CargoNames from "@/views/cargo-names/index.vue";
import CargoNameCreate from "@/views/cargo-names/create.vue";
import CargoNameId from "@/views/cargo-names/id.vue";

//F13 Port Names
import PortNames from "@/views/port-names/index.vue";
import PortNameCreate from "@/views/port-names/create.vue";
import PortNameId from "@/views/port-names/id.vue";

//F16 Vessels
import VesselUpload from "@/views/vessels/upload.vue";
import Vessels from "@/views/vessels/index.vue";
import VesselCreate from "@/views/vessels/create.vue";
import VesselId from "@/views/vessels/id.vue";

//F20 Cargos
import Cargos from "@/views/cargos/index.vue";
import CargoUpload from "@/views/cargos/upload.vue";
import CargoCreate from "@/views/cargos/create.vue";
import CargoId from "@/views/cargos/id.vue";

//F24 TcIns
import TcIns from "@/views/tc-ins/index.vue";
import TcInCreate from "@/views/tc-ins/create.vue";
import TcInId from "@/views/tc-ins/id.vue";
import TcInMakePayment from "@/views/tc-ins/make-payment.vue";
import TcInCreateAddDetails from "@/views/tc-ins/craete-add-details.vue";

//F28 Estimates
import Estimates from "@/views/estimates/index.vue";
import EstimateCreate from "@/views/estimates/create.vue";
import EstimateId from "@/views/estimates/id.vue";

//F30 QuickEstimateId
import QuickEstimates from "@/views/quick-estimate/index.vue";
import QuickEstimateCreate from "@/views/quick-estimate/create.vue";
import QuickEstimateId from "@/views/quick-estimate/id.vue";

//F19 Fixtures
import Fixtures from "@/views/fixtures/index.vue";
import FixtureCreate from "@/views/fixtures/create.vue";
import FixtureId from "@/views/fixtures/id.vue";

// Voyage List
import VoyageList from "@/views/voyage/index.vue";
import VoyageListCreate from "@/views/voyage/create.vue";
import VoyageListId from "@/views/voyage/id.vue";

// Voyage List 1
import VoyageList1 from "@/views/voyage/create1.vue";

// Distances
import Distances from "@/views/distances/index.vue";

// Freight
import FreightInvoice from "@/views/freights/freight-invoice.vue";

// Other Revenue
import OtherRevenueExpense from "@/views/other-revenues/other-revenues-expenses.vue";
import DeviationAnalysis from "@/views/other-revenues/deviation-analysis.vue";
import ProfitShare from "@/views/other-revenues/profit-share.vue";

// Laytimes
import LaytimeEstimatedDemurrage from "@/views/laytimes/estimated-demurrage.vue";
import LaytimeCalculationSetup from "@/views/laytimes/laytime-calculation-setup.vue";
import LaytimeCalculationARClaim from "@/views/laytimes/laytime-calculation-ar-claim.vue";

// Commisions
import FreightCommission from "@/views/commissions/freight-commission.vue";
import TCINCommission from "@/views/commissions/tc-in-commission.vue";
import ClaimCommission from "@/views/commissions/claim-commission.vue";

// Bunkers
import VoyageBunker from "@/views/bunkers/voyage-bunker.vue";
import BunkerLifting from "@/views/bunkers/bunker-lifting.vue";
import BunkerNewRequirement from "@/views/bunkers/bunker-new-requirements.vue";

// Delays
import Delay from "@/views/delay.vue";

// F16 Sites
import Sites from "@/views/sites/index.vue";
import SiteCreate from "@/views/sites/create.vue";
import SiteId from "@/views/sites/id.vue";

// Bunker List
import BunkerList from "@/views/bunker/index.vue";
import BunkerListCreate from "@/views/bunker/create.vue";
import BunkerListId from "@/views/bunker/id.vue";

// TC Out
import TcOuts from "@/views/tc-outs/index.vue";
import TcOutCreate from "@/views/tc-outs/create.vue";
import TcOutId from "@/views/tc-outs/id.vue";

// Masters
import LaytimeToCommence from "@/views/masters/laytime-to-commences.vue";
import LaytimeType from "@/views/masters/laytime-types.vue";
import LoadingCost from "@/views/masters/loading-costs.vue";
import NorToTender from "@/views/masters/nor-to-tenders.vue";
import OtherLoadingTerm from "@/views/masters/other-loading-terms.vue";
import ShiftingTerm from "@/views/masters/shifting-terms.vue";
import TimeToTender from "@/views/masters/time-to-tenders.vue";
import TimeUsed from "@/views/masters/time-used.vue";
import WorkingDay from "@/views/masters/working-days.vue";
import BunkerPurchaseType from "@/views/masters/bunker-purchase-types.vue";
import BunkerDeliveryType from "@/views/masters/bunker-delivery-types.vue";
import CharterPartyForm from "@/views/masters/charter-party-forms.vue";
import ExtraFrieghtTerm from "@/views/masters/extra-freight-terms.vue";
import LdRateStandard from "@/views/masters/ld-rate-standards.vue";
import StandardParagraph from "@/views/masters/standard-paragraphs.vue";
import AccountPayable from "@/views/masters/account-payable.vue";
import AccountReceivable from "@/views/masters/account-receivable.vue";
import Clearance from "@/views/masters/clearances.vue";
import InterBank from "@/views/masters/interbanks.vue";
import RetainedEarning from "@/views/masters/retained-earnings.vue";
import ExchangeDifference from "@/views/masters/exchange-differences.vue";
import InterCompany from "@/views/masters/inter-companies.vue";
import OpenMonth from "@/views/masters/open-months.vue";

// TC In Options
import Lifting from "@/views/tc-ins/options/lifting.vue";
import Purchase from "@/views/tc-ins/options/purchase.vue";

// TC In Commission
import TCCommission from "@/views/tc-ins/commissions/tc-commission.vue";
import TCinClaimCommission from "@/views/tc-ins/commissions/claim-commission.vue";

// Finance Section
// Transaction
import Transactions from "@/views/transactions/index.vue";
import TransactionCreate from "@/views/transactions/create.vue";
import TransactionId from "@/views/transactions/id.vue";

// Payable
import Payables from "@/views/payables/index.vue";
import PayablesNew from "@/views/payables/payables_new.vue";
import PayableCreate from "@/views/payables/create.vue";
import PayableId from "@/views/payables/id.vue";

// Tc Out Receivables
import TcoutReceivables from "@/views/tc-out-receivables/index.vue";
import TcoutReceivablesNew from "@/views/tc-out-receivables/tc_out_receivables_new.vue";
import TcoutReceivableId from "@/views/tc-out-receivables/id.vue";

// Receivables
import Receivables from "@/views/receivables/index.vue";
import ReceivableCreate from "@/views/receivables/create.vue";
import ReceivableId from "@/views/receivables/id.vue";

// Trial Balance
import TrialBalances from "@/views/trial-balance/index.vue";

// Vendor Statement
import VendorStatements from "@/views/vendor-statement/index.vue";

// invoices
// Create Voyage invoice
import createVoyageInvoice from "@/views/invoices/voyage-invoice/index.vue";

// Create Tc invoice
import TcInvoice from "@/views/invoices/tc-invoice/index.vue";
import CreateTcInvoice from "@/views/invoices/tc-invoice/create.vue";

// Create Bunker invoice
import CreateBunkerInvoice from "@/views/invoices/bunker-invoice/index.vue";

// Create Trade Settlement
import CreateTradeSettlement from "@/views/invoices/trade-settlement/index.vue";

// Create Trade Commission
import CreateTradeCommission from "@/views/invoices/trade-commission/index.vue";

// Create Rebill Invoice
import Rebill from "@/views/invoices/rebill-invoice/index.vue";

// Create Edit Invoice
import EditInvoice from "@/views/invoices/edit-invoice/index.vue";
import EditInvoiceCreate from "@/views/invoices/edit-invoice/create.vue";

// Create Post Invoice
import PostInvoice from "@/views/invoices/post-invoice/index.vue";
import PostInvoiceCreate from "@/views/invoices/post-invoice/create.vue";

// Create Approve Invoice
import ApproveInvoice from "@/views/invoices/approve-invoice/index.vue";
import ApproveInvoiceCreate from "@/views/invoices/approve-invoice/create.vue";

// finance => Transactions
// Transactions
import Transaction from "@/views/financials/transactions/transaction/index.vue";

// Payment and Receipt
import PaymentReceipt from "@/views/financials/transactions/payment-receipt/index.vue";

// Journal Entry
import JournalEntry from "@/views/financials/transactions/journal-entry/index.vue";

// Advanced payment
import AdvancedPayment from "@/views/financials/transactions/advanced-payment/index.vue";

// Operations
// Vessel Schedule
import VesselSchedule from "@/views/operations/vessel-schedule.vue";

// Port Schedule
import PortSchedule from "@/views/operations/port-schedule.vue";

// Fleet Map
import FleetMap from "@/views/operations/fleet-map.vue";

// Task
import Task from "@/views/operations/task-list.vue";

// alert
import Alert from "@/views/operations/alert.vue";

// onboard
import Onboard from "@/views/operations/onboard.vue";

// port calls
import PortCalls from "@/views/operations/port-calls.vue";

// form
import Form from "@/views/operations/form.vue";

// Freight Demurrage
// Freight Invoive
import FDFreightInvoice from "@/views/operations/freight-demurrages/freight-invoice.vue";

// Final Freight Statement
import FinalFreightStatement from "@/views/operations/freight-demurrages/final-freight-statement.vue";

// Laytime Calculator
import LaytimeCalculator from "@/views/operations/freight-demurrages/laytime-calculator.vue";

// Commission
import Commission from "@/views/operations/freight-demurrages/commission.vue";

// Bunker
// Bunker Requirement List
import BunkerRequirementList from "@/views/operations/bunkers/bunker-requirement-list.vue";
import BunkerRequirementCreate from "@/views/operations/bunkers/bunker-requirement-create.vue";

// Bunker Invoice
import BunkerInvoice from "@/views/operations/bunkers/bunker-invoice.vue";

// Bunker Purchase
import BunkerPurachase from "@/views/operations/bunkers/bunker-purchase-list.vue";

// Time Charter In
// Time Charter in List
import TcInList from "@/views/operations/time-charter-ins/time-charter-list.vue";

// Time Charter in Payment
import TcInPayment from "@/views/operations/time-charter-ins/time-charter-payment.vue";

// Time Charter in Payment Schedule
import TcInPaymentSchedule from "@/views/operations/time-charter-ins/tc-payment-schedule.vue";

// Commission
import TcCommission from "@/views/operations/time-charter-ins/commission.vue";

// Owner's Commission
import OwnerCommission from "@/views/operations/time-charter-ins/owner-commission.vue";

// Hire Statement
import TcHireStatement from "@/views/operations/time-charter-ins/hire-statement.vue";

// Bareboat Conatct In
import BareboatContactIn from "@/views/operations/time-charter-ins/bareboat-contract-in.vue";

// Bareboat Payment
import BareboatPayment from "@/views/operations/time-charter-ins/bareboat-payment.vue";

// Bareboat Payment Schedule
import BareboatPaymentSchedule from "@/views/operations/time-charter-ins/bareboat-payment-schedule.vue";

// Time Charter Out
// Time Charter Out List
import TcOutList from "@/views/operations/time-charter-outs/time-charter-out-list.vue";

// Time Charter Out Bill
import TcOutBill from "@/views/operations/time-charter-outs/time-charter-bill.vue";

// Time Charter Out Bill Schedule
import TcOutBillSchedule from "@/views/operations/time-charter-outs/tc-billing-schedule.vue";

// Time Charter Out Commission
import TcOutCommission from "@/views/operations/time-charter-outs/commission.vue";

// Time Charter Out Hire Statement
import TcOutHireStatement from "@/views/operations/time-charter-outs/hire-statement.vue";

// SetUp
// Bunker Purchase Type
import SetUpBunkerPurchaseType from "@/views/operations/set-ups/bunker-purchase-type.vue";

// Bunker Delivery Type
import SetUpBunkerDeliveryType from "@/views/operations/set-ups/bunker-delivery-type.vue";

// Define Send Form
import DefineSendForm from "@/views/operations/set-ups/define-send-form.vue";

// Port/Other Costs
// Port Advanced/DA
import PortAdvancedDA from "@/views/operations/port-other-costs/port-advanced-da.vue";

// Ledge Expenses Setup
import LedgeExpensesSetup from "@/views/operations/port-other-costs/ledge-expenses-setup.vue";

// Other Revenues/Expenses
import OtherRevenuesExpenses from "@/views/operations/port-other-costs/other-revenues-expenses.vue";

// Performance Reporting
// Bunker Inventory Accrual
import BunkerInventoryAccrual from "@/views/operations/performances-reportings/bunker-inventory-accrual.vue";

// Bunker Invoices Summary
import BunkerInvoicesSummary from "@/views/operations/performances-reportings/bunker-invoices-summary.vue";

// Cargo Loaded Dischared
import CargoLodedDischarged from "@/views/operations/performances-reportings/cargos-loaded-discharged.vue";

// Check Voyage P&L
import CheckVoyagePL from "@/views/operations/performances-reportings/check-voyage-p-l.vue";

// Demurrage Summary
import DemurrageSummary from "@/views/operations/performances-reportings/demurrage-summary.vue";

// EEOI Reporting
import EEOIReporting from "@/views/operations/performances-reportings/eeoi-reporting.vue";

// Fixure Cargo Summary
import FixureCargoSummary from "@/views/operations/performances-reportings/fixure-cargo-summary.vue";

// General Operation Report
import GeneralOperationReport from "@/views/operations/performances-reportings/general-operation-report.vue";

// On Off Hire Summary
import OnOffHireSummary from "@/views/operations/performances-reportings/on-off-hire-summary.vue";

// Operation Receivable
import OperationReceivable from "@/views/operations/performances-reportings/operation-receivable.vue";

// Outstanding Hire Payment Adjustment
import OutstandingHirePaymentAdjustment from "@/views/operations/performances-reportings/outstanding-hire-payment-adjustment.vue";

// Performance Analysis Report
import PerformanceAnalysisReport from "@/views/operations/performances-reportings/performance-analysis-report.vue";

// Port Expenses Rebillable Summary
import PortExpensesRebillableSummary from "@/views/operations/performances-reportings/port-expenses-rebillable-summary.vue";

// Port Expenses Summary
import PortExpensesSummary from "@/views/operations/performances-reportings/port-expenses-summary.vue";

// Port Production Report
import PortProductionReport from "@/views/operations/performances-reportings/port-production-report.vue";

// TCE Summary
import TCESummary from "@/views/operations/performances-reportings/tce-summary.vue";

// US Army Corps Of Engineer Report
import USEngineerReport from "@/views/operations/performances-reportings/us-army-corps-of-engineers-report.vue";

// Utilization Report
import UtilizationReport from "@/views/operations/performances-reportings/utilization-report.vue";

// Vessel Accraul Summary
import VesselAccrualSummary from "@/views/operations/performances-reportings/vessel-accrual-summary.vue";

// Vessel Bunker Period Report
import VesselBunkerPeriodReport from "@/views/operations/performances-reportings/vessel-bunker-period-report.vue";

// Vessel Bunker Summary
import VesselBunkerSummary from "@/views/operations/performances-reportings/vessel-bunker-summary.vue";

// Vessel Freight Summary
import VesselfreightSummary from "@/views/operations/performances-reportings/vessel-freight-summary.vue";

// Vessel P&L Summary
import VesselPLSummary from "@/views/operations/performances-reportings/vessel-p-l-summary.vue";

// Vessel Performance Report
import VesselPerformanceReport from "@/views/operations/performances-reportings/vessel-performance-report.vue";

// Vessel Position Report
import VesselPositionReport from "@/views/operations/performances-reportings/vessel-position-report.vue";

// WorldScale ATRS Route Report
import WorldScaleRoute from "@/views/operations/performances-reportings/worldscale-atrs-route-report.vue";

// Financials => Statements
// Account Period
import AccountPeriods from "@/views/financials/statements/account-periods/index.vue";
import AccountPeriodCreate from "@/views/financials/statements/account-periods/create.vue";
import AccountPeriodId from "@/views/financials/statements/account-periods/id.vue";

// Account Period
import AccountPeriodOpenMonths from "@/views/financials/statements/account-period-open-months/index.vue";
import AccountPeriodOpenMonthCreate from "@/views/financials/statements/account-period-open-months/create.vue";
import AccountPeriodOpenMonthId from "@/views/financials/statements/account-period-open-months/id.vue";

// Business Rule
import BusinessRules from "@/views/financials/statements/business-rules/index.vue";
import BusinessRuleCreate from "@/views/financials/statements/business-rules/create.vue";
import BusinessRuleId from "@/views/financials/statements/business-rules/id.vue";

// Charts Of Accounts
import ChartsOfAccounts from "@/views/financials/statements/charts-of-accounts/index.vue";
import ChartsOfAccountCreate from "@/views/financials/statements/charts-of-accounts/create.vue";
import ChartsOfAccountId from "@/views/financials/statements/charts-of-accounts/id.vue";

// Company Bank Links
import CompanyBankLinks from "@/views/financials/statements/company-bank-links/index.vue";
import CompanyBankLinkCreate from "@/views/financials/statements/company-bank-links/create.vue";
import CompanyBankLinkId from "@/views/financials/statements/company-bank-links/id.vue";

// Credit Accounts
import CreditAccounts from "@/views/financials/statements/credit-accounts/index.vue";
import CreditAccountCreate from "@/views/financials/statements/credit-accounts/create.vue";
import CreditAccountId from "@/views/financials/statements/credit-accounts/id.vue";

// Debit Accounts
import DebitAccounts from "@/views/financials/statements/debit-accounts/index.vue";
import DebitAccountCreate from "@/views/financials/statements/debit-accounts/create.vue";
import DebitAccountId from "@/views/financials/statements/debit-accounts/id.vue";

// Department
import Departments from "@/views/financials/statements/departments/index.vue";
import DepartmentCreate from "@/views/financials/statements/departments/create.vue";
import DepartmentId from "@/views/financials/statements/departments/id.vue";

// Ledger Code
import LedgerCodes from "@/views/financials/statements/ledger-codes/index.vue";
import LedgerCodeCreate from "@/views/financials/statements/ledger-codes/create.vue";
import LedgerCodeId from "@/views/financials/statements/ledger-codes/id.vue";

// Bank Link
import BankLink from "@/views/financials/statements/bank-link.vue";

// Business Rule
// import BusinessRule from "@/views/financials/statements/business-rule.vue";

// Chart Account
import ChartAccount from "@/views/financials/statements/chart-account.vue";

// Company Information
import CompanyInfo from "@/views/financials/statements/company-info.vue";

// Currency
import Currency from "@/views/financials/statements/currency.vue";

// Department
import Department from "@/views/financials/statements/department.vue";

// Document number
import DocumentNumber from "@/views/financials/statements/document-number.vue";

//Exchange Rate
import ExchangeRate from "@/views/financials/statements/exchange-rate.vue";

//Invoice Action
import InvoiceAction from "@/views/financials/statements/invoice-action.vue";

//Invoice Approval
import InvoiceApproval from "@/views/financials/statements/invoice-approval.vue";

//Invoice Realization
import InvoiceRealization from "@/views/financials/statements/invoice-realization.vue";

//Voyage P&L Variance Tolerance
import VoyagePLVarianceTolerance from "@/views/financials/statements/voyage-p-l-variance-tolerance.vue";

//Financials => Period Closing
// Monthly Accrrual
import MonthlyAccrual from "@/views/financials/period-closings/monthly-accrual.vue";

// Period FX Adjustment
import PeriodFXAdjustment from "@/views/financials/period-closings/period-fx-adjustment.vue";

// Time Charter Period journal
import TcPeriodJournal from "@/views/financials/period-closings/time-charter-period-journal.vue";

// Voyage Period JOURNAL
import VoyagePeriodJournal from "@/views/financials/period-closings/voyage-period-journal.vue";

//M77 Late Reasons
import LateReasons from "@/views/late-reasons/index.vue";
import LateReasonCreate from "@/views/late-reasons/create.vue";
import LateReasonId from "@/views/late-reasons/id.vue";

//M80 Reversal Reasons
import ReversalReasons from "@/views/reversal-reasons/index.vue";
import ReversalReasonCreate from "@/views/reversal-reasons/create.vue";
import ReversalReasonId from "@/views/reversal-reasons/id.vue";

//M78 Lines of Business
import LinesOfBusinesses from "@/views/lines-of-business/index.vue";
import LinesOfBusinessCreate from "@/views/lines-of-business/create.vue";
import LinesOfBusinessId from "@/views/lines-of-business/id.vue";

//M79 Paymenth Methods
import PaymentMethods from "@/views/payment-methods/index.vue";
import PaymentMethodCreate from "@/views/payment-methods/create.vue";
import PaymentMethodId from "@/views/payment-methods/id.vue";

//M81 & M82  Exchange Rates & Exchange rate Details
import ExchangeRates from "@/views/exchange-rates/index.vue";
import ExchangeRateCreate from "@/views/exchange-rates/create.vue";
import ExchangeRateId from "@/views/exchange-rates/id.vue";

//M83 & M84  Bunker Fuel Price & Bunker Fuel Price Indesxes
import BunkerFuelPrices from "@/views/bunker-fuel-prices/index.vue";
import BunkerFuelPriceCreate from "@/views/bunker-fuel-prices/create.vue";
import BunkerFuelPriceId from "@/views/bunker-fuel-prices/id.vue";

//M85  Bunker Hedging Values
import BunkerHedgingValues from "@/views/bunker-hedging-values/index.vue";
import BunkerHedgingValueCreate from "@/views/bunker-hedging-values/create.vue";
import BunkerHedgingValueId from "@/views/bunker-hedging-values/id.vue";

//M86  Cp Places
import CPPlaces from "@/views/cp-places/index.vue";
import CPPlaceCreate from "@/views/cp-places/create.vue";
import CPPlaceId from "@/views/cp-places/id.vue";

//M87  Holidays
import Holidays from "@/views/holidays/index.vue";
import HolidayCreate from "@/views/holidays/create.vue";
import HolidayId from "@/views/holidays/id.vue";

//M88  Lightering Types
import LighteringTypes from "@/views/lightering-types/index.vue";
import LighteringTypeCreate from "@/views/lightering-types/create.vue";
import LighteringTypeId from "@/views/lightering-types/id.vue";

//M89  Note Categories
import NoteCategories from "@/views/note-categories/index.vue";
import NoteCategoryCreate from "@/views/note-categories/create.vue";
import NoteCategoryId from "@/views/note-categories/id.vue";

//M90 & M91  Opa Rates & Opa Rates Details
import OpaRates from "@/views/opa-rates/index.vue";
import OpaRateCreate from "@/views/opa-rates/create.vue";
import OpaRateId from "@/views/opa-rates/id.vue";

// Fingerprint Spinner
import FingerprintSpinner from "@/components/fingerprint.vue";

// Invoice Pdf
import HirePDF from "@/views/invoice-pdfs/hire-payments.vue";
import FreightInvoicePDF from "@/views/invoice-pdfs/freight-invoice.vue";
import BunkerInvoicePDF from "@/views/invoice-pdfs/bunker-invoice.vue";
import LaytimeCalculationInvoicePDF from "@/views/invoice-pdfs/laytime-calculation-invoice-simple.vue";
import FinalInvoicePDF from "@/views/invoice-pdfs/final-invoice.vue";

// Voyage Payale
import VoyageOtherRevExpansesPayables from "@/views/voyage/voyage_payables/index.vue";
import VoyageOtherRevExpansesPayableId from "@/views/voyage/voyage_payables/id.vue";

// Voyage Receivable
import VoyageOtherRevExpansesReceivables from "@/views/voyage/voyage_receivables/index.vue";
import VoyageOtherRevExpansesReceivableId from "@/views/voyage/voyage_receivables/id.vue";

// Bank Statement
import BankStatements from "@/views/statements/bank-statement.vue";

// Payable/Receivable Statement
import PayableReceivableStatements from "@/views/statements/payable-receivable-statement.vue";

// import $ from 'jquery'
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "QuickEstimateCreate",
        component: QuickEstimateCreate,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      // Profile
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      //M1 Address types
      {
        path: "/address-types",
        name: "AddressTypes",
        component: AddressTypes,
      },
      {
        path: "/address-types/create",
        name: "AddressTypeCreate",
        component: AddressTypeCreate,
      },
      {
        path: "/address-types/:addressTypeId",
        name: "AddressTypeId",
        component: AddressTypeId,
      },
      //M2 Currencies
      {
        path: "/currencies",
        name: "Currencies",
        component: Currencies,
      },
      {
        path: "/currencies/create",
        name: "CurrencyCreate",
        component: CurrencyCreate,
      },
      {
        path: "/currencies/:currencyId",
        name: "CurrencyId",
        component: CurrencyId,
      },
      //M3 Countries
      {
        path: "/countries",
        name: "Countries",
        component: Countries,
      },
      {
        path: "/countries/create",
        name: "CountryCreate",
        component: CountryCreate,
      },
      {
        path: "/countries/upload",
        name: "CountryUpload",
        component: CountryUpload,
      },
      {
        path: "/countries/:countryId",
        name: "CountryId",
        component: CountryId,
      },

      //M4 Units
      {
        path: "/units",
        name: "Units",
        component: Units,
      },
      {
        path: "/units/create",
        name: "UnitCreate",
        component: UnitCreate,
      },
      {
        path: "/units/:unitId",
        name: "UnitId",
        component: UnitId,
      },
      //M5 ClassSocieties
      {
        path: "/class-societies",
        name: "ClassSocieties",
        component: ClassSocieties,
      },
      {
        path: "/class-societies/create",
        name: "ClassSocietyCreate",
        component: ClassSocietyCreate,
      },
      {
        path: "/class-societies/:classSocietyId",
        name: "ClassSocietyId",
        component: ClassSocietyId,
      },
      //M6 FuelTypes
      {
        path: "/fuel-types",
        name: "FuelTypes",
        component: FuelTypes,
      },
      {
        path: "/fuel-types/create",
        name: "FuelTypeCreate",
        component: FuelTypeCreate,
      },
      {
        path: "/fuel-types/:fuelTypeId",
        name: "FuelTypeId",
        component: FuelTypeId,
      },
      //M7 VesselTypes
      {
        path: "/vessel-types",
        name: "VesselTypes",
        component: VesselTypes,
      },
      {
        path: "/vessel-types/create",
        name: "VesselTypeCreate",
        component: VesselTypeCreate,
      },
      {
        path: "/vessel-types/:vesselTypeId",
        name: "VesselTypeId",
        component: VesselTypeId,
      },
      //M8 FuelZoneSets
      {
        path: "/fuel-zone-sets",
        name: "FuelZoneSets",
        component: FuelZoneSets,
      },
      {
        path: "/fuel-zone-sets/create",
        name: "FuelZoneSetCreate",
        component: FuelZoneSetCreate,
      },
      {
        path: "/fuel-zone-sets/:fuelZoneSetId",
        name: "FuelZoneSetId",
        component: FuelZoneSetId,
      },
      //M8 FuelZones
      {
        path: "/fuel-zones",
        name: "FuelZones",
        component: FuelZones,
      },
      {
        path: "/fuel-zones/create",
        name: "FuelZoneCreate",
        component: FuelZoneCreate,
      },
      {
        path: "/fuel-zones/:fuelZoneId",
        name: "FuelZoneId",
        component: FuelZoneId,
      },
      //M10 LoadlineZones
      {
        path: "/loadline-zones",
        name: "LoadlineZones",
        component: LoadlineZones,
      },
      {
        path: "/loadline-zones/create",
        name: "LoadlineZoneCreate",
        component: LoadlineZoneCreate,
      },
      {
        path: "/loadline-zones/:loadlineZoneId",
        name: "LoadlineZoneId",
        component: LoadlineZoneId,
      },
      //M10 LoadlineZoneSeasons
      {
        path: "/loadline-zone-seasons",
        name: "LoadlineZoneSeasons",
        component: LoadlineZoneSeasons,
      },
      {
        path: "/loadline-zone-seasons/create",
        name: "LoadlineZoneSeasonCreate",
        component: LoadlineZoneSeasonCreate,
      },
      {
        path: "/loadline-zone-seasons/:loadlineZoneSeasonId",
        name: "LoadlineZoneSeasonId",
        component: LoadlineZoneSeasonId,
      },
      //M12 ReasonTypes
      {
        path: "/reason-types",
        name: "ReasonTypes",
        component: ReasonTypes,
      },
      {
        path: "/reason-types/create",
        name: "ReasonTypeCreate",
        component: ReasonTypeCreate,
      },
      {
        path: "/reason-types/:reasonTypeId",
        name: "ReasonTypeId",
        component: ReasonTypeId,
      },
      //M13 ActionTypes
      {
        path: "/action-types",
        name: "ActionTypes",
        component: ActionTypes,
      },
      {
        path: "/action-types/create",
        name: "ActionTypeCreate",
        component: ActionTypeCreate,
      },
      {
        path: "/action-types/:actionTypeId",
        name: "ActionTypeId",
        component: ActionTypeId,
      },
      // Port upload
      {
        path: "/ports/upload",
        name: "PortUpload",
        component: PortUpload,
      },
      // Cargo Name upload
      {
        path: "/cargo-names/upload",
        name: "CargoNameUpload",
        component: CargoNameUpload,
      },

      //M14 PortActivities
      {
        path: "/port-activities",
        name: "PortActivities",
        component: PortActivities,
      },
      {
        path: "/port-activities/create",
        name: "PortActivityCreate",
        component: PortActivityCreate,
      },
      {
        path: "/port-activities/:portActivityId",
        name: "PortActivityId",
        component: PortActivityId,
      },
      //M15 PortAreas
      {
        path: "/port-areas",
        name: "PortAreas",
        component: PortAreas,
      },
      {
        path: "/port-areas/create",
        name: "PortAreaCreate",
        component: PortAreaCreate,
      },
      {
        path: "/port-areas/:portAreaId",
        name: "PortAreaId",
        component: PortAreaId,
      },
      //M16 PortFunctions
      {
        path: "/port-functions",
        name: "PortFunctions",
        component: PortFunctions,
      },
      {
        path: "/port-functions/create",
        name: "PortFunctionCreate",
        component: PortFunctionCreate,
      },
      {
        path: "/port-functions/:portFunctionId",
        name: "PortFunctionId",
        component: PortFunctionId,
      },
      //M17 TradeAreas
      {
        path: "/trade-areas",
        name: "TradeAreas",
        component: TradeAreas,
      },
      {
        path: "/trade-areas/create",
        name: "TradeAreaCreate",
        component: TradeAreaCreate,
      },
      {
        path: "/trade-areas/:tradeAreaId",
        name: "TradeAreaId",
        component: TradeAreaId,
      },
      //M18 BeaufortWindForceScales
      {
        path: "/beaufort-wind-force-scales",
        name: "BeaufortWindForceScales",
        component: BeaufortWindForceScales,
      },
      {
        path: "/beaufort-wind-force-scales/create",
        name: "BeaufortWindForceScaleCreate",
        component: BeaufortWindForceScaleCreate,
      },
      {
        path: "/beaufort-wind-force-scales/:beaufortWindForceScaleId",
        name: "BeaufortWindForceScaleId",
        component: BeaufortWindForceScaleId,
      },
      //M19 DelayTypes
      {
        path: "/delay-types",
        name: "DelayTypes",
        component: DelayTypes,
      },
      {
        path: "/delay-types/create",
        name: "DelayTypeCreate",
        component: DelayTypeCreate,
      },
      {
        path: "/delay-types/:delayTypeId",
        name: "DelayTypeId",
        component: DelayTypeId,
      },
      //M20 DelayReasons
      {
        path: "/delay-reasons",
        name: "DelayReasons",
        component: DelayReasons,
      },
      {
        path: "/delay-reasons/create",
        name: "DelayReasonCreate",
        component: DelayReasonCreate,
      },
      {
        path: "/delay-reasons/:delayReasonId",
        name: "DelayReasonId",
        component: DelayReasonId,
      },
      //M21 SeaStates
      {
        path: "/sea-states",
        name: "SeaStates",
        component: SeaStates,
      },
      {
        path: "/sea-states/create",
        name: "SeaStateCreate",
        component: SeaStateCreate,
      },
      {
        path: "/sea-states/:seaStateId",
        name: "SeaStateId",
        component: SeaStateId,
      },
      //M21 StateOfSwells
      {
        path: "/state-of-swells",
        name: "StateOfSwells",
        component: StateOfSwells,
      },
      {
        path: "/state-of-swells/create",
        name: "StateOfSwellCreate",
        component: StateOfSwellCreate,
      },
      {
        path: "/state-of-swells/:stateOfSwellId",
        name: "StateOfSwellId",
        component: StateOfSwellId,
      },
      //M23 FuelGrades
      {
        path: "/fuel-grades",
        name: "FuelGrades",
        component: FuelGrades,
      },
      {
        path: "/fuel-grades/create",
        name: "FuelGradeCreate",
        component: FuelGradeCreate,
      },
      {
        path: "/fuel-grades/:fuelGradeId",
        name: "FuelGradeId",
        component: FuelGradeId,
      },
      //M24 FuelConsumptionCategories
      {
        path: "/fuel-consumption-categories",
        name: "FuelConsumptionCategories",
        component: FuelConsumptionCategories,
      },
      {
        path: "/fuel-consumption-categories/create",
        name: "FuelConsumptionCategoryCreate",
        component: FuelConsumptionCategoryCreate,
      },
      {
        path: "/fuel-consumption-categories/:fuelConsumptionCategoryId",
        name: "FuelConsumptionCategoryId",
        component: FuelConsumptionCategoryId,
      },
      //M25 PoolPointTypes
      {
        path: "/pool-point-types",
        name: "PoolPointTypes",
        component: PoolPointTypes,
      },
      {
        path: "/pool-point-types/create",
        name: "PoolPointTypeCreate",
        component: PoolPointTypeCreate,
      },
      {
        path: "/pool-point-types/:poolPointTypeId",
        name: "PoolPointTypeId",
        component: PoolPointTypeId,
      },
      //M26 VesselFleets
      {
        path: "/vessel-fleets",
        name: "VesselFleets",
        component: VesselFleets,
      },
      {
        path: "/vessel-fleets/create",
        name: "VesselFleetCreate",
        component: VesselFleetCreate,
      },
      {
        path: "/vessel-fleets/:vesselFleetId",
        name: "VesselFleetId",
        component: VesselFleetId,
      },
      // F16 Sites
      {
        path: "/sites",
        name: "Sites",
        component: Sites,
      },
      {
        path: "/sites/create",
        name: "SiteCreate",
        component: SiteCreate,
      },
      {
        path: "/sites/:siteId",
        name: "SiteId",
        component: SiteId,
      },
      //M27 PortTypes
      {
        path: "/port-types",
        name: "PortTypes",
        component: PortTypes,
      },
      {
        path: "/port-types/create",
        name: "PortTypeCreate",
        component: PortTypeCreate,
      },
      {
        path: "/port-types/:portTypeId",
        name: "PortTypeId",
        component: PortTypeId,
      },
      //M28 Timezones
      {
        path: "/timezones",
        name: "Timezones",
        component: Timezones,
      },
      {
        path: "/timezones/create",
        name: "TimezoneCreate",
        component: TimezoneCreate,
      },
      {
        path: "/timezones/:timezoneId",
        name: "TimezoneId",
        component: TimezoneId,
      },
      //M29 RegionCodes
      {
        path: "/region-codes",
        name: "RegionCodes",
        component: RegionCodes,
      },
      {
        path: "/region-codes/create",
        name: "RegionCodeCreate",
        component: RegionCodeCreate,
      },
      {
        path: "/region-codes/:regionCodeId",
        name: "RegionCodeId",
        component: RegionCodeId,
      },
      //M30 TypeCodes
      {
        path: "/type-codes",
        name: "TypeCodes",
        component: TypeCodes,
      },
      {
        path: "/type-codes/create",
        name: "TypeCodeCreate",
        component: TypeCodeCreate,
      },
      {
        path: "/type-codes/:typeCodeId",
        name: "TypeCodeId",
        component: TypeCodeId,
      },
      //M31 SpeedConsumptionTypes
      {
        path: "/speed-consumption-types",
        name: "SpeedConsumptionTypes",
        component: SpeedConsumptionTypes,
      },
      {
        path: "/speed-consumption-types/create",
        name: "SpeedConsumptionTypeCreate",
        component: SpeedConsumptionTypeCreate,
      },
      {
        path: "/speed-consumption-types/:speedConsumptionTypeId",
        name: "SpeedConsumptionTypeId",
        component: SpeedConsumptionTypeId,
      },
      //M32 Ownerships
      {
        path: "/ownerships",
        name: "Ownerships",
        component: Ownerships,
      },
      {
        path: "/ownerships/create",
        name: "OwnershipCreate",
        component: OwnershipCreate,
      },
      {
        path: "/ownerships/:ownershipId",
        name: "OwnershipId",
        component: OwnershipId,
      },
      //M33 FreightTypes
      {
        path: "/freight-types",
        name: "FreightTypes",
        component: FreightTypes,
      },
      {
        path: "/freight-types/create",
        name: "FreightTypeCreate",
        component: FreightTypeCreate,
      },
      {
        path: "/freight-types/:freightTypeId",
        name: "FreightTypeId",
        component: FreightTypeId,
      },
      //M34 CargoStatuses
      {
        path: "/cargo-statuses",
        name: "CargoStatuses",
        component: CargoStatuses,
      },
      {
        path: "/cargo-statuses/create",
        name: "CargoStatusCreate",
        component: CargoStatusCreate,
      },
      {
        path: "/cargo-statuses/:cargoStatusId",
        name: "CargoStatusId",
        component: CargoStatusId,
      },
      //M35 UnitOfMeasureOfRates
      {
        path: "/unit-of-measure-of-rates",
        name: "UnitOfMeasureOfRates",
        component: UnitOfMeasureOfRates,
      },
      {
        path: "/unit-of-measure-of-rates/create",
        name: "UnitOfMeasureOfRateCreate",
        component: UnitOfMeasureOfRateCreate,
      },
      {
        path: "/unit-of-measure-of-rates/:unitOfMeasureOfRateId",
        name: "UnitOfMeasureOfRateId",
        component: UnitOfMeasureOfRateId,
      },
      //M36 LaytimeTerms
      {
        path: "/laytime-terms",
        name: "LaytimeTerms",
        component: LaytimeTerms,
      },
      {
        path: "/laytime-terms/create",
        name: "LaytimeTermCreate",
        component: LaytimeTermCreate,
      },
      {
        path: "/laytime-terms/:laytimeTermId",
        name: "LaytimeTermId",
        component: LaytimeTermId,
      },
      //M37 PaymentTerms
      {
        path: "/payment-terms",
        name: "PaymentTerms",
        component: PaymentTerms,
      },
      {
        path: "/payment-terms/create",
        name: "PaymentTermCreate",
        component: PaymentTermCreate,
      },
      {
        path: "/payment-terms/:paymentTermId",
        name: "PaymentTermId",
        component: PaymentTermId,
      },
      //M38 ContractTypes
      {
        path: "/contract-types",
        name: "ContractTypes",
        component: ContractTypes,
      },
      {
        path: "/contract-types/create",
        name: "ContractTypeCreate",
        component: ContractTypeCreate,
      },
      {
        path: "/contract-types/:contractTypeId",
        name: "ContractTypeId",
        component: ContractTypeId,
      },
      //M39 TcInStatuses
      {
        path: "/tc-in-statuses",
        name: "TcInStatuses",
        component: TcInStatuses,
      },
      {
        path: "/tc-in-statuses/create",
        name: "TcInStatusCreate",
        component: TcInStatusCreate,
      },
      {
        path: "/tc-in-statuses/:tcInStatusId",
        name: "TcInStatusId",
        component: TcInStatusId,
      },
      //M40 PricingCodes
      {
        path: "/pricing-codes",
        name: "PricingCodes",
        component: PricingCodes,
      },
      {
        path: "/pricing-codes/create",
        name: "PricingCodeCreate",
        component: PricingCodeCreate,
      },
      {
        path: "/pricing-codes/:pricingCodeId",
        name: "PricingCodeId",
        component: PricingCodeId,
      },
      //M93 ExpenseRevenueGroups
      {
        path: "/expense-revenue-groups",
        name: "ExpenseRevenueGroups",
        component: ExpenseRevenueGroups,
      },
      {
        path: "/expense-revenue-groups/create",
        name: "ExpenseRevenueGroupCreate",
        component: ExpenseRevenueGroupCreate,
      },
      {
        path: "/expense-revenue-groups/:expenseRevenueGroupId",
        name: "ExpenseRevenueGroupId",
        component: ExpenseRevenueGroupId,
      },
      //M41 ExpenseRevenueCodes
      {
        path: "/expense-revenue-codes",
        name: "ExpenseRevenueCodes",
        component: ExpenseRevenueCodes,
      },
      {
        path: "/expense-revenue-codes/create",
        name: "ExpenseRevenueCodeCreate",
        component: ExpenseRevenueCodeCreate,
      },
      {
        path: "/expense-revenue-codes/:expenseRevenueCodeId",
        name: "ExpenseRevenueCodeId",
        component: ExpenseRevenueCodeId,
      },
      //M42 OperationTypes
      {
        path: "/operation-types",
        name: "OperationTypes",
        component: OperationTypes,
      },
      {
        path: "/operation-types/create",
        name: "OperationTypeCreate",
        component: OperationTypeCreate,
      },
      {
        path: "/operation-types/:operationTypeId",
        name: "OperationTypeId",
        component: OperationTypeId,
      },
      // -------------------------------------------
      //F1 Users
      {
        path: "/users",
        name: "Users",
        component: Users,
      },
      {
        path: "/users/create",
        name: "UserCreate",
        component: UserCreate,
      },
      {
        path: "/users/:userId",
        name: "UserId",
        component: UserId,
      },
      //F8 Addresses
      {
        path: "/addresses",
        name: "Addresses",
        component: Addresses,
      },
      {
        path: "/addresses/create",
        name: "AddressCreate",
        component: AddressCreate,
      },
      // Address upload
      {
        path: "/addresses/upload",
        name: "AddressUpload",
        component: AddressUpload,
      },
      {
        path: "/addresses/:addressId",
        name: "AddressId",
        component: AddressId,
      },
      //F11 CargoGroups
      {
        path: "/cargo-groups",
        name: "CargoGroups",
        component: CargoGroups,
      },
      {
        path: "/cargo-groups/create",
        name: "CargoGroupCreate",
        component: CargoGroupCreate,
      },
      {
        path: "/cargo-groups/:cargoGroupId",
        name: "CargoGroupId",
        component: CargoGroupId,
      },
      //F12 CargoNames
      {
        path: "/cargo-names",
        name: "CargoNames",
        component: CargoNames,
      },
      {
        path: "/cargo-names/create",
        name: "CargoNameCreate",
        component: CargoNameCreate,
      },
      {
        path: "/cargo-names/:cargoNameId",
        name: "CargoNameId",
        component: CargoNameId,
      },
      //F13 PortNames
      {
        path: "/port-names",
        name: "PortNames",
        component: PortNames,
      },
      {
        path: "/port-names/create",
        name: "PortNameCreate",
        component: PortNameCreate,
      },
      {
        path: "/port-names/:portNameId",
        name: "PortNameId",
        component: PortNameId,
      },
      //F16 Vessels
      {
        path: "/vessels",
        name: "Vessels",
        component: Vessels,
      },
      {
        path: "/vessels/create",
        name: "VesselCreate",
        component: VesselCreate,
      },
      {
        path: "/vessels/upload",
        name: "VesselUpload",
        component: VesselUpload,
      },
      {
        path: "/vessels/:vesselId",
        name: "VesselId",
        component: VesselId,
      },
      //F20 Cargos

      {
        path: "/cargos/upload",
        name: "CargoUpload",
        component: CargoUpload,
      },
      {
        path: "/cargos",
        name: "Cargos",
        component: Cargos,
      },
      {
        path: "/cargos/create",
        name: "CargoCreate",
        component: CargoCreate,
      },
      {
        path: "/cargos/:cargoId",
        name: "CargoId",
        component: CargoId,
      },
      //F24 TcIns
      {
        path: "/tc-ins/make-paytment",
        name: "TcInMakePayment",
        component: TcInMakePayment,
      },
      {
        path: "/tc-ins/create-add-details",
        name: "TcInCreateAddDetails",
        component: TcInCreateAddDetails,
      },
      {
        path: "/tc-ins",
        name: "TcIns",
        component: TcIns,
      },
      {
        path: "/tc-ins/create",
        name: "TcInCreate",
        component: TcInCreate,
      },
      {
        path: "/tc-ins/:tcInId",
        name: "TcInId",
        component: TcInId,
      },
      // TC IN Options
      {
        path: "/tc-ins/options/lifting",
        name: "Lifting",
        component: Lifting,
      },
      {
        path: "/tc-ins/options/purchase",
        name: "Purchase",
        component: Purchase,
      },
      // TC IN Commissions
      {
        path: "/tc-ins/commissions/tc-commission",
        name: "TCCommission",
        component: TCCommission,
      },
      {
        path: "/tc-ins/commissions/claim-commission",
        name: "TCinClaimCommission",
        component: TCinClaimCommission,
      },
      //F28 Estimates
      {
        path: "/estimates",
        name: "Estimates",
        component: Estimates,
      },
      {
        path: "/estimates/create",
        name: "EstimateCreate",
        component: EstimateCreate,
      },
      {
        path: "/estimates/:estimateId",
        name: "EstimateId",
        component: EstimateId,
      },
      //F30 QuickEstimates
      {
        path: "/quick-estimate",
        name: "QuickEstimates",
        component: QuickEstimates,
      },
      {
        path: "/quick-estimate/create",
        name: "QuickEstimateCreate",
        component: QuickEstimateCreate,
      },
      {
        path: "/quick-estimate/:quickEstimateId",
        name: "QuickEstimateId",
        component: QuickEstimateId,
      },
      //F19 Fixtures
      {
        path: "/fixtures",
        name: "Fixtures",
        component: Fixtures,
      },
      {
        path: "/fixtures/create",
        name: "FixtureCreate",
        component: FixtureCreate,
      },
      {
        path: "/fixtures/:fixtureId",
        name: "FixtureId",
        component: FixtureId,
      },
      //Voyage List
      {
        path: "/voyages",
        name: "VoyageList",
        component: VoyageList,
      },
      {
        path: "/voyages1",
        name: "VoyageList1",
        component: VoyageList1,
      },
      {
        path: "/distances",
        name: "Distances",
        component: Distances,
      },
      {
        path: "/voyages/create",
        name: "VoyageListCreate",
        component: VoyageListCreate,
      },

      {
        path: "/voyages/:voyageListId",
        name: "VoyageListId",
        component: VoyageListId,
      },
      //Laytimes
      {
        path: "/laytime/estimated-demurrage",
        name: "LaytimeEstimatedDemurrage",
        component: LaytimeEstimatedDemurrage,
      },
      {
        path: "/laytime/calculation-setup",
        name: "LaytimeCalculationSetup",
        component: LaytimeCalculationSetup,
      },
      {
        path: "/laytime/calculation-ar-claim",
        name: "LaytimeCalculationARClaim",
        component: LaytimeCalculationARClaim,
      },
      // freight Invoice
      {
        path: "/freight/freight-invoice",
        name: "FreightInvoice",
        component: FreightInvoice,
      },
      // Other Revenues
      {
        path: "/other-revenue/other-revenue-expenses",
        name: "OtherRevenueExpense",
        component: OtherRevenueExpense,
      },
      {
        path: "/other-revenue/deviation-analysis",
        name: "DeviationAnalysis",
        component: DeviationAnalysis,
      },
      {
        path: "/other-revenue/profit-share",
        name: "ProfitShare",
        component: ProfitShare,
      },
      // Freight Commision
      {
        path: "/commissions/freight-commission",
        name: "FreightCommission",
        component: FreightCommission,
      },
      // TC IN Commision
      {
        path: "/commissions/tc-in-commission",
        name: "TCINCommission",
        component: TCINCommission,
      },
      // Claim Commision
      {
        path: "/commissions/claim-commission",
        name: "ClaimCommission",
        component: ClaimCommission,
      },
      // Voyage Bunker
      {
        path: "/bunkers/voyage-bunker",
        name: "VoyageBunker",
        component: VoyageBunker,
      },
      // Bunker Lifting
      {
        path: "/bunkers/bunker-lifting",
        name: "BunkerLifting",
        component: BunkerLifting,
      },
      // Bunker New Requirement
      {
        path: "/bunkers/new-requirements",
        name: "BunkerNewRequirement",
        component: BunkerNewRequirement,
      },
      // Delays
      {
        path: "/delay",
        name: "Delay",
        component: Delay,
      },
      //Bunker List
      {
        path: "/bunkers",
        name: "BunkerList",
        component: BunkerList,
      },
      {
        path: "/bunkers/create",
        name: "BunkerListCreate",
        component: BunkerListCreate,
      },
      {
        path: "/bunkers/:bunkerListId",
        name: "BunkerListId",
        component: BunkerListId,
      },
      // Tc Outs
      {
        path: "/chartering/tc-outs",
        name: "TcOuts",
        component: TcOuts,
      },
      {
        path: "/chartering/tc-outs/create",
        name: "TcOutCreate",
        component: TcOutCreate,
      },
      {
        path: "/chartering/tc-outs/:tcOutId",
        name: "TcOutId",
        component: TcOutId,
      },
      // Transactions
      {
        path: "/financials/transactions",
        name: "Transactions",
        component: Transactions,
      },
      {
        path: "/financials/transactions/create",
        name: "TransactionCreate",
        component: TransactionCreate,
      },
      {
        path: "/financials/transactions/:transactionListId",
        name: "TransactionId",
        component: TransactionId,
      },
      // Payables
      {
        path: "/financials/payables",
        name: "Payables",
        component: Payables,
      },
      {
        path: "/financials/payables_new",
        name: "PayablesNew",
        component: PayablesNew,
      },
      {
        path: "/financials/payables/create",
        name: "PayableCreate",
        component: PayableCreate,
      },
      {
        path: "/financials/payables/:payableListId",
        name: "PayableId",
        component: PayableId,
      },
      // Receivables
      {
        path: "/financials/tc-out-receivables",
        name: "TcoutReceivables",
        component: TcoutReceivables,
      },
      {
        path: "/financials/tc-out-receivables_new",
        name: "TcoutReceivablesNew",
        component: TcoutReceivablesNew,
      },
      {
        path: "/financials/tc-out-receivables/:tcOutReceivableId",
        name: "TcoutReceivableId",
        component: TcoutReceivableId,
      },
      {
        path: "/financials/receivables",
        name: "Receivables",
        component: Receivables,
      },
      {
        path: "/financials/receivables/create",
        name: "ReceivableCreate",
        component: ReceivableCreate,
      },
      {
        path: "/financials/receivables/:receivableListId",
        name: "ReceivableId",
        component: ReceivableId,
      },
      {
        path: "/financials/trial-balance",
        name: "TrialBalances",
        component: TrialBalances,
      },
      {
        path: "/financials/vendor-statement",
        name: "VendorStatements",
        component: VendorStatements,
      },
      // Masters
      {
        path: "/masters/laytime-to-commences",
        name: "LaytimeToCommence",
        component: LaytimeToCommence,
      },
      {
        path: "/masters/time-to-tenders",
        name: "TimeToTender",
        component: TimeToTender,
      },
      {
        path: "/masters/laytime-types",
        name: "LaytimeType",
        component: LaytimeType,
      },
      {
        path: "/masters/loading-costs",
        name: "LoadingCost",
        component: LoadingCost,
      },
      {
        path: "/masters/nor-to-tenders",
        name: "NorToTender",
        component: NorToTender,
      },
      {
        path: "/masters/other-loading-terms",
        name: "OtherLoadingTerm",
        component: OtherLoadingTerm,
      },
      {
        path: "/masters/shifting-terms",
        name: "ShiftingTerm",
        component: ShiftingTerm,
      },
      {
        path: "/masters/time-used",
        name: "TimeUsed",
        component: TimeUsed,
      },
      {
        path: "/masters/working-days",
        name: "WorkingDay",
        component: WorkingDay,
      },
      {
        path: "/masters/bunker-purchase-types",
        name: "BunkerPurchaseType",
        component: BunkerPurchaseType,
      },
      {
        path: "/masters/bunker-delivery-types",
        name: "BunkerDeleftiveryType",
        component: BunkerDeliveryType,
      },
      {
        path: "/masters/charter-party-forms",
        name: "CharterPartyForm",
        component: CharterPartyForm,
      },
      {
        path: "/masters/extra-freight-terms",
        name: "ExtraFrieghtTerm",
        component: ExtraFrieghtTerm,
      },
      {
        path: "/masters/ld-rate-standards",
        name: "LdRateStandard",
        component: LdRateStandard,
      },
      {
        path: "/masters/standard-paragraphs",
        name: "StandardParagraph",
        component: StandardParagraph,
      },
      {
        path: "/masters/account-payables",
        name: "AccountPayable",
        component: AccountPayable,
      },
      {
        path: "/masters/account-receivables",
        name: "AccountReceivable",
        component: AccountReceivable,
      },
      {
        path: "/masters/clearances",
        name: "Clearance",
        component: Clearance,
      },
      {
        path: "/masters/interbanks",
        name: "InterBank",
        component: InterBank,
      },
      {
        path: "/masters/retained-earnings",
        name: "RetainedEarning",
        component: RetainedEarning,
      },
      {
        path: "/masters/exchange-differences",
        name: "ExchangeDifference",
        component: ExchangeDifference,
      },
      {
        path: "/masters/inter-companies",
        name: "InterCompany",
        component: InterCompany,
      },
      {
        path: "/masters/open-months",
        name: "OpenMonth",
        component: OpenMonth,
      },
      // Invoices
      // Create Voyage invoice
      {
        path: "/invoices/voyage-invoice",
        name: "createVoyageInvoice",
        component: createVoyageInvoice,
      },
      // Tc invoice
      {
        path: "/invoices/tc-invoice",
        name: "TcInvoice",
        component: TcInvoice,
      },
      // Create Tc invoice
      {
        path: "/invoices/tc-invoice/create",
        name: "CreateTcInvoice",
        component: CreateTcInvoice,
      },
      // Create Bunker invoice
      {
        path: "/invoices/bunker-invoice",
        name: "CreateBunkerInvoice",
        component: CreateBunkerInvoice,
      },
      // Create Trade Settlement
      {
        path: "/invoices/trade-settlement",
        name: "CreateTradeSettlement",
        component: CreateTradeSettlement,
      },
      // Create Trade Commission
      {
        path: "/invoices/trade-commission",
        name: "CreateTradeCommission",
        component: CreateTradeCommission,
      },
      // Rebill
      {
        path: "/invoices/rebill-invoice",
        name: "Rebill",
        component: Rebill,
      },
      // Edit Invoice
      {
        path: "/invoices/edit-invoice",
        name: "EditInvoice",
        component: EditInvoice,
      },
      {
        path: "/invoices/edit-invoice/create",
        name: "EditInvoiceCreate",
        component: EditInvoiceCreate,
      },
      // Post Invoice
      {
        path: "/invoices/post-invoice",
        name: "PostInvoice",
        component: PostInvoice,
      },
      {
        path: "/invoices/post-invoice/create",
        name: "PostInvoiceCreate",
        component: PostInvoiceCreate,
      },
      // Approve Invoice
      {
        path: "/invoices/approve-invoice",
        name: "ApproveInvoice",
        component: ApproveInvoice,
      },
      {
        path: "/invoices/approve-invoice/create",
        name: "ApproveInvoiceCreate",
        component: ApproveInvoiceCreate,
      },
      // Fiancial => Transaction
      // Transaction
      {
        path: "/Transactions/Invoice-entry",
        name: "Transaction",
        component: Transaction,
      },
      // Payment And Receipt
      {
        path: "/Transactions/payment-entry",
        name: "PaymentReceipt",
        component: PaymentReceipt,
      },
      // journal Entry
      {
        path: "/Transactions/journal-entry",
        name: "JournalEntry",
        component: JournalEntry,
      },
      // Advanced Payment Entry
      {
        path: "/Transactions/advanced-payment",
        name: "AdvancedPayment",
        component: AdvancedPayment,
      },
      // Operations
      // Vessel Schedule
      {
        path: "/operations/vessel-schedule",
        name: "VesselSchedule",
        component: VesselSchedule,
      },
      // Port Schedule
      {
        path: "/operations/port-schedule",
        name: "PortSchedule",
        component: PortSchedule,
      },
      // Fleet Map
      {
        path: "/operations/fleet-map",
        name: "FleetMap",
        component: FleetMap,
      },
      // Task
      {
        path: "/operations/task",
        name: "Task",
        component: Task,
      },
      // Alert
      {
        path: "/operations/alert",
        name: "Alert",
        component: Alert,
      },
      // Onboard
      {
        path: "/operations/onboard",
        name: "Onboard",
        component: Onboard,
      },
      // PortCalls
      {
        path: "/operations/port-call",
        name: "PortCalls",
        component: PortCalls,
      },
      // Form
      {
        path: "/operations/form",
        name: "Form",
        component: Form,
      },
      // Operations
      // Freight Demurrage
      // Freight Invoice
      {
        path: "/operations/freight-demurrage/freight-invoice",
        name: "FDFreightInvoice",
        component: FDFreightInvoice,
      },
      // Final Freight Statement
      {
        path: "/operations/freight-demurrage/final-freight-statement",
        name: "FinalFreightStatement",
        component: FinalFreightStatement,
      },
      // Laytime Calculator
      {
        path: "/operations/freight-demurrage/laytime-calculator",
        name: "LaytimeCalculator",
        component: LaytimeCalculator,
      },
      // Commission
      {
        path: "/operations/freight-demurrage/commission",
        name: "Commission",
        component: Commission,
      },
      // Bunker
      // Bunker Requirement List
      {
        path: "/operations/bunkers/bunker-requirement-list",
        name: "BunkerRequirementList",
        component: BunkerRequirementList,
      },
      {
        path: "/operations/bunkers/bunker-requirement-create",
        name: "BunkerRequirementCreate",
        component: BunkerRequirementCreate,
      },
      // Bunker Invoice
      {
        path: "/operations/bunkers/bunker-invoice",
        name: "BunkerInvoice",
        component: BunkerInvoice,
      },
      // Bunker Purchase
      {
        path: "/operations/bunkers/bunker-purachase",
        name: "BunkerPurachase",
        component: BunkerPurachase,
      },
      // Time Charter In
      // Bunker Purchase
      {
        path: "/operations/time-charter-ins/tc-in-list",
        name: "TcInList",
        component: TcInList,
      },
      // Time Charter in Payment
      {
        path: "/operations/time-charter-ins/tc-in-payment",
        name: "TcInPayment",
        component: TcInPayment,
      },
      // Time Charter in Payment Schedule
      {
        path: "/operations/time-charter-ins/tc-in-payment-schedule",
        name: "TcInPaymentSchedule",
        component: TcInPaymentSchedule,
      },
      // Commission
      {
        path: "/operations/time-charter-ins/commission",
        name: "TcCommission",
        component: TcCommission,
      },
      // Owner's Commission
      {
        path: "/operations/time-charter-ins/ower-commission",
        name: "OwnerCommission",
        component: OwnerCommission,
      },
      // Hire Statement
      {
        path: "/operations/time-charter-ins/hire-statement",
        name: "TcHireStatement",
        component: TcHireStatement,
      },
      // Bareboat Conatct In
      {
        path: "/operations/time-charter-ins/barboat-contact-in",
        name: "BareboatContactIn",
        component: BareboatContactIn,
      },
      // Bareboat Payment
      {
        path: "/operations/time-charter-ins/barboat-payment",
        name: "BareboatPayment",
        component: BareboatPayment,
      },
      // Bareboat Payment Schedule
      {
        path: "/operations/time-charter-ins/barboat-payment-schedule",
        name: "BareboatPaymentSchedule",
        component: BareboatPaymentSchedule,
      },
      // Time Charter Out
      // Time Charter Out List
      {
        path: "/operations/time-charter-outs/tc-out-list",
        name: "TcOutList",
        component: TcOutList,
      },
      // Time Charter Out Bill
      {
        path: "/operations/time-charter-outs/bill",
        name: "TcOutBill",
        component: TcOutBill,
      },
      // Time Charter Out Bill Schedule
      {
        path: "/operations/time-charter-outs/billing-schedule",
        name: "TcOutBillSchedule",
        component: TcOutBillSchedule,
      },
      // Time Charter Out Commission
      {
        path: "/operations/time-charter-outs/commission",
        name: "TcOutCommission",
        component: TcOutCommission,
      },
      // Time Charter Out Hire Statement
      {
        path: "/operations/time-charter-outs/hire-statement",
        name: "TcOutHireStatement",
        component: TcOutHireStatement,
      },
      // SetUp
      // Bunker Purchase Type
      {
        path: "/operations/set-ups/bunker-purachase-type",
        name: "SetUpBunkerPurchaseType",
        component: SetUpBunkerPurchaseType,
      },
      // Bunker Delivery Type
      {
        path: "/operations/set-ups/bunker-delivery-type",
        name: "SetUpBunkerDeliveryType",
        component: SetUpBunkerDeliveryType,
      },
      // Define Send Form
      {
        path: "/operations/set-ups/define-send-form",
        name: "DefineSendForm",
        component: DefineSendForm,
      },
      // Port/Other Costs
      // Port Advanced/DA
      {
        path: "/operations/port-advanced-costt/port-advanced-da",
        name: "PortAdvancedDA",
        component: PortAdvancedDA,
      },
      // Ledge Expenses Setup
      {
        path: "/operations/port-advanced-costt/ledge-expenses-set-up",
        name: "LedgeExpensesSetup",
        component: LedgeExpensesSetup,
      },
      // Other Revenues/Expenses
      {
        path: "/operations/port-advanced-costt/other-revenue-expenses",
        name: "OtherRevenuesExpenses",
        component: OtherRevenuesExpenses,
      },
      // Peformances Reporting
      // Bunker Inventory Accrual
      {
        path: "/operations/performances-reportings/bunker-inventory-accraul",
        name: "BunkerInventoryAccrual",
        component: BunkerInventoryAccrual,
      },
      {
        path: "/operations/performances-reportings/bunker-invoices-summary",
        name: "BunkerInvoicesSummary",
        component: BunkerInvoicesSummary,
      },
      {
        path: "/operations/performances-reportings/cargos-loaded-discharged",
        name: "CargoLodedDischarged",
        component: CargoLodedDischarged,
      },
      {
        path: "/operations/performances-reportings/check-voayge-p&l",
        name: "CheckVoyagePL",
        component: CheckVoyagePL,
      },
      {
        path: "/operations/performances-reportings/demurrage-summary",
        name: "DemurrageSummary",
        component: DemurrageSummary,
      },
      {
        path: "/operations/performances-reportings/eeoi-reporting",
        name: "EEOIReporting",
        component: EEOIReporting,
      },
      {
        path: "/operations/performances-reportings/fixure-cargo-summary",
        name: "FixureCargoSummary",
        component: FixureCargoSummary,
      },
      {
        path: "/operations/performances-reportings/general-operation-report",
        name: "GeneralOperationReport",
        component: GeneralOperationReport,
      },
      {
        path: "/operations/performances-reportings/on-off-hire-summary",
        name: "OnOffHireSummary",
        component: OnOffHireSummary,
      },
      {
        path: "/operations/performances-reportings/operation-receivable",
        name: "OperationReceivable",
        component: OperationReceivable,
      },
      {
        path: "/operations/performances-reportings/outstanding-hire-payment-adjustment",
        name: "OutstandingHirePaymentAdjustment",
        component: OutstandingHirePaymentAdjustment,
      },
      {
        path: "/operations/performances-reportings/performance-analysis-report",
        name: "PerformanceAnalysisReport",
        component: PerformanceAnalysisReport,
      },
      {
        path: "/operations/performances-reportings/port-expenses-rebillable-summary",
        name: "PortExpensesRebillableSummary",
        component: PortExpensesRebillableSummary,
      },
      {
        path: "/operations/performances-reportings/port-expenses-summary",
        name: "PortExpensesSummary",
        component: PortExpensesSummary,
      },
      {
        path: "/operations/performances-reportings/port-production-report",
        name: "PortProductionReport",
        component: PortProductionReport,
      },
      {
        path: "/operations/performances-reportings/tce-summary",
        name: "TCESummary",
        component: TCESummary,
      },
      {
        path: "/operations/performances-reportings/us-army-corps-of-engineer-report",
        name: "USEngineerReport",
        component: USEngineerReport,
      },
      {
        path: "/operations/performances-reportings/utilization-report",
        name: "UtilizationReport",
        component: UtilizationReport,
      },
      {
        path: "/operations/performances-reportings/vessel-accrual-summary",
        name: "VesselAccrualSummary",
        component: VesselAccrualSummary,
      },
      {
        path: "/operations/performances-reportings/vessel-bunker-period-report",
        name: "VesselBunkerPeriodReport",
        component: VesselBunkerPeriodReport,
      },
      {
        path: "/operations/performances-reportings/vessel-bunker-summary",
        name: "VesselBunkerSummary",
        component: VesselBunkerSummary,
      },
      {
        path: "/operations/performances-reportings/vessel-freight-summary",
        name: "VesselfreightSummary",
        component: VesselfreightSummary,
      },
      {
        path: "/operations/performances-reportings/vessel-p&l-summary",
        name: "VesselPLSummary",
        component: VesselPLSummary,
      },
      {
        path: "/operations/performances-reportings/vessel-performance-report",
        name: "VesselPerformanceReport",
        component: VesselPerformanceReport,
      },
      {
        path: "/operations/performances-reportings/vessel-position-report",
        name: "VesselPositionReport",
        component: VesselPositionReport,
      },
      {
        path: "/operations/performances-reportings/worldscale-atrs-route-report",
        name: "WorldScaleRoute",
        component: WorldScaleRoute,
      },
      // Financials => Statement
      // Account Periods
      {
        path: "/financial/statement/account-periods",
        name: "AccountPeriods",
        component: AccountPeriods,
      },
      {
        path: "/financial/statement/account-periods/create",
        name: "AccountPeriodCreate",
        component: AccountPeriodCreate,
      },
      {
        path: "/financial/statement/account-periods/:accountPeriodId",
        name: "AccountPeriodId",
        component: AccountPeriodId,
      },
      // Account Period Open Months
      {
        path: "/financial/statement/account-period-open-months",
        name: "AccountPeriodOpenMonths",
        component: AccountPeriodOpenMonths,
      },
      {
        path: "/financial/statement/account-period-open-months/create",
        name: "AccountPeriodOpenMonthCreate",
        component: AccountPeriodOpenMonthCreate,
      },
      {
        path: "/financial/statement/account-period-open-months/:accountPeriodOpenMonthId",
        name: "AccountPeriodOpenMonthId",
        component: AccountPeriodOpenMonthId,
      },
      // Business Rules
      {
        path: "/financial/statement/business-rules",
        name: "BusinessRules",
        component: BusinessRules,
      },
      {
        path: "/financial/statement/business-rules/create",
        name: "BusinessRuleCreate",
        component: BusinessRuleCreate,
      },
      {
        path: "/financial/statement/business-rules/:businessRuleId",
        name: "BusinessRuleId",
        component: BusinessRuleId,
      },
      // Charts Of Accounts
      {
        path: "/financial/statement/charts-of-accounts",
        name: "ChartsOfAccounts",
        component: ChartsOfAccounts,
      },
      {
        path: "/financial/statement/charts-of-accounts/create",
        name: "ChartsOfAccountCreate",
        component: ChartsOfAccountCreate,
      },
      {
        path: "/financial/statement/charts-of-accounts/:chartsOfAccountId",
        name: "ChartsOfAccountId",
        component: ChartsOfAccountId,
      },
      // Company Bank Links
      {
        path: "/financial/statement/company-bank-links",
        name: "CompanyBankLinks",
        component: CompanyBankLinks,
      },
      {
        path: "/financial/statement/company-bank-links/create",
        name: "CompanyBankLinkCreate",
        component: CompanyBankLinkCreate,
      },
      {
        path: "/financial/statement/company-bank-links/:companyBankLinkId",
        name: "CompanyBankLinkId",
        component: CompanyBankLinkId,
      },
      // Credit Accounts
      {
        path: "/financial/statement/credit-accounts",
        name: "CreditAccounts",
        component: CreditAccounts,
      },
      {
        path: "/financial/statement/credit-accounts/create",
        name: "CreditAccountCreate",
        component: CreditAccountCreate,
      },
      {
        path: "/financial/statement/credit-accounts/:creditAccountId",
        name: "CreditAccountId",
        component: CreditAccountId,
      },
      // Debit Accounts
      {
        path: "/financial/statement/debit-accounts",
        name: "DebitAccounts",
        component: DebitAccounts,
      },
      {
        path: "/financial/statement/debit-accounts/create",
        name: "DebitAccountCreate",
        component: DebitAccountCreate,
      },
      {
        path: "/financial/statement/debit-accounts/:debitAccountId",
        name: "DebitAccountId",
        component: DebitAccountId,
      },
      // Department
      {
        path: "/financial/statement/departments",
        name: "Departments",
        component: Departments,
      },
      {
        path: "/financial/statement/departments/create",
        name: "DepartmentCreate",
        component: DepartmentCreate,
      },
      {
        path: "/financial/statement/departments/:departmentId",
        name: "DepartmentId",
        component: DepartmentId,
      },
      // Ledger Code
      {
        path: "/financial/statement/ledger-codes",
        name: "LedgerCodes",
        component: LedgerCodes,
      },
      {
        path: "/financial/statement/ledger-codes/create",
        name: "LedgerCodeCreate",
        component: LedgerCodeCreate,
      },
      {
        path: "/financial/statement/ledger-codes/:ledgerCodeId",
        name: "LedgerCodeId",
        component: LedgerCodeId,
      },
      {
        path: "/financial/statement/bank-link",
        name: "BankLink",
        component: BankLink,
      },

      {
        path: "/financial/statement/chart-account",
        name: "ChartAccount",
        component: ChartAccount,
      },
      {
        path: "/financial/statement/company-info",
        name: "CompanyInfo",
        component: CompanyInfo,
      },
      {
        path: "/financial/statement/currancy",
        name: "Currency",
        component: Currency,
      },
      {
        path: "/financial/statement/department",
        name: "Department",
        component: Department,
      },
      {
        path: "/financial/statement/document-number",
        name: "DocumentNumber",
        component: DocumentNumber,
      },
      {
        path: "/financial/statement/exchage-rate",
        name: "ExchangeRate",
        component: ExchangeRate,
      },
      {
        path: "/financial/statement/invoice-action",
        name: "InvoiceAction",
        component: InvoiceAction,
      },
      {
        path: "/financial/statement/invoice-approval",
        name: "InvoiceApproval",
        component: InvoiceApproval,
      },
      {
        path: "/financial/statement/invoice-realization",
        name: "InvoiceRealization",
        component: InvoiceRealization,
      },

      {
        path: "/financial/statement/voyage-p&l-variance-tolerance",
        name: "VoyagePLVarianceTolerance",
        component: VoyagePLVarianceTolerance,
      },
      //Financials => Period Closing
      {
        path: "/financial/period-closing/monthly-accrual",
        name: "MonthlyAccrual",
        component: MonthlyAccrual,
      },
      {
        path: "/financial/period-closing/period-fx-adjustment",
        name: "PeriodFXAdjustment",
        component: PeriodFXAdjustment,
      },
      {
        path: "/financial/period-closing/time-charter-period-journal",
        name: "TcPeriodJournal",
        component: TcPeriodJournal,
      },
      {
        path: "/financial/period-closing/voyage-period-journal",
        name: "VoyagePeriodJournal",
        component: VoyagePeriodJournal,
      },
      //M77 Late Reasons
      {
        path: "/late-reasons",
        name: "LateReasons",
        component: LateReasons,
      },
      {
        path: "/late-reasons/create",
        name: "LateReasonCreate",
        component: LateReasonCreate,
      },
      {
        path: "/late-reasons/:lateReasonId",
        name: "LateReasonId",
        component: LateReasonId,
      },
      //M80 Reversal Reasons
      {
        path: "/reversal-reasons",
        name: "ReversalReasons",
        component: ReversalReasons,
      },
      {
        path: "/reversal-reasons/create",
        name: "ReversalReasonCreate",
        component: ReversalReasonCreate,
      },
      {
        path: "/reversal-reasons/:reversalReasonId",
        name: "ReversalReasonId",
        component: ReversalReasonId,
      },
      //M78 Lines of Business
      {
        path: "/lines-of-business",
        name: "LinesOfBusinesses",
        component: LinesOfBusinesses,
      },
      {
        path: "/lines-of-business/create",
        name: "LinesOfBusinessCreate",
        component: LinesOfBusinessCreate,
      },
      {
        path: "/lines-of-business/:linesOfBusinessId",
        name: "LinesOfBusinessId",
        component: LinesOfBusinessId,
      },
      //M79 Payment Methods
      {
        path: "/payment-methods",
        name: "PaymentMethods",
        component: PaymentMethods,
      },
      {
        path: "/payment-methods/create",
        name: "PaymentMethodCreate",
        component: PaymentMethodCreate,
      },
      {
        path: "/payment-methods/:paymentMethodId",
        name: "PaymentMethodId",
        component: PaymentMethodId,
      },
      //M81 & *M82 Exchange Rate & Exchange rate details
      {
        path: "/exchange-rates",
        name: "ExchangeRates",
        component: ExchangeRates,
      },
      {
        path: "/exchange-rates/create",
        name: "ExchangeRateCreate",
        component: ExchangeRateCreate,
      },
      {
        path: "/exchange-rates/:exchangeRateId",
        name: "ExchangeRateId",
        component: ExchangeRateId,
      },
      //M83 & M84 Bunker Fuel Price & Bunker Fuel Price Indexes
      {
        path: "/bunker-fuel-prices",
        name: "BunkerFuelPrices",
        component: BunkerFuelPrices,
      },
      {
        path: "/bunker-fuel-prices/create",
        name: "BunkerFuelPriceCreate",
        component: BunkerFuelPriceCreate,
      },
      {
        path: "/bunker-fuel-prices/:bunkerFuelPriceId",
        name: "BunkerFuelPriceId",
        component: BunkerFuelPriceId,
      },
      //M85 Bunker Hedging Values
      {
        path: "/bunker-hedging-values",
        name: "BunkerHedgingValues",
        component: BunkerHedgingValues,
      },
      {
        path: "/bunker-hedging-values/create",
        name: "BunkerHedgingValueCreate",
        component: BunkerHedgingValueCreate,
      },
      {
        path: "/bunker-hedging-values/:bunkerHedgingValueId",
        name: "BunkerHedgingValueId",
        component: BunkerHedgingValueId,
      },
      //M86 Cp Places
      {
        path: "/cp-places",
        name: "CPPlaces",
        component: CPPlaces,
      },
      {
        path: "/cp-places/create",
        name: "CPPlaceCreate",
        component: CPPlaceCreate,
      },
      {
        path: "/cp-places/:cpPlaceId",
        name: "CPPlaceId",
        component: CPPlaceId,
      },
      //M87 Holidays
      {
        path: "/holidays",
        name: "Holidays",
        component: Holidays,
      },
      {
        path: "/holidays/create",
        name: "HolidayCreate",
        component: HolidayCreate,
      },
      {
        path: "/holidays/:holidayId",
        name: "HolidayId",
        component: HolidayId,
      },
      //M88 Lightering Types
      {
        path: "/lightering-types",
        name: "LighteringTypes",
        component: LighteringTypes,
      },
      {
        path: "/lightering-types/create",
        name: "LighteringTypeCreate",
        component: LighteringTypeCreate,
      },
      {
        path: "/lightering-types/:lighteringTypeId",
        name: "LighteringTypeId",
        component: LighteringTypeId,
      },
      //M89 Note Categories
      {
        path: "/note-categories",
        name: "NoteCategories",
        component: NoteCategories,
      },
      {
        path: "/note-categories/create",
        name: "NoteCategoryCreate",
        component: NoteCategoryCreate,
      },
      {
        path: "/note-categories/:noteCategoryId",
        name: "NoteCategoryId",
        component: NoteCategoryId,
      },
      //M90 Opa Rates
      {
        path: "/opa-rates",
        name: "OpaRates",
        component: OpaRates,
      },
      {
        path: "/opa-rates/create",
        name: "OpaRateCreate",
        component: OpaRateCreate,
      },
      {
        path: "/opa-rates/:opaRateId",
        name: "OpaRateId",
        component: OpaRateId,
      },
      {
        path: "/invoice-pdfs/hire-pdf",
        name: "HirePDF",
        component: HirePDF,
      },
      {
        path: "/invoice-pdfs/freight-invoice-pdf",
        name: "FreightInvoicePDF",
        component: FreightInvoicePDF,
      },
      {
        path: "/invoice-pdfs/bunker-invoice-pdf",
        name: "BunkerInvoicePDF",
        component: BunkerInvoicePDF,
      },
      {
        path: "/invoice-pdfs/laytime-calculation-invoice-pdf",
        name: "LaytimeCalculationInvoicePDF",
        component: LaytimeCalculationInvoicePDF,
      },
      {
        path: "/invoice-pdfs/final-invoice-pdf",
        name: "FinalInvoicePDF",
        component: FinalInvoicePDF,
      },
      // Voyage Payables
      {
        path: "/financials/voyage-other-rev-expanse-payables",
        name: "VoyageOtherRevExpansesPayables",
        component: VoyageOtherRevExpansesPayables,
      },
      {
        path: "/financials/voyage-other-rev-expanse-payable/:vorepId",
        name: "VoyageOtherRevExpansesPayableId",
        component: VoyageOtherRevExpansesPayableId,
      },
      // Voyage Receivables
      {
        path: "/financials/voyage-other-rev-expanse-receivables",
        name: "VoyageOtherRevExpansesReceivables",
        component: VoyageOtherRevExpansesReceivables,
      },
      {
        path: "/financials/voyage-other-rev-expanse-receivable/:vorerId",
        name: "VoyageOtherRevExpansesReceivableId",
        component: VoyageOtherRevExpansesReceivableId,
      },
      // Bank Statement
      {
        path: "/financials/bank-statements",
        name: "BankStatements",
        component: BankStatements,
      },
      // Paybale Receivable Statement
      {
        path: "/financials/payable-receivable-statements",
        name: "PayableReceivableStatements",
        component: PayableReceivableStatements,
      },
    ],
  },
  {
    path: "",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/loader",
        name: "FingerprintSpinner",
        component: FingerprintSpinner,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // alert('before');
  // FingerprintSpinner.isLoading;
  if (
    to.name === "Login" ||
    to.name === "Register" ||
    to.name === "ForgotPassword"
  ) {
    if (store.getters["auth/authenticated"]) {
      return next({
        name: "Home",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      name: "Login",
    });
  }
  // else if (to.name != "Settings") {
  //   if (store.getters["auth/authenticated"]) {
  //     if (Object.keys(store.getters["auth/site"]).length == 0) {
  //       return next({
  //         name: "Settings",
  //       });
  //     }
  //   }
  // }
  next();
});

router.afterEach(() => {
  // const AppLoader = document.getElementsByClassName("myloader");
  //   if (AppLoader) {
  //     Vue.component('my-loader')
  //   }
});

export default router;
