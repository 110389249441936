<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/cargos"></back-button> Upload Cargos
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Cargos</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/cargos-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />

                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudeCargos.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>
                <div v-if="crudeCargos.length != 0">
                  <div class="form-footer">
                    <button
                      class="btn btn-success btn-block"
                      @click="processFile"
                    >
                      Process file
                    </button>
                  </div>
                </div>
                <!-- <div
                  class="form-footer"
                  v-if="conflicTargets.length != 0"
                  
                > -->
                <!-- <download-excel
                    :title="title"
                    class="btn btn-success btn-block"
                    :data="conflict_targets_excel"
                    :fields="excel_fields"
                    worksheet="ConflictUsers"
                    name="Conflict_Target.xls"
                  >
                    <a href="#" class="download">Export Conflics</a>
                  </download-excel> -->
                <!-- <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Laycan Range </th>
                        <th>CP Date </th>
                        <th>Cargo Type </th>
                        <th>Cargo Group </th>
                        <th>Product Code </th>
                        <th>Cargo ID </th>
                        <th>Cargo Quantity </th>
                        <th>Cargo Unit </th>
                        <th>Counterparty </th>
                        <th>COA ID </th>
                        <th>Laycan From </th>
                        <th>Laycan To </th>
                        <th>Laycan Month </th>
                        <th>Laycan Range1 </th>
                        <th>Created on </th>
                        <th>Discharge Port </th>
                        <th>Receiver </th>
                        <th>Estimate ID </th>
                        <th>Fixture ID </th>
                        <th>Last Modified on </th>
                        <th>Last Modified by </th>
                        <th>Load Port </th>
                        <th>Status </th>
                        <th>Trade Area </th>
                        <th>Vessel </th>
                        <th>Vessel Type </th>
                        <th>Shipment No </th>
                        <th>Sales Contract ID </th>
                        <th>Purch Contract ID </th>
                        <th>Ref No </th>
                        <th>Voyage No </th>
                        <th>Voyage Status </th>
                        <th>Shipper </th>
                        <th>Chtr Coordinator </th>
                        <th>Company </th>
                        <th>Line of Business </th>
                        <th>Buy or Sell </th>
                        <th>Booking No </th>
                        <th>COA Sequence No </th>
                        <th>Scheduler </th>
                        <th>Trader </th>
                        <th>Load Port Area </th>
                        <th>Load Port Ocean </th>
                        <th>Load Berth </th>
                        <th>Disc Port Area </th>
                        <th>Disc Port Ocean </th>
                        <th>Disc Berth </th>
                        <th>Trade Route </th>
                        <th>FOB/Delivered Cargo </th>
                        <th>Cargo Persistent </th>
                        <th>B/L Date </th>
                        <th>Load Port Comm Id </th>
                        <th>Disc Port Comm Id </th>
                        <th>Reference Vessel </th>
                        <th>Department </th>
                        <th>COA Ref Ballast Port </th>
                        <th>COA Ref Reposition Port </th>
                        <th>URL Onboard </th>
                        <th>Bunker Hedging </th>
                        <th>Bunker Hedged By </th>
                        <th>Freight Type </th>
                        <th>Frt/Daily Rate </th>
                        <th>Lumpsum </th>
                        <th>OBL </th>
                        <th>Comingle </th>
                        <th>Cargo Remark </th>
                        <th>Inbound </th>
                        <th>WSC Flat </th>
                        <th>WSC % </th>
                        <th>Exposure Basis Month </th>
                        <th>Is Option </th>
                        <th>First Benchmark Est Route </th>
                        <th>Ref Voyage Route </th>
                        <th>Index-Linked Frt Type </th>
                        <th>MT Equivalent Factor </th>
                        <th>Option Declaration Date </th>
                        <th>Bunker Surcharge Type </th>
                        <th>Min Qty </th>
                        <th>Max Qty </th>
                        <th>Confirmation Date </th>
                        <th>Linked Paper Trade </th>
                        <th>Cargo Grade </th>
                        <th>Laycan Confirmed </th>
                        <th>Strategy </th>
                        <th>Un-ack Rev </th>
                        <th>Ref Contract </th>
                      </tr> 
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <tr
                        v-for="(crudeCargo, at) in crudeCargos"
                        :key="`crudeCargo${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudeCargo.laycon_range }}</td>
                        <td>{{ crudeCargo.cp_rate }}</td>
                        <td>{{ crudeCargo.cargo_type }}</td>
                        <td>{{ crudeCargo.cargo_group }}</td>
                        <td>{{ crudeCargo.product_code }}</td>
                        <td>{{ crudeCargo.cargo_id }}</td>
                        <td>{{ crudeCargo.cargo_quantity }}</td>
                        <td>{{ crudeCargo.cargo_unit }}</td>
                        <td>{{ crudeCargo.counterparty }}</td>
                        <td>{{ crudeCargo.coa_id }}</td>
                        <td>{{ crudeCargo.laycon_from }}</td>
                        <td>{{ crudeCargo.laycon_to }}</td>
                        <td>{{ crudeCargo.laycon_month }}</td>
                        <td>{{ crudeCargo.laycon_range_1 }}</td>
                        <td>{{ crudeCargo.created_on }}</td>
                        <td>{{ crudeCargo.discharge_port }}</td>
                        <td>{{ crudeCargo.receiver }}</td>
                        <td>{{ crudeCargo.estimate_id }}</td>
                        <td>{{ crudeCargo.fixture_id }}</td>
                        <td>{{ crudeCargo.last_modified_on }}</td>
                        <td>{{ crudeCargo.last_modified_by }}</td>
                        <td>{{ crudeCargo.load_port }}</td>
                        <td>{{ crudeCargo.status }}</td>
                        <td>{{ crudeCargo.trade_area }}</td>
                        <td>{{ crudeCargo.vessel }}</td>
                        <td>{{ crudeCargo.vessel_type }}</td>
                        <td>{{ crudeCargo.shipment_no }}</td>
                        <td>{{ crudeCargo.sales_contract_id }}</td>
                        <td>{{ crudeCargo.purch_contract_id }}</td>
                        <td>{{ crudeCargo.ref_no }}</td>
                        <td>{{ crudeCargo.voyage_no }}</td>
                        <td>{{ crudeCargo.voyage_status }}</td>
                        <td>{{ crudeCargo.shipper }}</td>
                        <td>{{ crudeCargo.chtr_coordinator }}</td>
                        <td>{{ crudeCargo.company }}</td>
                        <td>{{ crudeCargo.line_of_business }}</td>
                        <td>{{ crudeCargo.buy_or_sell }}</td>
                        <td>{{ crudeCargo.booking_no }}</td>
                        <td>{{ crudeCargo.coa_sequence_no }}</td>
                        <td>{{ crudeCargo.scheduler }}</td>
                        <td>{{ crudeCargo.trader }}</td>
                        <td>{{ crudeCargo.load_port_area }}</td>
                        <td>{{ crudeCargo.load_port_ocean }}</td>
                        <td>{{ crudeCargo.load_berth }}</td>
                        <td>{{ crudeCargo.disc_port_area }}</td>
                        <td>{{ crudeCargo.disc_port_ocean }}</td>
                        <td>{{ crudeCargo.disc_berth }}</td>
                        <td>{{ crudeCargo.trade_route }}</td>
                        <td>{{ crudeCargo.fob_delivered_cargo }}</td>
                        <td>{{ crudeCargo.cargo_persistent }}</td>
                        <td>{{ crudeCargo.b_l_date }}</td>
                        <td>{{ crudeCargo.load_port_comm_id }}</td>
                        <td>{{ crudeCargo.disc_port_comm_id }}</td>
                        <td>{{ crudeCargo.reference_vessel }}</td>
                        <td>{{ crudeCargo.department }}</td>
                        <td>{{ crudeCargo.coa_ref_ballast_port }}</td>
                        <td>{{ crudeCargo.coa_ref_reposition_port }}</td>
                        <td>{{ crudeCargo.url_onboard }}</td>
                        <td>{{ crudeCargo.bunker_hedging }}</td>
                        <td>{{ crudeCargo.bunker_hedged_by }}</td>
                        <td>{{ crudeCargo.freight_type }}</td>
                        <td>{{ crudeCargo.frt_daily_rate }}</td>
                        <td>{{ crudeCargo.lumpsum }}</td>
                        <td>{{ crudeCargo.obl }}</td>
                        <td>{{ crudeCargo.comingle }}</td>
                        <td>{{ crudeCargo.cargo_remark }}</td>
                        <td>{{ crudeCargo.inbound }}</td>
                        <td>{{ crudeCargo.wsc_flat }}</td>
                        <td>{{ crudeCargo.wsc_percentage }}</td>
                        <td>{{ crudeCargo.exposure_basis_month }}</td>
                        <td>{{ crudeCargo.is_option }}</td>
                        <td>{{ crudeCargo.first_benchmark_est_route }}</td>
                        <td>{{ crudeCargo.ref_voyage_route }}</td>
                        <td>{{ crudeCargo.index_linked_frt_type }}</td>
                        <td>{{ crudeCargo.mt_equivalent_factor }}</td>
                        <td>{{ crudeCargo.option_declaration_date }}</td>
                        <td>{{ crudeCargo.bunker_surchare_type }}</td>
                        <td>{{ crudeCargo.min_qty }}</td>
                        <td>{{ crudeCargo.max_qty }}</td>
                        <td>{{ crudeCargo.confirmation_date }}</td>
                        <td>{{ crudeCargo.linked_paper_trades }}</td>
                        <td>{{ crudeCargo.cargo_grades }}</td>
                        <td>{{ crudeCargo.laycon_confirmed }}</td>
                        <td>{{ crudeCargo.strategy }}</td>
                        <td>{{ crudeCargo.un_ack_rev }}</td>
                        <td>{{ crudeCargo.ref_contract }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "uploadTargets",
  data() {
    return {
      crudeCargos: [],
      company_id: "",
      month: "",
      year: "",
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      months: [],
      years: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   Source: "source",
      //   "Employee Code": "employee_code",
      // },
      // conflict_targets_excel: [],
    };
  },
  mounted() {
    this.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudeCargos = await axios.get(`/crude_cargos`);
      this.crudeCargos = crudeCargos.data.data;
      this.count = crudeCargos.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.cargo = this.$refs.file.files[0];
      let formData = new FormData();

      formData.append("site_id", this.site_id);
      formData.append("CargoData", this.cargo);
      await axios
        .post("upload_cargo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudeCargos = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_cargo");
        // if (conflicTargets != "") {
        //   // console.log('Conflicts');
        //   this.conflicTargets = conflicTargets.data.data;
        //   this.exportToExcel();
        alert("Processed Successfully");
        // }
        await axios.get("truncate_cargos");
        this.crudeCargos = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_cargos");
      alert("Truncated Successfully");
      this.crudeCargos = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_cargos/${$id}`);
        this.crudeCargos = this.crudeCargos.filter((d) => d.id != $id);
      }
    },
    // exportToExcel() {
    //   this.title = "Conflict User Master";
    //   this.conflict_targets_excel = [];
    //   let sr_no = 1;
    //   this.conflicTargets.forEach((user) => {
    //     this.conflict_targets_excel.push({
    //       sr_no: sr_no,
    //       source: user.source,
    //       employee_code: user.store_code ? user.store_code : user.employee_code,
    //     });
    //     sr_no++;
    //   });
    //   console.log(this.conflict_targets_excel);
    // },
  },
};
</script>
