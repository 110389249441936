<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            TC in Hire Statement
            <add-button link="/invoices/tc-invoice/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Operation</li>
            <li class="active">TC in Hire Statement</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- TC in Hire Statement Form -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Vessel Name</label>
                      <div class="col-sm-9">
                        <my-select
                          :selection.sync="form"
                          :suggestions="vessels"
                          placeholder="Select vessel"
                        ></my-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">CP Date*</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          disabled
                          class="form-control"
                          placeholder="CP Date"
                          v-model="form.cp_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">TC Code</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          disabled
                          class="form-control"
                          placeholder="TC Code"
                          v-model="form.tc_date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Delivery</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          disabled
                          class="form-control"
                          placeholder="Delivery"
                          v-model="form.delivery"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label"
                        >Chartered From</label
                      >
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          disabled
                          class="form-control"
                          placeholder="Chartered From"
                          v-model="form.delivery"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Redelivery</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          disabled
                          class="form-control"
                          placeholder="Redelivery"
                          v-model="form.redelivery"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <br>
                <div class="col-md-12" style="overflow-x:scroll">
                  <div class="table-wrap">
                    <table class="table table-hover table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Ref No</th>
                          <th>Date</th>
                          <th>Terms</th>
                          <th>Amt Paid</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <my-checkbox
                      :selection.sync="form.invoiced"
                      placeholder="Invoiced"
                    ></my-checkbox>
                  </div>
                  <div class="col-md-2">
                    <my-checkbox
                      :selection.sync="form.paid"
                      placeholder="Paid"
                    ></my-checkbox>
                  </div>
                  <div class="col-md-4">
                    <my-checkbox
                      :selection.sync="form.hire_lines_with_zero_amount"
                      placeholder="Hide Lines with Zero Amount"
                    ></my-checkbox>
                    <my-checkbox
                      :selection.sync="form.exclude_pending_invoices"
                      placeholder="Exclude Pending Invoices"
                    ></my-checkbox>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="col-sm-3 control-label">Total</label>
                      <div class="col-sm-9">
                        <input
                          v-uppercase
                          type="number"
                          class="form-control"
                          placeholder="0.00"
                          v-model="form.total"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group">
                    <label class="col-sm-3 control-label"
                      >Display Rebillable Admin Fee As</label
                    >
                    <div class="col-sm-9">
                      <input
                        v-uppercase
                        type="text"
                        class="form-control"
                        v-model="form.eta"
                      />
                    </div>
                  </div>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "tc_voices",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      tc_voices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let tc_voices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.tc_voices = tc_voices.data.data;
      this.count = tc_voices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
