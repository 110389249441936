<template>
  <section>
    <h5>Broker</h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Broker</th>
            <th>Rate</th>
            <th>Type</th>
            <th>Payment Method</th>
            <th>Claim</th>
            <th>From GMT</th>
            <th>To GMT</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tc_in_broker, ar) in form.tc_in_brokers"
            :key="`contact${ar}`"
          >
            <td @click="deleteTcInBroker(tc_in_broker)">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              {{ ar + 1 }}.
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_broker.broker_id"
                :suggestions="addresses"
                placeholder="Broker"
              ></my-select>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Rate"
                v-model="tc_in_broker.rate"
              />
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_broker.rate_type"
                :suggestions="brokerRateTypes"
                placeholder="Rate Type"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_broker.payment_method"
                :suggestions="brokerPaymentMethods"
                placeholder="Payment Method"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="tc_in_broker.is_claim_commissionable"
                :suggestions="brokerCommissionables"
                placeholder="Claim"
              ></my-select>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_broker.from_gmt"
                placeholder="From GMT"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="tc_in_broker.to_gmt"
                placeholder="To GMT"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Remarks"
                v-model="tc_in_broker.remarks"
              />
            </td>
          </tr>
          <tr>
            <td colspan="9" align="center" @click="addEmptyTcInBroker()">
              Add New Row
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: [
    "form",
    "addresses",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerCommissionables",
  ],
  methods: {
    addEmptyTcInBroker() {
      this.form.tc_in_brokers.push({
        broker_id: "",
        rate: 0,
        rate_type: "%",
        payment_method: "Deduct from hire",
        is_claim_commissionable:
          "Claim comissions will be deducted from claim invoice",
        from_gmt: "",
        to_gmt: "",
        remarks: "",
      });
      this.onPortsUpdated()
    },
    deleteTcInBroker(tcInBroker) {
      this.form.tc_in_brokers.splice(
        this.form.tc_in_brokers.indexOf(tcInBroker),
        1
      );
    },
    onPortsUpdated() {
      this.$emit('onPortsUpdated')
    }
  },
};
</script>