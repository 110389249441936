<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/vessel-types"></back-button>
            Create a new vessel type
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/vessel-types">Vessel Types</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel Type*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Vessel Type"
                                v-model="form.vessel_type"
                              />
                              <span class="error-block">{{
                                errors.vessel_type
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Basis Route</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Basis Route"
                                v-model="form.basis_route"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Route Correction</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Route Correction"
                                v-model="form.route_correction"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Benchmark Vsi</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Benchmark Vsi"
                                v-model="form.benchmark_vsi"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Basis Quantity</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Basis Quantity"
                                v-model="form.basis_qty"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Weighted Days Correction</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Weighted Days Correction"
                                v-model="form.weighted_days_correction"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="col-sm-offset-3 col-sm-9">
                              <my-checkbox
                                :selection.sync="form.restrict_speed"
                                placeholder="Restrict Speed"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fuel Zone Set</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_zone_set_id"
                                :suggestions="fuelZoneSets"
                                placeholder="Enter Fuel Zone Set"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Fuel 1</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_1"
                                :suggestions="fuelTypes"
                                placeholder="Enter Fuel 1"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fuel 1 Unit</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_1_unit_id"
                                :suggestions="units"
                                placeholder="Enter Fuel 1 Unit"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Fuel 2</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_2"
                                :suggestions="fuelTypes"
                                placeholder="Enter Fuel 2"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fuel 2 Unit</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_2_unit_id"
                                :suggestions="units"
                                placeholder="Enter Fuel 2 Unit"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Fuel 3</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_3"
                                :suggestions="fuelTypes"
                                placeholder="Enter Fuel 3"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fuel 3 Unit</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.fuel_3_unit_id"
                                :suggestions="units"
                                placeholder="Enter Fuel 3 Unit"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_active"
                                placeholder="Is Active"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group mb-0">
                            <div class="col-sm-offset-3 col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "VesselTypesCreate",
  data() {
    return {
      form: {
        fuel_zone_set_id: "",
        fuel_1: "",
        fuel_1_unit_id: "",
        fuel_2: "",
        fuel_2_unit_id: "",
        fuel_3: "",
        fuel_3_unit_id: "",
        is_active: true,
      },
      fuelZoneSets: [],
      fuelTypes: [],
      units: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("vessel_types/masters");
      masters = masters.data;
      masters.fuel_zone_sets.forEach((fuel_zone_set) => {
        this.fuelZoneSets.push({
          id: fuel_zone_set.id,
          name: fuel_zone_set.name + " - " + fuel_zone_set.description,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name:
            fuel_type.code +
            " - " +
            fuel_type.fuel_type +
            " - " +
            fuel_type.description,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit + " - " + unit.description,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessel_types", this.form);
        this.isLoading = false;
        this.$router.push("/vessel-types");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
