<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/bunker-fuel-places"></back-button>
            Create a new Bunker Fuel Price
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>CRUDES</li>
            <li>
              <router-link to="/bunker-fuel-places"
                >Bunker Fuel Prices</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Index Name*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Index Name"
                                v-model="form.index_name"
                              />
                              <span class="error-block">{{
                                errors.index_name
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Start Date*</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.start_date"
                                placeholder="Enter Start Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_active"
                                placeholder="Is Active"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Bunker Fuel Price Indexes</h5>
                      <br />
                      <div class="col-md-12 editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Date</th>
                              <th>Port</th>
                              <th>fuel Type</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                bunker_fuel_price_index, ar
                              ) in form.bunker_fuel_price_indexes"
                              :key="`bunker_fuel_price_index${ar}`"
                            >
                              <td
                                @click="
                                  deleteBunkerFuelPriceIndexes(
                                    bunker_fuel_price_index
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="bunker_fuel_price_index.date"
                                  placeholder="Enter Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-select
                                  :selection.sync="
                                    bunker_fuel_price_index.port_id
                                  "
                                  :suggestions="portNames"
                                  placeholder="Select Port"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  :selection.sync="
                                    bunker_fuel_price_index.fuel_type_id
                                  "
                                  :suggestions="fuel_types"
                                  placeholder="Select Fuel Type"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="price"
                                  v-model="bunker_fuel_price_index.price"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="4"
                                align="center"
                                @click="addEmptyBunkerFuelPriceIndexes()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "bunker_fuel_priceCreate",
  data() {
    return {
      form: {
        index_name: "",
        start_date: "",
        bunker_fuel_price_indexes: [],
        is_active: 1,
      },
      portNames: [],
      fuel_types: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/bunker_fuel_prices/masters");
      masters = masters.data;
      masters.port_names.forEach((port_name) => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuel_types.push({
          id: fuel_type.id,
          name: fuel_type.code,
        });
      });
    },
    async save() {
      try {
        let bunker_fuel_price = await axios.post(
          `/bunker_fuel_prices`,
          this.form
        );
        this.form = bunker_fuel_price.data.data;
        this.isLoading = false;
        this.$router.push("/bunker-fuel-places");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyBunkerFuelPriceIndexes() {
      this.form.bunker_fuel_price_indexes.push({
        bunker_fuel_price_id: "",
        date: "",
        port_id: "",
        fuel_type_id: "",
        price: "",
      });
    },
    deleteBunkerFuelPriceIndexes(bunker_fuel_price_indexes) {
      this.form.bunker_fuel_price_indexes =
        this.form.bunker_fuel_price_indexes.filter(
          (ar) => ar != bunker_fuel_price_indexes
        );
    },
  },
};
</script>
