<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Transactions
            <add-button link="/financials/transactions/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Transactions</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Action</th>
                        <th>AP/AR</th>
                        <th>ID</th>
                        <th>Invite Date</th>
                        <th>Invoice Due</th>
                        <th>Description</th>
                        <th>Counterparty</th>
                        <th>Curr</th>
                        <th>Invoice Amt (Curr)</th>
                        <th>Invoice Amt (Base)</th>
                        <th>Vessel</th>
                        <th>Voyage No</th>
                        <th>Ops Type</th>
                        <th>Ops Coordination</th>
                        <th>Invoice No</th>
                        <th>Status</th>
                        <th>last Update User</th>
                        <th>Approal Status</th>
                        <th>Approal User</th>
                        <th>Approal Date</th>
                        <th>Cargo B/L Code </th>
                        <th>Cargo B/L Date</th>
                        <th>Cargo B/L Gross</th>
                        <th>Chtr Specialist</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table :isLoading="isLoading" cols="10"></loading-table>
                      <router-link 
                        v-for="(transaction, at) in transactions"
                        :key="`transaction${at}`"
                        :to="`/transactions/${transaction.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <!-- <td>{{ transaction.dwf }}</td>
                        <td>{{ transaction.remarks }}</td> -->
                        <!-- <td>{{ transaction.notes ? transaction.type_code.name : '' }}</td>
                        <td>{{ transaction.owner ? transaction.owner.full_name : '' }}</td>
                        <td>{{ transaction.transaction_type ? transaction.transaction_type.transaction_type : '' }}</td>
                        <td>{{ transaction.dwt_mt }} MT</td>
                        <td>{{ transaction.daily_cost }}</td>
                        <td>{{ transaction.laden_speed }}</td>
                        <td>{{ transaction.ballast_speed }}</td> -->
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'transactions',
  data () {
    return {
      transactions: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      let transactions = await axios.get(`/transactions?page=${page}&rowsPerPage=${this.rowsPerPage}`)
      this.transactions = transactions.data.data
      this.count = transactions.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    }
  }
}
</script>