<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Rebill Invoices over Port Expense Cap
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-6 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Rebill Invoices over Port Expense Cap</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Voyages commencing on or after</label>
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.commencing_date"
                                placeholder="Enter Commencing Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage statuses</label
                            >
                            <div class="col-sm-9">
                                                          <my-checkbox :selection.sync="form.is_commenced" placeholder="Commenced" ></my-checkbox>
                            <my-checkbox :selection.sync="form.is_completed" placeholder="Completed" ></my-checkbox>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" style="overflow-x:scroll">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Vessel Name</th>
                              <th>Voyage No</th>
                              <th>Cargo</th>
                              <th>Charterer</th>
                              <th>COA</th>
                              <th>Port Expenses Group</th>
                              <th>Port Expenses Cap</th>
                              <th>Post Expenses</th>
                              <th>Rebill Invoice Total</th>
                              <th>Outstanding Amt</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <errors :data="errors" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "rebill_invoices",
  data() {
    return {
      rebill_invoices: [],
      form: {},
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let rebill_invoices = await axios.get(
        `/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`
      );
      this.rebill_invoices = rebill_invoices.data.data;
      this.count = rebill_invoices.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
