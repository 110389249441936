<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button
              link="/financial/statement/business-rules"
            ></back-button>
            Create a new Business Rule
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financial</li>
            <li>Statement</li>
            <li>
              <router-link to="/financial/statement/business-rules"
                >Business Rule</router-link
              >
            </li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Source*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Source"
                            v-model="form.source"
                          />
                          <span class="error-block">{{ errors.source }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Code*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Code"
                            v-model="form.code"
                          />
                          <span class="error-block">{{ errors.code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Description</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Description"
                            v-model="form.description"
                          />
                          <span class="error-block">{{
                            errors.description
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">LOB</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="LOB"
                            v-model="form.lob"
                          />
                          <span class="error-block">{{ errors.lob }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Account Code</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Account Code"
                            v-model="form.account_code"
                          />
                          <span class="error-block">{{
                            errors.account_code
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Account Description</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Account Description"
                            v-model="form.account_description"
                          />
                          <span class="error-block">{{
                            errors.account_description
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Account Category</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Account Category"
                            v-model="form.account_category"
                          />
                          <span class="error-block">{{
                            errors.account_category
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Credit Acc</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.credit_acc_id"
                            :suggestions="countries"
                            placeholder="Enter Credit Acc"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Debit Acc</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.debit_acc_id"
                            :suggestions="vendors"
                            placeholder="Enter Debit Acc"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Vendor</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vendor_id"
                            :suggestions="vendors"
                            placeholder="Enter Vendor"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Type</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Type"
                            v-model="form.type"
                          />
                          <span class="error-block">{{ errors.type }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Country</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.country_id"
                            :suggestions="countries"
                            placeholder="Enter Country"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Is InterCompany</label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_intercompany"
                            placeholder="Is InterCompany"
                          ></my-checkbox>

                          <span class="error-block">{{ errors.is_intercompany }}</span>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="save"
                            text="Save"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "siteCreate",
  data() {
    return {
      form: {
        company_name: "",
        bank_name: "",
        bank_account: "",
        country_id: "",
        vendor_id: "",
      },
      countries: [],
      vendors: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("business_rules/masters");
      masters = masters.data;
      masters.countries.forEach((country) => {
        this.countries.push({
          id: country.id,
          name: country.name,
        });
      });
      masters.addresses.forEach((vendor) => {
        this.vendors.push({
          id: vendor.id,
          name: vendor.short_name + " - " + vendor.full_name,
        });
      });
    },
    async save() {
      try {
        let business_rule = await axios.post(`/business_rules`, this.form);
        this.form = business_rule.data.data;
        this.isLoading = false;
        this.$router.push("/financial/statement/business-rules");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
