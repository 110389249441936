<template>
  <span>
    <span @click="openMyPortActivitiesModal" title="Port Activities">
      <i class="fa fa-anchor" aria-hidden="true"></i>
    </span>

    <div :id="`myPortActivitiesModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Port Activities</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-4">Prev. Port :</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ previousPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Curr. Port</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ currentPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Destination:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ destinationPort }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Function:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ portFunction }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Arrival:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ arrivalTime }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">Departure:</div>
                    <div class="col-md-8">
                      <span class="myBold">{{ departureTime }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <BunkerMasters
                    :voyageItenary="voyageItenary"
                    :voyageItenaries="voyageItenaries"
                    :voyageBunkerMasters="voyageBunkerMasters"
                    @onBunkerChanged="onBunkerChanged"
                  ></BunkerMasters>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <Activities
            :voyageItenary="voyageItenary"
            :voyageItenaries="voyageItenaries"
            :portActivities="portActivities"
            :reasonTypes="reasonTypes"
          ></Activities>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="savePortActivitiesExpenses"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyPortActivitiesModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script type="text/javascript">
import Activities from "./activities.vue";
import BunkerMasters from "./bunker_masters.vue";

export default {
  data() {
    return {
      previousPort: "",
      currentPort: "",
      destinationPort: "",
      portFunction: "",
      arrivalTime: "",
      departureTime: "",
    };
  },
  components: {
    Activities,
    BunkerMasters,
  },
  props: [
    "voyageItenary",
    "voyageBunkerMasters",
    "voyageItenaries",
    "count",
    "portActivities",
    "reasonTypes",
  ],
  watch: {
    voyageItenary: {
      handler(value) {
        this.onVogayeItenaryChanged(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.onVogayeItenaryChanged(this.voyageItenary);
  },
  methods: {
    async openMyPortActivitiesModal() {
      var modal = document.getElementById("myPortActivitiesModal" + this.count);
      modal.style.display = "block";
    },
    closeMyPortActivitiesModal() {
      var modal = document.getElementById("myPortActivitiesModal" + this.count);
      modal.style.display = "none";
    },
    savePortActivitiesExpenses() {
      this.$emit("updateItenaryDetails");
      this.closeMyPortActivitiesModal();
    },
    onBunkerChanged() {
      this.$emit("onBunkerChanged");
    },
    onVogayeItenaryChanged(value) {
      if (value.position != 1)
        this.previousPort = this.voyageItenaries[parseInt(value.position) - 2]
          .port_name
          ? this.voyageItenaries[parseInt(value.position) - 2].port_name
              .port_name
          : "";
      this.currentPort = value.port_name ? value.port_name.port_name : "";
      this.destinationPort = this.voyageItenaries[
        this.voyageItenaries.length - 1
      ].port_name
        ? this.voyageItenaries[this.voyageItenaries.length - 1].port_name
            .port_name
        : "";
      this.portFunction = value.port_function
        ? value.port_function.port_operation
        : "";
      this.arrivalTime = value.arrival_date;
      this.departureTime = value.departure_date;
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>
