<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Business Rules
            <add-button
              link="/financial/statement/business-rules/create"
            ></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financial</li>
            <li>Statement</li>
            <li class="active">Business Rules</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Source</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>LOB</th>
                        <th>Account Code</th>
                        <th>Account Description</th>
                        <th>Account Category</th>
                        <th>Vendor</th>
                        <th>Country</th>
                        <th>Is InterCompany</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="11"
                      ></loading-table>
                      <router-link
                        v-for="(business_rule, at) in business_rules"
                        :key="`business_rule${at}`"
                        :to="`/financial/statement/business-rules/${business_rule.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ business_rule.source }}</td>
                        <td>{{ business_rule.code }}</td>
                        <td>{{ business_rule.description }}</td>
                        <td>{{ business_rule.lob }}</td>
                        <td>{{ business_rule.account_code }}</td>
                        <td>{{ business_rule.account_description }}</td>
                        <td>{{ business_rule.account_category }}</td>
                        <td>
                          {{
                            business_rule.vendor.short_name +
                            " - " +
                            business_rule.vendor.full_name
                          }}
                        </td>
                        <td>{{ business_rule.country.name }}</td>
                        <td>{{ business_rule.is_intercompany?'YES':"NO" }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "business_rules",
  data() {
    return {
      business_rules: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let business_rules = await axios.get(`/business_rules`);
      this.business_rules = business_rules.data.data;
      this.count = business_rules.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
