<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/voyages"></back-button>
            Create a new voyage
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Operations</li>
            <li><router-link to="/voyages">Voyages</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Estimate*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.estimate_id"
                                :suggestions="vessels"
                                placeholder="Select estimate"
                              ></my-select>
                              <span class="error-block">{{
                                errors.estimate_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Select vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >TC IN *</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.tc_in_id"
                                :suggestions="tc_ins"
                                placeholder="Select TC in"
                              ></my-select>
                              <span class="error-block">{{
                                errors.tc_in_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel DWT*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Vessel DWT"
                                v-model="form.vessel_dwt"
                              />
                              <span class="error-block">{{
                                errors.vessel_dwt
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Daily Hire</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Daily Hire"
                                v-model="form.daily_hire"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Voyage Number"
                                v-model="form.voyage_no"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Operation Type
                            </label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.operation_type_id"
                                :suggestions="operation_types"
                                placeholder="Select Operation Type"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trade Area</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.trade_area_id"
                                :suggestions="tradeAreas"
                                placeholder="Select trade area"
                              ></my-select>
                              <span class="error-block">{{
                                errors.trade_area_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Specialist</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.specialist_id"
                                :suggestions="spacialists"
                                placeholder="Select specialist"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_consecutive_voyage"
                                placeholder="Is Consecutive Voyage"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_drydock"
                                placeholder="Is Dry Dock"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <div class="col-sm-9">
                              <my-checkbox
                                :selection.sync="form.is_last_tci_voyage"
                                placeholder="Is Last TCI Voyage"
                              ></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage Status</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.voyage_status_id"
                                :suggestions="voyage_statuses"
                                placeholder="Select Voyage Status"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage Commencing Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.voyage_commening_date"
                                placeholder="Enter Voyage Commencing Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Voyage Completing Date</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.voyage_completing_date"
                                placeholder="Enter Voyage Compleing Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Piracy Routing</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Piracy Routing"
                                v-model="form.piracy_routing"
                              />
                              <span class="error-block">{{
                                errors.piracy_routing
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >ECA Routing</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="ECA Routing"
                                v-model="form.eca_routing"
                              />
                              <span class="error-block">{{
                                errors.eca_routing
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Itenary</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port Name</th>
                              <th>Miles</th>
                              <th>Speed</th>
                              <th>Weather Factor</th>
                              <th>Sea Days</th>
                              <th>Extra Sea Days</th>
                              <th>Total Sea Days</th>
                              <th>Cargo</th>
                              <th>BL Discharge Qty</th>
                              <th>Arrival Date</th>
                              <th>Departure Date</th>
                              <th>Port Days</th>
                              <th>Berth</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(voyage_itenary,
                              ar) in form.voyage_itenaries"
                              :key="`voyage_itenary${ar}`"
                            >
                              <td @click="deleteVoyageItenary(voyage_itenary)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="voyage_itenary.port_name_id"
                                  :suggestions="port_names"
                                  placeholder="Select Port Name"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Miles"
                                  v-model="voyage_itenary.miles"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Speed"
                                  v-model="voyage_itenary.speed"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Weather Factor"
                                  v-model="voyage_itenary.waether_factor"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Sea Days"
                                  v-model="voyage_itenary.sea_days"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Extra Sea Days"
                                  v-model="voyage_itenary.extra_sea_days"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Total Sea Days"
                                  v-model="voyage_itenary.total_sea_days"
                                />
                              </td>
                              <td>
                                <my-select
                                  @blur="updateVesselSpeedConsumptionFuels"
                                  disableFormControl="true"
                                  :selection.sync="voyage_itenary.cargo_id"
                                  :suggestions="cargos"
                                  placeholder="Select Cargos"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="BL Discharge Qty"
                                  v-model="voyage_itenary.bl_discharge_qty"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="voyage_itenary.arrival_date"
                                  placeholder="Enter Arrival Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="voyage_itenary.departure_date"
                                  placeholder="Enter Departure Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Port Days"
                                  v-model="voyage_itenary.port_days"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Berth"
                                  v-model="voyage_itenary.berth"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="14"
                                align="center"
                                @click="addEmptyVoyageItenary()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="14"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Bunkers</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port</th>
                              <th>Arrival</th>
                              <th>Departure</th>
                              <th>LSF Arr</th>
                              <th>LSF Rec</th>
                              <th>LSF Dep</th>
                              <th>LSM Arr</th>
                              <th>LSM Rec</th>
                              <th>LSM Dep</th>
                              <th>IFO Arr</th>
                              <th>IFO Rec</th>
                              <th>IFO Dep</th>
                              <th>MDO Arr</th>
                              <th>MDO Rec</th>
                              <th>MDO Dep</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>FUJAIRAH</td>
                              <td></td>
                              <td>2021-06-09T18:00:00</td>
                              <td>0</td>
                              <td></td>
                              <td>1550</td>
                              <td>0</td>
                              <td></td>
                              <td>145</td>
                              <td>0</td>
                              <td></td>
                              <td>0</td>
                              <td>0</td>
                              <td></td>
                              <td>0</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Port/Date</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Port Name</th>
                              <th>F</th>
                              <th>Miles</th>
                              <th>ECA Miles</th>
                              <th>D To Go</th>
                              <th>Speed</th>
                              <th>WF %</th>
                              <th>SDays</th>
                              <th>XDS</th>
                              <th>TsDays</th>
                              <th>BL</th>
                              <th>Day</th>
                              <th>Arrival</th>
                              <th>Day</th>
                              <th>Departure</th>
                              <th>PDays</th>
                              <th>CO2 (MT)</th>
                              <th>ID No</th>
                              <th>ST</th>
                              <th>Berth</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(vesselInPortConsumption,
                              ar) in form.vessel_in_port_consumptions"
                              :key="`vesselInPortConsumption${ar}`"
                            >
                              <td
                                @click="
                                  deleteVesselInPortConsumption(
                                    vesselInPortConsumption
                                  )
                                "
                              >
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  @blur="updateVesselSpeedConsumptionFuels"
                                  disableFormControl="true"
                                  :selection.sync="
                                    vesselInPortConsumption.port_name
                                  "
                                  :suggestions="portNames"
                                  placeholder="Select Port Name"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  @blur="updateVesselSpeedConsumptionFuels"
                                  disableFormControl="true"
                                  :selection.sync="vesselInPortConsumption.f"
                                  :suggestions="f"
                                  placeholder="Select f"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Miles"
                                  v-model="vesselInPortConsumption.miles"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="ECA Miles"
                                  v-model="vesselInPortConsumption.eca_miles"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="D To Go"
                                  v-model="vesselInPortConsumption.dtogo"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Speed"
                                  v-model="vesselInPortConsumption.speed"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="WF %"
                                  v-model="vesselInPortConsumption.wf"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="S Days"
                                  v-model="vesselInPortConsumption.sdays"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="XDS"
                                  v-model="vesselInPortConsumption.xds"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="TsDays"
                                  v-model="vesselInPortConsumption.tsdays"
                                />
                              </td>
                              <td>
                                <my-select
                                  @blur="updateVesselSpeedConsumptionFuels"
                                  disableFormControl="true"
                                  :selection.sync="vesselInPortConsumption.bl"
                                  :suggestions="bls"
                                  placeholder="Select B/L"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Day"
                                  v-model="vesselInPortConsumption.day"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.arrival_date"
                                  placeholder="Enter Arrival Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Day"
                                  v-model="vesselInPortConsumption.day"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="form.deparure_date"
                                  placeholder="Enter Departure Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="PDays"
                                  v-model="vesselInPortConsumption.pdays"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="CO2"
                                  v-model="vesselInPortConsumption.co2"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="ID No"
                                  v-model="vesselInPortConsumption.id_no"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="ST"
                                  v-model="vesselInPortConsumption.st"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Berth"
                                  v-model="vesselInPortConsumption.berth"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="21"
                                align="center"
                                @click="addEmptyVesselInPortConsumption()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="21"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Cargos</h5>
                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Cargo Name</th>
                              <th>Qty</th>
                              <th>Unit</th>
                              <th>Freight Type</th>
                              <th>Freight Rate</th>
                              <th>Chartere</th>
                              <th>Exc Currency</th>
                              <th>Exc Exchange Rate</th>
                              <th>CP Date</th>
                              <th>Option Percent</th>
                              <th>Min Qty</th>
                              <th>Max Qty</th>
                              <th>Laycan Form</th>
                              <th>Laycan To</th>
                              <th>Demurrage Amount</th>
                              <th>Depatch Amount</th>
                              <th>Per Day / Per Hr</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(voyage_cargo, ar) in form.voyage_cargos"
                              :key="`voyage_cargo${ar}`"
                            >
                              <td @click="deleteVoyageCargo(voyage_cargo)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="voyage_cargo.cargo_name_id"
                                  :suggestions="cargo_names"
                                  placeholder="Select Cargo Name"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="QTY"
                                  v-model="voyage_cargo.qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Unit"
                                  v-model="voyage_cargo.unit"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="voyage_cargo.freight_type_id"
                                  :suggestions="freightTypes"
                                  placeholder="Enter freight Type"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Freight Rate"
                                  v-model="voyage_cargo.freight_rate"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="voyage_cargo.chartere_id"
                                  :suggestions="charterers"
                                  placeholder="Enter charterers"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="voyage_cargo.exc_currency_id"
                                  :suggestions="excCurrencies"
                                  placeholder="Enter exc currencies"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Exc Currency Rate"
                                  v-model="voyage_cargo.exc_exchange_rate"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  :date.sync="voyage_cargo.cp_date"
                                  placeholder="Enter CP Date"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Option Percent"
                                  v-model="voyage_cargo.option_percent"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Min Qty"
                                  v-model="voyage_cargo.min_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Max Qty"
                                  v-model="voyage_cargo.max_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Laycan From"
                                  v-model="voyage_cargo.laycan_from"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Laycan To"
                                  v-model="voyage_cargo.laycan_to"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Demurrage Amount"
                                  v-model="voyage_cargo.demurrage_amount"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Despatch Amount"
                                  v-model="voyage_cargo.despatch_amount"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Per Day / Hr"
                                  v-model="voyage_cargo.per_day_per_hr"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="18"
                                align="center"
                                @click="addEmptyVoyageCargo()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td colspan="18"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="col-sm-11">
                              <textarea
                                class="form-control"
                                placeholder="Remarks"
                                v-model="form.remarks"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <div class="col-sm-11">
                              <textarea
                                class="form-control"
                                placeholder="Notes"
                                v-model="form.notes"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="save"
                                text="Save"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "voyageCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        voyage_itenaries: [],
        voyage_cargos: [],
      },
      vessels: [],
      operation_types: [],
      tc_ins: [],
      tradeAreas: [],
      port_names: [],
      cargos: [],
      cargo_names: [],
      voyage_statuses: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/voyages/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_name,
        });
      });
      masters.operation_types.forEach((operation_type) => {
        this.operation_types.push({
          id: operation_type.id,
          name: operation_type.description,
        });
      });
      masters.tc_ins.forEach((tc_in) => {
        this.tc_ins.push({
          id: tc_in.id,
          name: tc_in.tc_code,
        });
      });
      masters.voyage_statuses.forEach((voyage_status) => {
        this.voyage_statuses.push({
          id: voyage_status.id,
          name: voyage_status.status,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });

      masters.port_names.forEach((port_name) => {
        this.port_names.push({
          id: port_name.id,
          name: port_name.port_name,
        });
      });
      masters.cargos.forEach((cargo) => {
        this.cargos.push({
          id: cargo.id,
          name: cargo.cargo_name_id,
        });
      });
      masters.cargo_names.forEach((cargo_name) => {
        this.cargo_names.push({
          id: cargo_name.id,
          name: cargo_name.short_name,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/voyages", this.form);
        this.isLoading = false;
        this.$router.push("/voyages");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVoyageItenary() {
      this.form.voyage_itenaries.push({
        fuel_type_id: "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
        voyage_itenary_port_activities:{}
      });
    },
    deleteVoyageItenary(voyage_itenary) {
      this.form.voyage_itenaries = this.form.voyage_itenaries.filter(
        (ar) => ar != voyage_itenary
      );
    },
    addEmptyVoyageCargo() {
      this.form.voyage_cargos.push({
        fuel_type_id: "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
    },
    deleteVoyageCargo(voyage_cargo) {
      this.form.voyage_cargos = this.form.voyage_cargos.filter(
        (ar) => ar != voyage_cargo
      );
    },

    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
