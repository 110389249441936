<template>
  <div class="myDivInput" contenteditable
    :placeholder="placeholder"
    oninput="if(this.innerHTML.trim()==='<br>')this.innerHTML=''"
  ></div>
</template>

<script type="text/javascript">
export default {
  props: ['placeholder']
}
</script>

<style type="text/css">
  .myDivInput {
  }
  .editor {
    border: solid 1px gray;
    width: 300px;
    height: 100px;
    padding: 6px;
    overflow: scroll;
  }
  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: grey;
    background-color: transparent;
  }
</style>