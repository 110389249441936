<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/estimates"></back-button> 
            Update estimate: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/currencies">Currencies</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">

                    <estimate
                      :estimateId="estimateId"
                    ></estimate>

                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'
import Estimate from '@/components/estimate/estimate.vue'

export default {
  name: 'EstimateUpdate',
  data () {
    return {
      form: {
      },
      estimateId: '',
      isLoading: false,
    }
  },
  components: {
    Estimate
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.estimateId = this.$route.params.estimateId
      this.isLoading = true
      let form = await axios.get(`/estimates/${this.$route.params.estimateId}`)
      form = form.data.data
      form.cargos = form.estimate_cargos
      form.itenaries = form.estimate_itenaries
      form.bunkersMaster = form.estimate_bunker_masters
      this.form = form
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.patch(`/estimates/${this.$route.params.estimateId}`, this.form)
        this.isLoading = false
        this.$router.push('/estimates')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>