<template>
  <!-- Top Menu Items -->
  <nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="mobile-only-brand pull-left">
      <div class="nav-header pull-left">
        <div class="logo-wrap">
          <router-link to="/">
            <img v-if="site.logo_path" style="width: 40px; height: 40px;" class="brand-img" :src="mediaUrl + site.logo_path" />
            <span class="brand-text">{{ site.name }}</span>
          </router-link>
        </div>
      </div>  
      <a id="toggle_nav_btn" class="toggle-left-nav-btn inline-block ml-20 pull-left" href="javascript:void(0);"><i class="zmdi zmdi-menu"></i></a>
      <a id="toggle_mobile_search" data-toggle="collapse" data-target="#search_form" class="mobile-only-view" href="javascript:void(0);"><i class="zmdi zmdi-search"></i></a>
      <a id="toggle_mobile_nav" class="mobile-only-view" href="javascript:void(0);"><i class="zmdi zmdi-more"></i></a>
      <form id="search_form" role="search" class="top-nav-search collapse pull-left"
        v-if="role.id == 2"
      >
        <select-site></select-site>
      </form>
    </div>
    <div id="mobile_only_nav" class="mobile-only-nav pull-right">
      <ul class="nav navbar-right top-nav pull-right">
        <li>
          <a href="javascript:void" @click="logout"><i class="zmdi zmdi-power"></i> &nbsp;<span>Log Out</span></a>
        </li>
      </ul>
    </div>  
  </nav>
  <!-- /Top Menu Items -->
</template>

<script type="text/javascript">
import { mapActions } from 'vuex'
import store from '@/store'
import SelectSite from '@/components/select-site.vue'

export default {
  computed: {
    site() {
      let site = store.getters['auth/site']
      return site ? site : {}
    },
    role() {
      let role = this.user.roles[0];
      return role;
    },
  },
  components: {
    SelectSite
  },
  methods: {
    ...mapActions({
      logOut: 'auth/logOut',
    }),
    logout() {
      this.logOut()
      location.replace('/');
    },
  }
}
</script>