<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Approve Invoices
            <add-button link="/invoices/approve-invoice/create"></add-button>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Financials</li>
            <li class="active">Approve Invoices</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Action</th>
                        <th>Trans No</th>
                        <th>Invoice Date</th>
                        <th>Due Date</th>
                        <th>Description</th>
                        <th>Counterparty</th>
                        <th>Curr</th>
                        <th>Invoice Amount</th>
                        <th>Vessel</th>
                        <th>Voyage No</th>
                        <th>Internal BU</th>
                        <th>Opr Type</th>
                        <th>Ops Coordinator</th>
                        <th>Chtr Specialist</th>
                        <th>Invoice No.</th>
                        <th>Status</th>
                        <th>Last Update User</th>
                        <th>Rejected Reason</th>
                        <th>Approval User</th>
                        <th>Approval Date</th>
                        <th>Company Name</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'approve_invoices',
  data () {
    return {
      approve_invoices: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      let approve_invoices = await axios.get(`/estimates?isFixed=1&page=${page}&rowsPerPage=${this.rowsPerPage}`)
      this.approve_invoices = approve_invoices.data.data
      this.count = approve_invoices.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    }
  }
}
</script>