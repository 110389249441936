<template>
    <TcOutCommission></TcOutCommission>
</template>

<script>
import TcOutCommission from '../../tc-ins/commissions/tc-commission.vue'
export default {
    components : {
        TcOutCommission
    }
}
</script>