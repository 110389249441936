
    import store from '@/store'
    import axios from 'axios'

    axios.defaults.baseURL = "http://api.ff.aaibuzz.com/api";
    store.dispatch('settings/setMediaUrl', "https://az-do-bucket-1.blr1.cdn.digitaloceanspaces.com/")
    store.dispatch('settings/setBaseUrl', "http://api.ff.aaibuzz.com/")

    axios.interceptors.request.use(config => {
        store.dispatch('errors/setErrors', {})
        store.dispatch('errors/setStatus', {})
        return config
    }, error => {
        return Promise.reject(error)
    })
    
    axios.interceptors.response.use((response) => {
        return response
    }, error => {
        const errors = error.response.data.errors
        Object.keys(errors).forEach((key) => {
            errors[key] = errors[key][0]
        })
        store.dispatch('errors/setErrors', errors)
        store.dispatch('errors/setStatus', error.response.status)
        return Promise.reject(error)
    })
