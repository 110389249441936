<template>
  <input style="margin-left: 10px; width: 50%" type="text" :placeholder="placeholder" v-model="value" @keydown.enter="searchData">
</template>

<script type="text/javascript">
export default {
  name: 'MySearch',
  data () {
    return {
      value: '',
    }
  },
  props: ['placeholder', 'search'],
  watch: {
    'search': 'getValue',
  },
  mounted() {
    this.getValue()
  },
  methods: {
    getValue() {
      this.value = this.search
    },
    searchData() {
      this.$emit('searchData', this.value)
    }
  }
}
</script>

<style scoped>
input {
  width: 200px;
  margin: 5px;
}
</style>