<template>
  <div class="fixed-sidebar-left">
    <ul class="nav navbar-nav side-nav nicescroll-bar">
      <li>
        <router-link to="/sites" v-if="role.id == 2">
          <div class="pull-left">
            <span class="right-nav-text">Company</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <!-- Chartering -->
      <li class="navigation-header">
        <span>Chartering</span>
        <i class="zmdi zmdi-more"></i>
      </li>
      <li>
        <router-link to="/quick-estimate/create">
          <div class="pull-left">
            <span class="right-nav-text">Quick Estimate</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li>
        <router-link to="/estimates">
          <div class="pull-left">
            <span class="right-nav-text">Estimates</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li>
        <router-link to="/fixtures">
          <div class="pull-left">
            <span class="right-nav-text">Fixture - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li>
        <a href="/tc-ins?type=tcin"
          ><div class="pull-left">
            <span class="right-nav-text">Tc In - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></a>
      </li>
      <li>
        <a href="/tc-ins?type=tcout"
          ><div class="pull-left">
            <span class="right-nav-text">Tc Out - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></a>
      </li>
      <li><hr class="light-grey-hr mb-10" /></li>
      <!-- Chartering End -->

      <!-- Operations -->
      <li class="navigation-header">
        <span>Operations</span>
        <i class="zmdi zmdi-more"></i>
      </li>
      <li>
        <router-link to="/voyages"
          ><div class="pull-left">
            <span class="right-nav-text">Voyage - list</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li><hr class="light-grey-hr mb-10" /></li>
      <!-- Operations end -->

      <!-- Financials -->
      <li class="navigation-header">
        <span>Financials</span>
        <i class="zmdi zmdi-more"></i>
      </li>
      <!-- <li>
        <router-link to="/financials/transactions"
          ><div class="pull-left">
            <span class="right-nav-text">Transactions</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li> -->
      <li>
        <router-link to="/financials/payables"
          ><div class="pull-left">
            <span class="right-nav-text">Payables</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/voyage-other-rev-expanse-payables"
          ><div class="pull-left">
            <span class="right-nav-text">Voyage Payables</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/payables_new?type=tcin"
          ><div class="pull-left">
            <span class="right-nav-text">Payables New</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/tc-out-receivables"
          ><div class="pull-left">
            <span class="right-nav-text">TC Out Receivables</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/voyage-other-rev-expanse-receivables"
          ><div class="pull-left">
            <span class="right-nav-text">Voyage Receivables</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/tc-out-receivables_new?type=tcout"
          ><div class="pull-left">
            <span class="right-nav-text">TC Out Receivables New</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/bank-statements"
          ><div class="pull-left">
            <span class="right-nav-text">Bank Statements</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/financials/payable-receivable-statements"
          ><div class="pull-left">
            <span class="right-nav-text">Payable/Receivable Statements</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <!-- <li>
        <router-link to="/financials/receivables"
          ><div class="pull-left">
            <span class="right-nav-text">Receivables</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li> -->
      <li><hr class="light-grey-hr mb-10" /></li>
      <!-- Financials end -->

      <!-- Data center -->
      <li class="navigation-header">
        <span>Data Center</span>
        <i class="zmdi zmdi-more"></i>
      </li>
      <li>
        <router-link to="/addresses"
          ><div class="pull-left">
            <span class="right-nav-text">Address - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div
        ></router-link>
      </li>
      <li>
        <router-link to="/cargo-names">
          <div class="pull-left">
            <span class="right-nav-text">Cargo name - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li>
        <router-link to="/port-names">
          <div class="pull-left">
            <span class="right-nav-text">Port - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li>
        <router-link to="/vessels">
          <div class="pull-left">
            <span class="right-nav-text">Vessel - List</span>
          </div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>

      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#charter_party_terms_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Charter Party Terms</span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="charter_party_terms_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/laytime-terms"> Laytime Terms </router-link>
          </li>
          <li>
            <router-link to="/payment-terms"> Payment Terms </router-link>
          </li>
          <li>
            <router-link to="/contract-types"> Contract Types </router-link>
          </li>
          <li>
            <router-link to="/tc-in-statuses"> TC In Statuses </router-link>
          </li>
          <li>
            <router-link to="/pricing-codes"> Pricing Codes </router-link>
          </li>
          <li>
            <router-link to="/expense-revenue-groups">
              Expense Revenue Groups
            </router-link>
          </li>
          <li>
            <router-link to="/expense-revenue-codes">
              Expense Revenue Codes
            </router-link>
          </li>
          <li>
            <router-link to="/operation-types"> Operation Types </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#delays_and_weather_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Delays & Weather</span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="delays_and_weather_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/beaufort-wind-force-scales">
              Beaufort Scale
            </router-link>
          </li>
          <li>
            <router-link to="/delay-reasons"> Delay Reasons </router-link>
          </li>
          <li>
            <router-link to="/delay-types"> Delay Types </router-link>
          </li>
          <li>
            <router-link to="/sea-states"> Sea States </router-link>
          </li>
          <li>
            <router-link to="/state-of-swells"> Sea Swells </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#vessels_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Vessels </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="vessels_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/class-societies"> Class Societies </router-link>
          </li>
          <li>
            <router-link to="/fuel-types"> Fuel/Lube Types </router-link>
          </li>
          <li>
            <router-link to="/fuel-consumption-categories">
              Fuel Consumption Categories
            </router-link>
          </li>
          <li>
            <router-link to="/fuel-grades"> Fuel Grades </router-link>
          </li>
          <li>
            <router-link to="/pool-point-types"> Pool-Point Types </router-link>
          </li>
          <!-- <li>
            <router-link to="index.html">Vessel Daily Cost</router-link>
          </li> -->
          <li>
            <router-link to="/vessel-fleets"> Vessel Fleets </router-link>
          </li>
          <li>
            <router-link to="/vessel-types"> Vessel Types </router-link>
          </li>
          <li>
            <router-link to="/type-codes"> Type Codes </router-link>
          </li>
          <li>
            <router-link to="/speed-consumption-types">
              Speed Consumption Types
            </router-link>
          </li>
          <li>
            <router-link to="/ownerships"> Ownerships </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#currencies_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Currencies </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="currencies_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/currencies">Currency Types</router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#ports_and_areas_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Ports & Areas </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="ports_and_areas_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/countries"> Country - List </router-link>
          </li>
          <li>
            <router-link to="/fuel-zone-sets"> Fuel Zone Sets </router-link>
          </li>
          <li>
            <router-link to="/fuel-zones"> Fuel Zones </router-link>
          </li>
          <li>
            <router-link to="/loadline-zones"> Loadline Zones </router-link>
          </li>
          <li>
            <router-link to="/loadline-zone-seasons">
              Loadline Zone Seasons
            </router-link>
          </li>
          <li>
            <router-link to="/reason-types"> Reason Types </router-link>
          </li>
          <li>
            <router-link to="/action-types"> Action Types </router-link>
          </li>
          <li>
            <router-link to="/port-activities"> Port Activities </router-link>
          </li>
          <li>
            <router-link to="/port-areas"> Port Areas </router-link>
          </li>
          <li>
            <router-link to="/port-functions"> Port Functions </router-link>
          </li>
          <li>
            <router-link to="/port-types"> Port Types </router-link>
          </li>
          <li>
            <router-link to="/region-codes"> Region Codes </router-link>
          </li>
          <li>
            <router-link to="/timezones"> Timezones </router-link>
          </li>
          <li>
            <router-link to="/trade-areas"> Trade Areas </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#cargos"
          ><div class="pull-left">
            <span class="right-nav-text">Cargos</span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="cargos" class="collapse collapse-level-1">
          <li>
            <router-link to="/freight-types"> Freight Types </router-link>
          </li>
          <li>
            <router-link to="/cargo-statuses"> Cargo Statuses </router-link>
          </li>
          <li>
            <router-link to="/unit-of-measure-of-rates">
              Unit of Measure of Rates
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#others_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Others </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="others_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/address-types"> Address Types </router-link>
          </li>
          <li>
            <router-link to="/units"> Units </router-link>
          </li>
          <li>
            <router-link to="/cargo-groups"> Cargo Groups </router-link>
          </li>
        </ul>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#masters_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Masters </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="masters_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/masters/bunker-purchase-types"
              >Bunker Purchase Types</router-link
            >
          </li>
          <li>
            <router-link to="/masters/bunker-delivery-types"
              >Bunker Delivery Types</router-link
            >
          </li>
          <li>
            <router-link to="/masters/time-to-tenders"
              >Time To Tenders</router-link
            >
          </li>
          <li>
            <router-link to="/masters/time-used">Time Used</router-link>
          </li>
          <li>
            <router-link to="/masters/working-days">Working Days</router-link>
          </li>
          <li>
            <router-link to="/masters/laytime-to-commences"
              >Laytime To Commences</router-link
            >
          </li>
          <li>
            <router-link to="/masters/laytime-types">Laytime Types</router-link>
          </li>
          <li>
            <router-link to="/masters/loading-costs">Loading Costs</router-link>
          </li>
          <li>
            <router-link to="/masters/nor-to-tenders"
              >Nor To Tenders</router-link
            >
          </li>
          <li>
            <router-link to="/masters/other-loading-terms"
              >Other Loading Terms</router-link
            >
          </li>
          <li>
            <router-link to="/masters/shifting-terms"
              >Shifting Terms</router-link
            >
          </li>
          <li>
            <router-link to="/masters/charter-party-forms"
              >Charter Party Forms</router-link
            >
          </li>
          <li>
            <router-link to="/masters/extra-freight-terms"
              >Extra Freight Terms</router-link
            >
          </li>
          <li>
            <router-link to="/masters/ld-rate-standards"
              >Ld Rate Standards</router-link
            >
          </li>
          <li>
            <router-link to="/masters/standard-paragraphs"
              >Standard Paragraphs</router-link
            >
          </li>
          <li>
            <router-link to="/masters/account-payables"
              >Account Payables</router-link
            >
          </li>
          <li>
            <router-link to="/masters/account-receivables"
              >Account Receivables</router-link
            >
          </li>
          <li>
            <router-link to="/masters/clearances">Clearances</router-link>
          </li>
          <li>
            <router-link to="/masters/interbanks">InterBank</router-link>
          </li>
          <li>
            <router-link to="/masters/retained-earnings"
              >Retained Earning</router-link
            >
          </li>
          <li>
            <router-link to="/masters/exchange-differences"
              >Exchange Difference</router-link
            >
          </li>
          <li>
            <router-link to="/masters/inter-companies"
              >InterCompany</router-link
            >
          </li>
          <li>
            <router-link to="/masters/open-months">Open Month</router-link>
          </li>
        </ul>
      </li>
      <li>
        <a
          href="javascript:void(0);"
          data-toggle="collapse"
          data-target="#crude_dr"
          ><div class="pull-left">
            <span class="right-nav-text">Crudes </span>
          </div>
          <div class="pull-right"><i class="zmdi zmdi-caret-down"></i></div>
          <div class="clearfix"></div
        ></a>
        <ul id="crude_dr" class="collapse collapse-level-1">
          <li>
            <router-link to="/late-reasons">Late Reasons</router-link>
          </li>
          <li>
            <router-link to="/reversal-reasons">Reversal Reasons</router-link>
          </li>
          <li>
            <router-link to="/lines-of-business">Lines Of Business</router-link>
          </li>
          <li>
            <router-link to="/payment-methods">Payment Methods</router-link>
          </li>
          <li>
            <router-link to="/exchange-rates">Exchange rates</router-link>
          </li>
          <li>
            <router-link to="/bunker-fuel-prices"
              >Bunker Fuel Prices</router-link
            >
          </li>
          <li>
            <router-link to="/bunker-hedging-values"
              >Bunker Hedging Values</router-link
            >
          </li>
          <li>
            <router-link to="/cp-places">Cp Places</router-link>
          </li>
          <li>
            <router-link to="/holidays">Holidays</router-link>
          </li>
          <li>
            <router-link to="/lightering-types">Lightering Types</router-link>
          </li>
          <li>
            <router-link to="/note-categories">Note Categories</router-link>
          </li>
          <li>
            <router-link to="/opa-rates">OPA Rates</router-link>
          </li>
        </ul>
      </li>
      <li><hr class="light-grey-hr mb-10" /></li>
      <!-- Data center end -->

      <!-- Admin -->
      <li class="navigation-header" v-if="role.id == 2">
        <span>Admin</span>
        <i class="zmdi zmdi-more"></i>
      </li>
      <li v-if="role.id == 2">
        <router-link to="/users">
          <div class="pull-left"><span class="right-nav-text">Users</span></div>
          <div class="pull-right"></div>
          <div class="clearfix"></div>
        </router-link>
      </li>
      <li><hr class="light-grey-hr mb-10" /></li>
      <!-- Admin end -->
    </ul>
  </div>
  <!-- /Left Sidebar Menu -->
</template>

<script type="text/javascript">
export default {
  data() {
    return {};
  },
  computed: {
    role() {
      let role = this.user.roles[0];
      return role;
    },
  },
};
</script>
