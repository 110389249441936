<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Bunkers
            <!-- <add-button link="/opeartions/bunkers/bunker-requirement-create"></add-button> -->
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Bunkers</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->
      
      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Requirement ID</th>
                        <th>Request Status</th>
                        <th>Bunker Manager</th>
                        <th>Procurement Status</th>
                        <th>Vessel</th>
                        <th>Voyage No. </th>
                        <th>Port</th>
                        <th>ETA</th>
                        <th>ETB</th>
                        <th>ETD</th>
                        <th>Delivery Date</th>
                        <th>Requested Delivery Date</th>
                        <th>Window From</th>
                        <th>Window To</th>
                        <th>Trade Area</th>
                        <th>Last Updated By</th>
                        <th>Last Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table :isLoading="isLoading" cols="10"></loading-table>
                      <router-link  
                        v-for="(bunker, at) in bunkers"
                        :key="`bunker${at}`"
                        :to="`/bunkers/${bunker.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ bunker.requirement_id }}</td>
                        <td>{{ bunker.request_status }}</td>
                        <td>{{ bunker.bunker_manager }} </td>
                        <td>{{ bunker.procurement_status }}</td>
                        <td>{{ bunker.vessel }}</td>
                        <td>{{ bunker.voyage_no }}</td>
                        <td>{{ bunker.port }}</td>
                        <td>{{ bunker.eta }}</td>
                        <td>{{ bunker.etb }}</td>
                        <td>{{ bunker.etd }}</td>
                        <td>{{ bunker.delivery_date }}</td>
                        <td>{{ bunker.requested_delivery_date }}</td>
                        <td>{{ bunker.window_from }}</td>
                        <td>{{ bunker.window_to }}</td>
                        <td>{{ bunker.trade_area }}</td>
                        <td>{{ bunker.last_updated_by }}</td>
                        <td>{{ bunker.last_updated_on }}</td>
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'bunkers',
  data () {
    return {
      bunkers: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true
      let bunkers = await axios.get(`/bunkers?page=${page}&rowsPerPage=${this.rowsPerPage}`)
      this.bunkers = bunkers.data.data
      this.count = bunkers.data.count
      this.serialNoStarting = (page - 1) * this.rowsPerPage
      this.isLoading = false
    }
  }
}
</script>