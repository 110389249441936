<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/tc-outs"></back-button>
            Port Advance/DA
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Operations</li>
            <li>Performace Report</li>
            <li class="active">Port Advance/DA</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <!-- Port Advance/DA -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="form-wrap form-horizontal">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Vessel</label>
                        <div class="col-sm-5">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Vessel"
                          ></my-select>
                        </div>
                        <div class="col-sm-4">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="CP Code"
                            v-model="form.cp_code"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Advance Inv No</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disbursement Inv No</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PO No</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Payee</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Payee"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Service Date</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Voyage No</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Voyage No"
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PDA Status</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select PDA Status"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">FD</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select FDA Status"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-2 control-label">Port</label>
                        <div class="col-sm-5">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Port"
                            v-model="form.vessel"
                          />
                        </div>
                        <label class="col-sm-1 control-label">No</label>

                        <div class="col-sm-4">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="0"
                            v-model="form.vessel"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Advance Sent</label
                        >
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.from_date"
                            placeholder="Enter Advance Sent"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disbursement Sent</label
                        >
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.from_date"
                            placeholder="Enter Disbursement Sent"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Port Func</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.cp_code"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Reference</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            v-model="form.cp_code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Arrival</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Arrival"
                            v-model="form.from_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Advance Currency</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Advance Currency"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disbursement Currency</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.from_voyage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Departure</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Departure"
                            v-model="form.from_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Advance Payment Terms</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Advance Payment Terms"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disbursement Payment Terms</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Disbursement Payment Terms"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Agent Short</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Agent Short"
                            v-model="form.from_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Advance Inv Due</label
                        >
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.to_date"
                            placeholder="Enter Advance Inv Due"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disbursement Inv Due</label
                        >
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.to_date"
                            placeholder="Enter Disbursement Inv Due"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Full Name</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Full Name"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Exchange Rate</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="0.000"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Exchange Rate</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="number"
                            class="form-control"
                            placeholder="0.000"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Estimate total</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="Estimate Total"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Rem bank</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.vessel_id"
                            :suggestions="vessels"
                            disabled
                            placeholder="Select Rem bank"
                          ></my-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">PDA Amount</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            placeholder="0.00"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >FDA Person In Change</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            disabled
                            class="form-control"
                            v-model="form.to_voyage"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >PDA Recieved Date</label
                        >
                        <div class="col-sm-9">
                          <my-datepicker
                            :date.sync="form.to_date"
                            placeholder="Enter PDA Recieved Due"
                          ></my-datepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Adv Remarks</label
                        >
                        <div class="col-sm-9">
                          <textarea
                            class="form-control"
                            v-model="form.bunkering_details"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Disb Remarks</label
                        >
                        <div class="col-sm-9">
                          <textarea
                            class="form-control"
                            v-model="form.bunkering_details"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="Advance_Disbursement-tab"
                          data-toggle="tab"
                          href="#Advance_Disbursement"
                          role="tab"
                          aria-controls="Advance_Disbursement"
                          aria-selected="true"
                          >Advance/Disbursement</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="PDA_APR-tab"
                          data-toggle="tab"
                          href="#PDA_APR"
                          role="tab"
                          aria-controls="PDA_APR"
                          aria-selected="false"
                          >PDA/APR</a
                        >
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade"
                      id="Advance_Disbursement"
                      role="tabpanel"
                      aria-labelledby="Advance_Disbursement-tab"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="panel-wrapper collapse in">
                            <div class="panel-body">
                              <div class="table-wrap">
                                <table class="table table-hover table-striped">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Ledger</th>
                                      <th>Description</th>
                                      <th>Est In curr</th>
                                      <th>Act In Curr</th>
                                      <th>Differences</th>
                                      <th>E Tax %</th>
                                      <th>Est Tax %</th>
                                      <th>A Tax %</th>
                                      <th>Act Tax</th>
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <td rowspan="3">Est Cost</td>
                                      <td>0</td>
                                      <td>Total Tax</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>
                                        <my-checkbox
                                          :selection.sync="form.freight_invoice"
                                          placeholder="List In Alphabetic Order"
                                        ></my-checkbox>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        <p style="float: right;">
                                          Total Currency
                                        </p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>
                                        <my-checkbox
                                          :selection.sync="form.freight_invoice"
                                          placeholder="Hide Lines with Zero Amount"
                                        ></my-checkbox>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        <p style="float: right;">Total</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>
                                        <my-checkbox
                                          :selection.sync="form.freight_invoice"
                                          placeholder="Keep Advance Balance"
                                        ></my-checkbox>
                                      </td>
                                      <td>Due to Agent</td>
                                      <td>0.00</td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <pagination
                                :count="count"
                                @submit="getData"
                              ></pagination>
                            </div>

                            <div class="row">
                              <div class="table-wrap">
                                <table class="table table-hover table-striped">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Use</th>
                                      <th>Invoice No</th>
                                      <th>Invoice Date</th>
                                      <th>Available Amt</th>
                                      <th>Applied Amt</th>
                                      <th>Remarks</th>
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <td colspan="4">
                                        <p style="float: right;">Total</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <pagination
                                :count="count"
                                @submit="getData"
                              ></pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="PDA_APR"
                      role="tabpanel"
                      aria-labelledby="PDA_APR-tab"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="panel-wrapper collapse in">
                            <div class="panel-body">
                              <div class="table-wrap">
                                <p>APR Status</p>
                                <table class="table table-hover table-striped">
                                  <thead>
                                    <tr>
                                      <th>Sr. No.</th>
                                      <th>Ledger</th>
                                      <th>Description</th>
                                      <th>PDA In curr</th>
                                      <th>APR In Curr</th>
                                      <th>APR In Curr</th>
                                      <th>APR In Curr</th>
                                      <th>APR In Curr</th>
                                    </tr>
                                  </thead>
                                  <tfoot>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">Total</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">
                                          Amount Advanced
                                        </p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">Inv No</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>Due To Agent</td>
                                      <td>0.00</td>
                                    </tr>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">Inv Date</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">
                                          Payment Terms
                                        </p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                    <tr>
                                      <td colspan="3">
                                        <p style="float: right;">Due Date</p>
                                      </td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                      <td>0.00</td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <pagination
                                :count="count"
                                @submit="getData"
                              ></pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="panel-wrapper collapse in">
                        <div class="panel-body">
                          <div class="table-wrap">
                            <table class="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Use</th>
                                  <th>Invoice No</th>
                                  <th>Invoice Date</th>
                                  <th>Available Amt</th>
                                  <th>Applied Amt</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tfoot>
                                <tr>
                                  <td colspan="4">
                                    <p style="float: right;">Total</p>
                                  </td>
                                  <td>0.00</td>
                                  <td>0.00</td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <pagination
                            :count="count"
                            @submit="getData"
                          ></pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Row -->
      </div>
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
