<template>
  <section>
    <h5><u>Port Activities</u></h5>
    <div class="tab-struct custom-tab-1">
      <ul role="tablist" class="nav nav-tabs" id="myTabs_7">
        <li
          :class="vl == 0 ? 'active' : ''"
          role="presentation"
          v-for="(voyageLaytime, vl) in form.voyage_laytimes.filter(
            (voyageLaytime) =>
              voyageLaytime.position == count && voyageLaytime.port_name_id
          )"
          :key="`voyageLaytime${count}${vl}`"
        >
          <a
            aria-expanded="true"
            data-toggle="tab"
            role="tab"
            :id="`port_activity_tab_${count}${vl}`"
            :href="`#port_activity_${count}${vl}`"
            >{{
              voyageLaytime.port_name ? voyageLaytime.port_name.port_name : ""
            }}</a
          >
        </li>
      </ul>

      <div class="tab-content" id="`myTabContent_7`">
        <div
          :class="vl == 0 ? 'tab-pane fade in active' : 'tab-pane fade in'"
          role="tabpanel"
          v-for="(voyageLaytime, vl) in form.voyage_laytimes.filter(
            (voyageLaytime) =>
              voyageLaytime.position == count && voyageLaytime.port_name_id
          )"
          :key="`voyageLaytime${count}${vl}`"
          :id="`port_activity_${count}${vl}`"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="editable-table-wrapper">
                <table class="table table-striped" style="height: 400px">
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Activity</th>
                      <th>From Date/Time</th>
                      <th>To Date/Time</th>
                      <th>Duration</th>
                      <th>%</th>
                      <th>Remarks</th>
                      <th>Deduction</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        voyageLaytimePortActivity, vlpa
                      ) in voyageLaytime.voyage_laytime_port_activities"
                      :key="`voyageLaytimePortActivity${vlpa}`"
                    >
                      <td
                        @click="
                          deleteActivity(
                            voyageLaytime,
                            voyageLaytimePortActivity
                          )
                        "
                      >
                        <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                        {{ vlpa + 1 }}.
                      </td>
                      <td>
                        <my-select
                          maxWidth="YES"
                          disableFormControl="true"
                          :selection.sync="
                            voyageLaytimePortActivity.port_activity_id
                          "
                          :suggestions="portActivities"
                          placeholder="Select activity"
                        ></my-select>
                      </td>
                      <td>
                        <my-datepicker
                          :disableFormControl="true"
                          :date.sync="voyageLaytimePortActivity.from_date_time"
                          placeholder="From date and time"
                          myWidth="200px"
                          @change="onLaytimeUpdated"
                        ></my-datepicker>
                      </td>
                      <td>
                        <my-datepicker
                          :disableFormControl="true"
                          :date.sync="voyageLaytimePortActivity.to_date_time"
                          placeholder="To date and time"
                          myWidth="200px"
                          @change="onLaytimeUpdated"
                        ></my-datepicker>
                      </td>
                      <td>
                        {{ voyageLaytimePortActivity.duration | dhm }}
                      </td>
                      <td>
                        <input
                          :style="
                            voyageLaytimePortActivity.percent != 100
                              ? 'color: blue'
                              : 'color: black'
                          "
                          v-uppercase
                          placeholder="%"
                          v-model="voyageLaytimePortActivity.percent"
                          @change="onLaytimeUpdated"
                        />
                      </td>
                      <td>
                        <input
                          style="width: 150px !important"
                          v-uppercase
                          placeholder="Remarks"
                          v-model="voyageLaytimePortActivity.remarks"
                        />
                      </td>
                      <td>
                        {{ voyageLaytimePortActivity.deduction | dhm }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="12"
                        align="center"
                        @click="addEmptyActivity(voyageLaytime)"
                      >
                        Add New Row
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cp_date: "",
    };
  },
  props: ["form", "portActivities", "count"],
  mounted() {},
  methods: {
    addEmptyActivity(voyageLaytime) {
      let portActivity = {
        port_activity_id: "",
        from_date_time: 0,
        duration: 0,
        percent: 100,
        to_date_time: 0,
        remarks: "",
        deduction: 0,
      };
      voyageLaytime.voyage_laytime_port_activities.push(portActivity);
    },
    deleteActivity(voyageLaytime, voyageLaytimePortActivity) {
      voyageLaytime.voyage_laytime_port_activities.splice(
        voyageLaytime.voyage_laytime_port_activities.indexOf(
          voyageLaytimePortActivity
        ),
        1
      );
    },
    onLaytimeUpdated() {
      this.$emit("onLaytimeUpdated");
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}

table td input {
  width: 70px !important;
}
.myBold {
  font-weight: bold;
}
</style>
