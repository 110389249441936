<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/port-functions"></back-button>
            Update port function: {{ form.id
            }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li>
              <router-link to="/port-functions">Class Societies</router-link>
            </li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Code*</label>
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Code"
                            v-model="form.code"
                          />
                          <span class="error-block">{{ errors.code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Port Operation</label
                        >
                        <div class="col-sm-9">
                          <input
                            v-uppercase
                            type="text"
                            class="form-control"
                            placeholder="Port Operation"
                            v-model="form.port_operation"
                          />
                          <span class="error-block">{{
                            errors.port_operation
                          }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Delay Type</label>
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.delay_type_id"
                            :suggestions="delayTypes"
                            placeholder="Enter Delay Type"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"
                          >Delay Reason</label
                        >
                        <div class="col-sm-9">
                          <my-select
                            :selection.sync="form.delay_reason_id"
                            :suggestions="delayReasons"
                            placeholder="Enter Delay Reason"
                          ></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_active"
                            placeholder="Is Active"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button
                            :isLoading="isLoading"
                            @submit="update"
                            text="Update"
                          ></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "portFunctionUpdate",
  data() {
    return {
      form: {},
      delayTypes: [],
      delayReasons: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("port_functions/masters");
      masters = masters.data;
      masters.delay_types.forEach((delay_type) => {
        this.delayTypes.push({
          id: delay_type.id,
          name: delay_type.code + " - " + delay_type.description,
        });
      });
      masters.delay_reasons.forEach((delay_reason) => {
        this.delayReasons.push({
          id: delay_reason.id,
          name: delay_reason.code + " - " + delay_reason.reason,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/port_functions/${this.$route.params.portFunctionId}`
      );
      this.form = form.data.data;
      this.form.delay_type_id = this.form.delay_type_id || "";
      this.form.delay_reason_id = this.form.delay_reason_id || "";
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/port_functions/${this.$route.params.portFunctionId}`,
          this.form
        );
        this.isLoading = false;
        this.$router.push("/port-functions");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
