<template>
  <section>
    <a
      href="javascript:void"
      @click="openVoyageFreightInvoicesModal"
      title="Freight Invoices"
      >Freight Invoices</a
    >

    <div id="myVoyageFreightInvoicesModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Freight Invoices</h4>
          <VoyageFreightInvoiceCreate
            :form="form"
            :voyageFreightInvoices="form.voyage_freight_invoices"
            :count="`create`"
            :voyageCargos="voyageCargos"
            :paymentTerms="paymentTerms"
            :freightTypes="freightTypes"
            :portFunctions="portFunctions"
            @onSaveVoyageFreightInvoiceCreate="onSaveVoyageFreightInvoiceCreate"
            :voyageBrokers="voyageBrokers"
            :addresses="addresses"
            :brokerRateTypes="brokerRateTypes"
            :brokerPaymentMethods="brokerPaymentMethods"
            :brokerFreightPaymentMethods="brokerFreightPaymentMethods"
          ></VoyageFreightInvoiceCreate>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Action</th>
                <th>SER</th>
                <th>Counterparty</th>
                <th>Invoice Date</th>
                <th>Invoice No.</th>
                <th>Invoice %</th>
                <th>Amount (USD)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(voyageFreightInv, vfi) in form.voyage_freight_invoices"
                :key="`voyageFreightInvoice${vfi}`"
              >
                <td>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <i
                            @click="
                              deleteVoyageFreightInvoice(voyageFreightInv)
                            "
                            class="fa fa-times-circle-o"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="col-md-6">
                          <VoyageFreightInvoiceCreate
                            :count="vfi + 1"
                            :form="form"
                            :voyageCargos="voyageCargos"
                            :paymentTerms="paymentTerms"
                            :freightTypes="freightTypes"
                            :portFunctions="portFunctions"
                            :voyageFreightInvEdit="voyageFreightInv"
                            :brokerFreightPaymentMethods="
                              brokerFreightPaymentMethods
                            "
                            @onSaveVoyageFreightInvoiceCreate="
                              onSaveVoyageFreightInvoiceCreate
                            "
                            :voyageBrokers="voyageBrokers"
                            :addresses="addresses"
                            :brokerRateTypes="brokerRateTypes"
                            :brokerPaymentMethods="brokerPaymentMethods"
                          ></VoyageFreightInvoiceCreate>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ voyageFreightInv.position }}.</td>
                <td>
                  {{
                    form.voyage_cargos.length > 0
                      ? form.voyage_cargos[0].charterer.short_name
                      : ""
                  }}
                </td>
                <td>{{ voyageFreightInv.invoice_date | ddmmyyyy }}</td>
                <td>{{ voyageFreightInv.invoice_no }}</td>
                <td>{{ voyageFreightInv.invoice_percent }}</td>
                <td>{{ voyageFreightInv.invoice_amount | round2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveMyVoyageFreightInvoices"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeMyVoyageFreightInvoicesModal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import VoyageFreightInvoiceCreate from "./create.vue";

export default {
  data() {
    return {};
  },
  props: [
    "form",
    "paymentTerms",
    "freightTypes",
    "portFunctions",
    "voyageCargos",
    "voyageBrokers",
    "addresses",
    "brokerRateTypes",
    "brokerPaymentMethods",
    "brokerFreightPaymentMethods",
  ],
  components: {
    VoyageFreightInvoiceCreate,
  },
  methods: {
    async openVoyageFreightInvoicesModal() {
      var modal = document.getElementById("myVoyageFreightInvoicesModal");
      modal.style.display = "block";
    },
    closeMyVoyageFreightInvoicesModal() {
      var modal = document.getElementById("myVoyageFreightInvoicesModal");
      modal.style.display = "none";
    },
    saveMyVoyageFreightInvoices() {
      this.$emit("calculateFreightInvoices");
      this.closeMyVoyageFreightInvoicesModal();
    },
    onSaveVoyageFreightInvoiceCreate(voyageFreightInvoice) {
      this.$emit("onSaveVoyageFreightInvoiceCreate", voyageFreightInvoice);
    },
    onLaytimeUpdated() {
      this.$emit("onLaytimeUpdated");
    },
    deleteVoyageFreightInvoice(voyageFreightInv) {
      this.form.voyage_freight_invoices.splice(
        this.form.voyage_freight_invoices.indexOf(voyageFreightInv),
        1
      );
    },
  },
};
</script>
