<template>
  <section>
    <h5><u>Itinerary</u></h5>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Actions</th>
            <th colspan="2">Sr. No.</th>
            <th>Port</th>
            <th>F</th>
            <th>Miles</th>
            <th>Spd</th>
            <th>WF%</th>
            <th>SDays</th>
            <th>XSD</th>
            <th>TSDays</th>
            <th>Arrival</th>
            <th>Departure</th>
            <th>TPD</th>
            <th>Cargo</th>
            <th>L/D Qty</th>
            <th>L/D Rate</th>
            <th>Terms</th>
            <!-- <th>PDays</th>
            <th>XPD</th> -->
            <th>PortExp</th>
            <!-- <th>OExp$/t</th>
            <th>Dem</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(itenary, it) in voyageItenaries"
            :key="`itenary${it}`"
            :style="
              itenary.miles < 0 ||
              itenary.speed < 0 ||
              itenary.sea_days < 0 ||
              itenary.extra_sea_days < 0 ||
              itenary.total_sea_days < 0 ||
              itenary.total_port_days < 0
                ? 'background: lightpink;'
                : ''
            "
          >
            <td colspan="3">
              <PortActivitiesModal
                :voyageItenary="itenary"
                :voyageBunkerMasters="voyageBunkerMasters"
                :count="it"
                :voyageItenaries="voyageItenaries"
                :portActivities="portActivities"
                :reasonTypes="reasonTypes"
                @updateItenaryDetails="updateItenaryDetails"
                @onBunkerChanged="onBunkerChanged"
              ></PortActivitiesModal>
              <PortExpensesModal
                :voyageItenary="itenary"
                :voyageItenaries="voyageItenaries"
                :agents="agents"
                :currencies="currencies"
                :count="it"
                :form="form"
                @onPortExpensesChanged="onPortExpensesChanged"
              ></PortExpensesModal>
              <PortCargosModal
                :voyageItenary="itenary"
                :voyageItenaries="voyageItenaries"
                :cargoNames="cargoNames"
                :count="it"
                :form="form"
                @onPortCargosChanged="onPortCargosChanged"
              ></PortCargosModal>
              &nbsp;
              <span
                v-if="it != 0 && it != voyageItenaries.length - 1"
                @click="deleteItenary(itenary)"
              >
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
              </span>
              &nbsp;
              <span v-if="it != 0" @click="addItenary(it)">
                <i class="fa fa-plus-circle"></i>
              </span>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.port_name_id"
                :suggestions="portNames"
                placeholder="Select port"
                @change="updateMiles(itenary)"
              ></my-select>
            </td>
            <td>
              <my-select
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.port_function_id"
                :suggestions="portFunctions"
                placeholder="Select port function"
                @change="updateItenaryDetails"
              ></my-select>
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Miles"
                v-model="itenary.miles"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Speed"
                v-model="itenary.speed"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Weather Factor"
                v-model="itenary.weather_factor"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Sea Days"
                v-model="itenary.sea_days"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Extra Sea Days"
                v-model="itenary.extra_sea_days"
              />
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Total Sea Days"
                v-model="itenary.total_sea_days"
              />
            </td>
            <td>
              <my-datepicker
                disabled
                :disableFormControl="true"
                :date.sync="itenary.arrival_date"
                placeholder="Enter arrival date"
                @change="updateItenaryDetails"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disabled
                :disableFormControl="true"
                :date.sync="itenary.departure_date"
                placeholder="Enter departure date"
                @change="updateItenaryDetails"
                myWidth="200px"
              ></my-datepicker>
            </td>
            <td>
              <input
                disabled
                v-uppercase
                type="number"
                placeholder="Total Port Days"
                v-model="itenary.total_port_days"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <my-select
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.cargo_id"
                :suggestions="cargoNames"
                placeholder="Select cargo"
              ></my-select>
            </td>
            <td>
              <input
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                v-uppercase
                type="number"
                placeholder="L/D Qty"
                v-model="itenary.load_discharge_qty"
                @blur="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                v-uppercase
                type="number"
                placeholder="L/D Rate"
                v-model="itenary.load_discharge_rate"
                @blur="updateItenaryDetails"
              />
            </td>
            <td>
              <my-select
                v-if="
                  portFunctions.find((portFunction) => portFunction.code == 'L')
                    .id == itenary.port_function_id ||
                  portFunctions.find((portFunction) => portFunction.code == 'D')
                    .id == itenary.port_function_id
                "
                maxWidth="YES"
                disableFormControl="true"
                :selection.sync="itenary.laytime_term_id"
                :suggestions="laytimeTerms"
                placeholder="Select laytime term"
              ></my-select>
            </td>
            <!-- <td>
              <input
                v-uppercase
                type="number"
                placeholder="Port Days"
                v-model="itenary.port_days"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Extra Port Days"
                v-model="itenary.extra_port_days"
                @change="updateItenaryDetails"
              />
            </td> -->
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Port Expenses"
                v-model="itenary.port_expenses"
                @change="updateItenaryDetails"
              />
            </td>
            <!-- <td>
              <input
                v-uppercase
                type="number"
                placeholder="Other Expenses"
                v-model="itenary.other_expenses"
                @change="updateItenaryDetails"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Demurrage"
                v-model="itenary.demurrage"
                @change="updateItenaryDetails"
              />
            </td> -->
          </tr>
          <tr>
            <td></td>
            <!-- Actions -->
            <td></td>
            <!-- Sr. No. -->
            <td></td>
            <!-- Port -->
            <td></td>
            <!-- F -->
            <td>
              <!-- Miles -->
              <h5>{{ totalMiles | round2 }}</h5>
            </td>
            <td></td>
            <!-- Spd -->
            <td></td>
            <!-- WF% -->
            <td>
              <!-- SDays -->
              <h5>{{ totalSeaDays | round2 }}</h5>
            </td>
            <td>
              <!-- XSD -->
              <h5>{{ totalExtraSeaDays | round2 }}</h5>
            </td>
            <td>
              <h5>{{ compeleteSeaDays | round2 }}</h5>
            </td>
            <!-- TSDays -->
            <td></td>
            <!-- Arrival -->
            <td></td>
            <!-- Departure -->
            <td>
              <h5>{{ completePortDays | round2 }}</h5>
            </td>
            <!-- TPD -->
            <td></td>
            <!-- Cargo -->
            <td></td>
            <!-- L/D Qty -->
            <td></td>
            <!-- L/D Rate -->
            <td></td>
            <!-- Terms -->
            <!-- <td> -->
            <!-- PDays -->
            <!-- <h5>{{ totalPortDays | round2 }}</h5> -->
            <!-- </td> -->
            <!-- <td> -->
            <!-- XPD -->
            <!-- <h5>{{ totalExtraPortDays | round2 }}</h5> -->
            <!-- </td> -->
            <td>
              <!-- PortExp -->
              <h5>{{ totalPortExpenses | round2 }}</h5>
            </td>
            <!-- <td> -->
            <!-- OExp$/t -->
            <!-- <h5>{{ totalOtherExpenses }}</h5> -->
            <!-- </td> -->
            <!-- <td> -->
            <!-- Dem -->
            <!-- <h5>{{ totalDemurrageExpenses }}</h5> -->
            <!-- </td> -->
          </tr>
          <tr>
            <td
              colspan="12"
              align="center"
              @click="addEmptyVoyagetenaryAtTheEnd()"
            >
              Add New Row
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
import PortActivitiesModal from "./port_activities/index.vue";
import PortExpensesModal from "./port_expenses/index.vue";
import PortCargosModal from "./port_cargos/index.vue";

export default {
  name: "voyageItenaries",
  data() {
    return {
      cargoNames: [],
    };
  },
  components: {
    PortActivitiesModal,
    PortExpensesModal,
    PortCargosModal,
  },
  computed: {
    totalMiles() {
      let totalMiles = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalMiles += parseFloat(itenary.miles);
        });
      return totalMiles;
    },
    totalSeaDays() {
      let totalSeaDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalSeaDays += parseFloat(itenary.sea_days);
        });
      return totalSeaDays;
    },
    totalExtraSeaDays() {
      let totalExtraSeaDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalExtraSeaDays += parseFloat(itenary.extra_sea_days);
        });
      return totalExtraSeaDays;
    },
    compeleteSeaDays() {
      let compeleteSeaDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          compeleteSeaDays += parseFloat(itenary.total_sea_days);
        });
      return compeleteSeaDays;
    },
    totalPortDays() {
      let totalPortDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalPortDays += parseFloat(itenary.port_days);
        });
      return totalPortDays;
    },
    totalExtraPortDays() {
      let totalExtraPortDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalExtraPortDays += parseFloat(itenary.extra_port_days);
        });
      return totalExtraPortDays;
    },
    completePortDays() {
      let completePortDays = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          completePortDays += parseFloat(itenary.total_port_days);
        });
      return completePortDays;
    },
    totalPortExpenses() {
      let totalPortExpenses = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalPortExpenses += parseFloat(itenary.port_expenses);
        });
      return totalPortExpenses;
    },
    totalOtherExpenses() {
      let totalOtherExpenses = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalOtherExpenses += parseFloat(itenary.other_expenses);
        });
      return totalOtherExpenses;
    },
    totalDemurrageExpenses() {
      let totalDemurrageExpenses = 0;
      if (this.voyageItenaries)
        this.voyageItenaries.forEach((itenary) => {
          totalDemurrageExpenses += parseFloat(itenary.demurrage);
        });
      return totalDemurrageExpenses;
    },
  },
  props: [
    "form",
    "voyageItenaries",
    "voyageCargos",
    "voyageBunkerMasters",
    "portNames",
    "portFunctions",
    "laytimeTerms",
    "allCargoNames",
    "portActivities",
    "reasonTypes",
    "agents",
    "currencies",
  ],
  watch: {
    voyageCargos: {
      handler(value) {
        this.onVoyageCargosChanged(value);
      },
      deep: true,
    },
  },
  mounted() {
    this.onVoyageCargosChanged(this.voyageCargos);
  },
  methods: {
    deleteItenary(itenary) {
      let r = confirm("Are you sure you want to delete this itinerary");
      if (r) {
        // Delete any port_name if it is deleted from the itenary
        this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
          let voyageBunkerMasterBunker =
            voyageBunkerMaster.voyage_bunker_master_bunkers.find(
              (vbmb) =>
                vbmb.port_name_id == itenary.port_name_id &&
                vbmb.port_function_id == itenary.port_function_id
            );
          if (voyageBunkerMasterBunker) {
            voyageBunkerMaster.voyage_bunker_master_bunkers.splice(
              voyageBunkerMaster.voyage_bunker_master_bunkers.indexOf(
                voyageBunkerMasterBunker,
                1
              )
            );
          }
        });

        this.voyageItenaries.splice(this.voyageItenaries.indexOf(itenary), 1);
      }
      // this.itenaries = this.itenaries.filter(it => it != itenary)
      this.updateMiles();
    },
    addItenary(it) {
      let itenary = {
        port_name_id: "",
        // port_name: itenary.port_name,
        port_function_id: "",
        // port_function: itenary.port_function,
        miles: 0,
        speed: 0,
        weather_factor: this.form.dwf,
        sea_days: 0,
        extra_sea_days: 0,
        total_sea_days: 0,
        // cargo_name: itenary.cargo_name,
        cargo_id: "",
        load_discharge_qty: 0,
        load_discharge_rate: 0,
        port_days: 0,
        extra_port_days: 0,
        total_port_days: 0,
        port_expenses: 0,
        arrival_date: "",
        departure_date: "",
        // laytime_term: itenary.laytime_term,
        laytime_term_id: "",
        voyage_itenary_port_activities: [],
        voyage_itenary_port_expenses: [],
        voyage_itenary_cargos: [],
      };
      this.voyageItenaries.splice(it, 0, itenary);
      this.voyageItenaries.forEach((itenary, i) => (itenary.position = i + 1));
    },
    addEmptyVoyagetenaryAtTheEnd() {
      let itenary = {
        position: this.voyageItenaries.length + 1,
        port_name_id: "",
        // port_name: itenary.port_name,
        port_function_id: "",
        // port_function: itenary.port_function,
        miles: 0,
        speed: 0,
        weather_factor: this.form.dwf,
        sea_days: 0,
        extra_sea_days: 0,
        total_sea_days: 0,
        // cargo_name: itenary.cargo_name,
        cargo_id: "",
        load_discharge_qty: 0,
        load_discharge_rate: 0,
        port_days: 0,
        extra_port_days: 0,
        total_port_days: 0,
        port_expenses: 0,
        arrival_date: "",
        departure_date: "",
        // laytime_term: itenary.laytime_term,
        laytime_term_id: "",
        voyage_itenary_port_activities: [],
        voyage_itenary_port_expenses: [],
        voyage_itenary_cargos: [],
      };
      this.voyageItenaries.push(itenary);
    },
    updateMiles(itenary) {
      // console.log("Update Miles");
      let checkIfPortEmpty = false;
      this.voyageItenaries.forEach((it) => {
        if (it.port_name_id == "") checkIfPortEmpty = true;
      });
      if (!checkIfPortEmpty) {
        if (itenary) {
          let portName = this.portNames.find(
            (pn) => pn.id == itenary.port_name_id
          );
          itenary.port_name = portName;
          // itenary.port_name = portName;
          let portFunction = this.portFunctions.find(
            (pf) => pf.id == itenary.port_function_id
          );
          itenary.port_function = portFunction;
          let cargoName = this.cargoNames.find(
            (cn) => cn.id == itenary.cargo_id
          );
          itenary.cargo_name = cargoName;

          // Add Voyage Bunker Masters of new Itenary
          this.form.voyage_bunker_masters.forEach((voyageBunkerMaster) => {
            let voyageBunkerMasterBunker =
              voyageBunkerMaster.voyage_bunker_master_bunkers.find(
                (vbmb) =>
                  vbmb.port_name_id == itenary.port_name_id &&
                  vbmb.port_function_id == itenary.port_function_id
              );
            if (!voyageBunkerMasterBunker) {
              voyageBunkerMasterBunker = {
                port_name_id: itenary.port_name_id,
                port_name: itenary.port_name ? itenary.port_name.port_name : "",
                port_function_id: itenary.port_function_id,
                port_function: itenary.port_function
                  ? itenary.port_function.code
                  : "",
                rob_prev: 0,
                sea_consumption: 0,
                sea_consumption_rate: 0,
                rob_arrival: 0,
                received_qty: 0,
                received_price: 0,
                port_consumption: 0,
                rob_departure: 0,
              };
              voyageBunkerMaster.voyage_bunker_master_bunkers.push(
                voyageBunkerMasterBunker
              );
            }
          });
        }
        // console.log(itenary)
        // console.log("Update Miles 2");
        this.$emit("updateMiles");
        this.onBunkerChanged();
      }
    },
    updateItenaryDetails() {
      this.$emit("updateItenaryDetails");
    },
    onBunkerChanged() {
      this.$emit("onBunkerChanged");
    },
    onVoyageCargosChanged(value) {
      let cargoNames = [];
      value.forEach((cargo) => {
        let cargoName = this.allCargoNames.find(
          (acn) => acn.id == cargo.cargo_name_id
        );
        if (cargoName) cargoNames.push(cargoName);
      });
      this.cargoNames = cargoNames;
    },
    onPortCargosChanged() {
      this.$emit("onPortCargosChanged");
    },
    onPortExpensesChanged() {
      this.$emit("onPortExpensesChanged");
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>