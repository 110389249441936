<template>
  <section>
    <div class="editable-table-wrapper">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Port*</th>
            <th>Est GMT</th>
            <th>Proj./Act GMT</th>
            <th>Difference</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Delivery</td>
            <td>
              <my-select
                disableFormControl="true"
                :selection.sync="form.delivery_port_id"
                :suggestions="portNames"
                placeholder="Enter Port"
              ></my-select>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.delivery_est_gmt"
                placeholder="Est GMT"
                myWidth="200px"
                @change="onPortsUpdated('delivery_est_gmt')"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.delivery_actual_gmt"
                placeholder="Proj./Act GMT"
                myWidth="200px"
                @change="onPortsUpdated('delivery_actual_gmt')"
              ></my-datepicker>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Difference"
                v-model="form.delivery_difference"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Remarks"
                v-model="form.delivery_remarks"
              />
            </td>
          </tr>
          <tr>
            <td>Redelivery</td>
            <td>
              <my-select
                disableFormControl="true"
                :selection.sync="form.redelivery_port_id"
                :suggestions="portNames"
                placeholder="Enter Port"
              ></my-select>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.redelivery_est_gmt"
                placeholder="Est GMT"
                myWidth="200px"
                @change="onPortsUpdated('redelivery_est_gmt')"
              ></my-datepicker>
            </td>
            <td>
              <my-datepicker
                disableFormControl="true"
                :date.sync="form.redelivery_actual_gmt"
                placeholder="Proj./Act GMT"
                myWidth="200px"
                @change="onPortsUpdated('redelivery_actual_gmt')"
              ></my-datepicker>
            </td>
            <td>
              <input
                v-uppercase
                type="number"
                placeholder="Difference"
                v-model="form.redelivery_difference"
              />
            </td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Remarks"
                v-model="form.redelivery_remarks"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>Duration/Basis(days)</td>
            <td>
              <input
                v-uppercase
                type="text"
                placeholder="Duration"
                v-model="form.est_duration"
                @change="onPortsUpdated('est_duration')"
              />
            </td>
            <td>{{ form.actual_duration | round2 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  props: ["form", "portNames"],
  methods: {
    onPortsUpdated(elementName) {
      console.log("Element");
      console.log(elementName);
      this.$emit("onPortsUpdated", elementName);
    },
  },
};
</script>