<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/voyage-bunker"></back-button>
            Voyage Bunkers
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Voyage</li>
            <li>Bunkers</li>
            <li class="active">Voyage Bunkers</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="Summary-tab"
              data-toggle="tab"
              href="#Summary"
              role="tab"
              aria-controls="Summary"
              aria-selected="true"
              >Summary</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="LSF-tab"
              data-toggle="tab"
              href="#LSF"
              role="tab"
              aria-controls="LSF"
              aria-selected="false"
              >LSF</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="LSM-tab"
              data-toggle="tab"
              href="#LSM"
              role="tab"
              aria-controls="LSM"
              aria-selected="false"
              >LSM</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="IFO-tab"
              data-toggle="tab"
              href="#IFO"
              role="tab"
              aria-controls="IFO"
              aria-selected="false"
              >IFO</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="MDO-tab"
              data-toggle="tab"
              href="#MDO"
              role="tab"
              aria-controls="MDO"
              aria-selected="false"
              >MDO</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="Survey-tab"
              data-toggle="tab"
              href="#Survey"
              role="tab"
              aria-controls="Survey"
              aria-selected="false"
              >Survey</a
            >
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade"
            id="Summary"
            role="tabpanel"
            aria-labelledby="Summary-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Init Qty</th>
                  <th>Init Prc</th>
                  <th>Sea Cons</th>
                  <th>Avg Sea Cons</th>
                  <th>Port Cons</th>
                  <th>Avg Port Cons</th>
                  <th>Req Qty</th>
                  <th>Opr Qty</th>
                  <th>Last Prc</th>
                  <th>End Qty</th>
                  <th>End Prc</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="LSF"
                      v-model="form.add_1"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="LSM"
                      v-model="form.add_1"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="IFO"
                      v-model="form.add_1"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="MDO"
                      v-model="form.add_1"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>Bunker Requirements</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Port</th>
                  <th>Seq</th>
                  <th>LSF Req</th>
                  <th>LSM Req</th>
                  <th>IFO Req</th>
                  <th>MDO Req</th>
                  <th>Request Status</th>
                  <th>Procurement Status</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <h5>Bunker Liftings</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>S</th>
                  <th>Reference Port</th>
                  <th>LSF</th>
                  <th>LSM</th>
                  <th>IFO</th>
                  <th>MDO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <my-checkbox :selection.sync="form.demurrage"></my-checkbox>
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      class="form-control"
                      placeholder="FUJAIRAH"
                      v-model="form.add_1"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="number"
                      disabled
                      class="form-control"
                      placeholder="1212.00"
                      v-model="form.add_2"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="col-sm-3 control-label"
                    >Starting Lube (L)</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="starting_lube"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="col-sm-3 control-label">Received</label>
                  <div class="col-sm-9">
                    <input
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="received"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="col-sm-3 control-label">Ending Lube</label>
                  <div class="col-sm-9">
                    <input
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder=""
                      v-model="ending_lube"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <button class="btn btn-primary">
                  Pre-voyage Bunker Invoice
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-sm-3 control-label">Fuel Zone Set</label>
                  <div class="col-sm-9">
                    <input
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder="Fast Freight "
                      v-model="fuel_zone_set"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="col-sm-9">
                    <my-checkbox
                      :selection.sync="
                        form.turn_off_insufficient_bunker_warnings
                      "
                      placeholder="Turn Off Insufficient Bunker Warnings"
                    ></my-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="col-sm-3 control-label">Scrubber Type</label>
                  <div class="col-sm-9">
                    <input
                      v-uppercase
                      type="text"
                      class="form-control"
                      placeholder="None"
                      v-model="scrubber_type"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="col-sm-9">
                    <my-checkbox
                      :selection.sync="form.use_scrubber"
                      placeholder="Use Scrubber"
                    ></my-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <div class="col-sm-9">
                    <my-checkbox
                      :selection.sync="form.disable_backup_fuels"
                      placeholder="Disable Backup Fuels"
                    ></my-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="LSF"
            role="tabpanel"
            style="overflow-x: scroll;"
            aria-labelledby="LSF-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Sea Cons</th>
                  <th>Avg Sea Cons</th>
                  <th>ROB Arr</th>
                  <th>Port Cons</th>
                  <th>Avg Port Cons</th>
                  <th>Req Qty</th>
                  <th>Opr Qty</th>
                  <th>Price</th>
                  <th>ROB Dpt</th>
                  <th>ST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>1700.00</th>
                  <th></th>
                  <th></th>
                  <th>12.00</th>
                  <th></th>
                  <th>12.00</th>
                  <th>12.00</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="LSM"
            role="tabpanel"
            aria-labelledby="LSM-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Sea Cons</th>
                  <th>Avg Sea Cons</th>
                  <th>ROB Arr</th>
                  <th>Port Cons</th>
                  <th>Avg Port Cons</th>
                  <th>Req Qty</th>
                  <th>Opr Qty</th>
                  <th>Price</th>
                  <th>ROB Dpt</th>
                  <th>ST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>1700.00</th>
                  <th></th>
                  <th></th>
                  <th>12.00</th>
                  <th></th>
                  <th>12.00</th>
                  <th>12.00</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="IFO"
            role="tabpanel"
            aria-labelledby="IFO-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Sea Cons</th>
                  <th>Avg Sea Cons</th>
                  <th>ROB Arr</th>
                  <th>Port Cons</th>
                  <th>Avg Port Cons</th>
                  <th>Req Qty</th>
                  <th>Opr Qty</th>
                  <th>Price</th>
                  <th>ROB Dpt</th>
                  <th>ST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>1700.00</th>
                  <th></th>
                  <th></th>
                  <th>12.00</th>
                  <th></th>
                  <th>12.00</th>
                  <th>12.00</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="MDO"
            role="tabpanel"
            aria-labelledby="MDO-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Port Name</th>
                  <th>Sea Cons</th>
                  <th>Avg Sea Cons</th>
                  <th>ROB Arr</th>
                  <th>Port Cons</th>
                  <th>Avg Port Cons</th>
                  <th>Req Qty</th>
                  <th>Opr Qty</th>
                  <th>Price</th>
                  <th>ROB Dpt</th>
                  <th>ST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder="SOHAR"
                      v-model="port_name"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      placeholder=""
                      v-model="remarks"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                  <td>
                    <input
                      v-uppercase
                      type="text"
                      disabled
                      placeholder=""
                      v-model="days"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>1700.00</th>
                  <th></th>
                  <th></th>
                  <th>12.00</th>
                  <th></th>
                  <th>12.00</th>
                  <th>12.00</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="Survey"
            role="tabpanel"
            aria-labelledby="Survey-tab"
          >
            <br />
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Surveyor</th>
                  <th>Port</th>
                  <th>Inv No</th>
                  <th>Inv Date</th>
                  <th>Curr</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "vesselCreate",
  data() {
    return {
      form: {
        type_code_id: "",
        ownership_id: "",
        vessel_type_id: "",
        vessel_fleet_id: "",
        trade_area_id: "",
        owner_id: "",
        class_society_id: "",
        scrubber: "",
        dwt_mt: 0.0,
        sw_summer_draft_m: 0.0,
        tpc: 0.0,
        bale_capacity_ft3: 0.0,
        grain_capacity_ft3: 0.0,
        daily_cost: 0.0,
        laden_speed: 0.0,
        ballast_speed: 0.0,
        vessel_in_port_consumptions: [],
        vessel_speed_consumptions: [],
      },
      typeCodes: [],
      ownerships: [],
      vesselTypes: [],
      vesselFleets: [],
      tradeAreas: [],
      owners: [],
      classSocieties: [],
      fuelTypes: [],
      units: [],
      fuelGrades: [],
      consumptionTypes: [],
      b_ls: [],
      scrubbers: [],
      isLoading: false,
    };
  },
  mounted() {
    this.form.site_id = this.site.id;
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/vessels/masters");
      masters = masters.data;
      masters.type_codes.forEach((type_code) => {
        this.typeCodes.push({
          id: type_code.id,
          name: type_code.code + " - " + type_code.name,
        });
      });
      masters.ownerships.forEach((ownership) => {
        this.ownerships.push({
          id: ownership.id,
          name: ownership.code + " - " + ownership.name,
        });
      });
      masters.vessel_types.forEach((vessel_type) => {
        this.vesselTypes.push({
          id: vessel_type.id,
          name: vessel_type.vessel_type,
        });
      });
      masters.vessel_fleets.forEach((vessel_fleet) => {
        this.vesselFleets.push({
          id: vessel_fleet.id,
          name: vessel_fleet.fleet,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.owners.forEach((owner) => {
        this.owners.push({
          id: owner.id,
          name: owner.short_name + " - " + owner.full_name,
        });
      });
      masters.class_societies.forEach((class_society) => {
        this.classSocieties.push({
          id: class_society.id,
          name: class_society.name,
        });
      });
      masters.fuel_types.forEach((fuel_type) => {
        this.fuelTypes.push({
          id: fuel_type.id,
          name: fuel_type.fuel_type,
        });
      });
      masters.units.forEach((unit) => {
        this.units.push({
          id: unit.id,
          name: unit.unit,
        });
      });
      masters.fuel_grades.forEach((fuel_grade) => {
        this.fuelGrades.push({
          id: fuel_grade.id,
          name: fuel_grade.grade,
        });
      });
      masters.consumption_types.forEach((consumption_type) => {
        this.consumptionTypes.push({
          id: consumption_type.id,
          name: consumption_type.type,
        });
      });
      masters.bls.forEach((b_l) => {
        this.b_ls.push({
          id: b_l,
          name: b_l,
        });
      });
      masters.scrubbers.forEach((scrubber) => {
        this.scrubbers.push({
          id: scrubber,
          name: scrubber,
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/vessels", this.form);
        this.isLoading = false;
        this.$router.push("/vessels");
      } catch (e) {
        this.isLoading = false;
      }
    },
    addEmptyVesselInPortConsumption() {
      let unit = this.units.find((unit) => unit.name == "MT");
      this.form.vessel_in_port_consumptions.push({
        fuel_type_id: "",
        unit_id: unit ? unit.id : "",
        fuel_grade_id: "",
        capacity: 0,
        margin: 0,
        loading: 0,
        discharge: 0,
        idle_on: 0,
        idle_off: 0,
        heat: 0,
        heat_plus: 0,
        heat_plus_plus: 0,
        igs: 0,
        clean: 0,
        maneuver: 0,
        auxil: 0,
        cool: 0,
        boiler: 0,
        incinerator: 0,
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselInPortConsumption(vesselInPortConsumption) {
      this.form.vessel_in_port_consumptions = this.form.vessel_in_port_consumptions.filter(
        (ar) => ar != vesselInPortConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    addEmptyVesselSpeedConsumption() {
      this.form.vessel_speed_consumptions.push({
        speed: 0,
        b_l: "",
        engine_load: 0,
        consumption_type_id: "",
        vessel_speed_consumption_fuels: [],
      });
      this.updateVesselSpeedConsumptionFuels();
    },
    deleteVesselSpeedConsumption(vesselSpeedConsumption) {
      this.form.vessel_speed_consumptions = this.form.vessel_speed_consumptions.filter(
        (ar) => ar != vesselSpeedConsumption
      );
      this.updateVesselSpeedConsumptionFuels();
    },
    updateVesselSpeedConsumptionFuels() {
      this.form.vessel_speed_consumptions.forEach((vsc) => {
        this.form.vessel_in_port_consumptions.forEach((vipc) => {
          let vscf = vsc.vessel_speed_consumption_fuels.find(
            (vscf) => vscf.fuel_type_id == vipc.fuel_type_id
          );
          if (!vscf) {
            let fuel_type = this.fuelTypes.find(
              (fuelType) => fuelType.id == vipc.fuel_type_id
            );
            if (fuel_type)
              vsc.vessel_speed_consumption_fuels.push({
                fuel_type_id: vipc.fuel_type_id,
                fuel_type: fuel_type.name,
                value: 0,
              });
          }
        });
      });
    },
  },
};
</script>
