<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/port-activities"></back-button> 
            Update port activity: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/port-activities">Port Activities</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Activity*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Activity" v-model="form.activity">
                          <span class="error-block">{{ errors.activity }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Code*</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Code" v-model="form.code">
                          <span class="error-block">{{ errors.code }}</span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Remarks</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Remarks" v-model="form.remarks">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Action Type</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.action_type_id" :suggestions="actionTypes" placeholder="Enter Action Type"></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Pair With Activty</label>
                        <div class="col-sm-9">
                          <input v-uppercase type="text" class="form-control" placeholder="Pair With Activty" v-model="form.pair_with_activity">
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label">Reason Type</label>
                        <div class="col-sm-9">
                          <my-select :selection.sync="form.reason_type_id" :suggestions="reasonTypes" placeholder="Enter Reason Type"></my-select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox :selection.sync="form.import_into_laytime_calculations" placeholder="Import into Laytime Calculations"></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"></label>
                        <div class="col-sm-9">
                          <my-checkbox
                            :selection.sync="form.is_active"
                            placeholder="Is Active"
                          ></my-checkbox>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <div class="col-sm-offset-3 col-sm-9">
                          <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'PortActivityUpdate',
  data () {
    return {
      form: {},
      actionTypes: [],
      reasonTypes: [],
      isLoading: false,
    }
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('port_activities/masters')
      masters = masters.data
      masters.action_types.forEach(action_type => {
        this.actionTypes.push({
          id: action_type.id,
          name: action_type.action_code + ' - ' + action_type.description
        })
      })
      masters.reason_types.forEach(reason_type => {
        this.reasonTypes.push({
          id: reason_type.id,
          name: reason_type.reason_code + ' - ' + reason_type.description
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/port_activities/${this.$route.params.portActivityId}`)
      this.form = form.data.data
      this.form.action_type_id = this.form.action_type_id || ''
      this.form.reason_type_id = this.form.reason_type_id || ''
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.patch(`/port_activities/${this.$route.params.portActivityId}`, this.form)
        this.isLoading = false
        this.$router.push('/port-activities')
      } catch(e) {
        this.isLoading = false
      }
    }
  }
}
</script>