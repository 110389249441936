<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">

      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/port-names"></back-button> 
            Update port name: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/port-names">Port Names</router-link></li>
            <li class="active">Update</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Port Modal -->
      <div id="portModal" class="modal">

        <!-- Port Modal content -->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Port List</h4>
          </div>
          <div class="modal-body">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Select</th>
                  <th>Port Name</th>
                  <th>Port Code</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                </tr>
              </thead>
              <tbody>
                <loading-table :isLoading="isPortListLoading" cols="6"></loading-table>
                <tr 
                  v-for="(thirdPartyPort, at) in thirdPartyPorts"
                  :key="`thirdPartyPort${at}`"
                >
                  <td>{{ at + 1 }}</td>
                  <td>
                    <input type="radio" class="form-check-input" name="portradio" :value="thirdPartyPort" v-model="selectedThirdPartyPort" @change="updatePortDetails">
                  </td>
                  <td>{{ thirdPartyPort.port_name }}</td>
                  <td>{{ thirdPartyPort.port_code }}</td>
                  <td>{{ thirdPartyPort.latitude }}</td>
                  <td>{{ thirdPartyPort.longitude }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closePortModal">Close</button>
          </div>
        </div>

      </div>

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port Name</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Port Name" v-model="form.port_name" @blur="openPortModal">
                              <span class="error-block">{{ errors.port_name }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port Code</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Port Code" v-model="form.port_code">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port Type</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.port_type_id" :suggestions="portTypes" placeholder="Enter Port Type"></my-select>
                              <span class="error-block">{{ errors.port_type_id }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Country</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.country_id" :suggestions="countries" placeholder="Enter Country"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Timezone</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.timezone_id" :suggestions="timezones" placeholder="Enter Timezone"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Latitude</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Latitude" v-model="form.latitude">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Longitude</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Longitude" v-model="form.longitude">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">UN Code</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="UN Code" v-model="form.un_code">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port Operator</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Port Operator" v-model="form.port_operator">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port No</label>
                            <div class="col-sm-9">
                              <input v-uppercase type="text" class="form-control" placeholder="Port No" v-model="form.port_no">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Port Area</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.port_area_id" :suggestions="portAreas" placeholder="Enter Port Area"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Region Code</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.region_code_id" :suggestions="portAreas" placeholder="Enter Region Code"></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Loadline Zone</label>
                            <div class="col-sm-9">
                              <my-select :selection.sync="form.loadline_zone_id" :suggestions="portAreas" placeholder="Enter Loadline Zone"></my-select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox :selection.sync="form.inactive" placeholder="In Active"></my-checkbox>
                              <my-checkbox :selection.sync="form.bunkering_port" placeholder="Bunkering Port"></my-checkbox>
                              <my-checkbox :selection.sync="form.single_berth" placeholder="Single Berth"></my-checkbox>
                              <my-checkbox :selection.sync="form.port_range" placeholder="Port Range"></my-checkbox>
                              <my-checkbox :selection.sync="form.hide_passing_point_eta_etd_in_voyage" placeholder="Hide Passing Point ETA/ETD in Voyage"></my-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div class="col-sm-9">
                              <my-checkbox :selection.sync="form.projection_port" placeholder="Projection Port"></my-checkbox>
                              <my-checkbox :selection.sync="form.lightering_port" placeholder="Lightering Port"></my-checkbox>
                              <my-checkbox :selection.sync="form.waterway_port" placeholder="Waterway Port"></my-checkbox>
                              <my-checkbox :selection.sync="form.low_sulfur_eca" placeholder="Low Sulfur ECA"></my-checkbox>
                              <my-checkbox :selection.sync="form.is_active" placeholder="Is Active"></my-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5>Port Berths</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Short Name</th>
                              <th>Full Name</th>
                              <th>Is Active</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(portBerth, ar) in form.port_berths"
                              :key="`berth${ar}`"
                            >
                              <td @click="deletePortBerth(portBerth)">
                                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input v-uppercase type="text" placeholder="Short Name" v-model="portBerth.short_name">
                              </td>
                              <td>
                                <input v-uppercase type="text" placeholder="Full Name" v-model="portBerth.full_name">
                              </td>
                              <td>
                                <my-checkbox :selection.sync="portBerth.is_active"></my-checkbox>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9" align="center" @click="addEmptyPortBerth()">Add New Row</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Port Aliases</h5>
                      <br>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Alias Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(portAlias, ar) in form.port_aliases"
                              :key="`berth${ar}`"
                            >
                              <td @click="deletePortAlias(portAlias)">
                                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input v-uppercase type="text" placeholder="Alias Name" v-model="portAlias.alias_name">
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9" align="center" @click="addEmptyPortAlias()">Add New Row</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button :isLoading="isLoading" @submit="update" text="Update"></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
      <!-- /Row -->
    </div>

  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'portNameUpdate',
  data () {
    return {
      form: {},
      portTypes: [],
      countries: [],
      timezones: [],
      portAreas: [],
      regionCodes: [],
      loadlineZones: [],
      isLoading: false,
      isPortListLoading: false,
      thirdPartyPorts: [],
      selectedThirdPartyPort: null,
    }
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get('port_names/masters')
      masters = masters.data
      masters.port_types.forEach(port_type => {
        this.portTypes.push({
          id: port_type.id,
          name: port_type.code + ' - ' + port_type.type,
        })
      })
      masters.countries.forEach(country => {
        this.countries.push({
          id: country.id,
          name: country.name + ' - ' + country.code,
        })
      })
      masters.timezones.forEach(timezone => {
        this.timezones.push({
          id: timezone.id,
          name: timezone.code + ' - ' + timezone.description,
        })
      })
      masters.port_areas.forEach(port_area => {
        this.portAreas.push({
          id: port_area.id,
          name: port_area.code + ' - ' + port_area.area_name,
        })
      })
      masters.region_codes.forEach(region_code => {
        this.regionCodes.push({
          id: region_code.id,
          name: region_code.code + ' - ' + region_code.region_name,
        })
      })
      masters.loadline_zones.forEach(loadline_zone => {
        this.loadlineZones.push({
          id: loadline_zone.id,
          name: loadline_zone.name + ' - ' + loadline_zone.description,
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/port_names/${this.$route.params.portNameId}`)
      this.form = form.data.data
      this.form.port_type_id = this.form.port_type_id || '',
      this.form.country_id = this.form.country_id || '',
      this.form.timezone_id = this.form.timezone_id || '',
      this.form.port_area_id = this.form.port_area_id || '',
      this.form.region_code_id = this.form.region_code_id || '',
      this.form.loadline_zone_id = this.form.loadline_zone_id || '',
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.post(`/port_names`, this.form)
        this.isLoading = false
        this.$router.push('/port-names')
      } catch(e) {
        this.isLoading = false
      }
    },
    addEmptyPortBerth() {
      this.form.port_berths.push({
        short_name: '',
        full_name: '',
        is_active: 1,
      })
    },
    deletePortBerth(portBerth) {
      this.form.port_berths = this.form.port_berths.filter(pb => pb != portBerth)
    },
    addEmptyPortAlias() {
      this.form.port_aliases.push({
        alias_name: '',
      })
    },
    deletePortAlias(portAlias) {
      this.form.port_aliases = this.form.port_aliases.filter(pa => pa != portAlias)
    },
    async openPortModal() {
      var modal = document.getElementById("portModal");
      modal.style.display = "block";
      if(this.form.port_name != null && this.form.port_name != '') {
        this.isPortListLoading = true
        let thirdPartyPorts = await axios.get(`/third-party-route-api/getPorts?portName=${this.form.port_name}`)
        this.thirdPartyPorts = thirdPartyPorts.data.data
        this.isPortListLoading = false
      }
    },
    closePortModal() {
      var modal = document.getElementById("portModal");
      modal.style.display = "none";
    },
    updatePortDetails() {
      console.log(this.selectedThirdPartyPort)
      this.form.port_name = this.selectedThirdPartyPort.port_name
      this.form.port_code = this.selectedThirdPartyPort.port_code
      this.form.latitude = this.selectedThirdPartyPort.latitude
      this.form.longitude = this.selectedThirdPartyPort.longitude
      this.closePortModal()
    }
  }
}
</script>