<template>
  <section>
    <span @click="openTCPaymentModal" title="TC Payment">
      <i class="fa fa-external-link-square" aria-hidden="true"></i>
    </span>

    <div :id="`myTCPaymentModal${count}`" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <span class="glyphicon glyphicon-indent-left"></span> TC Payment
          <a title="Close" @click="saveTCPayment"
            ><i
              ng-click="CloseModal()"
              class="glyphicon glyphicon-remove pull-right"
            ></i>
          </a>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <!-- v-if="
                      tcInPayment.payment_type == 'Period' ||
                      tcInPayment.payment_type == 'Special'
                    " -->
                  <AddDetails
                    :count="count"
                    :form="form"
                    :tcInPayment="tcInPayment"
                    :delayTypes="delayTypes"
                    @updateTCPaymentAddDetails="updateTCPaymentAddDetails"
                  ></AddDetails>
                </div>
                <div class="col-md-3">
                  <Invoice
                    :count="count"
                    :tcInPayment="tcInPayment"
                    :form="form"
                  ></Invoice>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3">Vessel</div>
                    <div class="col-md-9">
                      <span class="myBold">{{
                        form.vessel ? form.vessel.vessel_name : ""
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">TC Code</div>
                    <div class="col-md-9">
                      <span class="myBold">{{ form.tc_code }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Owner</div>
                    <div class="col-md-9">
                      <span class="myBold">{{
                        form.owner ? form.owner.short_name : ""
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">TC Date</div>
                    <div class="col-md-9">
                      <span class="myBold">{{ form.date | ddmmyyyy }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Delivery</div>
                    <div class="col-md-9">
                      <span class="myBold">{{
                        form.delivery_est_gmt | ddmmyyyy
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">Redelivery</div>
                    <div class="col-md-9">
                      <span class="myBold">{{
                        form.redelivery_est_gmt | ddmmyyyy
                      }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <my-checkbox
                        :selection.sync="tcInPayment.include_in_pand_l"
                        placeholder="Include In P&L"
                      ></my-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Period From</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="tcInPayment.period_from"
                        placeholder="Enter date and time"
                        myWidth="200px"
                        @change="updateTcInPayment"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Period To</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="tcInPayment.period_to"
                        placeholder="Enter date and time"
                        myWidth="200px"
                        @change="updateTcInPayment"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Days</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        disabled
                        class="form-control"
                        type="number"
                        placeholder="Days"
                        v-model="tcInPayment.days"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Remarks</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        type="text"
                        placeholder="Remarks"
                        v-model="tcInPayment.remarks"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Payment Type</div>
                    <div class="col-md-9">
                      <my-select
                        :selection.sync="tcInPayment.payment_type"
                        :suggestions="paymentTypes"
                        placeholder="Select type"
                        @change="updateTCPaymentAddDetails"
                      ></my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Contact</div>
                    <div class="col-md-9">
                      <input
                        class="form-control"
                        v-uppercase
                        placeholder="Contact name"
                        v-model="tcInPayment.contact_name"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Hire Rate</div>
                    <div class="col-md-9">
                      <input
                        class="form-control"
                        v-uppercase
                        placeholder="Hire Rate"
                        v-model="hire_rate"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <br />
                    <div class="col-md-3 control-label">Approved</div>
                    <div class="col-md-9">
                      <my-select
                        :selection.sync="tcInPayment.is_approved"
                        :suggestions="approvalTypes"
                        placeholder=""
                      ></my-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-3 control-label">Invoice No.</div>
                    <div class="col-md-9">
                      <input
                        v-uppercase
                        class="form-control"
                        placeholder="Invoice no"
                        v-model="tcInPayment.invoice_no"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Issue Date</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="tcInPayment.issue_date"
                        placeholder="Enter date and time"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 control-label">Due Date</div>
                    <div class="col-md-9">
                      <my-datepicker
                        :date.sync="tcInPayment.due_date"
                        placeholder="Enter date and time"
                        myWidth="200px"
                      ></my-datepicker>
                    </div>

                    <div class="form-group" style="margin-top: 60px">
                      <label class="col-sm-3 control-label">Attachment</label>
                      <div class="col-sm-9">
                        <a
                          v-if="tcInPayment.attachment"
                          :href="mediaUrl + tcInPayment.attachment"
                          target="_blank"
                        >
                          {{ tcInPayment.attachment }}
                        </a>
                        <input
                          style="margin-top: 10px; margin-bottom: 10px"
                          type="file"
                          id="file"
                          name="attachment"
                          ref="file"
                          accept="image/*"
                          @input="attachmentAdded"
                        />
                      </div>
                    </div>
                    <div class="form-group" style="margin-top: 100px">
                      <label class="col-sm-3 control-label">Attachment 2</label>
                      <div class="col-sm-9">
                        <a
                          v-if="tcInPayment.attachment_1"
                          :href="mediaUrl + tcInPayment.attachment_1"
                          target="_blank"
                        >
                          {{ tcInPayment.attachment_1 }}
                        </a>
                        <input
                          style="margin-top: 10px; margin-bottom: 10px"
                          type="file"
                          id="file1"
                          name="attachment2"
                          ref="file1"
                          accept="image/*"
                          @input="attachmentAdded2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5><u>Details</u></h5>

          <div class="editable-table-wrapper">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Code</th>
                  <th>Description</th>
                  <th>Voyage no.</th>
                  <th>Invoice no</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Days/Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>Amount USD</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    tcInPaymentDetail, tipd
                  ) in tcInPayment.tc_in_payment_details.filter(
                    (tipd) => tipd.include_in_invoice == '1'
                  )"
                  :key="`tcInPaymentDetail${tipd}`"
                  :style="
                    tcInPaymentDetail.code == 'OFFH' ||
                    tcInPaymentDetail.code == 'OHAC' ||
                    tcInPaymentDetail.code == 'OGAP' ||
                    tcInPaymentDetail.code == 'OHRAC' ||
                    tcInPaymentDetail.code == 'ORGAP'
                      ? 'background: yellow'
                      : tcInPaymentDetail.code == 'OLSF' ||
                        tcInPaymentDetail.code == 'OLSM' ||
                        tcInPaymentDetail.code == 'ORLSM' ||
                        tcInPaymentDetail.code == 'ORLSM'
                      ? 'background: pink'
                      : tcInPaymentDetail.code == 'LSFDL' ||
                        tcInPaymentDetail.code == 'LSFOAC' ||
                        tcInPaymentDetail.code == 'LSFRDL' ||
                        tcInPaymentDetail.code == 'LSMDL' ||
                        tcInPaymentDetail.code == 'LSMOAC' ||
                        tcInPaymentDetail.code == 'LSMRDL'
                      ? 'background: chartreuse'
                      : tcInPaymentDetail.code == 'PREVINV'
                      ? 'background: bisque'
                      : ''
                  "
                >
                  <td @click="deleteTcInPaymentDetail(tcInPaymentDetail)">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                    {{ tipd + 1 }}.
                  </td>
                  <td>{{ tcInPaymentDetail.code }}</td>
                  <td>{{ tcInPaymentDetail.description }}</td>
                  <td>
                    <my-select
                      maxWidth="YES"
                      disableFormControl="true"
                      :selection.sync="tcInPaymentDetail.voyage_no"
                      :suggestions="tcInVoyages"
                      placeholder="Voyage no"
                    ></my-select>
                  </td>
                  <!-- <td
                    v-if="
                      tcInPaymentDetail.code == 'VOYEXP' ||
                      tcInPaymentDetail.code == 'ILOHC'
                    "
                  >
                    <my-select
                      :selection.sync="tcInPaymentDetail.voyage_no"
                      :suggestions="tcInVoyages"
                      placeholder="Voyage no"
                    ></my-select>
                  </td>
                  <td v-else></td> -->
                  <td>{{ tcInPaymentDetail.invoice_no }}</td>
                  <td>{{ tcInPaymentDetail.from | ddmmyyyy }}</td>
                  <td>{{ tcInPaymentDetail.to | ddmmyyyy }}</td>
                  <td>
                    {{ tcInPaymentDetail.days_qty | round3 }}
                    <!-- <br />
                    {{ tcInPaymentDetail.days_qty * 24 }} hrs -->
                  </td>
                  <td>{{ tcInPaymentDetail.rate }}</td>
                  <td>
                    <input
                      v-uppercase
                      style="width: 70px; !important"
                      placeholder="Amount"
                      v-model="tcInPaymentDetail.amount"
                      @change="calculateAmount"
                    />
                  </td>
                  <td>
                    {{ tcInPaymentDetail.amount_usd | round2 }}
                  </td>
                  <td>
                    <input
                      v-uppercase
                      style="width: 150px; !important"
                      placeholder="Remarks"
                      v-model="tcInPaymentDetail.remarks"
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total</td>
                  <td>{{ tcInPayment.amount | round2 }}</td>
                  <td>{{ tcInPayment.amount | round2 }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    align="center"
                    @click="addEmptyPaymentDetailVoyage()"
                  >
                    Add Voyage Exp.
                  </td>
                  <td
                    colspan="2"
                    align="center"
                    @click="addEmptyPaymentDetailMisc()"
                  >
                    Add Misc. Exp.
                  </td>
                  <td
                    colspan="2"
                    align="center"
                    @click="addEmptyPaymentDetailIlohc()"
                  >
                    Add ILOHC
                  </td>
                  <td
                    colspan="2"
                    align="center"
                    @click="addEmptyPaymentDetailDeduction()"
                  >
                    Add Deductions
                  </td>
                </tr>
                <tr>
                  <td colspan="10"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveTCPayment">
            Save
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeTCPaymentModal"
          >
            Close
          </button>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import AddDetails from "./add-details.vue";
import Invoice from "./invoice.vue";
import moment from "moment";

export default {
  name: "TCPaymentModal",
  data() {
    return {
      tcInVoyages: [],
      attachmentData: [],
      codes: [
        {
          id: "VOYEXP",
          name: "Voyage Expenses",
        },
        {
          id: "ILOHC",
          name: "ILOHC",
        },
      ],
      hire_rate: 0,
    };
  },
  components: {
    AddDetails,
    Invoice,
  },
  props: [
    "form",
    "count",
    "tcInPayment",
    "tcInPayments",
    "fuelTypes",
    "expenseRevenueCodes",
    "voyages",
    "delayTypes",
    "paymentTypes",
    "approvalTypes",
  ],
  watch: {
    voyages: "onVoyagesUpdated",
    hire_rate: "updateTCPaymentAddDetails",
  },
  mounted() {
    this.onVoyagesUpdated();
    this.hire_rate = this.form.tc_in_pricings[0].hire_rate;
  },
  methods: {
    attachmentAdded() {
      this.tcInPayment.inputFile1 = this.$refs.file
        ? this.$refs.file.files[0]
        : null;
    },
    attachmentAdded2() {
      this.tcInPayment.inputFile2 = this.$refs.file1
        ? this.$refs.file1.files[0]
        : null;
    },
    async openTCPaymentModal() {
      var modal = document.getElementById("myTCPaymentModal" + this.count);
      modal.style.display = "block";
    },
    closeTCPaymentModal() {
      var modal = document.getElementById("myTCPaymentModal" + this.count);
      modal.style.display = "none";
    },
    addEmptyVoyageBroker() {
      this.form.voyage_brokers.push({
        broker_id: "",
        rate: 0,
        rate_type: "%",
        payment_method: "Deduct from hire",
        is_claim_commissionable:
          "Claim comissions will be deducted from claim invoice",
        remarks: "",
      });
    },
    deleteVoyageBroker(voyageBroker) {
      this.form.voyage_brokers.splice(
        this.form.voyage_brokers.indexOf(voyageBroker),
        1
      );
    },
    onVoyagesUpdated() {
      let tcInVoyages = [];
      this.voyages.forEach((voyage) => {
        tcInVoyages.push({
          id: voyage.voyage_no,
          name: voyage.voyage_no,
        });
      });
      this.tcInVoyages = tcInVoyages;
    },
    updateTcInPayment() {
      this.form.tc_in_payments.forEach((tcInPayment) => {
        let fromDate = moment(tcInPayment.period_from);
        let toDate = moment(tcInPayment.period_to);
        let diffInSeconds = toDate.diff(fromDate, "seconds");
        tcInPayment.days = parseFloat(diffInSeconds) / 86400;
      });
      // this.$emit("updateTcInPayment");
      this.updateTCPaymentAddDetails();
    },
    updateTCPaymentAddDetails(addDetails) {
      // if (this.tcInPayment.id) return;
      if (!(addDetails && addDetails.addedByUser)) return;

      if (this.tcInPayment.payment_type == "Increemental") {
        // if (this.tcInPayment.id) return;
        // change Period From on the basis of payment type
        this.tcInPayment.period_from =
          this.form.tc_in_payments.length > 0
            ? this.form.tc_in_payments[0].period_from
            : this.tcInPayment.period_from;
      } else {
        // this.tcInPayment.period_from =
        //   this.form.tc_in_payments.length > 0
        //     ? this.form.tc_in_payments[this.form.tc_in_payments.length - 1]
        //         .period_from
        //     : this.tcInPayment.period_from;
      }

      if (
        this.tcInPayment.period_from == "" ||
        this.tcInPayment.period_to == ""
        // || this.tcInPayment.issue_date == ""
      ) {
        // alert("Please enter Period From/To and Issue Date");
        // return;
      }
      // Check for Voyage Expense in this invoice
      let increementalPaymentVoyageExpenses = [];
      if (this.tcInPayment.payment_type == "Increemental") {
        this.form.tc_in_payments.forEach((tcInPayment) => {
          tcInPayment.tc_in_payment_details.forEach((tipd) => {
            if (
              tipd.code == "VOYEXP" &&
              tcInPayment.invoice_no == this.tcInPayment.invoice_no
            ) {
              let detail = {
                code: "VOYEXP",
                description: tcInPayment.invoice_no + ": Voyage Expenses",
                from: tipd.from,
                to: tipd.to,
                days_qty: tipd.days_qty,
                rate: tipd.rate,
                amount: tipd.amount,
                amount_usd: tipd.amount_usd,
                voyage_no: tipd.voyage_no,
                include_in_invoice: "1",
              };
              increementalPaymentVoyageExpenses.push(detail);
            }
          });
        });
      }

      this.tcInPayment.tc_in_payment_details = [];
      let detail = {};
      // Time Charter
      if (
        this.tcInPayment.payment_type == "Increemental" ||
        (addDetails && addDetails.timeCharterHire)
      ) {
        if (this.form.tc_in_pricings.length > 0) {
          // If multiple tc_in_pricings are there
          var periodFrom = this.tcInPayment.period_from;
          var periodTo = this.tcInPayment.period_to;
          this.form.tc_in_pricings.forEach((tcInPricing) => {
            var hire_rate = tcInPricing.hire_rate;
            // Check if tcInPayment.period_from is greater than tcInPricing.from_gmt
            var tcInPaymentPeriodFrom = moment(periodFrom);
            var tcInPricingFromGmt = moment(tcInPricing.from_gmt);
            let diffInSecondsFrom = tcInPricingFromGmt.diff(
              tcInPaymentPeriodFrom,
              "seconds"
            );
            console.log("DiffinSecondsFrom: " + diffInSecondsFrom);
            if (diffInSecondsFrom <= 0) {
              // Check if tcInPayment.period_to is less than tcInPricing.to_gmt
              var tcInPaymentPeriodTo = moment(this.tcInPayment.period_to);
              var tcInPricingToGmt = moment(tcInPricing.to_gmt);
              let diffInSecondsTo = tcInPricingToGmt.diff(
                tcInPaymentPeriodTo,
                "seconds"
              );
              console.log("DiffinSecondsTo: " + diffInSecondsTo);

              if (diffInSecondsTo > 0) {
                periodTo = this.tcInPayment.period_to;
              } else {
                periodTo = tcInPricing.to_gmt;
              }
              // Get the no of days
              let fromDate = moment(periodFrom);
              let toDate = moment(periodTo);
              let diffInSeconds = toDate.diff(fromDate, "seconds");
              var days = parseFloat(diffInSeconds) / 86400;

              if (days > 0) {
                detail.code = "TCH";
                detail.description =
                  "Time Charter Hire, rate=" + hire_rate + "USD/day";
                detail.from = periodFrom;
                detail.to = periodTo;
                detail.days_qty = days;
                detail.rate = hire_rate;
                detail.amount =
                  parseFloat(detail.days_qty) * parseFloat(detail.rate);
                detail.amount_usd = detail.amount;
                detail.remarks =
                  " (" +
                  moment(detail.from).format("DD-MM-YYYY H:m") +
                  " - " +
                  moment(detail.to).format("DD-MM-YYYY H:m") +
                  " : " +
                  parseFloat(detail.days_qty).toFixed(7) +
                  " Days)";
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push({ ...detail });
                console.log("Period From:" + periodFrom);
                console.log("Period To: " + periodTo);
                periodFrom = periodTo;
              }
            }
          });
          // End If multiple tc_in_pricings are there
        }
      }
      // Address Communication
      if (
        this.tcInPayment.payment_type == "Increemental" ||
        (addDetails && addDetails.addressCommunication)
      ) {
        if (this.form.tc_in_brokers.length > 0) {
          this.form.tc_in_brokers.forEach((tcInBroker) => {
            if (this.form.tc_in_pricings.length > 0) {
              // If multiple tc_in_pricings are there
              var periodFrom = this.tcInPayment.period_from;
              var periodTo = this.tcInPayment.period_to;
              this.form.tc_in_pricings.forEach((tcInPricing) => {
                var hire_rate = tcInPricing.hire_rate;
                // Check if tcInPayment.period_from is greater than tcInPricing.from_gmt
                var tcInPaymentPeriodFrom = moment(periodFrom);
                var tcInPricingFromGmt = moment(tcInPricing.from_gmt);
                let diffInSecondsFrom = tcInPricingFromGmt.diff(
                  tcInPaymentPeriodFrom,
                  "seconds"
                );
                if (diffInSecondsFrom <= 0) {
                  // Check if tcInPayment.period_to is less than tcInPricing.to_gmt
                  var tcInPaymentPeriodTo = moment(this.tcInPayment.period_to);
                  var tcInPricingToGmt = moment(tcInPricing.to_gmt);
                  let diffInSecondsTo = tcInPricingToGmt.diff(
                    tcInPaymentPeriodTo,
                    "seconds"
                  );

                  if (diffInSecondsTo > 0) {
                    periodTo = this.tcInPayment.period_to;
                  } else {
                    periodTo = tcInPricing.to_gmt;
                  }

                  // Get the no of days
                  let fromDate = moment(periodFrom);
                  let toDate = moment(periodTo);
                  let diffInSeconds = toDate.diff(fromDate, "seconds");
                  var days = parseFloat(diffInSeconds) / 86400;
                  if (days > 0) {
                    detail = {};
                    detail.code = "HAC";
                    detail.description =
                      "Comm. " +
                      (tcInBroker.broker ? tcInBroker.broker.short_name : "") +
                      " (" +
                      tcInBroker.rate +
                      "%)";
                    detail.from = periodFrom;
                    detail.to = periodTo;
                    detail.days_qty = days;
                    detail.rate = "";
                    detail.amount = -(this.form.tc_in_pricings.length > 0
                      ? (parseFloat(hire_rate) *
                          parseFloat(detail.days_qty) *
                          parseFloat(tcInBroker.rate)) /
                        100
                      : 0);
                    detail.amount_usd = detail.amount;
                    tcInBroker.payment_method != "Pay directly"
                      ? (detail.include_in_invoice = "1")
                      : (detail.include_in_invoice = "0");
                    this.tcInPayment.tc_in_payment_details.push({ ...detail });
                    periodFrom = periodTo;
                  }
                }
              });
              // End If multiple tc_in_pricings are there
            }
          });
        }
      }
      // Gratitudes
      if (
        this.tcInPayment.payment_type == "Increemental" ||
        (addDetails && addDetails.gratuitiesForPeriod)
      ) {
        let expenseRevenueCode = this.expenseRevenueCodes.find(
          (erc) => erc.name == "GRATUITIES"
        );
        let tcInExpense = {};
        if (expenseRevenueCode)
          tcInExpense = this.form.tc_in_expenses.find(
            (tie) => tie.expense_revenue_code_id == expenseRevenueCode.id
          );

        if (this.form.tc_in_pricings.length > 0) {
          // If multiple tc_in_pricings are there
          var periodFrom = this.tcInPayment.period_from;
          var periodTo = this.tcInPayment.period_to;
          this.form.tc_in_pricings.forEach((tcInPricing) => {
            var hire_rate = tcInPricing.hire_rate;
            // Check if tcInPayment.period_from is greater than tcInPricing.from_gmt
            var tcInPaymentPeriodFrom = moment(periodFrom);
            var tcInPricingFromGmt = moment(tcInPricing.from_gmt);
            let diffInSecondsFrom = tcInPricingFromGmt.diff(
              tcInPaymentPeriodFrom,
              "seconds"
            );
            console.log("DiffinSecondsFrom: " + diffInSecondsFrom);
            if (diffInSecondsFrom <= 0) {
              // Check if tcInPayment.period_to is less than tcInPricing.to_gmt
              var tcInPaymentPeriodTo = moment(this.tcInPayment.period_to);
              var tcInPricingToGmt = moment(tcInPricing.to_gmt);
              let diffInSecondsTo = tcInPricingToGmt.diff(
                tcInPaymentPeriodTo,
                "seconds"
              );
              console.log("DiffinSecondsTo: " + diffInSecondsTo);

              if (diffInSecondsTo > 0) {
                periodTo = this.tcInPayment.period_to;
              } else {
                periodTo = tcInPricing.to_gmt;
              }
              // Get the no of days
              let fromDate = moment(periodFrom);
              let toDate = moment(periodTo);
              let diffInSeconds = toDate.diff(fromDate, "seconds");
              var days = parseFloat(diffInSeconds) / 86400;

              if (days > 0) {
                detail = {};
                detail.code = "GAP";
                detail.description = "Gratuities For Period";
                detail.from = this.tcInPayment.period_from;
                detail.to = this.tcInPayment.period_to;
                detail.days_qty = days;
                detail.rate = 1500;
                detail.amount = tcInExpense
                  ? tcInExpense.rate_type == "Per 30 Days"
                    ? (parseFloat(detail.days_qty) * parseFloat(detail.rate)) /
                      30
                    : (parseFloat(detail.days_qty) * parseFloat(detail.rate)) /
                      30.4165063
                  : 0;
                detail.amount_usd = detail.amount;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push({ ...detail });
                console.log("Period From:" + periodFrom);
                console.log("Period To: " + periodTo);
                periodFrom = periodTo;
              }
            }
          });
          // End If multiple tc_in_pricings are there
        }
      }

      // Off Hire
      if (
        this.tcInPayment.payment_type == "Increemental" ||
        (addDetails && addDetails.offHire && addDetails.delays.length > 0)
      ) {
        // this.form.voyages.forEach((voyage) => {
        // voyage.voyage_delays.forEach((voyageDelay) => {
        addDetails.delays.forEach((voyageDelay) => {
          // To check if the delay is added in any of the previous invoices only for Period invoices
          let checkIfAlreadyAdded = false;
          if (this.tcInPayment.payment_type == "Period")
            this.form.tc_in_payments.forEach((tcInPayment) => {
              let vD = tcInPayment.tc_in_payment_details.find(
                (tIPD) =>
                  tIPD.element_id == voyageDelay.id &&
                  (tIPD.code == "OFFH" || tIPD.code == "OFFHR")
              );
              if (vD && !checkIfAlreadyAdded) {
                checkIfAlreadyAdded = true;
              }
            });

          // To check if the delay is between selected dates
          // From
          let tcInPeriodFrom = moment(this.tcInPayment.period_from);
          let voyageDelayFrom = moment(voyageDelay.from);
          let diffInDaysFrom = voyageDelayFrom.diff(tcInPeriodFrom, "seconds"); // voyageDelayTo should be negative
          // To
          let tcInPeriodTo = moment(this.tcInPayment.period_to);
          let voyageDelayTo = moment(voyageDelay.to);
          let diffInDaysTo = voyageDelayTo.diff(tcInPeriodTo, "seconds"); // voyageDelayTo should be negative
          // to bypass check between selected dates
          // diffInDaysFrom = diffInDaysTo >= 0 ? diffInDaysTo : -diffInDaysTo;
          // diffInDaysTo = diffInDaysTo <= 0 ? diffInDaysTo : -diffInDaysTo;
          // End to bypass check between selected dates
          // if (
          //   !checkIfAlreadyAdded &&
          //   diffInDaysFrom >= 0 &&
          //   diffInDaysTo <= 0
          // )
          if (!checkIfAlreadyAdded && voyageDelay.addToInvoice) {
            let delayType = this.delayTypes.find(
              (delayType) => delayType.description == "OFF HIRE"
            );
            if (delayType) {
              if (
                voyageDelay.delay_type_id == delayType.id &&
                ((this.form.is_tc_in_out == "tcin" &&
                  voyageDelay.tci_percent != 0) ||
                  (this.form.is_tc_in_out == "tcout" &&
                    voyageDelay.tco_percent != 0))
              ) {
                // If multiple tc_in_pricings are there
                var periodFrom = voyageDelay.from;
                var periodTo = voyageDelay.to;
                var hire_rate = this.hire_rate;
                this.form.tc_in_pricings.forEach((tcInPricing) => {
                  // Check if tcInPayment.period_from is greater than tcInPricing.from_gmt
                  var tcInPaymentPeriodFrom = moment(periodFrom);
                  var tcInPricingFromGmt = moment(tcInPricing.from_gmt);
                  let diffInSecondsFrom = tcInPricingFromGmt.diff(
                    tcInPaymentPeriodFrom,
                    "seconds"
                  );
                  // Check if tcInPayment.period_to is less than tcInPricing.to_gmt
                  var tcInPaymentPeriodTo = moment(periodTo);
                  var tcInPricingToGmt = moment(tcInPricing.to_gmt);
                  let diffInSecondsTo = tcInPricingToGmt.diff(
                    tcInPaymentPeriodTo,
                    "seconds"
                  );
                  if (diffInSecondsFrom <= 0 && diffInSecondsTo > 0) {
                    hire_rate = tcInPricing.hire_rate;
                  }
                });
                // End If multiple tc_in_pricings are there

                // Off Hire
                let detail = {};
                detail.element_id = voyageDelay.id;
                detail.code = "OFFH";
                detail.description =
                  (this.form.is_tc_in_out == "tcin"
                    ? voyageDelay.tci_percent
                    : voyageDelay.tco_percent) + " % Off Hire Time Deduction";
                detail.from = voyageDelay.from;
                detail.to = voyageDelay.to;
                detail.days_qty = (parseFloat(voyageDelay.hours) / 24).toFixed(
                  3
                );
                detail.rate = hire_rate;
                detail.amount =
                  (-parseFloat(
                    parseFloat(detail.days_qty) * parseFloat(detail.rate)
                  ) *
                    parseFloat(
                      this.form.is_tc_in_out == "tcin"
                        ? voyageDelay.tci_percent
                        : voyageDelay.tco_percent
                    )) /
                  100;
                detail.amount_usd = detail.amount;
                detail.remarks =
                  " (" +
                  moment(detail.from).format("DD-MM-YYYY HH:mm") +
                  " - " +
                  moment(detail.to).format("DD-MM-YYYY HH:mm") +
                  " : " +
                  parseFloat(detail.days_qty).toFixed(7) +
                  " Days) " +
                  voyageDelay.remarks;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push(detail);

                // Off Hire Add Comm.
                if (this.form.tc_in_brokers.length > 0) {
                  this.form.tc_in_brokers.forEach((tcInBroker) => {
                    detail = {};
                    detail.code = "OHAC";
                    detail.description =
                      (this.form.is_tc_in_out == "tcin"
                        ? voyageDelay.tci_percent
                        : voyageDelay.tco_percent) +
                      " % Off Hire Comm. " +
                      (tcInBroker.broker ? tcInBroker.broker.short_name : "") +
                      " (" +
                      tcInBroker.rate +
                      "%)";
                    detail.from = voyageDelay.from;
                    detail.to = voyageDelay.to;
                    detail.days_qty = (
                      parseFloat(voyageDelay.hours) / 24
                    ).toFixed(7);
                    detail.rate = "";
                    detail.amount =
                      (parseFloat(
                        this.form.tc_in_pricings.length > 0
                          ? (parseFloat(hire_rate) *
                              parseFloat(detail.days_qty) *
                              parseFloat(tcInBroker.rate)) /
                              100
                          : 0
                      ) *
                        parseFloat(
                          this.form.is_tc_in_out == "tcin"
                            ? voyageDelay.tci_percent
                            : voyageDelay.tco_percent
                        )) /
                      100;
                    detail.amount_usd = detail.amount;
                    tcInBroker.payment_method != "Pay directly"
                      ? (detail.include_in_invoice = "1")
                      : (detail.include_in_invoice = "0");
                    this.tcInPayment.tc_in_payment_details.push(detail);
                  });
                }
                // Off Hire Graduities
                let expenseRevenueCode = this.expenseRevenueCodes.find(
                  (erc) => erc.name == "GRATUITIES"
                );
                let tcInExpense = {};
                if (expenseRevenueCode)
                  tcInExpense = this.form.tc_in_expenses.find(
                    (tie) =>
                      tie.expense_revenue_code_id == expenseRevenueCode.id
                  );
                detail = {};
                detail.code = "OGAP";
                // detail.description =
                //   voyageDelay.tci_percent + " % Off Hire Gratuities";
                detail.description = "Off Hire Gratuities";
                detail.from = voyageDelay.from;
                detail.to = voyageDelay.to;
                detail.days_qty = (parseFloat(voyageDelay.hours) / 24).toFixed(
                  3
                );
                detail.rate = 1500;
                detail.amount =
                  (-parseFloat(
                    tcInExpense
                      ? tcInExpense.rate_type == "Per 30 Days"
                        ? (parseFloat(detail.days_qty) *
                            parseFloat(detail.rate)) /
                          30
                        : (parseFloat(detail.days_qty) *
                            parseFloat(detail.rate)) /
                          30.4165063
                      : 0
                  ) *
                    parseFloat(
                      this.form.is_tc_in_out == "tcin"
                        ? voyageDelay.tci_percent
                        : voyageDelay.tco_percent
                    )) /
                  100;
                detail.amount_usd = detail.amount;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push(detail);

                // Off Hire Bunkers
                this.fuelTypes.forEach((fuelType) => {
                  voyageDelay.voyage_delay_bunkers.forEach((vdb) => {
                    let tcInBunker = this.form.tc_in_bunkers.find(
                      (tib) => tib.description == "Est Delivery"
                    );
                    if (tcInBunker) {
                      let tcInBunkerDetail =
                        tcInBunker.tc_in_bunker_details.find(
                          (tibd) => tibd.fuel_type_id == fuelType.id
                        );
                      if (tcInBunkerDetail) {
                        if (vdb.fuel_type_id == fuelType.id && vdb.qty != 0) {
                          detail = {};
                          detail.code = "O" + fuelType.code;
                          detail.description =
                            (this.form.is_tc_in_out == "tcin"
                              ? voyageDelay.tci_percent
                              : voyageDelay.tco_percent) +
                            " % Off Hire " +
                            fuelType.code +
                            " Cost " +
                            vdb.qty +
                            " MT @ " +
                            tcInBunkerDetail.rate +
                            " USD/MT";
                          detail.from = "";
                          detail.to = "";
                          detail.days_qty = vdb.qty;
                          detail.rate = tcInBunkerDetail.rate;
                          detail.amount = -(
                            (parseFloat(detail.days_qty) *
                              parseFloat(detail.rate) *
                              parseFloat(
                                this.form.is_tc_in_out == "tcin"
                                  ? voyageDelay.tci_percent
                                  : voyageDelay.tco_percent
                              )) /
                            100
                          );
                          detail.amount_usd = detail.amount;
                          detail.remarks = voyageDelay.remarks;
                          detail.include_in_invoice = "1";
                          this.tcInPayment.tc_in_payment_details.push(detail);
                        }
                      }
                    }
                  });
                });
              }
            }
            delayType = this.delayTypes.find(
              (delayType) => delayType.description == "OFF HIRE REVERSE"
            );
            if (delayType) {
              if (
                voyageDelay.delay_type_id == delayType.id &&
                ((this.form.is_tc_in_out == "tcin" &&
                  voyageDelay.tci_percent != 0) ||
                  (this.form.is_tc_in_out == "tcout" &&
                    voyageDelay.tco_percent != 0))
              ) {
                // Off Hire Reversal
                if (voyageDelay.hours != 0) {
                  // If multiple tc_in_pricings are there
                  var periodFrom = voyageDelay.from;
                  var periodTo = voyageDelay.to;
                  var hire_rate = this.hire_rate;
                  this.form.tc_in_pricings.forEach((tcInPricing) => {
                    // Check if tcInPayment.period_from is greater than tcInPricing.from_gmt
                    var tcInPaymentPeriodFrom = moment(periodFrom);
                    var tcInPricingFromGmt = moment(tcInPricing.from_gmt);
                    let diffInSecondsFrom = tcInPricingFromGmt.diff(
                      tcInPaymentPeriodFrom,
                      "seconds"
                    );
                    // Check if tcInPayment.period_to is less than tcInPricing.to_gmt
                    var tcInPaymentPeriodTo = moment(periodTo);
                    var tcInPricingToGmt = moment(tcInPricing.to_gmt);
                    let diffInSecondsTo = tcInPricingToGmt.diff(
                      tcInPaymentPeriodTo,
                      "seconds"
                    );
                    if (diffInSecondsFrom <= 0 && diffInSecondsTo > 0) {
                      hire_rate = tcInPricing.hire_rate;
                    }
                  });
                  // End If multiple tc_in_pricings are there

                  detail = {};
                  detail.code = "OFFHR";
                  detail.description =
                    (this.form.is_tc_in_out == "tcin"
                      ? voyageDelay.tci_percent
                      : voyageDelay.tco_percent) +
                    " % Off Hire Reversal Time Deduction";
                  detail.from = voyageDelay.from;
                  detail.to = voyageDelay.to;
                  detail.days_qty = (
                    parseFloat(voyageDelay.hours) / 24
                  ).toFixed(7);
                  detail.rate = hire_rate;
                  detail.amount =
                    (parseFloat(
                      parseFloat(detail.days_qty) * parseFloat(detail.rate)
                    ) *
                      parseFloat(
                        this.form.is_tc_in_out == "tcin"
                          ? voyageDelay.tci_percent
                          : voyageDelay.tco_percent
                      )) /
                    100;
                  detail.amount_usd = detail.amount;
                  detail.remarks = voyageDelay.remarks;
                  detail.include_in_invoice = "1";
                  this.tcInPayment.tc_in_payment_details.push(detail);

                  // Off Hire Reversal Add Comm.
                  if (this.form.tc_in_brokers.length > 0) {
                    this.form.tc_in_brokers.forEach((tcInBroker) => {
                      // if (tcInBroker.payment_method != "Pay directly") {
                      detail = {};
                      detail.code = "OHRAC";
                      detail.description =
                        (this.form.is_tc_in_out == "tcin"
                          ? voyageDelay.tci_percent
                          : voyageDelay.tco_percent) +
                        " % Off Hire Reversal Comm. " +
                        (tcInBroker.broker
                          ? tcInBroker.broker.short_name
                          : "") +
                        " (" +
                        tcInBroker.rate +
                        "%)";
                      detail.from = voyageDelay.from;
                      detail.to = voyageDelay.to;
                      detail.days_qty = (
                        parseFloat(voyageDelay.hours) / 24
                      ).toFixed(7);
                      detail.rate = "";
                      detail.amount =
                        (-parseFloat(
                          this.form.tc_in_pricings.length > 0
                            ? (parseFloat(hire_rate) *
                                parseFloat(detail.days_qty) *
                                parseFloat(tcInBroker.rate)) /
                                100
                            : 0
                        ) *
                          parseFloat(
                            this.form.is_tc_in_out == "tcin"
                              ? voyageDelay.tci_percent
                              : voyageDelay.tco_percent
                          )) /
                        100;
                      detail.amount_usd = detail.amount;
                      tcInBroker.payment_method != "Pay directly"
                        ? (detail.include_in_invoice = "1")
                        : (detail.include_in_invoice = "0");
                      this.tcInPayment.tc_in_payment_details.push(detail);
                      // }
                    });
                  }
                  // Off Hire Reversal Graduities
                  let expenseRevenueCode = this.expenseRevenueCodes.find(
                    (erc) => erc.name == "GRATUITIES"
                  );
                  let tcInExpense = {};
                  if (expenseRevenueCode)
                    tcInExpense = this.form.tc_in_expenses.find(
                      (tie) =>
                        tie.expense_revenue_code_id == expenseRevenueCode.id
                    );
                  detail = {};
                  detail.code = "ORGAP";
                  detail.description =
                    (this.form.is_tc_in_out == "tcin"
                      ? voyageDelay.tci_percent
                      : voyageDelay.tco_percent) +
                    " % Off Hire Reversal Gratuities";
                  detail.from = voyageDelay.from;
                  detail.to = voyageDelay.to;
                  detail.days_qty = (
                    parseFloat(voyageDelay.hours) / 24
                  ).toFixed(7);
                  detail.rate = 1500;
                  detail.amount =
                    (parseFloat(
                      tcInExpense
                        ? tcInExpense.rate_type == "Per 30 Days"
                          ? (parseFloat(detail.days_qty) *
                              parseFloat(detail.rate)) /
                            30
                          : (parseFloat(detail.days_qty) *
                              parseFloat(detail.rate)) /
                            30.4165063
                        : 0
                    ) *
                      parseFloat(
                        this.form.is_tc_in_out == "tcin"
                          ? voyageDelay.tci_percent
                          : voyageDelay.tco_percent
                      )) /
                    100;
                  detail.amount_usd = detail.amount;
                  detail.include_in_invoice = "1";
                  this.tcInPayment.tc_in_payment_details.push(detail);
                }

                // Off Hire Reverse Bunkers
                this.fuelTypes.forEach((fuelType) => {
                  voyageDelay.voyage_delay_bunkers.forEach((vdb) => {
                    let tcInBunker = this.form.tc_in_bunkers.find(
                      (tib) => tib.description == "Est Delivery"
                    );
                    if (tcInBunker) {
                      let tcInBunkerDetail =
                        tcInBunker.tc_in_bunker_details.find(
                          (tibd) => tibd.fuel_type_id == fuelType.id
                        );
                      if (tcInBunkerDetail) {
                        if (vdb.fuel_type_id == fuelType.id && vdb.qty != 0) {
                          detail = {};
                          detail.code = "OR" + fuelType.code;
                          detail.description =
                            (this.form.is_tc_in_out == "tcin"
                              ? voyageDelay.tci_percent
                              : voyageDelay.tco_percent) +
                            " % Off Hire Reversal " +
                            fuelType.code +
                            " Cost " +
                            vdb.qty +
                            " MT @ " +
                            tcInBunkerDetail.rate +
                            " USD/MT";
                          detail.from = "";
                          detail.to = "";
                          detail.days_qty = vdb.qty;
                          detail.rate = tcInBunkerDetail.rate;
                          detail.amount =
                            (parseFloat(detail.days_qty) *
                              parseFloat(detail.rate) *
                              parseFloat(
                                this.form.is_tc_in_out == "tcin"
                                  ? voyageDelay.tci_percent
                                  : voyageDelay.tco_percent
                              )) /
                            100;
                          detail.amount_usd = detail.amount;
                          detail.remarks = voyageDelay.remarks;
                          detail.include_in_invoice = "1";
                          this.tcInPayment.tc_in_payment_details.push(detail);
                        }
                      }
                    }
                  });
                });
              }
            }
          }
        });
        //   });
        // });
      }
      //
      // Bunkers
      if (
        this.tcInPayment.payment_type == "Increemental" ||
        (addDetails && addDetails.bunkers)
      ) {
        this.fuelTypes.forEach((fuelType) => {
          // On Owner;s Account
          let tcInBunker = this.form.tc_in_bunkers.find(
            (tib) => tib.description == "For TCI Owner's Account"
          );
          if (tcInBunker) {
            let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
              (tibd) => tibd.fuel_type_id == fuelType.id
            );
            if (tcInBunkerDetail) {
              if (tcInBunkerDetail.qty != "" && tcInBunkerDetail.qty != null) {
                detail = {};
                detail.code = fuelType.code + "OAC";
                detail.description =
                  fuelType.code +
                  " For Owner's Account " +
                  tcInBunkerDetail.qty +
                  " MT @ " +
                  tcInBunkerDetail.rate +
                  " USD/MT";
                detail.from = "";
                detail.to = "";
                detail.days_qty = tcInBunkerDetail.qty;
                detail.rate = tcInBunkerDetail.rate;
                detail.amount =
                  parseFloat(detail.days_qty) * parseFloat(detail.rate);
                detail.amount_usd = detail.amount;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push(detail);
              }
            }
          }
          // Delivery
          tcInBunker = this.form.tc_in_bunkers.find(
            (tib) => tib.description == "Est Delivery"
          );
          if (tcInBunker) {
            let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
              (tibd) => tibd.fuel_type_id == fuelType.id
            );
            if (tcInBunkerDetail) {
              if (tcInBunkerDetail.qty != "" && tcInBunkerDetail.qty != null) {
                detail = {};
                detail.code = fuelType.code + "DL";
                detail.description =
                  fuelType.code +
                  " Cost On Delivery " +
                  tcInBunkerDetail.qty +
                  " MT @ " +
                  tcInBunkerDetail.rate +
                  " USD/MT";
                detail.from = "";
                detail.to = "";
                detail.days_qty = tcInBunkerDetail.qty;
                detail.rate = tcInBunkerDetail.rate;
                detail.amount =
                  parseFloat(detail.days_qty) * parseFloat(detail.rate);
                detail.amount_usd = detail.amount;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push(detail);
              }
            }
          }
          // To check if the ship has reached the last port
          let lastPortBunkerValue = 0;
          if (this.form.voyages.length > 0) {
            // If this is the last invoice
            let lastPortItenary;
            let redeliveryActualGmt = moment(this.form.redelivery_actual_gmt);
            let periodTo = moment(this.tcInPayment.period_to);
            let diff = periodTo.diff(redeliveryActualGmt);
            if (diff == 0) {
              lastPortItenary =
                this.form.voyages[0].voyage_itenaries.length > 0
                  ? this.form.voyages[0].voyage_itenaries[
                      this.form.voyages[0].voyage_itenaries.length - 1
                    ]
                  : null;
            }
            if (lastPortItenary) {
              if (this.form.voyages[0].voyage_bunker_masters.length > 0) {
                let bunkerMaster =
                  this.form.voyages[0].voyage_bunker_masters.find(
                    (vbm) => vbm.fuel_type_id == fuelType.id
                  );
                if (bunkerMaster) {
                  let lastPortBunker =
                    bunkerMaster.voyage_bunker_master_bunkers.find(
                      (vbmb) =>
                        vbmb.port_name_id == lastPortItenary.port_name_id &&
                        vbmb.port_function_id ==
                          lastPortItenary.port_function_id
                    );
                  if (lastPortBunker)
                    lastPortBunkerValue = lastPortBunker.rob_departure;
                }
              }
            }
          }

          tcInBunker = this.form.tc_in_bunkers.find(
            (tib) => tib.description == "Est Redelivery"
          );
          if (tcInBunker) {
            let tcInBunkerDetail = tcInBunker.tc_in_bunker_details.find(
              (tibd) => tibd.fuel_type_id == fuelType.id
            );
            if (tcInBunkerDetail) {
              if (tcInBunkerDetail.qty != "" && tcInBunkerDetail.qty != null) {
                detail = {};
                detail.code = fuelType.code + "RDL";
                detail.description =
                  fuelType.code +
                  " Cost On Redelivery " +
                  (lastPortBunkerValue && lastPortBunkerValue != 0
                    ? lastPortBunkerValue
                    : tcInBunkerDetail.qty) +
                  " MT @ " +
                  tcInBunkerDetail.rate +
                  " USD/MT";
                detail.from = "";
                detail.to = "";
                detail.days_qty =
                  lastPortBunkerValue && lastPortBunkerValue != 0
                    ? lastPortBunkerValue
                    : tcInBunkerDetail.qty;
                detail.rate = tcInBunkerDetail.rate;
                detail.amount = -(
                  parseFloat(detail.days_qty) * parseFloat(detail.rate)
                );
                detail.amount_usd = detail.amount;
                detail.include_in_invoice = "1";
                this.tcInPayment.tc_in_payment_details.push(detail);
              }
            }
          }
        });
      }
      // Voyage Expense
      if (addDetails && addDetails.voyageExpenses) {
        let detail = {
          code: "VOYEXP",
          description: "Voyage Expenses",
          from: "",
          to: "",
          days_qty: "",
          rate: 0,
          amount: 0,
          amount_usd: 0,
          voyage_no:
            this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
          include_in_invoice: "1",
        };
        this.tcInPayment.tc_in_payment_details.push(detail);
      }
      // ILOHC
      // if (
      //   this.tcInPayment.payment_type == "Increemental" ||
      //   (addDetails && addDetails.ilohc)
      // ) {
      //   let ilohcPaymentDetail = this.tcInPayment.tc_in_payment_details.find(
      //     (tIPD) => tIPD.code == "ILOHC"
      //   );
      //   if (!ilohcPaymentDetail) {
      //     let detail = {
      //       code: "ILOHC",
      //       description: "ILOHC",
      //       from: "",
      //       to: "",
      //       days_qty: "",
      //       rate: 0,
      //       amount: 5000,
      //       amount_usd: 0,
      //       voyage_no:
      //         this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
      //     };
      //     this.tcInPayment.tc_in_payment_details.push(detail);
      //   }
      // }
      if (addDetails && addDetails.ilohc) {
        let detail = {
          code: "ILOHC",
          description: "ILOHC",
          from: "",
          to: "",
          days_qty: "",
          rate: 0,
          amount: 5000,
          amount_usd: 0,
          voyage_no:
            this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
          include_in_invoice: "1",
        };
        this.tcInPayment.tc_in_payment_details.push(detail);
      }

      // Check for any previous Voyage Expense
      if (this.tcInPayment.payment_type == "Increemental") {
        this.form.tc_in_payments.forEach((tcInPayment) => {
          if (
            this.tcInPayment.id == null ||
            tcInPayment.id != this.tcInPayment.id
          ) {
            tcInPayment.tc_in_payment_details.forEach((tipd) => {
              if (
                tipd.code == "VOYEXP" &&
                tcInPayment.invoice_no != this.tcInPayment.invoice_no
              ) {
                let detail = {
                  code: "VOYEXPEX",
                  description: tcInPayment.invoice_no + ": Voyage Expenses",
                  from: tipd.from,
                  to: tipd.to,
                  days_qty: tipd.days_qty,
                  rate: tipd.rate,
                  amount: tipd.amount,
                  amount_usd: tipd.amount_usd,
                  voyage_no: tipd.voyage_no,
                  include_in_invoice: "1",
                };
                this.tcInPayment.tc_in_payment_details.push(detail);
              }

              if (
                tipd.code == "ILOHC" &&
                tcInPayment.invoice_no != this.tcInPayment.invoice_no
              ) {
                let detail = {
                  code: "ILOHCEX",
                  description: tcInPayment.invoice_no + ": ILOHC",
                  from: tipd.from,
                  to: tipd.to,
                  days_qty: tipd.days_qty,
                  rate: tipd.rate,
                  amount: tipd.amount,
                  amount_usd: tipd.amount_usd,
                  voyage_no: tipd.voyage_no,
                  include_in_invoice: "1",
                };
                this.tcInPayment.tc_in_payment_details.push(detail);
              }

              if (
                tipd.code == "MISCEXP" &&
                tcInPayment.invoice_no != this.tcInPayment.invoice_no
              ) {
                let detail = {
                  code: "MISCEXPEX",
                  description: tcInPayment.invoice_no + ": Misc. Expenses",
                  from: tipd.from,
                  to: tipd.to,
                  days_qty: tipd.days_qty,
                  rate: tipd.rate,
                  amount: tipd.amount,
                  amount_usd: tipd.amount_usd,
                  voyage_no: tipd.voyage_no,
                  include_in_invoice: "1",
                };
                this.tcInPayment.tc_in_payment_details.push(detail);
              }
            });
          }
        });
      }
      increementalPaymentVoyageExpenses.forEach(
        (increementalPaymentVoyageExpense) => {
          let detail = {
            code: "VOYEXP",
            description: "Voyage Expenses",
            from: increementalPaymentVoyageExpense.from,
            to: increementalPaymentVoyageExpense.to,
            days_qty: increementalPaymentVoyageExpense.days_qty,
            rate: increementalPaymentVoyageExpense.rate,
            amount: increementalPaymentVoyageExpense.amount,
            amount_usd: increementalPaymentVoyageExpense.amount_usd,
            voyage_no: increementalPaymentVoyageExpense.voyage_no,
            include_in_invoice: "1",
          };
          this.tcInPayment.tc_in_payment_details.push(detail);
        }
      );

      // Previous Invoices
      if (this.tcInPayment.payment_type == "Increemental") {
        this.form.tc_in_payments.forEach((tcInPayment) => {
          if (
            this.tcInPayment.id == null ||
            tcInPayment.id != this.tcInPayment.id
          ) {
            if (tcInPayment.invoice_no != this.tcInPayment.invoice_no) {
              let detail = {
                code: "PREVINV",
                description: "Previously Invoiced: " + tcInPayment.invoice_no,
                from: "",
                to: "",
                days_qty: "",
                rate: "",
                amount: -tcInPayment.amount,
                amount_usd: tcInPayment.amount_usd,
                voyage_no: "",
                include_in_invoice: "1",
              };
              this.tcInPayment.tc_in_payment_details.push(detail);
            }
          }
        });
      }
      if (this.tcInPayment.payment_type == "Increemental" || addDetails)
        this.calculateAmount();
    },
    updateTCPaymentIncreemental() {
      // if (this.tcInPayment.payment_type == "Increemental"){}
    },
    calculateAmount() {
      this.$emit("updateTcInPayment");
    },
    saveTCPayment() {
      this.$emit("calculateCost");
      this.closeTCPaymentModal();
    },
    deleteTcInPaymentDetail(tcInPaymentDetail) {
      this.tcInPayment.tc_in_payment_details.splice(
        this.tcInPayment.tc_in_payment_details.indexOf(tcInPaymentDetail),
        1
      );
      this.calculateAmount();
    },
    addEmptyPaymentDetailVoyage() {
      this.tcInPayment.tc_in_payment_details.push({
        code: "VOYEXP",
        description: "Voyage Expense",
        from: "",
        to: "",
        days_qty: "",
        rate: "",
        amount: 0,
        amount_usd: 0,
        voyage_no: this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
        remarks: "",
        include_in_invoice: "1",
      });
      this.calculateAmount();
    },
    addEmptyPaymentDetailMisc() {
      this.tcInPayment.tc_in_payment_details.push({
        code: "MISCEXP",
        description: "Misc. Expense",
        from: "",
        to: "",
        days_qty: "",
        rate: "",
        amount: 0,
        amount_usd: 0,
        voyage_no: this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
        remarks: "",
        include_in_invoice: "1",
      });
      this.calculateAmount();
    },
    addEmptyPaymentDetailIlohc() {
      this.tcInPayment.tc_in_payment_details.push({
        code: "ILOHC",
        description: "ILOHC",
        from: "",
        to: "",
        days_qty: "",
        rate: "",
        amount: 5000,
        amount_usd: 5000,
        voyage_no: this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
        remarks: "",
        include_in_invoice: "1",
      });
      this.calculateAmount();
    },
    addEmptyPaymentDetailDeduction() {
      this.tcInPayment.tc_in_payment_details.push({
        code: "VOYEXP",
        description: "Voyage Expense",
        from: "",
        to: "",
        days_qty: "",
        rate: "",
        amount: 0,
        amount_usd: 0,
        voyage_no: this.tcInVoyages.length > 0 ? this.tcInVoyages[0].name : "",
        remarks: "",
        include_in_invoice: "1",
      });
      this.calculateAmount();
    },
  },
};
</script>

<style scoped>
input {
  width: -webkit-fill-available !important;
}
.myBold {
  font-weight: bold;
}
</style>
