<template>
    <postinvoice></postinvoice>
</template>

<script>
import postinvoice from '../../transactions/create.vue'
export default {
    components : {
        postinvoice
    }
}
</script>