<template>
    <AdvancedPayment></AdvancedPayment>
</template>

<script>
import AdvancedPayment from '../../transactions/create.vue'
export default {
    components : {
        AdvancedPayment
    }
}
</script>