<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/chartering/tc-outs"></back-button> 
            Update tc out: {{ form.id }}<loading :isLoading="isLoading"></loading>
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-8 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li><router-link to="/chartering/tc-outs">Tc Outs</router-link></li>
            <li class="active">Create</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="row">
                  <div class="col-sm-12 col-xs-12">
                    <div class="form-wrap form-horizontal">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Vessel*</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.vessel_id"
                                :suggestions="vessels"
                                placeholder="Enter Vessel"
                              ></my-select>
                              <span class="error-block">{{
                                errors.vessel_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Date*</label>
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.date"
                                placeholder="Enter Date"
                              ></my-datepicker>
                              <span class="error-block">{{ errors.date }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Status</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.status_id"
                                :suggestions="tcOutStatuses"
                                placeholder="Enter Status"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >TC Code*</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="TC Code"
                                v-model="form.tc_code"
                              />
                              <span class="error-block">{{
                                errors.tc_code
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Company</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.company_id"
                                :suggestions="companies"
                                placeholder="Enter company"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Period</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_period"
                                :suggestions="billingPeriods"
                                placeholder="Enter billing period"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Owner</label>
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.owner_id"
                                :suggestions="owners"
                                placeholder="Enter owner"
                              ></my-select>
                              <span class="error-block">{{
                                errors.owner_id
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Department</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Department"
                                v-model="form.add_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Billing Schedule</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.billing_schedule"
                                :suggestions="billingSchedules"
                                placeholder="Enter billing schedule"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Beneficiary</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.beneficiary_id"
                                :suggestions="owners"
                                placeholder="Enter beneficiary"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Trade Area</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.trade_area_id"
                                :suggestions="tradeAreas"
                                placeholder="Enter trade area"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Rebillable Admin Fee %</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Rebillable Admin Fee"
                                v-model="form.rebillable_admin_fee_percent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan From</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laycan_from"
                                placeholder="Enter Laycan From"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref. No</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Ref. No"
                                v-model="form.ref_no"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Curr/Exch. Rate</label
                            >
                            <div class="col-sm-5">
                              <my-select
                                :selection.sync="form.exc_currency_id"
                                :suggestions="currencies"
                                placeholder="Enter Currency"
                              ></my-select>
                            </div>
                            <div class="col-sm-4">
                              <input
                                v-uppercase
                                type="number"
                                class="form-control"
                                placeholder="Curr/Exch. Rate"
                                v-model="form.exc_currency_rate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Laycan To</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.laycan_to"
                                placeholder="Enter Laycan To"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Payment Terms</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.payment_term_id"
                                :suggestions="paymentTerms"
                                placeholder="Enter payment term"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Ref Contract</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Ref Contract"
                                v-model="form.country_code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Fixed By</label
                            >
                            <div class="col-sm-9">
                              <input
                                v-uppercase
                                type="text"
                                class="form-control"
                                placeholder="Fixed By"
                                v-model="form.add_1"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Contract Type</label
                            >
                            <div class="col-sm-9">
                              <my-select
                                :selection.sync="form.contract_type_id"
                                :suggestions="contractTypes"
                                placeholder="Enter contract type"
                              ></my-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label class="col-sm-3 control-label"
                              >Conf Date GMT</label
                            >
                            <div class="col-sm-9">
                              <my-datepicker
                                :date.sync="form.conf_date_gmt"
                                placeholder="Select Conf. Date"
                              ></my-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Dur.</th>
                              <th>Unit</th>
                              <th>-Days</th>
                              <th>+Days</th>
                              <th>E/L Redel.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Min</td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Duration"
                                  v-model="form.min_duration"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="form.min_duration_unit"
                                  :suggestions="durationUnits"
                                  placeholder="Duration Unit"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="-Days"
                                  v-model="form.min_duration_minus_days"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="+Days"
                                  v-model="form.min_duration_plus_days"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.min_estimated_redelivery"
                                  placeholder="E/L Redel."
                                ></my-datepicker>
                              </td>
                            </tr>
                            <tr>
                              <td>Max</td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Duration"
                                  v-model="form.max_duration"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="form.max_duration_unit"
                                  :suggestions="durationUnits"
                                  placeholder="Duration Unit"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="-Days"
                                  v-model="form.max_duration_minus_days"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="+Days"
                                  v-model="form.max_duration_plus_days"
                                />
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.max_estimated_redelivery"
                                  placeholder="E/L Redel."
                                ></my-datepicker>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <br />
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Port*</th>
                              <th>Est GMT</th>
                              <th>Proj./Act GMT</th>
                              <th>Difference</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Delivery</td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="form.delivery_port_id"
                                  :suggestions="portNames"
                                  placeholder="Enter Port"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.delivery_est_gmt"
                                  placeholder="Est GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.delivery_actual_gmt"
                                  placeholder="Proj./Act GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Difference"
                                  v-model="form.delivery_difference"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Remarks"
                                  v-model="form.delivery_remarks"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Redelivery</td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="form.redelivery_port_id"
                                  :suggestions="portNames"
                                  placeholder="Enter Port"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.redelivery_est_gmt"
                                  placeholder="Est GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="form.redelivery_actual_gmt"
                                  placeholder="Proj./Act GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Difference"
                                  v-model="form.redelivery_difference"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Remarks"
                                  v-model="form.redelivery_remarks"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Duration/Basis(days)</td>
                              <td>{{ form.est_duration }}</td>
                              <td>{{ form.actual_duration }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Pricing</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Hire Rate</th>
                              <th>Rate Type</th>
                              <th>Code</th>
                              <th>Lock</th>
                              <th>From GMT</th>
                              <th>To GMT</th>
                              <th>Duration</th>
                              <th>Period</th>
                              <th>TCL Outs</th>
                              <th>Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(tc_out_pricing, ar) in form.tc_out_pricings"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteTcOutPricing(tc_out_pricing)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Hire Rate"
                                  v-model="tc_out_pricing.hire_rate"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_pricing.rate_type"
                                  :suggestions="pricingRateTypes"
                                  placeholder="Rate Type"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    tc_out_pricing.pricing_code_id
                                  "
                                  :suggestions="pricingCodes"
                                  placeholder="Pricing Code"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_pricing.lock"
                                  :suggestions="pricingLocks"
                                  placeholder="Lock"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_pricing.from_gmt"
                                  placeholder="From GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_pricing.to_gmt"
                                  placeholder="To GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Duration"
                                  v-model="tc_out_pricing.duration"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_pricing.duration_unit"
                                  :suggestions="durationUnits"
                                  placeholder="Unit"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="TCL Insurance Rate"
                                  v-model="tc_out_pricing.tcl_insurance_rate"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Comments"
                                  v-model="tc_out_pricing.comments"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="11"
                                align="center"
                                @click="addEmptyTcOutPricing()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Broker</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Broker</th>
                              <th>Rate</th>
                              <th>Type</th>
                              <th>Payment Method</th>
                              <th>Claim</th>
                              <th>From GMT</th>
                              <th>To GMT</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(tc_out_broker, ar) in form.tc_out_brokers"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteTcOutBroker(tc_out_broker)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_broker.broker_id"
                                  :suggestions="addresses"
                                  placeholder="Broker"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Rate"
                                  v-model="tc_out_broker.rate"
                                />
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_broker.rate_type"
                                  :suggestions="brokerRateTypes"
                                  placeholder="Rate Type"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_broker.payment_method"
                                  :suggestions="brokerPaymentMethods"
                                  placeholder="Payment Method"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    tc_out_broker.is_claim_commissionable
                                  "
                                  :suggestions="brokerCommissionables"
                                  placeholder="Claim"
                                ></my-select>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_broker.from_gmt"
                                  placeholder="From GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_broker.to_gmt"
                                  placeholder="To GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Remarks"
                                  v-model="tc_out_broker.remarks"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="9"
                                align="center"
                                @click="addEmptyTcOutBroker()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Expenses</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Description</th>
                              <th>Rate Type</th>
                              <th>Rate</th>
                              <th>Comm. Applicable</th>
                              <th>From GMT</th>
                              <th>To GMT</th>
                              <th>Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(tc_out_expense, ar) in form.tc_out_expenses"
                              :key="`contact${ar}`"
                            >
                              <td @click="deleteTcOutExpense(tc_out_expense)">
                                <i
                                  class="fa fa-times-circle-o"
                                  aria-hidden="true"
                                ></i>
                                {{ ar + 1 }}.
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="
                                    tc_out_expense.expense_revenue_code_id
                                  "
                                  :suggestions="expenseRevenueCodes"
                                  placeholder="Description"
                                ></my-select>
                              </td>
                              <td>
                                <my-select
                                  disableFormControl="true"
                                  :selection.sync="tc_out_expense.rate_type"
                                  :suggestions="expenseRateTypes"
                                  placeholder="Rate Type"
                                ></my-select>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="Rate"
                                  v-model="tc_out_expense.rate"
                                />
                              </td>
                              <td>
                                <my-checkbox
                                  :selection.sync="
                                    form.is_commission_applicable
                                  "
                                  placeholder=""
                                ></my-checkbox>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_expense.from_gmt"
                                  placeholder="From GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <my-datepicker
                                  disableFormControl="true"
                                  :date.sync="tc_out_expense.to_gmt"
                                  placeholder="To GMT"
                                ></my-datepicker>
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="text"
                                  placeholder="Remarks"
                                  v-model="tc_out_expense.remarks"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="9"
                                align="center"
                                @click="addEmptyTcOutExpense()"
                              >
                                Add New Row
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h5>Bunker</h5>
                      <div class="editable-table-wrapper">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Description</th>
                              <th>LSF Qty</th>
                              <th>LSF Price</th>
                              <th>LSM Qty</th>
                              <th>LSM Price</th>
                              <th>IFO Qty</th>
                              <th>IFO Price</th>
                              <th>MDO Qty</th>
                              <th>MDO Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Pre-purchased By Operator</td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="LSF Qty"
                                  v-model="lsf_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="LSF Price"
                                  v-model="lsf_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="LSM Qty"
                                  v-model="ls_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="LSM Price"
                                  v-model="lsm_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="IFO Qty"
                                  v-model="ifo_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="IFO Price"
                                  v-model="ifo_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="MDO Qty"
                                  v-model="ifo_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="MDO Price"
                                  v-model="mdo_price"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Est Delivery</td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="1,550.000"
                                  v-model="lsf_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="500.00"
                                  v-model="lsf_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="145.000"
                                  v-model="ls_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder="600.00"
                                  v-model="lsm_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="ifo_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="ifo_price"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="mdo_qty"
                                />
                              </td>
                              <td>
                                <input
                                  v-uppercase
                                  type="number"
                                  placeholder=""
                                  v-model="mdo_price"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <errors :data="errors" />

                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group mb-0">
                            <div class="col-sm-9">
                              <submit-button
                                :isLoading="isLoading"
                                @submit="update"
                                text="Update"
                              ></submit-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'tcOutUpdate',
  data() {
    return {
      form: {
        vessel_id: "",
        owner_id: "",
        billing_period: "",
        billing_schedule: "",
        beneficiary_id: "",
        fixed_by: "",
        company_id: "",
        department_id: "",
        trade_area_id: "",
        rebillable_admin_fee_percent: 0,
        payment_term_id: "",
        contract_type_id: "",
        status_id: "",
        exc_currency_id: "",
        delivery_port_id: "",
        redelivery_port_id: "",
        min_duration_unit: "",
        max_duration_unit: "",
        est_duration: 0,
        actual_duration: 0,
        tc_out_pricings: [],
        tc_out_brokers: [],
        tc_out_expenses: [],
      },
      vessels: [],
      addresses: [],
      billingPeriods: [],
      billingSchedules: [],
      tradeAreas: [],
      paymentTerms: [],
      contractTypes: [],
      tcOutStatuses: [],
      currencies: [],
      portNames: [],
      durationUnits: [],
      pricingRateTypes: [],
      brokerRateTypes: [],
      brokerPaymentMethods: [],
      brokerCommissionables: [],
      pricingCodes: [],
      pricingLocks: [],
      expenseRevenueCodes: [],
      expenseRateTypes: [],
      companies: [],
      owners: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData()
    this.getMasters()
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("/tc_outs/masters");
      masters = masters.data;
      masters.vessels.forEach((vessel) => {
        this.vessels.push({
          id: vessel.id,
          name: vessel.vessel_code + " - " + vessel.vessel_name,
        });
      });
      masters.addresses.forEach((address) => {
        this.addresses.push({
          id: address.id,
          name: address.short_name + " - " + address.full_name,
        });
      });
      masters.billing_periods.forEach((billingPeriod) => {
        this.billingPeriods.push({
          id: billingPeriod,
          name: billingPeriod,
        });
      });
      masters.billing_schedules.forEach((billingSchedule) => {
        this.billingSchedules.push({
          id: billingSchedule,
          name: billingSchedule,
        });
      });
      masters.trade_areas.forEach((trade_area) => {
        this.tradeAreas.push({
          id: trade_area.id,
          name: trade_area.code + " - " + trade_area.description,
        });
      });
      masters.currencies.forEach((currency) => {
        this.currencies.push({
          id: currency.id,
          name: currency.name + " - " + currency.description,
        });
      });
      masters.payment_terms.forEach((paymentTerm) => {
        this.paymentTerms.push({
          id: paymentTerm.id,
          name: paymentTerm.code + " - " + paymentTerm.description,
        });
      });
      masters.contract_types.forEach((contractType) => {
        this.contractTypes.push({
          id: contractType.id,
          name: contractType.type,
        });
      });
      masters.tc_out_statuses.forEach((tc_out_status) => {
        this.tcOutStatuses.push({
          id: tc_out_status.id,
          name: tc_out_status.status,
        });
      });
      masters.duration_units.forEach((durationUnit) => {
        this.durationUnits.push({
          id: durationUnit,
          name: durationUnit,
        });
      });
      masters.port_names.forEach((port_name) => {
        this.portNames.push({
          id: port_name.id,
          name: port_name.port_name,
        });
      });
      masters.pricing_rate_types.forEach((pricingRateType) => {
        this.pricingRateTypes.push({
          id: pricingRateType,
          name: pricingRateType,
        });
      });
      masters.broker_rate_types.forEach((brokerRateType) => {
        this.brokerRateTypes.push({
          id: brokerRateType,
          name: brokerRateType,
        });
      });
      masters.pricing_codes.forEach((pricing_code) => {
        this.pricingCodes.push({
          id: pricing_code.id,
          name: pricing_code.code + " - " + pricing_code.description,
        });
      });
      masters.pricing_locks.forEach((pricingLock) => {
        this.pricingLocks.push({
          id: pricingLock,
          name: pricingLock,
        });
      });
      masters.broker_payment_methods.forEach((brokerPaymentMethod) => {
        this.brokerPaymentMethods.push({
          id: brokerPaymentMethod,
          name: brokerPaymentMethod,
        });
      });
      masters.broker_commissionables.forEach((brokerCommissionable) => {
        this.brokerCommissionables.push({
          id: brokerCommissionable,
          name: brokerCommissionable,
        });
      });
      masters.expense_revenue_codes.forEach((expense_revenue_code) => {
        this.expenseRevenueCodes.push({
          id: expense_revenue_code.id,
          name:
            expense_revenue_code.code +
            " - " +
            expense_revenue_code.description,
        });
      });
      masters.expense_rate_types.forEach((expenseRateType) => {
        this.expenseRateTypes.push({
          id: expenseRateType,
          name: expenseRateType,
        });
      });
      masters.companies.forEach(company => {
        this.companies.push({
          id: company.id,
          name: company.short_name + ' -' + company.full_name,
        })
      })
      masters.owners.forEach(company => {
        this.owners.push({
          id: company.id,
          name: company.short_name + ' -' + company.full_name,
        })
      })
    },
    async getData() {
      this.isLoading = true
      let form = await axios.get(`/tc_outs/${this.$route.params.tcOutId}`)
      this.form = form.data.data
      this.form.vessel_id = this.form.vessel_id || '',
      this.form.owner_id = this.form.owner_id || '',
      this.form.billing_period = this.form.billing_period || '',
      this.form.billing_schedule = this.form.billing_schedule || '',
      this.form.beneficiary_id = this.form.beneficiary_id || '',
      this.form.fixed_by = this.form.fixed_by || '',
      this.form.company_id = this.form.company_id || '',
      this.form.department_id = this.form.department_id || '',
      this.form.trade_area_id = this.form.trade_area_id || '',
      this.form.payment_term_id = this.form.payment_term_id || '',
      this.form.contract_type_id = this.form.contract_type_id || '',
      this.form.status_id = this.form.status_id || '',
      this.form.exc_currency_id = this.form.exc_currency_id || '',
      this.form.delivery_port_id = this.form.delivery_port_id || '',
      this.form.redelivery_port_id = this.form.redelivery_port_id || '',
      this.form.min_duration_unit = this.form.min_duration_unit || '',
      this.form.max_duration_unit = this.form.max_duration_unit || '',
      this.form.tc_out_pricings.forEach(tc_out_pricing => {
        tc_out_pricing.rate_type = tc_out_pricing.rate_type || ''
        tc_out_pricing.pricing_code_id = tc_out_pricing.pricing_code_id || ''
        tc_out_pricing.lock = tc_out_pricing.lock || ''
        tc_out_pricing.duration_unit = tc_out_pricing.duration_unit || ''
      })
      this.form.tc_out_brokers.forEach(tc_out_broker => {
        tc_out_broker.broker_id = tc_out_broker.broker_id || ''
        tc_out_broker.rate_type = tc_out_broker.rate_type || ''
        tc_out_broker.payment_method = tc_out_broker.payment_method || ''
        tc_out_broker.is_claim_commissionable = tc_out_broker.is_claim_commissionable || ''
      })
      this.form.tc_out_expenses.forEach(tc_out_expense => {
        tc_out_expense.expense_revenue_code_id = tc_out_expense.expense_revenue_code_id || ''
        tc_out_expense.rate_type = tc_out_expense.rate_type || ''
      })
      this.isLoading = false
    },
    async update() {
      try {
        this.isLoading = true
        await axios.post(`/tc_outs`, this.form)
        this.isLoading = false
        this.$router.push('/chartering/tc-outs')
      } catch(e) {
        this.isLoading = false
      }
    },
    addEmptyTcOutPricing() {
      this.form.tc_out_pricings.push({
        hire_rate: "",
        rate_type: "",
        pricing_code_id: "",
        lock: "",
        from_gmt: "",
        to_gmt: "",
        duration: "",
        duration_unit: "",
        tcl_insurance_rate: "",
        comments: "",
      });
    },
    deleteTcOutPricing(tcOutPricing) {
      this.form.tc_out_pricings = this.form.tc_out_pricings.filter(
        (ar) => ar != tcOutPricing
      );
    },
    addEmptyTcOutBroker() {
      this.form.tc_out_brokers.push({
        broker_id: "",
        rate: "",
        rate_type: "",
        payment_method: "",
        is_claim_commissionable: "",
        from_gmt: "",
        to_gmt: "",
        remarks: "",
      });
    },
    deleteTcOutBroker(tcOutBroker) {
      this.form.tc_out_brokers = this.form.tc_out_brokers.filter(
        (ar) => ar != tcOutBroker
      );
    },
    addEmptyTcOutExpense() {
      this.form.tc_out_expenses.push({
        expense_revenue_code_id: "",
        rate_type: "",
        rate: "",
        is_commission_applicable: false,
        from_gmt: "",
        to_gmt: "",
        remarks: "",
      });
    },
    deleteTcOutExpense(tcOutExpense) {
      this.form.tc_out_expenses = this.form.tc_out_expenses.filter(
        (ar) => ar != tcOutExpense
      );
    },
  }
}
</script>