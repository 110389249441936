<template>
    <TcOutBill></TcOutBill>
</template>

<script>
import TcOutBill from '../../tc-ins/make-payment.vue'
export default {
    components : {
        TcOutBill
    }
}
</script>