<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            <back-button link="/cargo-names"></back-button> Upload Cargo Names
          </h5>
        </div>
        <!-- Breadcrumb -->
        <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
          <ol class="breadcrumb">
            <li><router-link to="/">Dashboard</router-link></li>
            <li>Data Center</li>
            <li class="active">Upload Cargo Names</li>
          </ol>
        </div>
        <!-- /Breadcrumb -->
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                &nbsp;&nbsp;
                <a
                  :href="`${mediaUrl}samples/cargos-names-sample-data.xlsx`"
                  target="_blank"
                >
                  [ Download Sample Excel ]
                </a>
                <input
                  type="file"
                  id="file"
                  name="file"
                  ref="file"
                  accept="application/ms-excel"
                  multiple
                />

                <br />
                <a href="javascript:void" @click="truncatePendingDocuments"
                  >Truncate List</a
                >
                <br /><br />
                <span style="color: red"> {{ message }} </span>
                <span v-if="isLoading"> Uploading...</span>

                <div class="form-footer" v-if="crudeCargoNames.length == 0">
                  <button
                    class="btn btn-primary btn-block"
                    @click="handleFileUpload"
                  >
                    Upload file
                  </button>
                </div>
                <div v-if="crudeCargoNames.length != 0">
                  <div class="form-footer">
                    <button
                      class="btn btn-success btn-block"
                      @click="processFile"
                    >
                      Process file
                    </button>
                  </div>
                </div>
                <!-- <div
                  class="form-footer"
                  v-if="conflicTargets.length != 0"
                  
                > -->
                <!-- <download-excel
                    :title="title"
                    class="btn btn-success btn-block"
                    :data="conflict_targets_excel"
                    :fields="excel_fields"
                    worksheet="ConflictUsers"
                    name="Conflict_Target.xls"
                  >
                    <a href="#" class="download">Export Conflics</a>
                  </download-excel> -->
                <!-- <button
                    class="btn btn-success btn-block"
                    @click="processFile"
                  >
                    Process file
                  </button> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Short Name</th>
                        <th>Full Name</th>
                        <th>CargoName Group</th>
                        <th>Inactive</th>
                        <th>UN Class</th>
                        <th>Is Dangerous</th>
                        <th>API</th>
                        <th>Product Code</th>
                        <th>Special Handling Required</th>
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="5"
                      ></loading-table>
                      <tr
                        v-for="(crudeCargoName, at) in crudeCargoNames"
                        :key="`crudeCargoName${at}`"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ crudeCargoName.short_name }}</td>
                        <td>{{ crudeCargoName.full_name }}</td>
                        <td>{{ crudeCargoName.cargo_group }}</td>
                        <td>{{ crudeCargoName.inactive }}</td>
                        <td>{{ crudeCargoName.un_class }}</td>
                        <td>{{ crudeCargoName.Is_Dangerous }}</td>
                        <td>{{ crudeCargoName.api }}</td>
                        <td>{{ crudeCargoName.product_code }}</td>
                        <td>{{ crudeCargoName.special_handling_equired }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "uploadTargets",
  data() {
    return {
      // crudeCargoNames:{
      //
      // },
      crudeCargoNames: [],
      // conflicTargets: [],
      company_id: "",
      month: "",
      year: "",
      message: "",
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      months: [],
      years: [],
      // excel_fields: {
      //   "Sr No": "sr_no",
      //   Source: "source",
      //   "Employee Code": "employee_code",
      // },
      // conflict_targets_excel: [],
    };
  },
  mounted() {
    this.site_id = this.site.id;
    this.getData();
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let crudeCargoNames = await axios.get(`/crude_cargo_names`);
      this.crudeCargoNames = crudeCargoNames.data.data;
      this.count = crudeCargoNames.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.cargo_names = this.$refs.file.files[0];
      // this.month = this.crudeCargoNames.month;
      // this.year = this.crudeCargoNames.year;

      let formData = new FormData();

      // formData.append("month", this.month);
      formData.append("site_id", this.site_id);
      formData.append("CargoNameData", this.cargo_names);
      await axios
        .post("upload_cargo_name", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudeCargoNames = response.data.data;
          this.isLoading = false;
          alert("Uploaded Successfully");
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_cargo_name");
        // if (conflicTargets != "") {
        //   // console.log('Conflicts');
        //   this.conflicTargets = conflicTargets.data.data;
        //   this.exportToExcel();
        alert("Processed Successfully");
        // }
        await axios.get("truncate_cargo_names");
        this.crudeCargoNames = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_cargo_names");
      alert("Truncated Successfully");
      this.crudeCargoNames = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_cargo_names/${$id}`);
        this.crudeCargoNames = this.crudeCargoNames.filter((d) => d.id != $id);
      }
    },
    // exportToExcel() {
    //   this.title = "Conflict User Master";
    //   this.conflict_targets_excel = [];
    //   let sr_no = 1;
    //   this.conflicTargets.forEach((user) => {
    //     this.conflict_targets_excel.push({
    //       sr_no: sr_no,
    //       source: user.source,
    //       employee_code: user.store_code ? user.store_code : user.employee_code,
    //     });
    //     sr_no++;
    //   });
    //   console.log(this.conflict_targets_excel);
    // },
  },
};
</script>
