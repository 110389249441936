<template>
  <!-- Main Content -->
  <div class="page-wrapper">
    <div class="container-fluid">
      <!-- Title -->
      <div class="row heading-bg">
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
          <h5 class="txt-dark">
            Voyages
            <my-search
              :search="searchText"
              placeholder="Search here..."
              @searchData="getData"
            ></my-search>
            <add-button link="/voyages/create"></add-button>
          </h5>
        </div>
      </div>
      <!-- /Title -->

      <!-- Row -->
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default card-view">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="table-wrap">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Vessel</th>
                        <th>Operation Type</th>
                        <th>Fixture No</th>
                        <th>TC IN Code</th>
                        <th>TC Out Code</th>
                        <th>Daily Cost</th>
                        <!-- <th>Speed Laden</th>
                        <th>Speed Ballast</th>
                        <th>Remarks</th>
                        <th>Notes</th>
                        <th>Ownership</th>
                        <th>Vessel Type</th> -->
                        <!-- <th>DWT</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <loading-table
                        :isLoading="isLoading"
                        cols="10"
                      ></loading-table>
                      <router-link
                        v-for="(voyage, at) in voyages"
                        :key="`voyage${at}`"
                        :to="`/voyages/${voyage.id}`"
                        tag="tr"
                      >
                        <td>{{ serialNoStarting + at + 1 }}</td>
                        <td>{{ voyage.vessel.vessel_name }}</td>
                        <td>
                          {{
                            voyage.operation_type
                              ? voyage.operation_type.type
                              : ""
                          }}
                        </td>
                        <td>{{ voyage.fixture_no }}</td>
                        <td>{{ voyage.tc_in.tc_code }}</td>
                        <td>
                          {{ voyage.tc_out ? voyage.tc_out.tc_code : "" }}
                        </td>
                        <td>{{ voyage.daily_hire }}</td>
                        <!-- <td>{{ voyage.laden_speed }}</td>
                        <td>{{ voyage.ballast_speed }}</td>
                        <td>{{ voyage.remarks }}</td>
                        <td>{{ voyage.notes ? voyage.notes : "" }}</td>
                        <td>
                          {{ voyage.owner ? voyage.owner.full_name : "" }}
                        </td>
                        <td>
                          {{
                            voyage.voyage_type
                              ? voyage.voyage_type.voyage_type
                              : ""
                          }}
                        </td>
                        <td>{{ voyage.dwt_mt }} MT</td> -->
                      </router-link>
                    </tbody>
                  </table>
                </div>
                <pagination :count="count" @submit="getData"></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Row -->
    </div>
  </div>
  <!-- /Main Content -->
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "voyages",
  data() {
    return {
      voyages: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
      searchText: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(searchText, page = 1) {
      this.isLoading = true;
      let voyages = await axios.get(
        `/voyages?page=${page}&rowsPerPage=${this.rowsPerPage}&search=${searchText}`
      );
      this.voyages = voyages.data.data;
      this.count = voyages.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
  },
};
</script>
