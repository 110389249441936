<template>
  <section>
    <!-- Other Expenses Modal -->
    <div id="otherExpenseModal" class="modal">
      <!-- Other Expenses Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Other Expenses</h4>
        </div>
        <div class="modal-body">
          <!-- <div class="editable-table-wrapper"> -->
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(otherExpense, oe) in form.otherExpenses.filter(
                  (oE) => oE.expense_type == expenseType
                )"
                :key="`otherExpense${oe}`"
              >
                <td @click="deleteExpense(otherExpense)">
                  <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                  {{ oe + 1 }}.
                </td>
                <td>
                  <input
                    style="width: 400px !important"
                    disableFormControl="true"
                    v-uppercase
                    type="text"
                    placeholder="Description"
                    v-model="otherExpense.description"
                  />
                </td>
                <td>
                  <input
                    style="width: 400px !important"
                    v-uppercase
                    type="number"
                    placeholder="Amount"
                    v-model="otherExpense.cost"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="10" align="center" @click="addEmptyExpense()">
                  Add New Row
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="saveOtherExpenses"
          >
            Save
          </button>
          <button type="button" class="btn btn-default" @click="closePortModal">
            Close
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="panel panel-default card-view pandl">
        <div class="panel-wrapper collapse in">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-wrap">
                  <table class="table table-hover table-striped">
                    <caption>
                      P&L
                    </caption>
                    <thead>
                      <tr>
                        <th>All</th>
                        <th class="right">Estimated</th>
                      </tr>
                    </thead>
                    <tbody v-if="form.operation_type_id != 4">
                      <tr>
                        <td colspan="2">
                          <b><u>REVENUES</u></b>
                        </td>
                      </tr>
                      <tr>
                        <td>Freight</td>
                        <td class="right">{{ form.freight_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td>Freight Comm.</td>
                        <td class="right">
                          - {{ form.freight_commission_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b><u>Total Revenues</u></b>
                        </td>
                        <td class="right">{{ totalRevenue | round2 }}</td>
                      </tr>
                    </tbody>

                    <tbody v-else>
                      <tr>
                        <td>
                          <b><u>REVENUES</u></b>
                        </td>
                      </tr>
                      <tr>
                        <td>TCO Hire</td>
                        <td class="right">{{ form.Ohire_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td>TCO Hire Add Comm.</td>
                        <td class="right">
                          -{{ form.Ohire_commission_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td>TCO CVE</td>
                        <td class="right">{{ form.Ocve_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td @click="openOtherExpenseModal('REVENUE')">
                          <u>TCO Misc. Revenue</u>
                        </td>
                        <td class="right">
                          {{ form.Oother_expenses_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b><u>Total Revenues</u></b>
                        </td>
                        <td class="right">{{ totalRevenue | round2 }}</td>
                      </tr>
                    </tbody>

                    <br />

                    <tbody>
                      <tr>
                        <td colspan="2">
                          <b><u>EXPENSES</u></b>
                        </td>
                      </tr>
                      <tr>
                        <td>Hire</td>
                        <td class="right">{{ form.hire_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td>Hire Add Comm.</td>
                        <td class="right">
                          -{{ form.hire_commission_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td>Bunkers</td>
                        <td class="right">{{ form.bunker_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td>CVE</td>
                        <td class="right">{{ form.cve_cost | round2 }}</td>
                      </tr>
                      <tr>
                        <td>Port Expenses</td>
                        <td class="right">
                          {{ form.port_expenses_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td @click="openOtherExpenseModal('EXPENSE')">
                          <u>Misc. Expenses</u>
                        </td>
                        <td class="right">
                          {{ form.other_expenses_cost | round2 }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b><u>Total Expenses</u></b>
                        </td>
                        <td class="right">{{ totalExpense | round2 }}</td>
                      </tr>
                    </tbody>

                    <br />

                    <tbody>
                      <tr>
                        <td>Voyage Result</td>
                        <td class="right">{{ voyageResult | round2 }}</td>
                      </tr>
                      <tr>
                        <td>Profit/Loss</td>
                        <td class="right">
                          <h5>{{ voyageResult | round2 }}</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>Net Voyage Days</td>
                        <td class="right">
                          {{ parseFloat(form.voyage_days) }}
                        </td>
                      </tr>
                      <!-- <tr>
                        <td>Daily Profit/Loss</td>
                        <td>4</td>
                      </tr> -->
                    </tbody>

                    <br />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
export default {
  data() {
    return { expenseType: "" };
  },
  props: ["form"],
  computed: {
    totalRevenue() {
      if (this.form.operation_type_id != 4)
        return this.form.freight_cost - this.form.freight_commission_cost;
      else
        return (
          parseFloat(this.form.Ohire_cost) -
          parseFloat(this.form.Ohire_commission_cost) +
          parseFloat(this.form.Ocve_cost) +
          parseFloat(this.form.Oother_expenses_cost)
        );
    },
    totalExpense() {
      return (
        parseFloat(this.form.hire_cost) -
        parseFloat(this.form.hire_commission_cost) +
        parseFloat(this.form.bunker_cost) +
        parseFloat(this.form.cve_cost) +
        parseFloat(this.form.port_expenses_cost) +
        parseFloat(this.form.other_expenses_cost)
      );
    },
    voyageResult() {
      return this.totalRevenue - this.totalExpense;
    },
  },
  methods: {
    addEmptyExpense() {
      this.form.otherExpenses.push({
        expense_type: this.expenseType,
        description: "",
        cost: 0,
      });
    },
    deleteExpense(expense) {
      this;
      this.form.otherExpenses = this.form.otherExpenses.filter(
        (oe) => oe != expense
      );
    },
    async openOtherExpenseModal(expenseType) {
      this.expenseType = expenseType;
      var modal = document.getElementById("otherExpenseModal");
      modal.style.display = "block";
    },
    closePortModal() {
      var modal = document.getElementById("otherExpenseModal");
      modal.style.display = "none";
    },
    saveOtherExpenses() {
      this.$emit("calculateCost");
      this.closePortModal();
    },
  },
};
</script>

<style scoped>
.right {
  float: right !important;
}
</style>